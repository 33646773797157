const temp = "ADDING_FILES";

export default {
  OPEN_MODAL_BUTTON: `${temp}_OPEN_MODAL_BUTTON`,
  OPEN_MODAL_DESC: `${temp}_MODAL_DESC`,
  MODAL: `${temp}_MODAL`,
  MODAL_TITLE: `${temp}_MODAL_TITLE`,
  MODAL_DESC: `${temp}_MODAL_DESC`,
  MODAL_MAX_FILE_DESC: `${temp}_MODAL_MAX_FILE_DESC`,
  MODAL_SELECT_BUTTON: `${temp}_MODAL_SELECT_BUTTON`,
  MODAL_UPLOAD_BUTTON: `${temp}_MODAL_UPLOAD_BUTTON`,
  FILE_ITEM: `${temp}_FILE_ITEM`,
  FILE_REMOVE_BUTTON: `${temp}_FILE_REMOVE_BUTTON`,
  FILE_DOWNLOAD: `${temp}_FILE_DOWNLOAD`,
  FILE_IMAGE: `${temp}_FILE_IMAGE`,
  FILE_ICON: `${temp}_FILE_ICON`,
  FILE_NAME: `${temp}_FILE_NAME`,
  FILE_INFO_BUTTON: `${temp}_FILE_INFO_BUTTON`,
  FILE_CLOSE_UPLOAD_BUTTON: `${temp}_FILE_CLOSE_UPLOAD_BUTTON`,
  FILE_REFRESH_UPLOAD_BUTTON: `${temp}_FILE_REFRESH_UPLOAD_BUTTON`,
  FILE_ALERT_MODAL_TITLE: `${temp}_FILE_ALERT_MODAL_TITLE`,
  FILE_ALERT_MODAL_DESC: `${temp}_FILE_ALERT_MODAL_DESC`,
  FILE_ALERT_MODAL_BUTTON: `${temp}_FILE_ALERT_MODAL_BUTTON`,
  FILE_APPROVE_DELETE_MODAL_TITLE: `${temp}_FILE_APPROVE_DELETE_MODAL_TITLE`,
  FILE_APPROVE_DELETE_MODAL_DESC: `${temp}_FILE_APPROVE_DELETE_MODAL_DESC`,
  FILE_APPROVE_DELETE_BUTTON_ACCEPT: `${temp}_FILE_APPROVE_DELETE_BUTTON_ACCEPT`,
  FILE_APPROVE_DELETE_BUTTON_DECLINE: `${temp}_FILE_APPROVE_DELETE_BUTTON_DECLINE`
};
