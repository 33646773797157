import React from "react";
import { IconWithText, Text } from "ibolit-ui";
import { getDHMDiff, getTzDate } from "~/utils/dateUtils";
import {
  pluralizeDays,
  pluralizeHours,
  pluralizeMinutes
} from "~/utils/pluralize";
import { CHAT_HEADER } from "~/locators/chats";
import {
  DAILY_SUBSCRIPTION_DURATION,
  DURATION_ONE_MINUTE,
  EXPIRED_TEXT,
  WEEKLY_SUBSCRIPTION_DURATION
} from "~/store/chat/chatConsts";
import { useTimer } from "~/hooks/useTimer";

const textProps = {
  testid: CHAT_HEADER.STATUS,
  variant: "caption",
  colorVariant: "tertiary"
};

const iconWithText = {
  testid: textProps.testid,
  textVariant: textProps.variant,
  iconVariant: "time",
  type: "secondary"
};

const PayInfo = ({ tariff, user }) => {
  const { duration, end_at, is_subscription, name, can_close } = tariff || {};
  const targetDate = useTimer(DURATION_ONE_MINUTE);
  const formattedText = `${name}, ${getLeftTime(
    getTzDate(end_at),
    targetDate,
    true
  ).toLowerCase()}`;

  if (user.is_chat_free) return <></>;

  if (can_close) {
    return <IconWithText {...iconWithText} text={formattedText} />;
  }

  if (is_subscription) {
    return <Text {...textProps}>{durationToText(duration)}</Text>;
  }

  const leftTimeText = end_at && getLeftTime(getTzDate(end_at), targetDate);
  const isExpired = tariff === null;

  return (
    <div className="row">
      <IconWithText
        {...iconWithText}
        textColorVariant={isExpired ? "error" : "tertiary"}
        iconColor={isExpired ? "var(--pink)" : "var(--grey)"}
        text={isExpired ? EXPIRED_TEXT : leftTimeText}
      />
    </div>
  );
};

const getLeftTime = (date, targetDate) => {
  if (!date) return "";
  if (targetDate.isAfter(date)) return EXPIRED_TEXT;
  const [days, hours, minutes] = getDHMDiff(date, targetDate);

  let result = [];
  if (days > 0) {
    result = [pluralizeDays(days)];
  } else {
    hours > 0 && result.push(pluralizeHours(hours % 24));
    minutes > 0 && result.push(pluralizeMinutes(minutes));
    hours === 0 && minutes === 0 && result.push("< 1 минуты");
  }

  return `осталось ${result.join(" ")}`;
};

export const durationToText = duration => {
  if (duration <= DAILY_SUBSCRIPTION_DURATION) {
    return "Ежедневная подписка";
  }
  if (duration <= WEEKLY_SUBSCRIPTION_DURATION) {
    return "Еженедельная подписка";
  }
  return "Ежемесячная подписка";
};

export default PayInfo;
