import React from "react";
import { Icon, Text } from "ibolit-ui";
import styles from "./Mobile.scss";
import googlePlay from "~/assets/images/google-play.png";
import appStore from "~/assets/images/app-store.png";
import mobileStub from "~/assets/images/mobile-stub.png";
import { ANDROID, appLinks } from "~/utils/mobile";

const Mobile = ({ os }) => {
  const buttonLink = appLinks[os];
  const buttonImage = os === ANDROID ? googlePlay : appStore;
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.topBg} />
        <img src={mobileStub} alt="mobile app" className={styles.bgImage} />
      </div>
      <div className={styles.bottom}>
        <Text variant="h3" className={styles.header}>
          Скачайте приложение iBolit
        </Text>
        <Text variant="desc" className={styles.desc}>
          Будьте всегда на связи с врачом
        </Text>
        <a href={buttonLink} className={styles.panel}>
          <ul className={styles.facilities}>
            <Facility icon="webcam">
              <div>Видео и аудио</div>
              <div>консультация</div>
            </Facility>
            <Facility icon="msg-bubble">
              <div>Чат</div>
              <div>с врачом</div>
            </Facility>
            <Facility icon="gift">
              <div>Акции</div>
              <div>клиники</div>
            </Facility>
            <Facility icon="hospital">
              <div>Запись</div>
              <div>на прием</div>
            </Facility>
          </ul>
          <span className={styles.storeWrap}>
            <span
              className={styles.storeButton}
              style={{ backgroundImage: `url(${buttonImage})` }}
            />
          </span>
        </a>
      </div>
    </div>
  );
};
const Facility = ({ children, icon }) => (
  <li className={styles.facility}>
    <Icon variant={icon} />
    <div className={styles.facilityCaption}>{children}</div>
  </li>
);

export default Mobile;
