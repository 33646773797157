import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import UserItem from "~/components/UserItem";

const subscriptionItemSelector = ({ users }, { doctor }) =>
  users.getIn(["items", doctor], {});

const SubscriptionsListItem = ({ key, item, onClick }) => {
  const subscriptionItemMemoized = useCallback(subscriptionItemSelector, []);

  const user = useSelector(state => subscriptionItemMemoized(state, item));

  const handleClick = useCallback(() => onClick(item), []);

  if (item.deleted) return null;

  return (
    <UserItem key={key} user={user} onClick={handleClick} showIsNewStatus />
  );
};

export default SubscriptionsListItem;
