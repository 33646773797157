import React from "react";
import PropTypes from "prop-types";
import { IconWithText } from "ibolit-ui";
import { CHAT_FOOTER } from "~/locators/chats";
import styles from "./ChatAttachList.scss";

const ChatAttachList = ({ onSendFileClick }) => (
  <ul className={styles.ul}>
    <IconWithText
      className={styles.button}
      onClick={onSendFileClick}
      testid={CHAT_FOOTER.BUTTON_SEND_FILE}
      iconVariant="file"
      text="Отправить файл"
      hasHoverEffect
    />
  </ul>
);

ChatAttachList.defaultProps = {
  user: PropTypes.object.isRequired,
  onSendFileClick: PropTypes.func.isRequired
};

export default ChatAttachList;
