import React from "react";
import cn from "classnames";
import styles from "./ModalHeader.scss";
import { Text, Container, ImageLoader } from "ibolit-ui";
import { pluralizeYears } from "~/utils/pluralize";
import { getTypeData } from "~/modals/ChooseDateTime/Utils";
import { formatDateAndTime } from "~/utils/dateUtils";
import { useSelector } from "react-redux";
import { selectSpecialization } from "~/store/modals/specializations/selectors";

const defaultTestId = "ModalHeader";

export const ModalHeader = ({
  doctor,
  noItem,
  slotType,
  price,
  startAt,
  specialty
}) => {
  const { title: specialtyTitle } = useSelector(selectSpecialization);
  if (!doctor) {
    return null;
  }
  const typeData = getTypeData(slotType);

  return (
    <div
      className={noItem ? "" : cn(styles.item, styles.panel)}
      data-testid={"choose_service_doctor_container"}
    >
      <Container className={styles.doctor}>
        <ImageLoader
          src={doctor.avatar}
          className={styles.avatar}
          testid={`${defaultTestId}-avatar`}
        />
        <div className={styles.content}>
          <Text
            className={styles.info}
            variant="desc"
            colorVariant="tertiary"
            testid={`${defaultTestId}-info`}
          >
            {specialtyTitle || specialty}
          </Text>
          <Text
            className={styles.title}
            variant="h3"
            title={doctor.full_name}
            testid={`${defaultTestId}-fullName`}
          >
            {doctor.full_name}
          </Text>
          <Container>
            {doctor.experience ? (
              <Text
                className={styles.info}
                weight="bold"
                variant="h5"
                colorVariant="tertiary"
                testid={`${defaultTestId}-experience`}
              >
                Опыт работы {pluralizeYears(doctor.experience)}
              </Text>
            ) : null}
            <Text
              className={styles.category}
              variant="h5"
              weight="normal"
              colorVariant="tertiary"
              testid={`${defaultTestId}-category`}
            >
              {doctor.category}
            </Text>
          </Container>
        </div>
      </Container>
      {typeData && price !== undefined ? (
        <>
          <div className={styles.line} />
          <Container>
            <Container direction="column">
              <Text
                colorVariant="tertiary"
                variant="body1"
                testid={`${defaultTestId}-type`}
              >
                Тип приема
              </Text>
              <Text variant="h4" testid={`${defaultTestId}-type-title`}>
                {typeData.title === "Написать в чат"
                  ? "Чат с врачом"
                  : typeData.title}
              </Text>
            </Container>
            <Container direction="column" className={styles.priceContainer}>
              <Text
                colorVariant="tertiary"
                variant="body1"
                testid={`${defaultTestId}-price-title`}
              >
                Стоимость
              </Text>
              <Text variant="h4" testid={`${defaultTestId}-price`}>
                {price} ₽
              </Text>
            </Container>
            {startAt ? (
              <Container direction="column" className={styles.priceContainer}>
                <Text
                  colorVariant="tertiary"
                  variant="body1"
                  testid={`${defaultTestId}-date-title`}
                >
                  Дата и время приёма
                </Text>
                <Text variant="h4" testid={`${defaultTestId}-date`}>
                  {formatDateAndTime(new Date(startAt))}
                </Text>
              </Container>
            ) : null}
          </Container>
        </>
      ) : null}
    </div>
  );
};
