import React from "react";
import { Text } from "ibolit-ui";
import styles from "./ConsultationsListTitle.scss";

const ConsultationsListTitle = ({ item: { date } }) => (
  <Text
    variant="h6"
    className={styles.text}
    colorVariant={/сегодня/i.test(date) ? "error" : "tertiary"}
  >
    {date}
  </Text>
);

export default ConsultationsListTitle;
