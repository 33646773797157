import { useEffect, useState } from "react";
import moment from "moment-timezone";

export const useTimer = duration => {
  const [date, setDate] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(moment());
    }, duration);
    return () => {
      clearInterval(interval);
    };
  }, [duration]);

  return date;
};
