import React, { useEffect } from "react";
import { Box, Button, Text, Icon, Container } from "ibolit-ui";
import { useHistory } from "react-router-dom";
import success from "../../assets/images/success.png";
import styles from "./Statuses.scss";
import { useCloseModal } from "~/hooks/useModal";
import { NEW_CONSULTATION } from "~/locators/newConsultation";
import { ModalHeader } from "~/components/ModalHeader/ModalHeader";

const defaultTestId = "StatusSuccess";

export const NEW_CONSULTATION_SUCCESS_MODAL = "newConsultationSuccessModal";

export default function StatusSuccess({
  consultationId,
  doctor,
  price,
  slotType,
  startAt
}) {
  const handleClose = useCloseModal(NEW_CONSULTATION_SUCCESS_MODAL);

  return (
    <Box
      className={styles.status_box}
      testId={NEW_CONSULTATION.SUCCESS_STATUS_MODAL}
      isModalBlock
    >
      <div className={styles.header}>
        <Text tag="span" variant="h2" testid="header_date_time_modal_window">
          Запись к врачу
        </Text>
        <div
          className={styles.close}
          data-testid="close_date_time_modal_window"
        >
          <Icon variant="close" fill="var(--grey)" onClick={handleClose} />
        </div>
      </div>
      <ModalHeader
        doctor={doctor}
        price={price}
        slotType={slotType}
        startAt={startAt}
        specialty={doctor.specialty || doctor.specialties[0]}
      />
      <div className={styles.statusImgContainer}>
        <img
          src={success}
          alt="Консультация назначена"
          className={styles.status_img}
        />
      </div>
      <Text
        tag="h2"
        variant="h2"
        maxLength={60}
        className={styles.status_header}
        testid={NEW_CONSULTATION.SUCCESS_STATUS_DESC}
      >
        Вы успешно создали заявку на консультацию
      </Text>
      <Text
        tag="div"
        variant="body1"
        colorVariant={"tertiary"}
        maxLength={60}
        className={styles.status_desc}
        testid={`${defaultTestId}-you-can-visit`}
      >
        Вы можете посетить консультацию в мобильном приложении.
      </Text>
      <Button
        fullWidth
        colorVariant="patient"
        onClick={handleClose}
        testid={NEW_CONSULTATION.SUCCESS_STATUS_BUTTON}
      >
        Хорошо
      </Button>
    </Box>
  );
}
