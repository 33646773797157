import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import styles from './Badge.scss';

const Badge = ({ children, variant, className, fill }) => (
  <div className={cn(styles.badge, styles[variant], className, { [styles.fill]: fill })}>
    <Text variant="captionUpper" colorVariant="inherit">
      {children}
    </Text>
  </div>
);

Badge.defaultProps = {
  variant: 'primary',
  fill: false,
};

Badge.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  fill: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Badge;
