import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Scrollbar } from "ibolit-ui";
import usersActions from "~/store/users/userActions";
import Loading from "~/components/Loading/Loading";
import PublicProfileComponent from "./components/PublicProfile";
import { CookieList, getCookie, setCookie } from "~/utils/cookies";
import metricsApi from "~/api/metricsApi";

const PublicProfile = ({
  user,
  userId,
  variant,
  checkNew,
  isRequest,
  getDetailInfo
}) => {
  // Если пациент пришел с пуша (рекомендация следующей консультации), нужно сохранить инфу
  // для аналитики
  const location = useLocation();
  useEffect(() => {
    let params = new URLSearchParams(location.search.substring(1));
    let recommendationId = params.get("recommendationId");
    let problemId = params.get("problemId");

    if (problemId) {
      const cookieRecommendationId = getCookie(CookieList.RECOMMENDATION_ID);
      // проверка, чтобы отправить запрос 1 раз (до сохранения куки)
      if (!cookieRecommendationId) {
        metricsApi.sendMetrics({
          event: "Opened push",
          event_properties: {
            problemId
          }
        });
      }

      setCookie(CookieList.RECOMMENDATION_ID, recommendationId, {
        "max-age": 3600
      });
    }
  }, []);

  useEffect(() => {
    getDetailInfo(userId);
  }, [userId]);

  const isNew = user && user.is_new;

  useEffect(() => {
    isNew && checkNew(userId);
  }, [isNew]);

  return (
    <Scrollbar>
      {user && (
        <PublicProfileComponent
          key={userId}
          userId={userId}
          variant={variant}
          user={user}
        />
      )}
      <Loading showLoading={!user && isRequest} />
    </Scrollbar>
  );
};

PublicProfile.defaultProps = {
  variant: "default"
};

PublicProfile.propTypes = {
  isRequest: PropTypes.bool,
  getDetailInfo: PropTypes.func,
  user: PropTypes.object,
  userId: PropTypes.number,
  variant: PropTypes.oneOf(["default", "inChat"])
};

const mapStateToProps = ({ users: { items, infoFetching } }, { userId }) => {
  const user = items[userId];
  return {
    isRequest: infoFetching,
    user
  };
};

const mapDispatchToProps = dispatch => ({
  getDetailInfo: userId => dispatch(usersActions.userInfoRequest(userId)),
  checkNew: userId => dispatch(usersActions.userCheckNewRequest(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicProfile);
