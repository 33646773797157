import Immutable from "seamless-immutable";
import { createSelector } from "reselect";

const baseTypes = [
  "REQUEST_SEND",
  "REQUEST_SUCCESS",
  "REQUEST_FAILED",
  "UPDATE_ITEMS",
  "CHANGE_MODE"
];
const makeRequestTypes = name => {
  if (__DEV__ && !name) {
    throw new Error("makeRequestTypes не передано имя для создания типов");
  }
  return baseTypes.reduce(
    (acc, type) => ({
      ...acc,
      [type]: `${name.toLowerCase()}/${type}`
    }),
    {}
  );
};

const partialState = {
  initialFetched: false,
  canLoadMore: true,
  isFetching: true,
  errors: {},
  order: []
};
const makeInitialState = modes => {
  if (__DEV__ && !modes.length) {
    throw new Error(
      "makeInitialState не передан массив modes для создания частей стейта"
    );
  }
  return Immutable({ items: {}, mode: modes[0] }).merge(
    modes.reduce(
      (acc, current) => ({
        ...acc,
        [current]: Immutable(partialState)
      }),
      {}
    )
  );
};

const checkMode = (type, payload, modes) => {
  if (!modes.includes(payload.mode)) {
    throw new Error(`${type} не содержит mode`);
  }
};

const makeRequestActions = (types, modes) => {
  if (__DEV__ && !modes.length) {
    throw new Error(
      "makeRequestActions не переданно имя или массив для создания частей стейта"
    );
  }

  return {
    requestSend: payload => {
      if (__DEV__) {
        checkMode(types.REQUEST_SEND, payload, modes);
      }
      return {
        type: types.REQUEST_SEND,
        payload
      };
    },
    requestSuccess: payload => {
      if (__DEV__) {
        checkMode(types.REQUEST_SUCCESS, payload, modes);
      }
      return {
        type: types.REQUEST_SUCCESS,
        payload
      };
    },
    requestFailed: payload => {
      if (__DEV__) {
        checkMode(types.REQUEST_FAILED, payload, modes);
      }
      return {
        type: types.REQUEST_FAILED,
        payload
      };
    },
    updateItems: items => ({
      type: types.UPDATE_ITEMS,
      payload: { items }
    }),
    changeMode: mode => ({
      type: types.CHANGE_MODE,
      mode
    })
  };
};

const sendReducer = (state, action) => {
  const { mode } = action.payload;
  return state.update(mode, modeState =>
    modeState.merge({
      isFetching: true,
      errors: {}
    })
  );
};

const successReducer = (state, action) => {
  const {
    payload: { mode, order, limit, page }
  } = action;
  return state.update(mode, modeState =>
    modeState
      .merge({
        initialFetched: true,
        isFetching: false,
        canLoadMore: order.length >= limit,
        errors: {}
      })
      .update("order", oldOrder => [
        ...oldOrder.slice(0, (page - 1) * limit),
        ...order
      ])
  );
};

const failedReducer = (state, action) => {
  const { mode, errors } = action.payload;
  return state.update(mode, modeState =>
    modeState.merge({
      isFetching: false,
      canLoadMore: false,
      errors
    })
  );
};

const updateReducer = (state, action) =>
  state.merge({ items: action.payload.items }, { deep: true });

const changeModeReducer = (state, action) => state.merge({ mode: action.mode });

const makeRequestReducer = (types, initialState) => (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.REQUEST_SEND:
      return sendReducer(state, action);
    case types.REQUEST_SUCCESS:
      return successReducer(state, action);
    case types.REQUEST_FAILED:
      return failedReducer(state, action);
    case types.UPDATE_ITEMS:
      return updateReducer(state, action);
    case types.CHANGE_MODE:
      return changeModeReducer(state, action);

    default:
      return state;
  }
};

const makeSelector = name => {
  const getState = state => state[name];
  const getItems = createSelector(getState, targetState => targetState.items);
  const getModeState = createSelector(
    getState,
    getItems,
    (_, mode) => mode,
    (targetState, mapItems, mode) => {
      const {
        order,
        initialFetched,
        isFetching,
        canLoadMore,
        page,
        errors
      } = targetState[mode];
      const hasErrors = !!Object.keys(errors).length;
      const items = order.map(id => mapItems[id]).filter(Boolean);
      return {
        items,
        isFetching,
        initialFetched,
        canLoadMore: !isFetching && canLoadMore,
        page,
        hasErrors,
        isEmpty: !items.length && !hasErrors
      };
    }
  );

  return (state, mode) => getModeState(state, mode);
};

export default (name, modes) => {
  const types = makeRequestTypes(name);
  const state = makeInitialState(modes);
  return {
    types,
    state,
    selector: makeSelector(name),
    actions: makeRequestActions(types, modes),
    reducer: makeRequestReducer(types, state)
  };
};
