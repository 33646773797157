import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import cn from 'classnames';

import styles from './ImageLoader.scss';

const _loaded = {};

const ImageLoader = ({
  className,
  asBackground,
  loadingClassName,
  loadedClassName,
  onLoad,
  src,
  style,
  testid,
}) => {
  const [loaded, setLoaded] = useState(_loaded[src]);
  const handleLoad = useCallback(() => {
    _loaded[src] = true;
    setLoaded(true);
    if (onLoad) onLoad();
  }, []);

  const itemProps = {
    style: style || {},
  };

  if (loaded) {
    if (asBackground) {
      itemProps.style.backgroundImage = `url(${src}`;
    } else {
      itemProps.src = src;
    }
  }

  const Item = asBackground ? 'div' : 'img';

  return (
    <>
      <Item
        className={cn(className, {
          [styles.bgImageContainer]: asBackground,
          [styles.loaded]: loaded,
          [loadedClassName]: loaded,
          [loadingClassName]: !loaded,
          [styles.loading]: !loaded,
        })}
        {...itemProps}
        data-testid={testid}
      />
      {!loaded && (
        <img alt="stub loader" src={src} onLoad={handleLoad} className={styles.imageStub} />
      )}
    </>
  );
};

ImageLoader.defaultProps = {
  asBackground: false,
};

ImageLoader.propTypes = {
  /** Адрес картинки */
  src: PropTypes.string.isRequired,
  /** Выводить картинку как фоновое изображение */
  asBackground: PropTypes.bool,
  /** Основной класс */
  className: PropTypes.string,
  /** Класс после загрузки */
  loadedClassName: PropTypes.string,
  /** Класс во время загрузки */
  loadingClassName: PropTypes.string,
  /** Функция, вызываемая после загрузки */
  onLoad: PropTypes.func,
  style: PropTypes.object,
  /** Уникальный QA идентификатор */
  testid: PropTypes.string,
};

export default React.memo(ImageLoader);
