import cn from 'classnames';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import getColorByUserId from '../../utils/getColorByUserId';
import styles from './Avatar.scss';
import ImageLoader from '../ImageLoader';

const Avatar = ({
  srcImg,
  id,
  firstName,
  lastName,
  size,
  onClick,
  className,
  noColor,
  testid,
}) => {
  // TODO: full_name to props, add utils/splitUserName from main app
  const getDefaultAvatar = useCallback(() => {
    const style = {};
    !noColor && (style.backgroundColor = getColorByUserId(id));
    return (
      <Text
        className={cn(
          styles.defaultAvatar,
          [styles[size]],
        )}
        style={style}
        variant="caption"
        colorVariant={noColor ? 'tertiary' : 'secondary'}
      >
        {firstName && firstName[0]}
        {lastName && lastName[0]}
      </Text>
    );
  }, [firstName, lastName, id, size]);

  return (
    <div
      onClick={onClick}
      data-testid={testid}
      className={cn(
        styles.avatar,
        [styles[size]],
        className,
        {
          [styles.bordered]: srcImg || noColor,
        },
      )}
    >
      {
        srcImg
          ? (
            <ImageLoader
              className={styles.img}
              src={srcImg}
              alt={`${firstName} ${lastName}`}
            />
          )
          : getDefaultAvatar()
      }
    </div>
  );
};

Avatar.defaultProps = {
  size: 'small',
  noColor: false,
};

Avatar.propTypes = {
  /** Картинка для аватара. */
  srcImg: PropTypes.string,
  /** Id для генирации цвета если нет изображения. */
  id: PropTypes.number,
  /** Имя для генирации инициалов если нет изображения */
  firstName: PropTypes.string,
  /** Фамилия для генирации инициалов если нет изображения */
  lastName: PropTypes.string,
  /** Размер */
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge']),
  /** Обработчик клика */
  onClick: PropTypes.func,
  /** Устанавливает для контейнера имя класса. */
  className: PropTypes.string,
  /** Запрещает цветовую заливку если нет изображения. */
  noColor: PropTypes.bool,
  /** Устанавливает уникальный QA идентификтор. */
  testid: PropTypes.string,
};

export default Avatar;
