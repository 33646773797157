import React from "react";
import noop from "lodash/noop";
import cn from "classnames";
import { connect } from "react-redux";
import { ImageLoader, Text } from "ibolit-ui";
import { getDaysDiffText, getTzDate } from "~/utils/dateUtils";
import RowWithImage from "~/components/RowWithImage";
import UserAvatar from "~/components/UserAvatar";
import parseMessage from "~/layouts/Chats/utils/parseMessage";
import ChatStat from "./ChatStat";
import styles from "./ChatsListItem.scss";
import { getCurrentUserId } from "~/store/auth/authSelectors";

const formatTime = time => {
  const format = getDaysDiffText(time, "DD.MM.YY", "HH:mm");
  return time.format(format);
};

const Image = ({ user }) =>
  typeof user.id === "number" ? (
    <UserAvatar user={user} size="medium" showOnlineStatus />
  ) : (
    <ImageLoader testid="chat_list_item-image" />
  );

const ChatsListItem = ({
  chat,
  chat: { count_new, updated_at },
  user,
  user: { full_name, is_new },
  message,
  onClick = noop,
  currentUserId,
  isSelected,
  ...rest
}) => (
  <RowWithImage
    {...rest}
    testid="chat_list_item"
    className={cn(styles.container, { [styles.redDot]: is_new })}
    contentClass={styles.content}
    image={<Image user={user} />}
    imageClass={styles.avatar}
    info={
      message && (
        <Text variant="desc" colorVariant="tertiary" className={styles.message}>
          {parseMessage(message, currentUserId === message.author_id)}
        </Text>
      )
    }
    isSelected={isSelected}
    onClick={isSelected ? undefined : () => onClick(chat)}
    title={full_name}
    titleClass={styles.title}
    titleRight={
      updated_at && (
        <Text
          colorVariant="tertiary"
          variant="caption"
          className={styles.rightPanel}
        >
          {formatTime(getTzDate(updated_at))}
        </Text>
      )
    }
    infoRight={
      message && (
        <ChatStat
          count_new={count_new}
          message={message}
          isOwn={currentUserId === message.author_id}
        />
      )
    }
  />
);

export default connect((state, { chat: { user, last_message } }) => ({
  user: state.users.items[user] || {},
  message: last_message,
  currentUserId: getCurrentUserId(state)
}))(ChatsListItem);
