import React from "react";
import { Text } from "ibolit-ui";
import Logo from "~/components/Logo";
import styles from "./InMaintain.scss";

const InMaintain = () => (
  <div className={styles.container}>
    <Logo size="l" />
    <Text variant="h3" className={styles.title}>
      Ошибка 500
    </Text>
    <Text variant="h5" className={styles.text}>
      Наши специалисты уже решают возникшую проблему. Попробуйте обновить
      страницу через несколько минут.
    </Text>
  </div>
);

export default InMaintain;
