import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import IconArrowSvg from './icons/IconArrow.svg';
import IconArrowBottomSvg from './icons/IconArrowBottom.svg';
import IconArrowRightSvg from './icons/IconArrowRight.svg';
import IconCallSvg from './icons/IconCall.svg';
import IconCallEndSvg from './icons/IconCallEnd.svg';
import IconMicOnSvg from './icons/IconMicOn.svg';
import IconMicOffSvg from './icons/IconMicOff.svg';
import IconVideoOnSvg from './icons/IconVideoOn.svg';
import IconVideoOffSvg from './icons/IconVideoOff.svg';
import IconExpandSvg from './icons/IconExpand.svg';
import IconCameraSvg from './icons/IconCamera.svg';
import IconCartSvg from './icons/IconCart.svg';
import IconChartSvg from './icons/IconChart.svg';
import IconChatSvg from './icons/IconChat.svg';
import IconChatVideoSvg from './icons/IconChatVideo.svg';
import IconCheckSvg from './icons/IconCheck.svg';
import IconCheckMarkSvg from './icons/IconCheckMark.svg';
import IconCloseSvg from './icons/IconClose.svg';
import IconCloseTinySvg from './icons/IconCloseTiny.svg';
import IconCrossedEyeSvg from './icons/IconCrossedEye.svg';
import IconDiagnosticsAssignedSvg from './icons/IconDiagnosticsAssigned.svg';
import IconEyeSvg from './icons/IconEye.svg';
import IconFailSvg from './icons/IconFail.svg';
import IconGradeSvg from './icons/IconGrade.svg';
import IconHelpSvg from './icons/IconHelp.svg';
import IconMedcardSvg from './icons/IconMedcard.svg';
import IconPhonendoscopeSvg from './icons/IconPhonendoscope.svg';
import IconPhoto from './icons/IconPhoto.svg';
import IconFile from './icons/IconFile.svg';
import IconSchoolSvg from './icons/IconSchool.svg';
import IconSearchSvg from './icons/IconSearch.svg';
import IconSettingsSvg from './icons/IconSettings.svg';
import IconSuccessSvg from './icons/IconSuccess.svg';
import IconUserAddSvg from './icons/IconUserAdd.svg';
import IconUsersRoundSvg from './icons/IconUsersRound.svg';
import IconUsersSvg from './icons/IconUsers.svg';
import IconNotFoundSvg from './icons/IconNotFound.svg';
import IconSend from './icons/IconSend.svg';
import IconChecksMark from './icons/IconChecksMark.svg';
import IconAttach from './icons/IconAttach.svg';
import IconBin from './icons/IconBin.svg';
import IconInfo from './icons/IconInfo.svg';
import IconInfoOutline from './icons/IconInfoOutline.svg';
import IconLoadFile from './icons/IconLoadFile.svg';
import IconLoadedFile from './icons/IconLoadedFile.svg';
import IconTime from './icons/IconTime.svg';
import IconTimeLock from './icons/IconTimeLock.svg'
import IconFilters from './icons/IconFilters.svg';
import IconHospital from './icons/IconHospital.svg';
import IconEmail from './icons/iconEmail.svg';
import IconGift from './icons/IconGift.svg';
import IconMsgBubble from './icons/IconMessagesBubble.svg';
import IconWebcam from './icons/IconWebcam.svg';
import IconNote from './icons/IconNote.svg';
import IconVideo from './icons/IconVideo.svg';
import IconDocuments from './icons/IconDocuments.svg';
import IconBadge from './children/IconBadge';
import IconPlay from './icons/IconPlay.svg';
import IconPreliminaryDiagnosisMadeSvg from './icons/IconPreliminaryDiagnosisMade.svg';
import IconAppointedOfflineReception from './icons/IconAppointedOfflineReception.svg';
import IconPrimaryAppointmentSvg from './icons/IconPrimaryAppointment.svg';
import IconRefresh from './icons/IconRefresh.svg';
import IconRepeatedAppointmentSvg from './icons/IconRepeatedAppointment.svg';
import IconSecondOpinionSvg from './icons/IconSecondOpinion.svg';
import IconTranscriptOfAnalyzesSvg from './icons/IconTranscriptOfAnalyzes.svg';
import IconTreatmentCompletedSvg from './icons/IconTreatmentCompleted.svg';
import IconTreatmentPrescribedSvg from './icons/IconTreatmentPrescribed.svg';
import IconPause from './icons/IconPause.svg';
import IconRollFilm from './icons/IconRollFilm.svg';
import IconMovieReel from './icons/IconMovieReel.svg';
import IconScreen from './icons/IconScreen.svg';
import IconPercent from './icons/IconPercent.svg';
import IconUser from './icons/IconUser.svg';
import IconFeedback from './icons/IconFeedback.svg';
import IconOutlineBig from './icons/IconOutlineBig.svg';
import IconReply from './icons/IconReply.svg';
import IconSorting from './icons/IconSorting.svg';
import NextStepIcon from './icons/NextStepIcon.svg';
import UploadIcon from './icons/UploadIcon.svg';
import CalendarIcon from './icons/CalendarIcon.svg';
import TrashIcon from './icons/TrashIcon.svg';
import PlusIcon from './icons/PlusIcon.svg'
import EditIcon from './icons/EditIcon.svg';
import SearchIcon11 from './icons/SearchIcon11.svg';
import MessageBubbleDoubleIcon from './icons/IconMessageBubbleDouble.svg';
import MeetingCameraIcon from './icons/MeetingCameraIcon.svg';

import styles, { primary, success, error, pressing } from './Icon.scss';

const COLORS = {
  primary,
  success,
  error,
  pressing,
};

const getFillColor = fill => COLORS[fill] || fill || primary;

const Icon = ({
  size,
  sizeValue,
  onClick,
  hasState,
  className,
  isHover,
  fill,
  style,
  variant,
  badge,
  testid,
}) => {
  const icon = getIcon(variant);

  const hasBadge = badge !== undefined;

  const Wrapper = onClick ? 'button' : 'span';

  const inlineStyle = sizeValue && {
    width: sizeValue,
    height: sizeValue,
  };

  return (
    <Wrapper
      style={inlineStyle}
      data-testid={testid}
      {...(onClick ? { type: 'button' } : {})}
      onClick={onClick}
      className={cn(styles.wrapper, styles[size], className, {
        [styles.button]: !!onClick,
      })}
    >
      <svg
        style={{ ...style, ...inlineStyle }}
        viewBox={icon.viewBox}
        fill={getFillColor(fill)}
        className={cn(styles.icon, styles[size], {
          [styles.state]: hasState,
          [styles.hovered]: isHover,
          [styles.clicked]: !!onClick,
        })}
      >
        <use xlinkHref={`#${icon.id}`} />
      </svg>
      {hasBadge && <IconBadge badge={badge} />}
    </Wrapper>
  );
};

function getIcon(iconId) {
  switch (iconId) {
    case 'arrow':
      return IconArrowSvg;
    case 'arrow-bottom':
      return IconArrowBottomSvg;
    case 'arrow-right':
      return IconArrowRightSvg;
    case 'call':
      return IconCallSvg;
    case 'call-end':
      return IconCallEndSvg;
    case 'mic-on':
      return IconMicOnSvg;
    case 'mic-off':
      return IconMicOffSvg;
    case 'video-on':
      return IconVideoOnSvg;
    case 'video-off':
      return IconVideoOffSvg;
    case 'expand':
      return IconExpandSvg;
    case 'camera':
      return IconCameraSvg;
    case 'cart':
      return IconCartSvg;
    case 'chart':
      return IconChartSvg;
    case 'chat':
      return IconChatSvg;
    case 'chat-video':
      return IconChatVideoSvg;
    case 'check':
      return IconCheckSvg;
    case 'check-mark':
      return IconCheckMarkSvg;
    case 'close':
      return IconCloseSvg;
    case 'crossed-eye':
      return IconCrossedEyeSvg;
    case 'eye':
      return IconEyeSvg;
    case 'fail':
      return IconFailSvg;
    case 'grade':
      return IconGradeSvg;
    case 'help':
      return IconHelpSvg;
    case 'medcard':
      return IconMedcardSvg;
    case 'phonendoscope':
      return IconPhonendoscopeSvg;
    case 'photo':
      return IconPhoto;
    case 'file':
      return IconFile;
    case 'school':
      return IconSchoolSvg;
    case 'search':
      return IconSearchSvg;
    case 'settings':
      return IconSettingsSvg;
    case 'success':
      return IconSuccessSvg;
    case 'user-add':
      return IconUserAddSvg;
    case 'users-round':
      return IconUsersRoundSvg;
    case 'users':
      return IconUsersSvg;
    case 'send':
      return IconSend;
    case 'attach':
      return IconAttach;
    case 'bin':
      return IconBin;
    case 'checks-mark':
      return IconChecksMark;
    case 'info':
      return IconInfo;
    case 'infoOutlined':
      return IconInfoOutline;
    case 'load-file':
      return IconLoadFile;
    case 'loaded-file':
      return IconLoadedFile;
    case 'time':
      return IconTime;
    case 'time-lock':
      return IconTimeLock;
    case 'filters':
      return IconFilters;
    case 'close-tiny':
      return IconCloseTinySvg;
    case 'hospital':
      return IconHospital;
    case 'email':
      return IconEmail;
    case 'webcam':
      return IconWebcam;
    case 'msg-bubble':
      return IconMsgBubble;
    case 'gift':
      return IconGift;
    case 'note':
      return IconNote;
    case 'video':
      return IconVideo;
    case 'documents':
      return IconDocuments;
    case 'play':
      return IconPlay;
    case 'refresh':
      return IconRefresh;
    case 'pause':
      return IconPause;
    case 'roll-film':
      return IconRollFilm;
    case 'movie-reel':
      return IconMovieReel;
    case 'diagnostics-assigned':
      return IconDiagnosticsAssignedSvg;
    case 'preliminary-diagnosis-made':
      return IconPreliminaryDiagnosisMadeSvg;
    case 'appointed-offline-reception':
      return IconAppointedOfflineReception;
    case 'treatment-prescribed':
      return IconTreatmentPrescribedSvg;
    case 'treatment-completed':
      return IconTreatmentCompletedSvg;
    case 'primary-appointment':
      return IconPrimaryAppointmentSvg;
    case 'transcript-of-analyzes':
      return IconTranscriptOfAnalyzesSvg;
    case 'repeated-appointment':
      return IconRepeatedAppointmentSvg;
    case 'second-opinion':
      return IconSecondOpinionSvg;
    case 'screen':
      return IconScreen;
    case 'percent':
      return IconPercent;
    case 'user':
      return IconUser;
    case 'feedback':
      return IconFeedback;
    case 'outline-bin':
      return IconOutlineBig;
    case 'outline-reply':
      return IconReply;
    case 'sorting':
      return IconSorting;
    case 'next-step':
      return NextStepIcon;
    case 'upload':
      return UploadIcon;
    case 'calendar':
      return CalendarIcon;
    case 'trash':
      return TrashIcon;
    case 'plus-icon':
      return PlusIcon;
    case 'edit':
      return EditIcon;
    case 'search11':
      return SearchIcon11;
    case 'message-bubble-double':
      return MessageBubbleDoubleIcon;
    case 'meeting-camera':
      return MeetingCameraIcon;

    default:
      return IconNotFoundSvg;
  }
}

Icon.defaultProps = {
  hasState: false,
  size: 'm',
};

Icon.propTypes = {
  variant: PropTypes.oneOf([
    'appointed-offline-reception',
    'arrow',
    'arrow-bottom',
    'arrow-right',
    'attach',
    'bin',
    'call',
    'call-end',
    'camera',
    'cart',
    'chart',
    'chat',
    'chat-video',
    'check',
    'check-mark',
    'checks-mark',
    'close',
    'close-tiny',
    'crossed-eye',
    'diagnostics-assigned',
    'documents',
    'email',
    'expand',
    'eye',
    'fail',
    'file',
    'filters',
    'gift',
    'grade',
    'help',
    'hospital',
    'info',
    'load-file',
    'loaded-file',
    'medcard',
    'mic-off',
    'mic-on',
    'msg-bubble',
    'note',
    'notfound',
    'pause',
    'percent',
    'phonendoscope',
    'photo',
    'play',
    'preliminary-diagnosis-made',
    'primary-appointment',
    'refresh',
    'repeated-appointment',
    'roll-film',
    'school',
    'screen',
    'search',
    'second-opinion',
    'send',
    'settings',
    'success',
    'time',
    'time-lock',
    'transcript-of-analyzes',
    'treatment-completed',
    'treatment-prescribed',
    'user',
    'user-add',
    'users',
    'users-round',
    'video',
    'video-off',
    'video-on',
    'webcam',
    'feedback',
    'outline-bin',
    'outline-reply',
    'sorting',
    'next-step',
    'upload',
    'trash',
    'plus-icon',
    'edit',
    'search11',
    'message-bubble-double',
    'meeting-camera'
  ]),
  /** Устанавливает размер иконки */
  size: PropTypes.oneOf(['m', 's']),
  /** Устанавливает размер иконки */
  sizeValue: PropTypes.string,
  /** Если true будет изменяться при наведении (пока нет стилей) */
  hasState: PropTypes.bool,
  /** Устанавливает обработчик клика на кнопку */
  onClick: PropTypes.func,
  /** Передает класс для контейнера */
  className: PropTypes.string,
  /** Заливает иконку цветом */
  fill: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(COLORS)), PropTypes.string]),
  /** Устанавливет иконку в :hover state */
  isHover: PropTypes.bool,
  /** Устанавливет стили */
  style: PropTypes.object,
  /** Устанавливет badge:
   * если 0 или false - элемент создается, но точка не показывается. Позволяет анимировать on/off
   * если передано число > 0 - оно выводится внутри красного круга
   * если true - выводится красная точка
   *  */
  badge: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  /** Устанавливает уникальный QA идентификтор. */
  testid: PropTypes.string,
};

export default Icon;
