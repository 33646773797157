import PropTypes from "prop-types";
import React from "react";
import { IconWithText } from "ibolit-ui";

const SubscriptionsButton = ({ className, onClick, testid }) => (
  <IconWithText
    padding
    text="Мои подписки"
    className={className}
    iconVariant="users-round"
    onClick={onClick}
    testid={`${testid}__button-show-modal`}
  />
);

SubscriptionsButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string
};

export default React.memo(SubscriptionsButton);
