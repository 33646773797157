import React from 'react';

import GoldStar from './GoldStar.svg';
import Star from './Star.svg';

import * as styles from './StarRating.scss';

export const StarRating = ({ rating, isBig }) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(i <= rating);
  }

  return (
    <div className={styles.rating}>
      {stars.map((isRated, index) => (
        <svg
          viewBox={isRated ? GoldStar.viewBox : Star.viewBox}
          className={isBig ? styles.bigSvg : styles.svg}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <use xlinkHref={`#${isRated ? GoldStar.id : Star.id}`} />
        </svg>
      ))}
    </div>
  );
};
