import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PROFILE_VIEW } from "~/modals/Profile";
import navLocators from "~/locators/navbar";
import Header from "./components/Header";
import uiActions from "~/store/ui/uiActions";
import { getUser } from "~/store/profile/profileSelectors";
import { getToolbar } from "~/store/toolbar/toolbarSelectors";

const NavigationBar = ({
  user,
  showModal,
  toolbar: { consultations, doctors, chatMessages, medCardMessages, loading }
}) => {
  const handleAvatarClick = useCallback(() => {
    showModal(PROFILE_VIEW);
  }, [showModal, PROFILE_VIEW]);

  const routesList = useMemo(
    () => [
      {
        id: 1,
        text: "Сообщения",
        to: "/chats",
        icon: "chat",
        badge: chatMessages,
        testid: navLocators.MESSAGES
      },
      {
        id: 2,
        text: "Приемы",
        to: "/consultations",
        icon: "chat-video",
        badge: consultations,
        testid: navLocators.CONSULTATIONS
      },
      {
        id: 3,
        text: "Клиники",
        to: "/clinics",
        icon: "users",
        badge: doctors,
        testid: navLocators.DOCTORS
      },
      {
        id: 4,
        text: "Мои документы",
        to: "/documents",
        icon: "documents",
        badge: medCardMessages,
        testid: navLocators.DOCUMENTS
      }
    ],
    [chatMessages, consultations, doctors]
  );
  if (!user) return null;

  return (
    <Header
      user={user}
      onAvatarClick={handleAvatarClick}
      routesList={routesList}
      loading={loading}
    />
  );
};

NavigationBar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    full_name: PropTypes.string,
    avatar: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    active: PropTypes.bool,
    online: PropTypes.bool,
    last_entered: PropTypes.string,
    type: PropTypes.string,
    date_of_birth: PropTypes.string,
    is_any_message_exist: PropTypes.bool,
    token: PropTypes.string,
    fingerprint: PropTypes.bool,
    send_sms: PropTypes.bool,
    send_email: PropTypes.bool,
    is_deleted: PropTypes.bool,
    created_at: PropTypes.string,
    updated_at: PropTypes.string
  }),
  toolbar: PropTypes.shape({
    appointments: PropTypes.number,
    doctors: PropTypes.number,
    chatMessages: PropTypes.number,
    medCardMessages: PropTypes.number,
    consultations: PropTypes.number
  }),
  showModal: PropTypes.func
};

export default connect(
  state => ({
    user: getUser(state),
    toolbar: getToolbar(state)
  }),
  {
    showModal: uiActions.showModal
  }
)(NavigationBar);
