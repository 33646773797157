import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MessagesListComponent from "./components/MessagesList";
import messagesActions from "~/store/messages/messagesActions";
import { LightBoxContext } from "~/layouts/App/LightBoxProvider/LightBoxProvider";
import Loading from "~/components/Loading/Loading";
import { isNotFoundRequest } from "~/utils/notFound";

const MessagesList = ({
  page,
  items,
  chatId,
  loading,
  onReady,
  readOnly,
  countNew,
  isDisabled,
  canLoadMore,
  fetchRequest,
  partner,
  partnerId,
  readingNewMessageRequest
}) => {
  const scrollRef = useRef();
  const prevBottomOffsest = useRef({});
  const { showLightBox } = useContext(LightBoxContext);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => fetchRequest(1), [chatId]);

  useEffect(() => scrollToBottom(), [items]);

  // Flowing issues hack start
  // This is the hackies shit I've ever done.
  // TODO: Remove it when chrome for macos gets updated properly. (still buggy on macOS 101.0.4951.41)
  useEffect(() => {
    // Цель не то чтобы вызвать reflow, а заставить браузер пересчитать высоту MessageList.
    // Все другие методы не подошли, решения за 2 дня развлечений я не нашел.
    // Поэтому пока так, очень опасно и завязано на customScrollbars напрямую.
    if (scrollRef.current) {
      scrollRef.current.container.style.position = "absolute";
      setTimeout(() => {
        scrollRef.current.container.style.position = "relative";
      }, 0);
    }
  }, [readOnly]);

  const scrollToBottom = () => {
    scrollRef.current.scrollToBottom();
  };

  const handleReady = useCallback(() => {
    onReady && onReady(items);
    scrollToBottom();
    setIsReady(true);
  }, [items]);

  useEffect(() => {
    if (!isReady) {
      items && items.length && handleReady();
    } else {
      const last = items.slice(-1)[0] || {};
      /*
        new message received
       */
      if (last.author_id === partnerId) {
        // it is remote message,
        if (prevBottomOffsest.current < 200) {
          scrollToBottom();
        }
      } else {
        // its own message
        scrollToBottom();
      }
    }
    countNew > 0 && readingNewMessageRequest();
  }, [items]);

  const handleScroll = values => {
    if (isReady) {
      if (page && canLoadMore) {
        values.scrollTop < 100 && !loading && fetchRequest(page + 1);
        // hook to prevent stick scroll to top when new items loaded
        values.scrollTop < 50 && (scrollRef.current.view.scrollTop = 50);
      }
      values.top === 1 && countNew > 0 && readingNewMessageRequest();
    }
    prevBottomOffsest.current =
      values.scrollHeight - (values.scrollTop + values.clientHeight);
  };
  return (
    <>
      <MessagesListComponent
        key={chatId}
        items={items}
        partnerId={partnerId}
        partner={partner}
        isReady={isReady}
        readOnly={readOnly}
        onReady={handleReady}
        onScroll={handleScroll}
        isDisabled={isDisabled}
        onFileClick={showLightBox}
        scrollRef={scrollRef}
        canLoadMore={canLoadMore}
      />
      <Loading showLoading={loading && !page} />
    </>
  );
};

MessagesList.propTypes = {
  chatId: PropTypes.number.isRequired,
  partnerId: PropTypes.number.isRequired
};

const mapStateToProps = ({ messages }, { chatId }) => {
  const { items, canLoadMore, page, ...state } = messages.getIn([chatId], {
    items: {}
  });
  const { order = [], error, isFetching, initialFetched } = state;
  return {
    items: [...order].map(messageId => items[messageId]).reverse(),
    isSuccess: initialFetched,
    loading: isFetching,
    notFound: error && isNotFoundRequest(error),
    canLoadMore,
    page
  };
};

export default connect(mapStateToProps, (dispatch, { partnerId, chatId }) => ({
  readingNewMessageRequest: messageIds =>
    dispatch(
      messagesActions.readingNewMessageRequest(partnerId, chatId, messageIds)
    )
}))(MessagesList);
