import { types } from "~/store/conclusions/conclusionModel";

export default {
  ...types,
  SEARCH_REQUEST: "conclusions/SEARCH_REQUEST",
  SEARCH_SUCCESS: "conclusions/SEARCH_SUCCESS",
  SEARCH_FAILURE: "conclusions/SEARCH_FAILURE",

  GET_CONCLUSION: "conclusions/GET_CONCLUSION",
  GET_CONCLUSION_SUCCESS: "conclusions/GET_CONCLUSION_SUCCESS",
  GET_CONCLUSION_FAILURE: "conclusions/GET_CONCLUSION_FAILURE",

  GET_CONCLUSION_PDF: "conclusions/GET_CONCLUSION_PDF",
  GET_CONCLUSION_PDF_FAILURE: "conclusions/GET_CONCLUSION_PDF__FAILURE"
};
