import { all, put, throttle, takeLatest } from "redux-saga/effects";
import { normalize } from "normalizr";
import api from "~/api/subscriptionsApi";
import Types from "~/store/subscriptions/subscriptionsTypes";
import Actions from "~/store/subscriptions/subscriptionsActions";
import commonActions from "~/store/common/commonActions";
import { subscriptionSchema } from "~/store/subscriptions/subscriptionsNormalizer";

function* searchSaga({ request }) {
  try {
    const { data } = yield api.subscriptionsSearchApi(request);
    const {
      result: order,
      entities: { subscriptions: items, ...rest }
    } = normalize(data, [subscriptionSchema]);
    yield put(Actions.searchSuccess({ items, order }, request));
    yield put(commonActions.updateItems(rest));
  } catch (e) {
    yield put(Actions.searchFailure(e.message, request));
  }
}

function* deleteSaga({ request }) {
  try {
    const { data } = yield api.subscriptionDeleteApi(request);
    yield put(Actions.deleteSuccess(data, request));
    yield put(Actions.selectItem(null));
  } catch (e) {
    yield put(Actions.deleteFailure(e.message, request));
  }
}

export default function*() {
  yield all([
    throttle(500, Types.SEARCH_REQUEST, searchSaga),
    takeLatest(Types.DELETE_REQUEST, deleteSaga)
  ]);
}
