import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback } from "react";
import cn from "classnames";
import useResizeObserver from "~/hooks/useResizeObserver";
import Scrollbar from "./Scrollbar";
import styles from "./Scrollbar.scss";

export const ScrollbarAutoSized = React.forwardRef(
  (
    {
      className,
      containerStyle = {},
      onResize,
      onScrollFrame,
      fixBottomOnResize,
      ...props
    },
    ref
  ) => {
    const scrollRef = ref || useRef();
    const scroll = useRef({});
    const [resizeRef, { height }] = useResizeObserver({ useHeight: true });
    const size = useRef({ height });

    useEffect(() => {
      onResize && onResize({ height });
      if (fixBottomOnResize) {
        if (scrollRef.current.getValues().top === 1) {
          scrollRef.current.scrollToBottom();
        } else {
          scrollRef.current.view.scrollTop +=
            scroll.current.clientHeight - height;
        }
      }
      size.current = { height };
    }, [height]);

    const handleScrollFrame = useCallback(
      value => {
        scroll.current = value;
        onScrollFrame && onScrollFrame(value);
      },
      [onScrollFrame]
    );

    return (
      <div
        className={cn(styles.maxSizeContainer, className)}
        style={containerStyle}
        ref={resizeRef}
      >
        <Scrollbar
          {...props}
          ref={scrollRef}
          onScrollFrame={handleScrollFrame}
          style={{ height }}
        />
      </div>
    );
  }
);

ScrollbarAutoSized.propTypes = {
  ...Scrollbar.propTypes,
  className: PropTypes.string,
  // Фиксирует позицию прокрутки по нижнему краю при изменении размеров контейнера
  fixBottomOnResize: PropTypes.bool,
  // Вызывается при изменении размеров контейнера
  onResize: PropTypes.func,
  // Стили для контейнера
  containerStyle: PropTypes.object
};

ScrollbarAutoSized.defaultProps = {};

export default ScrollbarAutoSized;
