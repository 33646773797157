import React, { useCallback, useState, useEffect } from "react";
import { CodeInput as CodeInputUI } from "ibolit-ui";
import useInput from "~/hooks/useInput";

const CODE_LENGTH = 4;

const validator = value => value.length === CODE_LENGTH;

const CodeInput = (
  { changeHandler: onChange, initialValue, testidProps, ...options },
  inputRef
) => {
  const [code, setCode] = useState(initialValue || "");
  useEffect(() => setCode(initialValue || ""), [initialValue]);

  const changeHandler = useCallback(value => {
    setCode(value);
    onChange && onChange(value);
  }, []);

  const [inputBinds, className] = useInput({
    ...options,
    inputRef,
    autoFocus: true,
    changeHandler,
    validator
  });

  return (
    <CodeInputUI
      testid={testidProps.input}
      value={code}
      textClassName={className}
      codeLength={CODE_LENGTH}
      inputBinds={inputBinds}
    />
  );
};

export default React.forwardRef(CodeInput);
