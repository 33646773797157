import React, { memo } from "react";
import MessagesList from "~/layouts/Chats/Chat/ChatMessagesList/MessagesList";
import ChatHeader from "./ChatHeader";
import ChatFooter from "./ChatFooter";
import styles from "./Chat.scss";

const Chat = ({
  chat,
  chat: { id: chatId, is_read_only, count_new, has_patient_problem, tariff },
  user,
  partnerId,
  fetchRequest,
  onMessageSubmit
}) => {
  const isDisabled = user && user.is_deleted;

  return (
    <div className={styles.container}>
      <ChatHeader partner={user} chat={chat} />
      <MessagesList
        chatId={chatId}
        partnerId={partnerId}
        chat={chat}
        partner={user}
        readOnly={is_read_only}
        isDisabled={isDisabled}
        countNew={count_new}
        fetchRequest={fetchRequest}
      />
      {!is_read_only && (
        <ChatFooter
          partner={user}
          chat={chat}
          partnerId={partnerId}
          onSubmit={onMessageSubmit}
          hasPatientProblem={has_patient_problem}
          chatTariff={tariff}
        />
      )}
    </div>
  );
};

export default memo(Chat);
