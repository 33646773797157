import Immutable from "seamless-immutable";
import Types from "~/store/chat/chatTypes";
import authTypes from "~/store/auth/authTypes";
import {
  state as requestState,
  reducer as requestReducer
} from "~/store/chat/chatModel";
import { composeReducers } from "~/utils/redux/compose";

const initialState = Immutable({
  meta: Immutable({
    hasPrimaryAcceptance: false
  })
}).merge(requestState);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.UP_CHAT: {
      const firstChatId = state.getIn(["all", "order", "0"]);
      const targetChatId = action.id;
      return firstChatId !== targetChatId
        ? state.updateIn(
            ["all", "order"],
            (order, target) => [
              target,
              ...order.filter(item => item !== target)
            ],
            targetChatId
          )
        : state;
    }

    case Types.INFO_SUCCESS:
      return state.merge(
        {
          items: action.payload.items
        },
        { deep: true }
      );

    case Types.INFO_FAILURE:
      return state.merge({
        error: action.error
      });

    case Types.CHAT_META_REQUEST_SUCCESS:
      return state.setIn(
        ["meta", "hasPrimaryAcceptance"],
        action.payload.has_primary_acceptance
      );

    case authTypes.LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default composeReducers(reducer, requestReducer);
