import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import consultations from "./consultation/consultationReducer";
import appStarted from "./appStarted/appStartedReducer";
import auth from "./auth/authReducer";
import chats from "./chat/chatReducer";
import documents from "~/store/documents/documentsReducer";
import clinics from "./clinics/clinicsReducer";
import error from "./error/errorReducer";
import faq from "./faq/faqReducer";
import feedback from "./feedback/feedbackReducer";
import messages from "./messages/messagesReducer";
import profile from "./profile/profileReducer";
import subscriptions from "./subscriptions/subscriptionsReducer";
import users from "./users/userReducer";
import conclusions from "./conclusions/conclusionReducer";
import toolbar from "./toolbar/toolbarReducer";
import slots from "./slots/slotsReducer";
import call from "./call/callReducer";
import ui from "./ui/uiReducer";
import newConsultation from "./newConsultation/newConsultationReducer";
import { modalSpecialization } from "./modals/specializations/reducer";
import { promocodeReducer } from "./promocode/reducer";

export default history =>
  combineReducers({
    appStarted,
    auth,
    chats,
    clinics,
    documents,
    subscriptions,
    error,
    faq,
    feedback,
    messages,
    profile,
    router: connectRouter(history),
    users,
    consultations,
    conclusions,
    toolbar,
    slots,
    call,
    ui,
    newConsultation,
    modalSpecialization,
    promocodeReducer
  });
