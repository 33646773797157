import Immutable from "seamless-immutable";
import types from "./errorTypes";

const initialState = Immutable({});

export default (state = initialState, { type, message, title, payload }) => {
  switch (type) {
    case types.ERROR_RISE:
      return state.merge({ message, title, payload });
    case types.ERROR_DROP:
      return Immutable({});
    default:
      return state;
  }
};
