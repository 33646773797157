import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

const usePreventAutocomplete = inputBinds => {
  const [readOnly, setReadOnly] = useState(true);
  const { onFocus = Function.prototype } = inputBinds;
  const onFocusHandler = useMemo(
    () =>
      readOnly
        ? e => {
            onFocus(e);
            setReadOnly(false);
          }
        : e => {
            onFocus(e);
          },
    [readOnly],
  );

  return [readOnly, { ...inputBinds, onFocus: onFocusHandler }];
};

const InputPreventAutocomplete = props => {
  const { inputBinds, readOnly, ...otherProps } = props;

  const [shouldPrevent, binds] = usePreventAutocomplete(inputBinds);
  return <Input readOnly={shouldPrevent || readOnly} inputBinds={binds} {...otherProps} />;
};

InputPreventAutocomplete.propTypes = {
  inputBinds: PropTypes.object,
  readOnly: PropTypes.bool,
};

InputPreventAutocomplete.defaultProps = {
  inputBinds: {},
};

export default InputPreventAutocomplete;
