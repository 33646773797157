import { useState } from "react";

const useErrorMessage = error => {
  const [message, setErrorMessage] = useState();
  const [errorSeverity, setErrorSeverity] = useState(
    error ? { [error]: "error" } : {}
  );

  const handleError = (hideError, _error, severity) => {
    setErrorMessage(hideError ? null : _error);

    if (_error && !errorSeverity[_error]) {
      setErrorSeverity(prevState => ({
        ...prevState,
        [_error]: severity || "error"
      }));
    }
  };

  const severity =
    message === undefined ? errorSeverity[error] : errorSeverity[message];
  return [message, severity, handleError];
};

export default useErrorMessage;
