import axios from "./httpService";

export default {
  chatsSearchApi: params => axios.get(`/v3/chats`, { params }),
  getChatApi: ({ chatId }) => axios.get(`/v3/chats/${chatId}`),
  getMetadata: () => axios.get("/v3/user/patient/metadata"),
  getChatByPartnerId: doctorId =>
    axios.get(`/v3/user/partner/${doctorId}/chat`),
  // { partner_id }
  createChat: params => axios.post("/v3/chats/create", params),
  attachProblem: ({ servicePatient, data }) =>
    axios.post(`/v3/user/consultation/${servicePatient}/attach-problem`, data)
};
