import React from "react";
import { Icon } from "ibolit-ui";

export const MessageIcon = ({ show, message }) => {
  if (!show || !message) return null;
  const { is_new, isUploading } = message;
  return isUploading ? (
    <Icon variant="clock" size="s" />
  ) : (
    <Icon
      variant={is_new ? "check-mark" : "checks-mark"}
      fill="var(--green)"
      size="s"
    />
  );
};

export default MessageIcon;
