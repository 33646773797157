import React from "react";
import { Avatar, Text } from 'ibolit-ui';

import { StarRating } from "../StarRating";

import { getDayOrDateText } from '../../utils/dateUtils';

import styles from "./RatingChatMessage.scss";

export const RatingChatMessage = ({
    consultation_rating: {
        patient: {
            avatar,
            full_name,
        },
        rating,
        comment,
    },
    created_at,
}) => {

  return (
    <div className={styles.ratingChat}>
      <div className={styles.bubble}>
        <div className={styles.header}>
            <Avatar srcImg={avatar} className={styles.avatar} />
            <div className={styles.headerText}>
                <Text
                  className={styles.name}
                  variant="body1"
                  weight="bold"
                >
                  {full_name}
                </Text>
                <div className={styles.headerRatingDate}>
                    <StarRating rating={rating} isBig />
                    <Text
                      className={styles.headerDate} 
                      variant="deskTitle"
                      colorVariant="silver"
                    >
                        {getDayOrDateText(created_at)}
                    </Text>
                </div>
            </div>
        </div>
        <Text variant="body1" className={styles.text}>
            {comment}
        </Text>
      </div>
    </div>
  );
};
