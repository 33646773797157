import React from "react";

import { Icon } from "ibolit-ui";

import cn from "classnames";

import styles from "./ConsultationInfo.module.scss";

export const ConsultationInfo = ({
  text,
  icon,
  label,
  textClassName,
  header,
  iconFill,
}) => {
  const renderLabel = () => {
    if (label) {
      return (
        <div className={styles.labelContainer}>
          {icon ? (
            <div className={styles.icon}>
              <Icon
                testid="problem-box-icon"
                sizeValue="40px"
                variant={icon}
                fill={iconFill}
              />
            </div>
          ) : null}
          <div
            className={styles.label}
            data-testid="Receptions__ConsultationInfo__label"
          >
            {label}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={cn(styles.container, label && styles.containerWithLabel)}>
      {renderLabel()}
      <div
        className={cn(styles.header, textClassName)}
        data-testid="Receptions__ConsultationInfo__header"
      >
        {header}
      </div>
      <div
        className={cn(label && styles.text, textClassName)}
        data-testid="Receptions__ConsultationInfo__text"
      >
        {text}
      </div>
    </div>
  );
};
