import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import formatPrice from "~/utils/formatPrice";
import { PAYMENT_IFRAME_VIEW } from "~/components/Payment/InvoicedPayment";
import { PAYMENT_VIEW } from "~/components/Payment/PaymentModal";
import uiActions from "~/store/ui/uiActions";

export function usePayment(
  isPaid,
  text,
  price,
  serviceId,
  consultationId,
  tariffsType,
  userId,
  doctor,
  onSuccess
) {
  const [paid, setPaid] = useState(isPaid);

  const currentText = useMemo(() => {
    if (text) {
      return text;
    }
    if (price === 0) {
      return !paid ? "Подтвердить" : "Подтверждено";
    }

    return !paid ? `Оплатить ${formatPrice(price)}` : "Оплачено";
  }, [price, paid, text]);

  const dispatch = useDispatch();

  const handleSuccess = useCallback(() => {
    setPaid(true);

    if (onSuccess) {
      onSuccess();
    }
  }, []);

  const showPaymentModal = useCallback(() => {
    const view = serviceId ? PAYMENT_IFRAME_VIEW : PAYMENT_VIEW;
    const modalProps = serviceId
      ? {
          serviceId,
          consultationId,
          onSuccess: handleSuccess,
          autoClose: true,
          preventClick: true
        }
      : {
          tariffsType,
          onSuccess: handleSuccess,
          userId,
          doctor,
          preventClick: true,
          price
        };

    dispatch(uiActions.showModal(view, modalProps));
  }, [userId, consultationId, serviceId]);

  return {
    paid,
    currentText,
    showPaymentModal
  };
}
