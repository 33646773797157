import React from "react";
import { useSelector, useDispatch } from "react-redux";

import cn from "classnames";

import { NEW_CONSULTATION_CLINICS_MODAL } from "~/modals/NewConsultation/components/SelectClinic/SelectClinic";

import metricsApi from "~/api/metricsApi";

import { Icon } from "ibolit-ui";

import { enumTabsNames } from "ibolit-components";

import CheckHealthButton from "~/components/HealthCheck/CheckHealthButton";

import { CONSULTATION_STATUS } from "~/store/consultation/consultationConsts";
import callActions from "~/store/call/callActions";
import { getConsultationInProgress } from "~/store/consultation/consultationSelectors";

import { SYMPTOMS_VIEW } from "~/modals/NewConsultation/components/Symptoms/Symptoms";
import { CONSULTATION_FEEDBACK_VIEW } from "~/modals/ConsultationFeedback/ConsultationFeedback";

import { useShowModal, useCloseModal } from "~/hooks/useModal";
import { usePayment } from "~/hooks/usePayment";

import styles from "./ConsultationPage.scss";
import { CHOOSE_SERVICE_VIEW } from "~/modals/ChooseService/ChooseService";

const RateConsultation = ({ onClick, rating }) => (
  <button
    className={styles.rateButton}
    onClick={onClick}
    data-testid="BottomButton-RateConsultation"
  >
    😊 {rating === null ? "Оцените консультацию" : "Редактировать отзыв"}
  </button>
);

const DownloadFilesButton = ({ onClick }) => (
  <button
    className={styles.button}
    onClick={e => {
      onClick(e);
    }}
    data-testid="BottomButton-DownloadFilesButton"
  >
    <Icon variant="plus-icon" className={styles.downloadButtonIcon} />
    <span className={styles.downloadButtonText}>Загрузить файлы</span>
  </button>
);

const CommonButton = ({ onClick, label, additionalClass }) => (
  <button
    className={cn(styles.button, additionalClass)}
    onClick={onClick}
    data-testid="BottomButton-CommonButton"
  >
    {label}
  </button>
);

export const BottomButton = ({
  activeTab,
  consultationData,
  doctorInfo,
  handleDownloadFile,
  isTariffTypeOffline
}) => {
  const dispatch = useDispatch();

  const showSymptomsModal = useShowModal(SYMPTOMS_VIEW, {
    doctor: doctorInfo,
    id: String(consultationData.id),
    tariffId: String(consultationData.tariff_id),
    tariffType: consultationData.type,
    isBackendCreated: consultationData.problem?.status === "auto",
    onClose: useCloseModal(SYMPTOMS_VIEW)
  });

  const {
    tariff_price: tariffPrice,
    tariff_id: tariffId,
    id,
    doctor: doctorId,
    status,
    rating
  } = consultationData;

  const { paid, showPaymentModal } = usePayment(
    false,
    "",
    tariffPrice,
    tariffId,
    id,
    "all",
    doctorId,
    undefined,
    () => null
  );

  function handlePaymentClick() {
    if (paid) return null;

    return showPaymentModal();
  }

  const openNewConsltationModal = useShowModal(CHOOSE_SERVICE_VIEW, {
    doctor: doctorInfo,
    clinicId: doctorInfo.clinic,
    specialtyProps: doctorInfo.specialty || doctorInfo.specialties[0],
    noBack: true
  });

  const showFeedbackModal = useShowModal(CONSULTATION_FEEDBACK_VIEW, {
    consultationId: id,
    doctorInfo,
    rating,
    sendMetrics: metricsApi.sendMetrics,
    isFromChat: false
  });
  async function handleFeedbackClick() {
    metricsApi.sendMetrics({
      event: "Pushed rate button"
    });

    showFeedbackModal();
  }

  let InnerComponent = null;
  const isInProgress = useSelector(state =>
    getConsultationInProgress(state, consultationData)
  );
  const handleStartCall = consultationId => () =>
    dispatch(callActions.callStart({ consultationId }));

  switch (true) {
    case activeTab === enumTabsNames.FILES:
      InnerComponent = () => (
        <DownloadFilesButton onClick={handleDownloadFile} />
      );
      break;
    case status === CONSULTATION_STATUS.CANCELED ||
      status === CONSULTATION_STATUS.EXPIRED ||
      status === CONSULTATION_STATUS.NOT_APPEARED ||
      activeTab === enumTabsNames.CONCLUSION:
      if (activeTab === enumTabsNames.ANAMNESIS) {
        InnerComponent = () => (
          <CommonButton
            onClick={openNewConsltationModal}
            label={"Записаться повторно"}
          />
        );
        break;
      }
      return null;
    case status === CONSULTATION_STATUS.COMPLETED:
      if (activeTab === enumTabsNames.ANAMNESIS) {
        InnerComponent = () => (
          <CommonButton
            onClick={openNewConsltationModal}
            label={"Записаться повторно"}
          />
        );
        break;
      }
      if (rating?.reply) {
        return null;
      }
      InnerComponent = () => (
        <RateConsultation onClick={handleFeedbackClick} rating={rating} />
      );
      break;

    case status === CONSULTATION_STATUS.WAIT_PAYMENT &&
      activeTab !== enumTabsNames.INFO:
      InnerComponent = () => (
        <CommonButton
          label={"Оплатить консультацию"}
          onClick={handlePaymentClick}
          additionalClass={styles.payButton}
        />
      );
      break;
    case activeTab === enumTabsNames.ANAMNESIS &&
      isInProgress &&
      !isTariffTypeOffline:
      InnerComponent = () => (
        <CommonButton label={"Войти на прием"} onClick={handleStartCall(id)} />
      );
      break;
    case status === CONSULTATION_STATUS.APPROVED &&
      activeTab !== enumTabsNames.INFO &&
      consultationData.problem?.status === "auto":
      InnerComponent = () => (
        <CommonButton
          label={"Ответьте на вопросы врача"}
          onClick={showSymptomsModal}
        />
      );
      break;
    case activeTab === enumTabsNames.INFO && !isTariffTypeOffline:
      InnerComponent = () => (
        <CheckHealthButton
          className={styles.checkHealthButton}
          modalProps={{ hasNoBackStep: true }}
          testid="BottomButton-CheckHealthButton"
        />
      );
      break;
    case status === CONSULTATION_STATUS.APPROVED &&
      !isInProgress &&
      !isTariffTypeOffline:
      InnerComponent = () => (
        <CommonButton
          label={"Ожидайте начала приема"}
          additionalClass={styles.opacityButton}
        />
      );
      break;
    default:
      return null;
  }

  return (
    <div className={styles.bottomButtonContainer}>
      <InnerComponent />
    </div>
  );
};
