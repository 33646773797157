export const CONSULTATION_STATUS = {
  PENDING: "pending",
  WAIT_PAYMENT: "wait_payment",
  WAIT_CLIENT_CONFIRMATION: "wait_client_confirmation",
  APPROVED: "approved",
  COMPLETED: "completed",
  CANCELED: "canceled",
  NOT_APPEARED: "not_appeared",
  EXPIRED: "expired",
  CONFIRMED: "confirmed" // phantom
};

export const MAP_CONSULTATION_STATUSES_TO_TEXTS = {
  [CONSULTATION_STATUS.PENDING]: "Ожидает подтверждения",
  [CONSULTATION_STATUS.WAIT_PAYMENT]: "Ожидает оплаты",
  [CONSULTATION_STATUS.CONFIRMED]: "Подтверждено",
  [CONSULTATION_STATUS.APPROVED]: "Оплачено",
  [CONSULTATION_STATUS.COMPLETED]: "Завершена",
  [CONSULTATION_STATUS.CANCELED]: "Отменена",
  [CONSULTATION_STATUS.NOT_APPEARED]: "Просрочена",
  [CONSULTATION_STATUS.EXPIRED]: "Просрочена"
};

export const CONSULTATION_STATUSES = [
  CONSULTATION_STATUS.PENDING,
  CONSULTATION_STATUS.WAIT_PAYMENT,
  CONSULTATION_STATUS.WAIT_CLIENT_CONFIRMATION,
  CONSULTATION_STATUS.APPROVED,
  CONSULTATION_STATUS.COMPLETED,
  CONSULTATION_STATUS.CANCELED,
  CONSULTATION_STATUS.NOT_APPEARED,
  CONSULTATION_STATUS.EXPIRED
];
export const CONSULTATION_INFO_REQUEST_ERROR_TEXT = "Консультация не найдена";

export const CONSULTATIONS_FILTER_STATUS_OPEN = "open";
export const CONSULTATIONS_FILTER_STATUS_CLOSED = "closed";

export const WAIT_PAYMENT_TITLE = "Консультация не оплачена";
export const WAIT_PAYMENT_TEXT =
  "Вам необходимо оплатить данную консультацию до начала приема.";

export const CANCELED_TEXT =
  "Для назначения новой консультации свяжитесь с врачом или позвоните в регистратуру.";
export const CANCELED_TITLE = "Консультация отменена";

export const PENDING_TITLE = "Ожидает подтверждения";
export const PENDING_TEXT =
  "Вы получите уведомление, после подтверждения вашей записи врачом.";
export const WAIT_CONFIRM_SUGGESTION =
  "Вы также можете связаться с регистратурой:";

export const APPROVED_TITLE = "Консультация подтверждена";
export const APPROVED_TEXT =
  "Консультация состоится в %s. Нажмите на кнопку ниже, чтобы начать звонок:";

export const IN_PROGRESS_TITLE = "Начните консультацию";
export const IN_PROGRESS_TEXT =
  "Когда будете готовы, нажмите на кнопку ниже, чтобы начать звонок:";

export const WAIT_CLIENT_CONFIRM_TITLE = "Подтвердите консультацию";
export const WAIT_CLIENT_CONFIRM_TEXT =
  "Вам необходимо подтвердить данную консультацию до начала приема.";

export const COMPLETED_TITLE = "Заключение врача";
export const COMPLETED_TEXT = "Врач не оставил заключения";
