import axios from "axios";
import { getToken } from "~/utils/localStorageHelper";

const instance = axios.create({
  baseURL: `${__API__}`,
  timeout: 20000
});

instance.interceptors.request.use(
  config => {
    const token = getToken();

    config.headers["X-Client-Token"] = __CLIENT_TOKEN__;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error)
);

export default instance;
