import { schema } from "normalizr";
import { consultationSchema } from "~/store/consultation/consultationNormalizer";

const processStrategy = (value, parent, key) => {
  switch (key) {
    case "last_message":
      // message from chats list
      return {
        ...value,
        chatId: parent.id
      };
    case "messages": {
      // message from socket
      const { id: chatId, user } = parent;

      return {
        ...value,
        user,
        chatId
      };
    }

    default:
      return value;
  }
};

export const messageSchema = new schema.Entity(
  "messages",
  {
    consultation: consultationSchema
  },
  {
    processStrategy
  }
);
