import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loading from "~/components/Loading/Loading";
import messagesActions from "~/store/messages/messagesActions";
import chatsActions from "~/store/chat/chatActions";
import Chat from "./Chat";
import { dateToServer, SERVER_DATETIME_FORMAT } from "~/utils/dateUtils";

const ChatContainer = ({
  user,
  chatId,
  partnerId,
  searchRequest,
  currentUserId,
  chatInfoRequest,
  sendMessageRequest,
  ...props
}) => {
  useEffect(() => {
    if (!partnerId) {
      chatInfoRequest();
    }
  }, [partnerId]);

  const handleFetch = useCallback(
    (page = 1) => {
      searchRequest(partnerId, page);
    },
    [partnerId]
  );

  const handleMessageSubmit = useCallback(
    messageData => {
      const key = new Date().getTime();
      const created_at = dateToServer(new Date()).format(
        SERVER_DATETIME_FORMAT
      );
      const message = {
        ...messageData,
        to: partnerId,
        author_id: currentUserId,
        client_message_key: key,
        created_at,
        id: key,
        is_new: false
      };
      sendMessageRequest(partnerId, message, messageData);
    },
    [partnerId, currentUserId]
  );

  return (
    <>
      {user ? (
        <Chat
          user={user}
          fetchRequest={handleFetch}
          onMessageSubmit={handleMessageSubmit}
          partnerId={partnerId}
          {...props}
        />
      ) : (
        <Loading showLoading />
      )}
    </>
  );
};

ChatContainer.propTypes = {
  searchRequest: PropTypes.func.isRequired
};

const mapStateToProps = (
  { auth: { currentUserId }, chats: { items: chats }, users: { items: users } },
  { chatId }
) => {
  const chat = chats[chatId] || {};
  return {
    chat,
    partnerId: chat.user,
    user: users[chat.user],
    currentUserId
  };
};

export default connect(mapStateToProps, (dispatch, { chatId }) => ({
  chatInfoRequest: () => dispatch(chatsActions.infoRequest(chatId)),
  searchRequest: (...args) =>
    dispatch(messagesActions.searchRequest(chatId, ...args)),
  sendMessageRequest: (...args) =>
    dispatch(messagesActions.sendRequest(chatId, ...args))
}))(ChatContainer);
