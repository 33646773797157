import React, { useCallback } from "react";
import { Button } from "ibolit-ui";
import { useShowModal } from "~/hooks/useModal";
import BlockHeader from "~/components/BlockHeader";
import EmptyStub from "~/components/EmptyStub";
import ItemsListContainer from "~/components/ItemsList/ItemsListContainer";
import { CONSULTATIONS_LIST } from "~/locators/consultations";
import TopBlock from "../TopBlock";
import ListItem from "../ConsultationsListItem";
import ListTitle from "../ConsultationsListTitle";
import styles from "./ConsultationsList.scss";
import { CONSULTATIONS_FILTER_STATUS_CLOSED } from "~/store/consultation/consultationConsts";

import { CLINICS_VIEW } from "~/modals/Clinics";
import { NEW_CONSULTATION } from "~/locators/newConsultation";

const renderEmpty = (fetched, mode) => (
  <EmptyStub
    variant={fetched ? "default" : "loading"}
    text={
      fetched
        ? `У вас нет ${
            mode === CONSULTATIONS_FILTER_STATUS_CLOSED
              ? "завершенных"
              : "назначенных"
          }\nприемов`
        : "Проверяем наличие\nприемов..."
    }
  />
);

const renderNotReady = (mode, text = "Загружаем список\nприемов") => (
  <EmptyStub text={text} />
);

const ConsultationsList = ({
  className,
  mode,
  fetched,
  filter,
  filters,
  setFilter,
  searchRequest,
  isEmpty,
  ...itemsListProps
}) => {
  const renderItem = useCallback(
    ({ item, ...itemProps }) =>
      React.createElement(item && item.id ? ListItem : ListTitle, {
        item,
        mode,
        ...itemProps,
        // Testing id only for consultation, not for date. Maybe add for date later if needed.
        ...(item && item.id && { testid: CONSULTATIONS_LIST.ITEM })
      }),
    [mode]
  );
  const handleShowModal = useShowModal(CLINICS_VIEW);

  return (
    <>
      <BlockHeader title="Приемы" testid={CONSULTATIONS_LIST.HEADER} />
      <div className={styles.block}>
        <Button
          fullWidth
          colorVariant="patient"
          onClick={handleShowModal}
          className={styles.button_icon}
          testid={NEW_CONSULTATION.OPEN_MODAL}
        >
          Записаться на консультацию
        </Button>
      </div>
      <TopBlock
        className={styles.block}
        filter={filter}
        filters={filters}
        setFilter={setFilter}
      />
      {isEmpty ? (
        renderEmpty(fetched, mode)
      ) : (
        <ItemsListContainer
          mode={mode}
          {...itemsListProps}
          isReady={fetched}
          renderItem={renderItem}
          renderEmpty={renderEmpty}
          renderNotReady={renderNotReady}
          fetchRequest={searchRequest}
          className={styles.contentContainer}
          testid={CONSULTATIONS_LIST.CONTAINER}
        />
      )}
    </>
  );
};

export default ConsultationsList;
