import React from "react";
import styles from "./Statuses.scss";
import { Box, Button, Icon, Text } from "ibolit-ui";
import { NEW_CONSULTATION } from "~/locators/newConsultation";
import { CHOOSE_DATE_TIME } from "~/modals/ChooseDateTime/ChooseDateTime";
import { useCloseModal, useReplaceModal } from "~/hooks/useModal";
import { ModalHeader } from "~/components/ModalHeader/ModalHeader";
import { PAYMENT_IFRAME_VIEW } from "~/components/Payment/InvoicedPayment";
import { NEW_CONSULTATION_SUCCESS_MODAL } from "./StatusSuccess";
import { NEW_CONSULTATION_ERROR_MODAL } from "./StatusError";
import { CHOOSE_SERVICE_VIEW } from "~/modals/ChooseService/ChooseService";
import { useDispatch } from "react-redux";
import chatsActions from "~/store/chat/chatActions";
import classNames from "classnames";
import { PROMO_MODAL_VIEW } from "~/modals/Promo/PromoModal";

const defaultTestId = "StatusPay";

export const STATUS_PAY = "STATUS_PAY";

export const StatusPay = ({
  doctor,
  tariffId,
  slotType,
  clinicId,
  price,
  tariffType,
  startAt,
  noBack,
  canApplyPromocode,
  promoModalProps
}) => {
  const handleClose = useCloseModal(STATUS_PAY);
  const dispatch = useDispatch();

  const onSuccess =
    slotType === "chat"
      ? () => {
          handleClose();
          dispatch(chatsActions.checkChat(doctor));
        }
      : useReplaceModal(STATUS_PAY, NEW_CONSULTATION_SUCCESS_MODAL);

  const onBackClick = useReplaceModal(
    STATUS_PAY,
    slotType === "chat" ? CHOOSE_SERVICE_VIEW : CHOOSE_DATE_TIME,
    {
      doctor,
      clinicId: doctor.clinic,
      specialtyProps: doctor.specialty || doctor.specialties?.[0],
      clinicId,
      noBack
    }
  );

  const closeNewConsultationErrorModal = useCloseModal(
    NEW_CONSULTATION_ERROR_MODAL
  );

  const onFailure = useReplaceModal(STATUS_PAY, NEW_CONSULTATION_ERROR_MODAL, {
    onClick: () => {
      closeNewConsultationErrorModal();
      onBackClick();
    }
  });

  const handlePay = useReplaceModal(
    STATUS_PAY,
    canApplyPromocode ? PROMO_MODAL_VIEW : PAYMENT_IFRAME_VIEW,
    {
      serviceId: tariffId,
      onSuccess,
      onFailure,
      promoModalProps,
      price
    }
  );

  return (
    <Box
      className={classNames(styles.status_box, styles.status_pay_box)}
      testId={"new_consultation_status_pay_modal"}
      isModalBlock
    >
      <div className={styles.header}>
        <div className={styles.back} data-testid="back_modal_window_date_time">
          <Icon variant="arrow" onClick={onBackClick} />
        </div>
        <Text tag="span" variant="h2" testid="header_date_time_modal_window">
          Запись к врачу
        </Text>
        <div
          className={styles.close}
          data-testid="close_date_time_modal_window"
        >
          <Icon variant="close" fill="var(--grey)" onClick={handleClose} />
        </div>
      </div>
      <ModalHeader
        doctor={doctor}
        price={price}
        slotType={slotType || tariffType}
        startAt={startAt}
        specialty={doctor.specialty || doctor.specialties[0]}
      />
      <Button
        fullWidth
        colorVariant="success"
        onClick={handlePay}
        testid={NEW_CONSULTATION.OCCUPIED_STATUS_BUTTON}
        className={styles.payButton}
        defaultTestId={`${defaultTestId}-payButton`}
      >
        Оплатить
      </Button>
    </Box>
  );
};
