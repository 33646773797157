import Immutable from "seamless-immutable";
import types from "~/store/conclusions/conclusionTypes";
import {
  state as requestInitialState,
  reducer as requestReducer
} from "~/store/conclusions/conclusionModel";
import {
  searchSuccessReducer,
  searchRequestReducer,
  searchFailureReducer
} from "~/store/utils";
import { composeReducers } from "~/utils/redux/compose";

const initialState = Immutable({
  items: {},
  posting: false,
  loading: false,
  ...requestInitialState
});
// очень сильно сомневаюсь в натягивании model на conclusion
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_REQUEST:
      return state.update(action.request.userId, (localState = initialState) =>
        searchRequestReducer(localState, action)
      );

    case types.SEARCH_SUCCESS:
      return state.update(action.request.userId, localState =>
        searchSuccessReducer(localState, action)
      );

    case types.SEARCH_FAILURE:
      return state.update(action.request.userId, localState =>
        searchFailureReducer(localState, action)
      );

    case types.GET_CONCLUSION_SUCCESS: {
      const { payload } = action;
      return state.setIn(["items", payload.id], payload);
    }

    default:
      return state;
  }
};

export default composeReducers(reducer, requestReducer);
