import React from "react";
import { Notice } from "ibolit-ui";
import { withRouter } from "react-router-dom";

const Notification = ({
  iconVariant,
  text,
  title,
  link,
  history,
  closeToast,
  location,
  ...props
}) => {
  const handleClick = () => {
    if (!link) return null;
    history.push(link);
    closeToast();
  };

  return (
    <Notice
      iconVariant={iconVariant}
      text={text}
      title={title}
      closeToast={closeToast}
      {...(link && location.pathname !== link && { onClick: handleClick })}
      {...props}
    />
  );
};

export default withRouter(Notification);
