import VMasker from "vanilla-masker";
import getNumbersFromString from "~/utils/getNumbersFromString";

export default (value, showCents = false) => {
  const _value = getNumbersFromString(value)[0];
  if (typeof _value !== "number") return null;
  return VMasker.toMoney(_value.toFixed(showCents ? 2 : 0), {
    precision: showCents ? 2 : 0,
    separator: ",",
    delimiter: " ",
    suffixUnit: "₽",
    zeroCents: false
  });
};
