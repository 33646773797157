import React from "react";

export const NextStepIcon = ({ fill = 'FF537C' }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.6124 36.6174C23.6109 38.486 22.0958 40 20.2272 40H19.7807C17.9111 40 16.3955 38.4844 16.3955 36.6148C16.3955 34.7452 17.9111 33.2295 19.7807 33.2295H20.2272C22.0978 33.2295 23.6138 34.7467 23.6124 36.6174ZM23.5055 29.7917C19.6363 29.7917 16.1976 26.3189 18.4863 23.1992C21.5303 19.0497 27.0005 18.0332 27.0005 13.229C27.0005 9.56271 23.8497 6.62546 20 6.62546C17.4375 6.62546 15.1851 8.02855 13.9654 10.0112C12.9515 11.6592 11.432 13.3333 9.49712 13.3333C7.56471 13.3333 5.95106 11.7402 6.47232 9.87938C6.6295 9.31828 6.8254 8.76637 7.05927 8.22734C7.76172 6.60831 8.79283 5.13726 10.0935 3.89855C11.3941 2.65984 12.9387 1.67783 14.6387 1.00883C16.3387 0.339833 18.1606 -0.00298977 20 1.96425e-05C27.7327 1.96425e-05 34 5.8755 34 13.229C34 21.55 23.5054 22.4992 23.5003 29.7863C23.5003 29.7892 23.5026 29.7917 23.5055 29.7917Z"
    />
  </svg>
);
