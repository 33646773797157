import React from 'react';
import PropTypes from 'prop-types';
import styles from './Dialog.scss';
import Text from '../Text';
import Icon from '../Icon';
import Box from '../Box';

const Dialog = ({
  onBackClick,
  onCloseClick,
  onSortingClick,
  boxClassName,
  headerClassName,
  bodyClassName,
  header,
  children,
  testId,
  headerTestId,
  headerColorVariant
}) => (
  <Box testId={testId} className={boxClassName}>
    <div className={headerClassName || styles.dialogHeader}>
      <Text
        tag="span"
        variant="h3"
        testid={headerTestId  || "header_modal_window"}
        colorVariant={headerColorVariant}
      >
        {header}
      </Text>
      {onSortingClick && (
        <div className={styles.dialogHeaderSorting} data-testid="sorting_modal_window">
          <Icon variant="sorting" onClick={onSortingClick} />
        </div>
      )}
      {onBackClick && (
        <div className={styles.dialogHeaderBack} data-testid="back_modal_window">
          <Icon variant="arrow" onClick={onBackClick} />
        </div>
      )}
      {onCloseClick && (
        <div className={styles.dialogHeaderClose} data-testid="close_modal_window">
          <Icon variant="close" fill="#858585" onClick={onCloseClick} />
        </div>
      )}
    </div>
    <div className={bodyClassName || styles.dialogBody}>{children}</div>
  </Box>
);

Dialog.propTypes = {
  header: PropTypes.string,
  testId: PropTypes.string,
  onBackClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  onSortingClick: PropTypes.func,
  boxClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Dialog;
