import axios from "./httpService";

const MCS_URL = __MS__ || "https://mcs-stage2.ibolit.pro:4098"; // Meta Call Server URL is retrieved from the old MS build parameter TODO: refactor this and inform backend

const getUsername = params =>
  axios.get(`${MCS_URL}/api/v2/username`, { params });

const getOneTimeToken = params =>
  axios.get(`${MCS_URL}/api/v2/get-token`, { params });

export default {
  callJoin: params => axios.get(`${MCS_URL}/api/v1/join`, { params }),
  callLeave: params => axios.get(`${MCS_URL}/api/v1/leave`, { params }),
  getUsername,
  getOneTimeToken
};
