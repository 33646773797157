import React from "react";
import { Text } from "ibolit-ui";

export const getAgreementText = className => (
  <Text
    className={className}
    variant="h6"
    tag="span"
    weight="normal"
    testid="getAgreementText__registration_text"
  >
    Регистрируясь, я принимаю&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://drive.google.com/file/d/1A7Sw_SrHAT_r8IFDdx8J118wpYkp8TCE/view"
    >
      соглашение об использовании сервиса
    </a>
    &nbsp;и&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://drive.google.com/file/d/1SUvzg6cEaH5VAb6CLUv86uTou4hEAAY-/view"
    >
      положение о порядке обработки персональных данных
    </a>
    &nbsp;и&nbsp;
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://drive.google.com/file/d/1JuBaPH_zxxpPT2TQ53uRr-dgvir2vYuZ/view"
    >
      согласие на их обработку
    </a>
  </Text>
);
