import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import useInterval from "~/hooks/useInterval";
import { getFormattedTime, getResendAtTimeout } from "./CountDown.helpers";

const CountDown = ({
  last,
  defaultTimeout,
  timeWrapper,
  wrapperSplitter,
  onEnd
}) => {
  const [timeout, setTimeout] = useState();
  const [count, setCount] = useState(timeout);
  useMemo(() => {
    const _timeout = getResendAtTimeout(last, defaultTimeout);
    setTimeout(_timeout);
    setCount(_timeout);
  }, [last]);

  useInterval(
    () => {
      setCount(count - 1);
    },
    count > 0 ? 1000 : null
  );

  useEffect(() => {
    if (count <= 0) {
      onEnd && onEnd();
    }
  }, [count]);

  const wrap = timeWrapper.split(wrapperSplitter);
  if (count <= 0) return null;
  return (
    <>
      {wrap[0]}
      <span>{getFormattedTime(count)}</span>
      {wrap[1]}
    </>
  );
};

export default CountDown;

CountDown.propTypes = {
  defaultTimeout: PropTypes.number.isRequired,
  onEnd: PropTypes.func,
  last: PropTypes.instanceOf(Date),
  wrapperSplitter: PropTypes.string,
  timeWrapper: PropTypes.string
};

CountDown.defaultProps = {
  timeWrapper: "",
  wrapperSplitter: "",
  last: new Date()
};
