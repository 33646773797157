import Immutable from "seamless-immutable";
import {
  searchFailureReducer,
  searchRequestReducer,
  searchSuccessReducer
} from "~/store/utils";
import types from "~/store/consultation/consultationTypes";
import authTypes from "~/store/auth/authTypes";
import {
  CONSULTATIONS_FILTER_STATUS_CLOSED,
  CONSULTATIONS_FILTER_STATUS_OPEN
} from "~/store/consultation/consultationConsts";
import { MAX_FILE_SIZE } from "~/layouts/Consultations/Consultation/components/FileManager/AddingFilesModal/AddingFilesModal";

const initialState = Immutable({
  items: {},
  [CONSULTATIONS_FILTER_STATUS_OPEN]: {
    initialFetched: false
  },
  [CONSULTATIONS_FILTER_STATUS_CLOSED]: {
    initialFetched: false
  },
  consultationInfoLoading: false,
  consultationFeedbackLoading: false,
  files: {}
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CONSULTATIONS_GET:
      return searchRequestReducer(state, action);

    case types.CONSULTATIONS_GET_SUCCESS: {
      return searchSuccessReducer(state, action);
    }

    case types.CONSULTATIONS_GET_FAILURE:
      return searchFailureReducer(state, action);

    case types.CONSULTATIONS_UPDATE_ITEMS:
      return state.merge({ items: action.items }, { deep: true });

    case types.CONSULTATIONS_INFO_GET:
      return state.merge({ consultationInfoLoading: true });

    case types.CONSULTATIONS_INFO_GET_SUCCESS: {
      return state
        .merge(
          {
            consultationInfoLoading: false,
            ...action.payload
          },
          {
            deep: true
          }
        )
        .updateIn(["files"], files => files.without(file => file.tempFileId));
    }

    case types.CONSULTATIONS_INFO_GET_FAILURE:
      return state.merge({
        consultationInfoLoading: false,
        error: action.error
      });

    case types.CONSULTATIONS_ADD_FILE:
      return state.merge({
        files: {
          ...state.files,
          [action.file.file.name]: action.file
        }
      });

    case types.CONSULTATIONS_REMOVE_FILE: {
      return state.updateIn(["files"], files =>
        files.without(action.file.file.name)
      );
    }

    case types.CONSULTATION_UPDATE_FILE: {
      const { file, tempFileId, isLinked, data, consultationId } = action.payload;

      return state.updateIn(["files"], files =>
        files
          .merge({ [tempFileId]: { ...data, ...file, tempFileId, isLinked } })
          .without(file.file.name)
      ).updateIn(['items', consultationId, 'attached_files'],
      files => files.concat([{ ...data }]),
    );
    }

    case types.CONSULTATION_UPLOAD_FILE_ERROR: {
      return state.updateIn(["files", action.payload.fileName], file =>
        file.merge({ error: action.payload.error, progress: null })
      );
    }

    case types.CONSULTATION_UPLOAD_PROGRESS: {
      return state.updateIn(["files", action.fileName], file =>
        file.merge({ progress: action.progress })
      );
    }

    case types.CONSULTATION_UPLOAD_SUCCESS:
      return state.updateIn(["files", action.fileName], file =>
        file.merge({ progress: 1 })
      );

    case types.CONSULTATION_CANCEL_UPLOAD: {
      return state.updateIn(["files", action.fileName], file =>
        file.merge({ progress: null, canceled: true })
      );
    }

    case types.CONSULTATION_START_UPLOAD_FILES: {
      const filesCopy = Object.entries(state.files).reduce(
        (acc, [fileName, file]) => {
          if (file.file.size < MAX_FILE_SIZE) {
            acc[fileName] = {
              ...file,
              progress: 0
            };
          }

          return acc;
        },
        {}
      );

      return Immutable({
        ...state,
        files: filesCopy
      });
    }

    case types.CONSULTATION_CLEAR_FILES: {
      return state.merge({
        files: {}
      });
    }

    case types.CONSULTATION_DELETE_FILE_SUCCESS: {
      const { consultationId, fileId } = action;

      return state
        .updateIn(["items", consultationId, "attached_files"], files =>
          files.filter(({ id }) => id !== fileId)
        )
        .updateIn(["files"], files =>
          Object.fromEntries(
            Object.entries(files).filter(
              ([tempId, value]) => value.id !== fileId
            )
          )
        );
    }

    case authTypes.LOGOUT:
      return initialState;

    case types.CONSULTATION_SEND_FEEDBACK:
      return state.merge({ consultationFeedbackLoading: true });

    case types.CONSULTATION_SEND_FEEDBACK_SUCCESS:
      return state
        .setIn(
          ["items", action.payload.consultationId, "rating"],
          action.payload.feedback
        )
        .merge({ consultationFeedbackLoading: false });

    case types.CONSULTATION_SEND_FEEDBACK_FAILURE:
      return state.merge({ consultationFeedbackLoading: false });

    default:
      return state;
  }
};
