import { all, put, throttle, call } from "redux-saga/effects";
import { normalize } from "normalizr";
import api from "~/api/documentsApi";
import Types from "./documentsTypes";
import Actions from "./documentsActions";
import commonActions from "~/store/common/commonActions";
import toastActions from "~/store/toast/toastActions";
import { documentsSchema } from "~/store/documents/documentsNormalizer";
import { ERROR_REQUEST_DEFAULT } from "~/consts/texts";

function* sendRequestSaga({ payload }) {
  const { page, limit, mode, value } = payload;
  try {
    const params = value
      ? { page, limit, query: value }
      : { page, limit, filter: mode };
    const { data } = yield call(api.documentsListApi, params);
    const { result: order, entities } = normalize(data, [documentsSchema]);
    yield put(commonActions.updateItems(entities));
    yield put(Actions.requestSuccess({ page, limit, order, mode }));
  } catch (error) {
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
    yield put(Actions.requestFailed({ page, limit, mode, errors: error }));
  }
}

export default function* documentsSaga() {
  yield all([throttle(2000, Types.REQUEST_SEND, sendRequestSaga)]);
}
