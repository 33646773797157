import React from "react";
import PropTypes from "prop-types";
import { Scrollbar, Separator, Text } from "ibolit-ui";
import EmptyStub from "~/components/EmptyStub";
import { TariffCategory } from "./TariffCategory";
import styles from "../Tariffs.scss";

const titles = {
  chat: "Консультация в чате",
  av: "Онлайн-консультации",
  offline: "Консультации в клинике"
};

const TariffsList = ({ tariffs, loading }) => {
  if (!tariffs) {
    return <EmptyStub text="Загружаем тарифы" />;
  }

  if (!loading && tariffs && !Object.keys(tariffs).length) {
    return <Text variant="desc">Тарифы не найдены</Text>;
  }
  const tariffsCategories = Object.keys(tariffs);
  return (
    <Scrollbar autoHeight autoHeightMax="calc(100vh - 120px)">
      {tariffsCategories.map((type, index) => {
        const hasSeparate = index !== tariffsCategories.length - 1;
        return (
          <>
            <TariffCategory
              title={titles[type]}
              category={type}
              items={tariffs[type]}
            />
            {hasSeparate && <Separator className={styles.separator} />}
          </>
        );
      })}
    </Scrollbar>
  );
};

TariffsList.propTypes = {
  tariffs: PropTypes.array,
  loading: PropTypes.bool
};

export default TariffsList;
