import React from 'react';

export const InfoIcon = ({ className, onMouseEnter = () => null, onMouseLeave = () => null }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke="#C7C7C7" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 12.2V9" stroke="#C7C7C7" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 5.80005H9.008" stroke="#C7C7C7" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);