import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import { Button } from "ibolit-ui";
import CountDown from "./CountDown";

const CountDownTextButton = ({
  text,
  style,
  enabled,
  className,
  onClick,
  testid,
  type,
  ...props
}) => {
  const [last, setLastSend] = useState(new Date());
  const [disabled, setDisabled] = useState(true);
  const [codeResend, setResend] = useState(1);

  const handleClick = (...args) => {
    setResend(codeResend + 1);
    onClick(...args);
  };

  useEffect(() => {
    setLastSend(new Date());
    setDisabled(true);
  }, [codeResend]);

  const handleEnd = useCallback(() => setDisabled(false), []);
  return (
    <Button
      type={type}
      disabled={!enabled || disabled}
      className={className}
      onClick={handleClick}
      fullWidth
      variant="tertiary"
      style={style}
      testid={testid}
    >
      {text}
      <CountDown {...props} last={last} onEnd={handleEnd} />
    </Button>
  );
};

CountDownTextButton.propTypes = {
  className: PropTypes.string,
  enabled: PropTypes.bool,
  text: PropTypes.string,
  textSplitter: PropTypes.string,
  timeWrapper: PropTypes.string,
  wrapperSplitter: PropTypes.string,
  defaultTimeout: PropTypes.number
};

CountDownTextButton.defaultProps = {
  text: "",
  textSplitter: "",
  timeWrapper: " (|)",
  wrapperSplitter: "|"
};

export default CountDownTextButton;
