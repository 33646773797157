import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import uiActions from "~/store/ui/uiActions";
import ConnectionCheck from "~/components/HealthCheck/ConnectionCheck";
import { PROFILE_VIEW } from "~/modals/Profile";

export const CONNECTION_CHECK_VIEW = "connectionCheck";

export default function ConnectionCheckContainer(props) {
  const dispatch = useDispatch();
  const onCloseClick = useCallback(
    () => dispatch(uiActions.hideModal(CONNECTION_CHECK_VIEW)),
    []
  );
  const onBackClick = useCallback(
    () => dispatch(uiActions.replaceModal(CONNECTION_CHECK_VIEW, PROFILE_VIEW)),
    []
  );
  return (
    <ConnectionCheck
      {...props}
      onCloseClick={onCloseClick}
      onBackClick={!props.hasNoBackStep ? onBackClick : undefined}
    />
  );
}
