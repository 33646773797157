export const PRIVATE_PROFILE = {
  TITLE: "privateProfile__title",
  ICON_CLOSE: "privateProfile__icon-close",
  AVATAR: "privateProfile__avatar",
  NICKNAME: "privateProfile__nickname",
  SUBSCRIPTIONS: "privateProfile__subscriptions",
  HEALTH: "privateProfiles__health-check",
  SETTINGS: "privateProfiles__settings",
  SUPPORT: "privateProfiles__support",
  SAVE_VIDEOS: "privateProfiles__save_videos",
  BIRTHDAY: "privateProfiles__birthday"
};

export const SETTINGS = {
  TITLE: "privateProfile__settings__title",
  ICON_BACK: "privateProfile__settings__icon-back",
  ICON_CLOSE: "privateProfile__settings__icon-close",
  AVATAR: "privateProfile__settings__avatar",
  INPUT_FULL_NAME: "privateProfile__settings__input-full-name",
  INPUT_DATE_OF_BIRTH: "privateProfile__settings__input-date-of-birth",
  INPUT_EMAIL: "privateProfile__settings__input-email",
  INPUT_PHONE: "privateProfile__settings__input-phone",
  BUTTON_CANCEL: "privateProfile__settings__button-cancel",
  BUTTON_SAVE: "privateProfile__settings__button-save",
  BUTTON_LOGOUT: "privateProfile__settings__button-logout",
  BUTTON_CHANGE_PASSWORD: "privateProfile__settings__button-change-password"
};

export const PASSWORD_CHANGE = {
  TITLE: "privateProfile__passwordChange__title",
  ICON_CLOSE: "privateProfile__passwordChange__icon-close",
  INPUT_NEW_PASSWORD: "privateProfile__passwordChange__input-new-password",
  INPUT_PASSWORD_CONFIRM:
    "privateProfile__passwordChange__input-password-confirm",
  BUTTON_SUBMIT: "privateProfile__passwordChange__button-submit"
};

export const CONNECTION_CHECK = {
  ICON_CLOSE: "privateProfile_connectionCheck_close",
  ICON_BACK: "privateProfile_connectionCheck_back"
};

export const SUPPORT = {
  TITLE: "privateProfile__support__title",
  ICON_BACK: "privateProfile__support__icon-back",
  ICON_CLOSE: "privateProfile__support__icon-close",
  TEXTAREA: "privateProfile__support__textarea",
  TEXTAREA_ERROR: "privateProfile__support__error-textarea",
  SEND_BUTTON: "privateProfile__support__button-send",
  ERROR: "privateProfile__support__error"
};
