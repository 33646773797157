import React from "react";
import { Box, Button, Container, Text } from "ibolit-ui";
import error from "../../assets/images/error.png";
import styles from "./Statuses.scss";
import { useReplaceModal } from "~/hooks/useModal";
import { NEW_CONSULTATION } from "~/locators/newConsultation";
import { CLINICS_VIEW } from "~/modals/Clinics";

const defaultTestId = "StatusError";

export const NEW_CONSULTATION_ERROR_MODAL = "newConsultationErrorModal";

export default function StatusOccupied({ onClick }) {
  const handleClose = useReplaceModal(
    NEW_CONSULTATION_ERROR_MODAL,
    CLINICS_VIEW
  );

  return (
    <Box
      className={styles.error_status_modal}
      testId={NEW_CONSULTATION.ERROR_STATUS_MODAL}
    >
      <Container direction="column" justify="center">
        <img
          src={error}
          alt="Хьюстон у нас проблемы"
          className={styles.status_img}
        />
        <Text
          tag="h3"
          variant="h3"
          maxLength={60}
          className={styles.status_header}
          testid={NEW_CONSULTATION.ERROR_STATUS_DESC}
        >
          {"Хьюстон, у нас проблемы"}
        </Text>
        <Text
          tag="div"
          variant="desc"
          maxLength={60}
          className={styles.status_desc}
          testid={`${defaultTestId}-repeat`}
        >
          {"Повторите попытку позже"}
        </Text>
        <Button
          fullWidth
          colorVariant="patient"
          onClick={onClick || handleClose}
          testid={NEW_CONSULTATION.ERROR_STATUS_BUTTON}
        >
          Попробовать ещё раз
        </Button>
      </Container>
    </Box>
  );
}
