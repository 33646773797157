import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Text from '../Text';
import styles from './Toast.scss';

const getToastIcon = variant => {
  switch (variant) {
    case 'success':
      return <Icon variant="check-mark" fill="#fff" />;

    case 'error':
      return <Icon variant="fail" fill="#fff" />;

    case 'info':
      return <Icon variant="info" fill="#fff" />;

    default:
      return '';
  }
};

const Toast = ({ variant, text, style, qaId, closeToast, dataTestId }) => {
  const toastIcon = getToastIcon(variant);

  return (
    <div style={style} className={cn(styles.toast, styles[variant])} data-qa={qaId} data-testid={dataTestId}>
      {toastIcon && <div className={styles.toastIcon}>{toastIcon}</div>}
      <Text variant="h5" colorVariant="secondary" className={styles.text}>
        {text}
      </Text>
      <Icon variant="close-tiny" fill="#fff" className={styles.closeIcon} onClick={closeToast} />
    </div>
  );
};

Toast.defaultProps = {
  style: {},
};

Toast.propTypes = {
  /** Вариант отображения. */
  variant: PropTypes.oneOf(['success', 'error', 'info']).isRequired,
  /** Текст который отображается внутри контейнера. */
  text: PropTypes.string.isRequired,
  /** Определение стиля элемента с помощью правил CSS. */
  style: PropTypes.object,
  /** Устанавливает уникальный QA идентификтор. */
  qaId: PropTypes.string,
  /** Функция закрытия тоста, приходит из react-toastify */
  closeToast: PropTypes.func,
};

export default Toast;
