import types from "./consultationTypes";
import { DEFAULT_SEARCH_ITEMS_LIMIT } from "~/consts/values";

export const CONSULTATION_TYPES = {
  DELETE_REPLY: "DELETE_REPLY",
  DOWNLOAD_FILE: "DOWNLOAD_FILE",
  DELETE_FILE: "DELETE_FILE"
};

export default {
  getConsultations: ({ page, mode, limit = DEFAULT_SEARCH_ITEMS_LIMIT }) => ({
    type: types.CONSULTATIONS_GET,
    request: {
      page,
      mode,
      limit
    }
  }),
  getConsultationsSuccess: (payload, request = {}, rest) => ({
    type: types.CONSULTATIONS_GET_SUCCESS,
    payload,
    request,
    rest
  }),
  getConsultationsFailure: (error, request) => ({
    type: types.CONSULTATIONS_GET_FAILURE,
    error,
    request
  }),
  getInfo: id => ({
    type: types.CONSULTATIONS_INFO_GET,
    request: { id }
  }),
  getInfoSuccess: (payload, rest) => ({
    type: types.CONSULTATIONS_INFO_GET_SUCCESS,
    payload,
    rest
  }),
  getInfoFailure: error => ({
    type: types.CONSULTATIONS_INFO_GET_FAILURE,
    error
  }),
  updateItems: items => ({
    type: types.CONSULTATIONS_UPDATE_ITEMS,
    items
  }),
  addFile: file => ({
    type: types.CONSULTATIONS_ADD_FILE,
    file
  }),
  removeFile: file => ({
    type: types.CONSULTATIONS_REMOVE_FILE,
    file
  }),
  uploadFiles: ({ files, consultationId }) => ({
    type: types.CONSULTATION_UPLOAD_FILES,
    files,
    consultationId
  }),
  uploadFile: ({ file, consultationId }) => ({
    type: types.CONSULTATION_UPLOAD_FILE,
    file,
    consultationId
  }),
  updateFile: payload => ({
    type: types.CONSULTATION_UPDATE_FILE,
    payload
  }),
  updateFileError: payload => ({
    type: types.CONSULTATION_UPLOAD_FILE_ERROR,
    payload
  }),
  uploadProgress: ({ fileName, progress }) => ({
    type: types.CONSULTATION_UPLOAD_PROGRESS,
    fileName,
    progress
  }),
  uploadSuccess: ({ fileName }) => ({
    type: types.CONSULTATION_UPLOAD_SUCCESS,
    fileName
  }),
  cancelUpload: ({ fileName }) => ({
    type: types.CONSULTATION_CANCEL_UPLOAD,
    fileName
  }),
  clearFiles: () => ({
    type: types.CONSULTATION_CLEAR_FILES
  }),
  sendFeedback: ({
    isNew,
    consultationId,
    rating,
    comment,
    isFromChat,
    externalId,
    messageId
  }) => ({
    type: types.CONSULTATION_SEND_FEEDBACK,
    payload: {
      isNew,
      consultationId,
      rating,
      comment,
      isFromChat,
      externalId,
      messageId
    }
  }),
  sendFeedbackSuccess: ({ consultationId, feedback }) => ({
    type: types.CONSULTATION_SEND_FEEDBACK_SUCCESS,
    payload: { consultationId, feedback }
  }),
  sendFeedbackFailure: ({ error }) => ({
    type: types.CONSULTATION_SEND_FEEDBACK_FAILURE,
    error
  }),
  deleteFile: ({ fileId, consultationId }) => ({
    type: types.CONSULTATION_DELETE_FILE,
    fileId,
    consultationId
  }),
  deleteFileSuccess: ({ fileId, consultationId }) => ({
    type: types.CONSULTATION_DELETE_FILE_SUCCESS,
    fileId,
    consultationId
  }),
  cancelConsultation: ({ consultationId }) => ({
    type: types.CONSULTATION_CANCEL,
    consultationId
  }),
  deleteConsultationReply: () => ({
    type: CONSULTATION_TYPES.DELETE_REPLY
  })
};

export const downloadFileAction = ({ fileUrl, fileName }) => ({
  type: CONSULTATION_TYPES.DOWNLOAD_FILE,
  fileUrl,
  fileName
});
