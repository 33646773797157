import makeModel from "../../utils/redux/requestModel";

const name = "chats";
export const CHAT_MODE_ALL = "all";
export const CHAT_MODE_NO_READ = "no_read";
export const modes = [CHAT_MODE_ALL, CHAT_MODE_NO_READ];

export const {
  types,
  state,
  actions,
  reducer,
  selector: chatRequestSelector
} = makeModel(name, modes);
