import isEmpty from "lodash/isEmpty";
import React from "react";
import { Text } from "ibolit-ui";
import Article from "~/components/Article";
import TariffItem from "./TariffItem";
import styles from "../Tariffs.scss";

export const TariffCategory = ({ title, items }) => (
  <Article title={title} className={styles.block}>
    {isEmpty(items) ? (
      <Text variant="desc">Услуга не предоставляется</Text>
    ) : (
      items.map(tariff => (
        <TariffItem
          key={`${tariff.id}_${tariff.name}`}
          name={tariff.name}
          is_subscription={tariff.is_subscription}
          price={tariff.price}
          duration={tariff.duration}
          type={tariff.type}
        />
      ))
    )}
  </Article>
);
