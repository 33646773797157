import Types from "./chatTypes";
import { actions } from "~/store/chat/chatModel";

export default {
  ...actions,
  infoRequest: chatId => ({
    type: Types.INFO_REQUEST,
    request: { chatId }
  }),
  infoSuccess: (payload, request = {}, rest) => ({
    type: Types.INFO_SUCCESS,
    payload,
    request,
    rest
  }),
  infoFailure: (error, request) => ({
    type: Types.INFO_FAILURE,
    error,
    request
  }),
  upChat: id => ({
    type: Types.UP_CHAT,
    id
  }),
  chatMetaRequest: () => ({
    type: Types.CHAT_META_REQUEST
  }),
  chatMetaRequestSuccess: meta => ({
    type: Types.CHAT_META_REQUEST_SUCCESS,
    payload: meta
  }),
  createChat: (doctorId, needCloseModal) => ({
    type: Types.CHATS_CREATE_CHAT,
    doctorId,
    needCloseModal
  }),
  checkChat: (doctor, isTryToCreate) => ({
    type: Types.CHATS_CHECK_CHAT,
    doctor,
    isTryToCreate
  }),
  attachSymptoms: data => ({
    type: Types.ATTACH_SYMPTOMS,
    data
  })
};
