import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, IconWithText, Separator, Switch } from "ibolit-ui";
import { getFormatDateOfBirth } from "~/utils/dateUtils";
import { SUBSCRIPTION_VIEW, SubscriptionsButton } from "~/modals/Subscriptions";
import { CONNECTION_CHECK_VIEW } from "~/components/HealthCheck/ConnectionCheckModal";
import { PROFILE_VIEW } from "~/modals/Profile";
import { PRIVATE_PROFILE } from "~/locators/privateProfile";
import ShortUserData from "../ShortUserData";
import { PROFILE_SETTINGS_VIEW } from "~/modals/ProfileSettings";
import styles from "./Profile.scss";
import subscriptionsAction from "~/store/subscriptions/subscriptionsActions";
import profileActions from "~/store/profile/profileActions";
import { getSubscriptionOrder } from "~/store/subscriptions/subscriptionsSelectors";
import { getUser } from "~/store/profile/profileSelectors";

const SEPARATOR_STYLE = {
  marginTop: 8,
  marginBottom: 8
};

const Profile = ({ user, hideModal, replaceModal }) => {
  const userProfile = useSelector(state => getUser(state));
  const isFetching = useSelector(state => state.profile.loadingUserData);

  const shortUserDataDesc = useMemo(
    () => getFormatDateOfBirth(user.date_of_birth),
    [user.date_of_birth]
  );

  const onCheckConnectionClick = useCallback(() => {
    replaceModal(PROFILE_VIEW, CONNECTION_CHECK_VIEW, { userId: user.id });
  }, [user.id]);

  const onSettingsClick = useCallback(() => {
    replaceModal(PROFILE_VIEW, PROFILE_SETTINGS_VIEW);
  });

  const onSubscriptionsClick = useCallback(() => {
    replaceModal(PROFILE_VIEW, SUBSCRIPTION_VIEW);
  });

  const handleSaveVideoChange = useCallback(
    updatedValue => {
      const updatedProfile = { ...userProfile, is_save_video: updatedValue };
      dispatch(profileActions.userDataUpdateRequest(updatedProfile));
    },
    [userProfile]
  );

  const dispatch = useDispatch();
  const hasSubscriptions = useSelector(getSubscriptionOrder).length > 0;

  useEffect(() => {
    dispatch(subscriptionsAction.searchRequest(1, "all"));
  }, []);

  return (
    <Dialog
      testId="ProfileModal"
      onCloseClick={() => hideModal(PROFILE_VIEW)}
      closeTestId={PRIVATE_PROFILE.ICON_CLOSE}
      header="Мой профиль"
      headerTestId={PRIVATE_PROFILE.TITLE}
      boxClassName={styles.modalBox}
    >
      <ShortUserData
        desc={shortUserDataDesc}
        userId={user && user.id}
        srcAvatar={user && user.avatar}
        userName={user && user.full_name}
      />
      <Separator style={SEPARATOR_STYLE} />
      {hasSubscriptions && (
        <>
          <SubscriptionsButton
            onClick={onSubscriptionsClick}
            testid={PRIVATE_PROFILE.SUBSCRIPTIONS}
          />
          <Separator style={SEPARATOR_STYLE} />
        </>
      )}
      <IconWithText
        onClick={onCheckConnectionClick}
        padding
        text="Проверка качества связи"
        iconVariant="check"
        testid={PRIVATE_PROFILE.HEALTH}
      />
      <div className={styles.wrapper}>
        <IconWithText
          padding
          text="Сохранять видео в iBolit"
          iconVariant="movie-reel"
          testid={PRIVATE_PROFILE.SAVE_VIDEOS}
        />
        <Switch
          disabled={isFetching}
          onChange={handleSaveVideoChange}
          id="saveVideosCheckbox"
          checked={userProfile.is_save_video}
        />
      </div>
      <IconWithText
        onClick={onSettingsClick}
        padding
        text="Настройки профиля"
        iconVariant="settings"
        testid={PRIVATE_PROFILE.SETTINGS}
      />
    </Dialog>
  );
};

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  replaceModal: PropTypes.func,
  hideModal: PropTypes.func
};

export default Profile;
