import { useState } from 'react';

const useHover = (enabled = true) => {
  const [isHover, setHover] = useState(false);

  const onMouseOver = () => enabled && setHover(true);
  const onMouseOut = () => enabled && setHover(false);

  return {
    isHover,
    onMouseOver,
    onMouseOut,
  };
};

export default useHover;
