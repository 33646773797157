export { Header } from './Receptions/Header/Header';
export { Tabs, tabsInfo, enumTabsNames, enumTabsIndexes } from './Receptions/Tabs/Tabs';
export { ConsultationInfo, statusInfo } from './Receptions/ConsultationInfo/ConsultationInfo';
export { ConsultationStatus } from './Receptions/ConsultationStatus/ConsultationStatus';
export { ConsultationRating } from './Receptions/ConsultationRating/ConsultationRating';
export * from './Receptions/ConsultationVideoInfo/ConsultationVideoInfo';
export * from './Receptions/ConsultationClinicData/ConsultationClinicData';
export * from './Receptions/ConsultationFiles/ConsultationFiles';
export * from './Receptions/ConsultationAnamnesis/ConsultationAnamnesis';
export { NoConclusionIcon } from './Receptions/ConsultationConclusion/NoConclusionIcon';
export { NoConclusionPlacehoder } from './Receptions/ConsultationConclusion/NoConclusionPlacehoder';
export { ConsultationConclusion } from './Receptions/ConsultationConclusion/ConsultationConclusion';
export { ConsultationComment } from './Receptions/ConsultationComment/ConsultationComment';

export * from './layouts/Chat';
export { StarRating } from './components/StarRating';

export { RatingChatMessage } from './components/RatingChatMessage/RatingChatMessage';

export * from './Receptions/icons';

export * from './utils/dateUtils';