import axios from "./httpService";

export default {
  conclusionsSearch: ({ userId, ...params }) =>
    axios.get(`/v3/conclusions/${userId}`, { params }),
  getConclusion: id => axios.get(`/v3/user/conclusion/${id}`),
  getConclusionPDF: id => axios.get(`/v3/user/conclusion/${id}/pdf`, {
    responseType: 'blob',
  }),
};
