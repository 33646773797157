import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Text from '../Text';

import styles from './CodeInput.scss';

const CodeInput = ({
  onChange,
  codeLength,
  value,
  name,
  autoFocus,
  nativeChange,
  textClassName,
  className,
  testid,
  inputBinds: { ref: inputRef, ...inputBinds },
  symbolsStyle,
}) => {
  const ref = inputRef || useRef(null);

  const onCodeClick = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  const symbols = useMemo(() => {
    const values = [];
    const caretPos = value.length;
    for (let i = 0; i < codeLength; i += 1) {
      const currentValue = value[i];
      const classNameInner = [styles.value];
      if (i === caretPos || (i === codeLength - 1 && codeLength === caretPos)) {
        classNameInner.push(styles.caret);
      }
      const props = {
        variant: 'h3',
        weight: 'bold',
        className: cn(classNameInner, textClassName),
        style: symbolsStyle,
      };
      values.push(
        <Text {...props} key={`${i}`} testid={`reset__password__input-${i}`} onClick={onCodeClick}>
          {currentValue}
        </Text>,
      );
    }
    return values;
  }, [value, textClassName, symbolsStyle]);

  const handleChange = useCallback(event => {
    if (nativeChange && onChange) {
      onChange(event);
    } else if (onChange) {
      const { target } = event;

      onChange(target.value);
    }
  }, []);

  const inputProps = useMemo(
    () => ({
      type: 'text',
      name,
      autoFocus,
      maxLength: codeLength,
      ...inputBinds,
      onChange: inputBinds.onChange || handleChange,
    }),
    [],
  );

  return (
    <>
      <input
        value={value}
        {...inputProps}
        ref={ref}
        className={styles.input}
        data-testid={testid}
      />
      <div className={cn(styles.symbolsContainer, className)}>{symbols}</div>
    </>
  );
};

CodeInput.defaultProps = {
  value: '',
  codeLength: 4,
  autoFocus: true,
  nativeChange: false,
  inputBinds: {},
};

CodeInput.propTypes = {
  /** Устанавливает значение элемента */
  value: PropTypes.string,
  /** автофокус input */
  autoFocus: PropTypes.bool,
  /** Устанавливает обработчик изменения значения элемента */
  onChange: PropTypes.func,
  /** Если true, в обработчик onChange передается аргумент event, вместо value */
  nativeChange: PropTypes.bool,
  /** Определяет уникальное имя элемента формы. */
  name: PropTypes.string,
  /** Передает класс для контейнера */
  className: PropTypes.string,
  /** Передает класс для элемента кода */
  textClassName: PropTypes.string,
  /** Устанавливает необходимую длину кода. */
  codeLength: PropTypes.number,
  /** Устанавливает внешние бинды для инпута (ref, onFocus, onBlur, onChange etc). */
  inputBinds: PropTypes.object,
  /** Устанавливает уникальный QA идентификтор. */
  testid: PropTypes.string,
  /** Устанавливает стили для символов. */
  symbolsStyle: PropTypes.object,
};

export default CodeInput;
