import PropTypes from "prop-types";
import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import ConsultationsList from "./ConsultationsList";
import actions from "~/store/consultation/consultationActions";
import { getDateText } from "~/utils/dateUtils";
import {
  getHasConsultationsItems,
  getIsConsultationsInitialFetched
} from "~/store/consultation/consultationSelectors";
import { DEFAULT_SEARCH_ITEMS_LIMIT } from "~/consts/values";
import {
  CONSULTATIONS_FILTER_STATUS_CLOSED,
  CONSULTATIONS_FILTER_STATUS_OPEN
} from "~/store/consultation/consultationConsts";

const FILTERS = {
  [CONSULTATIONS_FILTER_STATUS_OPEN]: "Будущие",
  [CONSULTATIONS_FILTER_STATUS_CLOSED]: "Прошедшие"
};

const itemsMapper = (order, items) => {
  let last = {};
  return order.reduce((acc, itemId) => {
    const item = items[itemId];
    const current = {
      ...item,
      date: getDateText(item.start_at)
    };
    if (current.date !== last.date) {
      acc[acc.length - 1] && (acc[acc.length - 1].isLast = true);
      acc.push({
        date: current.date,
        time: item.start_at,
        key: item.timeStamp
      });
    }
    last = current;
    acc.push(last);
    return acc;
  }, []);
};

const ConsultationsListContainer = ({
  onItemClick,
  searchRequest,
  id,
  fetched,
  hasItems
}) => {
  const [mode, setMode] = useState(CONSULTATIONS_FILTER_STATUS_OPEN);
  const location = useLocation();

  // Если пациент пришел с пуша (конец консультации), нужно открыть вкладку завершенных консультаций
  useEffect(() => {
    const params = new URLSearchParams(location.search.substring(1));
    const fromPush = params.get("push");

    if (fromPush) {
      setMode(CONSULTATIONS_FILTER_STATUS_CLOSED);
    }
  }, []);

  useEffect(() => {
    searchRequest({ page: 1, mode });
  }, [mode]);

  const handleFetch = useCallback(
    (page = 1) => {
      searchRequest({ page, mode, limit: DEFAULT_SEARCH_ITEMS_LIMIT });
    },
    [mode]
  );

  return (
    <ConsultationsList
      filters={FILTERS}
      filter={mode}
      setFilter={setMode}
      isEmpty={!hasItems}
      selectedItemId={id}
      onItemClick={onItemClick}
      searchRequest={handleFetch}
      itemsMapper={itemsMapper}
      mode={mode}
      fetched={fetched}
      storeKey="consultations"
    />
  );
};

ConsultationsListContainer.propTypes = {
  id: PropTypes.number,
  onItemClick: PropTypes.func.isRequired,
  searchRequest: PropTypes.func.isRequired
};

export default connect(
  state => ({
    hasItems: getHasConsultationsItems(state),
    fetched: getIsConsultationsInitialFetched(state)
  }),
  { searchRequest: actions.getConsultations }
)(ConsultationsListContainer);
