import types from "./profileTypes";

export default {
  userAvatarRequest: file => ({
    type: types.PROFILE_USER_AVATAR_REQUEST,
    file
  }),
  userDataLoadRequest: () => ({
    type: types.PROFILE_USER_DATA_LOAD_REQUEST
  }),
  userDataLoadSuccess: (data, deep = false) => ({
    type: types.PROFILE_USER_DATA_LOAD_SUCCESS,
    data,
    deep
  }),
  userDataUpdateRequest: values => ({
    type: types.PROFILE_USER_DATA_UPDATE_REQUEST,
    values
  }),
  userDataUpdateSuccess: data => ({
    type: types.PROFILE_USER_DATA_UPDATE_SUCCESS,
    data
  }),
  userDataUpdateError: () => ({
    type: types.PROFILE_USER_DATA_UPDATE_ERROR
  }),
  resetStateActions: () => ({
    type: types.PROFILE_RESET_STATE_ACTIONS_SET
  }),
  setProfileDefaultState: () => ({
    type: types.PROFILE_USER_DATA_DEFAULT_STATE_SET
  }),

  setProfileSettingsScene: scene => ({
    type: types.PROFILE_SETTINGS_CHANGE_SCENE,
    settingsScene: scene
  }),

  changePasswordRequest: ({ values, setErrors }) => ({
    type: types.PROFILE_PASSWORD_CHANGE_REQUEST,
    values,
    setErrors
  }),
  changePasswordSuccess: () => ({
    type: types.PROFILE_PASSWORD_CHANGE_SUCCESS
  }),
  changePasswordError: () => ({
    type: types.PROFILE_PASSWORD_CHANGE_ERROR
  }),
  changePasswordValidationError: errors => ({
    type: types.PROFILE_PASSWORD_CHANGE_VALIDATION_ERROR,
    errors
  }),

  changeLoginRequest: ({ values, scene, setErrors }) => ({
    type: types.PROFILE_LOGIN_CHANGE_REQUEST,
    values,
    scene,
    setErrors
  }),
  changeLoginSuccess: (values, scene, timeout) => ({
    type: types.PROFILE_LOGIN_CHANGE_SUCCESS,
    values,
    scene,
    timeout
  }),
  changeLoginError: () => ({
    type: types.PROFILE_LOGIN_CHANGE_ERROR
  }),
  changeLoginValidationError: errors => ({
    type: types.PROFILE_LOGIN_CHANGE_VALIDATION_ERROR,
    errors: {}
  }),

  goBack: () => ({
    type: types.PROFILE_SETTINGS_GO_BACK
  }),

  resetLoginSettings: () => ({
    type: types.PROFILE_LOGIN_RESET_SETTINGS
  }),

  sendCodeRequest: () => ({
    type: types.PROFILE_SETTINGS_CODE_SEND
  }),
  sendCodeSuccess: () => ({
    type: types.PROFILE_SETTINGS_CODE_SEND_SUCCESS
  }),
  sendCodeError: error => ({
    type: types.PROFILE_SETTINGS_CODE_SEND_ERROR,
    error
  }),
  confirmRequest: ({ code, setErrors }) => ({
    type: types.PROFILE_SETTINGS_CONFIRM_REQUEST,
    code,
    setErrors
  }),
  confirmSuccess: value => ({
    type: types.PROFILE_SETTINGS_CONFIRM_SUCCESS,
    value
  }),
  confirmError: () => ({
    type: types.PROFILE_SETTINGS_CONFIRM_ERROR
  }),
  confirmValidationError: errors => ({
    type: types.PROFILE_SETTINGS_CONFIRM_VALIDATION_ERROR,
    errors
  })
};
