import cn from "classnames";
import { ImageLoader, Text, Container } from "ibolit-ui";
import React from "react";
import { formatReplyDate } from "~/utils/dateUtils";
import { pluralizeYears } from "~/utils/pluralize";
import styles from "./DoctorsChat.scss";

const ReplayDate = ({ date }) => (
  <Text variant="desc" colorVariant="tertiary" className={styles.time}>
    {`Врач ответит вам ${formatReplyDate(date)}`}
  </Text>
);

const defaultTestId = "Doctor";

// /v3/clinics/{clinic_id}/specialties
// /v3/clinics/{clinic_id}/doctors?slot_type(могу не передавать)
// /v3/clinics/{clinic_id}/doctors/specialty?specialties[]=1

export function Doctor({ doctor, onButtonClick, testId, testButtonId }) {
  return (
    <div
      className={cn(styles.item, styles.panel)}
      data-testid={testId}
      onClick={() => onButtonClick(doctor)}
    >
      <div className={styles.doctor}>
        <ImageLoader
          src={doctor.avatar}
          className={styles.avatar}
          testid={`${defaultTestId}-avatar`}
        />
        <div className={styles.content}>
          <Text
            className={styles.info}
            variant="desc"
            colorVariant="tertiary"
            testid={`${defaultTestId}-specialty`}
          >
            {doctor.specialty}
          </Text>
          <Text
            className={styles.title}
            variant="h3"
            title={doctor.full_name}
            testid={`${defaultTestId}-fullName`}
          >
            {doctor.full_name}
          </Text>
          <Container>
            {doctor.experience ? (
              <Text
                className={styles.info}
                weight="bold"
                variant="h5"
                colorVariant="tertiary"
                testid={`${defaultTestId}-experience`}
              >
                Опыт работы {pluralizeYears(doctor.experience)}
              </Text>
            ) : null}
            <Text
              className={styles.category}
              variant="h5"
              weight="normal"
              colorVariant="tertiary"
              testid={`${defaultTestId}-category`}
            >
              {doctor.category}
            </Text>
          </Container>
        </div>
      </div>
      {doctor.next_activity_at && <ReplayDate date={doctor.next_activity_at} />}
    </div>
  );
}
