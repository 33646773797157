/* eslint-disable no-param-reassign */
import { useEffect } from "react";
import cn from "classnames";
import VMasker from "vanilla-masker";
import useValidator from "~/hooks/useValidator";

export const maskInput = (value, { mask, pattern }) => {
  if (!value) return "";
  if (mask) {
    return VMasker.toPattern(value, mask);
  }
  if (pattern) {
    try {
      const reg = new RegExp(`[^${pattern}]`);
      return value.replace(reg, "");
    } catch (error) {
      return value;
    }
  }
  return value;
};

const useInput = ({
  inputRef,
  initialValue = null,
  validator,
  mask,
  pattern,
  error: externalError,
  changeHandler,
  submitHandler,
  blurHandler,
  focusHandler,
  validHandler,
  errorHandler,
  validClassName,
  notValidClassName,
  validateOnFocus,
  validateOnBlur,
  className,
  ...inputProps
}) => {
  const { isValid, error, errorSeverity, validate } = useValidator(
    inputRef,
    validator,
    externalError || null,
    initialValue
  );

  useEffect(() => {
    if (mask || pattern) {
      inputRef.current.value = maskInput(initialValue, { mask, pattern });
    } else {
      inputRef.current.value = initialValue;
    }
    changeHandler && changeHandler(inputRef.current.value);
    validate(false);
  }, [initialValue]);

  useEffect(() => {
    validHandler && validHandler(isValid);
    errorHandler && errorHandler(isValid, error, errorSeverity);
  }, [isValid, error, errorSeverity]);

  const onChange = () => {
    if (mask || pattern) {
      inputRef.current.value = maskInput(inputRef.current.value, {
        mask,
        pattern
      });
    }
    validate(!!inputRef.current.error);
    changeHandler && changeHandler(inputRef.current.value);
  };

  const onKeyPress = event => {
    if (
      event.keyCode === 13 ||
      event.charCode === 13 ||
      event.key === "Enter"
    ) {
      inputRef.current.isValid && submitHandler && submitHandler(event);
    }
  };

  const onBlur = event => {
    blurHandler && blurHandler(event);
    validateOnBlur && validate(true);
  };

  const onFocus = event => {
    focusHandler && focusHandler(event);
    validateOnFocus && validate();
  };

  const _className = [className];

  if (isValid !== undefined) {
    if (isValid && validClassName) {
      _className.push(validClassName);
    }
    if (!isValid && notValidClassName) {
      _className.push(notValidClassName);
    }
  }

  return [
    {
      ref: inputRef,
      onBlur,
      onFocus,
      onChange,
      onKeyPress,
      ...inputProps
    },
    cn(_className)
  ];
};

export default useInput;
