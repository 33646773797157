import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Text, Icon } from "ibolit-ui";
import RowWithImage from "~/components/RowWithImage";
import Scrollbar from "~/components/Scrollbar";
import TariffItem from "./TariffItem";
import styles from "./TariffsSelector.scss";

const renderContainer = (props = {}) => (
  <div {...props} className={styles.itemsContainer} />
);

const TariffsSelector = ({ tariffs, selectedTariff, onClick, className }) => {
  const selector = React.cloneElement(
    tariffs.length < 4 ? (
      renderContainer()
    ) : (
      <Scrollbar
        renderView={renderContainer}
        autoHeight
        autoHeightMin={232}
        autoHeightMax={400}
        className={styles.scrollbar}
      />
    ),
    {},
    tariffs.map(tariff => (
      <TariffItem
        tariff={tariff}
        bordered={tariffs.length === 1}
        key={tariff.id}
        onClick={() => onClick(tariff)}
        isSelected={tariff.id === selectedTariff.id}
        dataTestid={`select_tariff_${tariff.id}`}
      />
    ))
  );

  return (
    <div className={cn(styles.container, className)}>
      {selector}
      <RowWithImage
        className={cn(styles.contraindication, {
          [styles.hidden]: !selectedTariff.contraindication
        })}
        image={<Icon variant="info" fill="var(--pink)" />}
        imageClass={styles.icon}
        info={
          <Text variant="caption" colorVariant="tertiary">
            {selectedTariff.contraindication}
          </Text>
        }
      />
    </div>
  );
};

TariffsSelector.propTypes = {
  tariffs: PropTypes.arrayOf(
    PropTypes.shape({
      can_close: PropTypes.bool,
      code: PropTypes.string,
      description: PropTypes.string,
      duration: PropTypes.number,
      id: PropTypes.string,
      is_default: PropTypes.bool,
      is_subscription: PropTypes.bool,
      name: PropTypes.string,
      price: PropTypes.number,
      type: PropTypes.string
    })
  ),
  selectedTariff: PropTypes.shape({
    can_close: PropTypes.bool,
    code: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.number,
    id: PropTypes.string,
    is_default: PropTypes.bool,
    is_subscription: PropTypes.bool,
    name: PropTypes.string,
    price: PropTypes.number,
    type: PropTypes.string,
    contraindication: PropTypes.string
  })
};

export default TariffsSelector;
