import types from "./nativeSocketTypes";

export default {
  newMessage: event => ({
    type: types.NEW_MSG,
    event
  }),
  error: error => ({
    type: types.ERROR,
    error
  })
};
