import Immutable from "seamless-immutable";
import types from "~/store/profile/profileTypes";

const DEFAULT_TIMEOUT = 60;

const initialState = Immutable({
  loadingAvatar: false,
  loadingUserData: false,
  successUpdateUserData: null,
  user: null,

  settingsScene: null,
  settingsBackScene: null,

  loadingChangePassword: false,
  loadingChangeLogin: false,
  loadingConfirm: false,
  settingsLogin: {},
  settingsConfirmCode: "",
  settingsConfirmTimeout: DEFAULT_TIMEOUT
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.PROFILE_USER_DATA_LOAD_REQUEST: {
      return state.merge({
        loadingUserData: true
      });
    }

    case types.PROFILE_USER_DATA_LOAD_SUCCESS: {
      return state.merge(
        {
          loadingUserData: false,
          user: action.data
        },
        { deep: !!action.deep }
      );
    }

    case types.PROFILE_USER_DATA_UPDATE_SUCCESS: {
      return state.merge({
        successUpdateUserData: true,
        loadingUserData: false,
        loadingAvatar: false,
        user: action.data
      });
    }

    case types.PROFILE_USER_AVATAR_REQUEST: {
      return state.merge({
        successUpdateUserData: null,
        loadingAvatar: true
      });
    }

    case types.PROFILE_USER_DATA_UPDATE_REQUEST: {
      return state.merge({
        successUpdateUserData: null,
        loadingUserData: true
      });
    }

    case types.PROFILE_PASSWORD_CHANGE_REQUEST: {
      return state.merge({
        loadingChangePassword: true
      });
    }
    case types.PROFILE_PASSWORD_CHANGE_SUCCESS: {
      return state.merge({
        loadingChangePassword: false
      });
    }
    case types.PROFILE_PASSWORD_CHANGE_ERROR: {
      return state.merge({
        loadingChangePassword: false
      });
    }
    case types.PROFILE_PASSWORD_CHANGE_VALIDATION_ERROR: {
      return state.merge({
        loadingChangePassword: false
      });
    }

    case types.PROFILE_LOGIN_CHANGE_REQUEST: {
      return state.merge({
        loadingChangeLogin: true
      });
    }
    case types.PROFILE_LOGIN_CHANGE_SUCCESS: {
      return state.merge({
        loadingChangeLogin: false,
        settingsLogin: action.values,
        settingsBackScene: action.scene,
        settingsConfirmTimeout: action.timeout
      });
    }
    case types.PROFILE_LOGIN_CHANGE_ERROR: {
      return state.merge({
        loadingChangeLogin: false
      });
    }
    case types.PROFILE_LOGIN_CHANGE_VALIDATION_ERROR: {
      return state.merge({
        loadingChangeLogin: false
      });
    }

    case types.PROFILE_LOGIN_RESET_SETTINGS: {
      return state.merge({
        settingsLogin: {},
        settingsConfirmCode: "",
        settingsBackScene: null
      });
    }

    case types.PROFILE_SETTINGS_CONFIRM_REQUEST: {
      return state.merge({
        loadingConfirm: true,
        settingsConfirmCode: action.code
      });
    }
    case types.PROFILE_SETTINGS_CONFIRM_SUCCESS: {
      return state.merge({
        loadingConfirm: false,
        user: { ...state.user, ...action.value }
      });
    }
    case types.PROFILE_SETTINGS_CONFIRM_VALIDATION_ERROR: {
      return state.merge({
        loadingConfirm: false
      });
    }
    case types.PROFILE_SETTINGS_CONFIRM_ERROR: {
      return state.merge({
        loadingConfirm: false
      });
    }

    case types.PROFILE_SETTINGS_CODE_SEND: {
      return state.merge({
        loadingConfirm: true
      });
    }

    case types.PROFILE_SETTINGS_CODE_SEND_SUCCESS: {
      return state.merge({
        settingsConfirmTimeout: action.timeout,
        loadingConfirm: false
      });
    }
    case types.PROFILE_SETTINGS_CODE_SEND_ERROR: {
      return state.merge({
        loadingConfirm: false
      });
    }

    case types.PROFILE_USER_DATA_DEFAULT_STATE_SET: {
      return state.merge({
        ...initialState
      });
    }

    case types.PROFILE_SETTINGS_CHANGE_SCENE: {
      return state.merge({
        settingsScene: action.settingsScene
      });
    }

    default:
      return state;
  }
};
