import React, { useState, useEffect } from "react";
import { ReactMask } from "react-mask-icon";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "ibolit-ui";
import AUTH_LOCATORS from "~/locators/auth";
import { PHONE_MASK_LOCATORS } from "~/locators/phoneMask";
import Field from "~/components/Field";
import styles from "../../Authorization.scss";
import { getAuthError, getLogin } from "~/store/auth/authSelectors";
import actions from "~/store/auth/authActions";

const testidFieldProps = {
  input: AUTH_LOCATORS.AUTH_LOGIN_INPUT_USER,
  label: AUTH_LOCATORS.AUTH_LOGIN_LABEL_INPUT_USER
};

const Login = () => {
  const [value, setValue] = useState("");
  const login = useSelector(getLogin);
  const error = useSelector(getAuthError);
  const dispatch = useDispatch();
  const handleSubmit = e => {
    e.preventDefault();
    dispatch(actions.checkLoginRequest(value.trim()));
  };
  const errors = typeof error === "string" ? { login: error } : error;
  const [canSubmit, setCanSubmit] = useState(false);

  const handleChange = newValue => setValue(newValue);
  useEffect(() => {
    setCanSubmit(!!value);
  }, [value]);

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Field
        Component={ReactMask}
        initialValue={login}
        errors={errors}
        errorKey="login"
        label="Ваш телефон"
        autoFocus
        submitHandler={handleSubmit}
        validHandler={setCanSubmit}
        value={value}
        onChange={handleChange}
        dataTestIds={{
          mainInput: testidFieldProps.input,
          label: testidFieldProps.label,
          container: PHONE_MASK_LOCATORS.MASK_CONTAINER,
          select: PHONE_MASK_LOCATORS.MASK_SELECT,
          selectTitle: PHONE_MASK_LOCATORS.MASK_SELECT_TITLE,
          input: PHONE_MASK_LOCATORS.MASK_INPUT,
          dropdown: PHONE_MASK_LOCATORS.MASK_DROPDOWN,
          dropdownMenuElement: PHONE_MASK_LOCATORS.dropdownMenuElement,
          dropdownElementImg: PHONE_MASK_LOCATORS.dropdownElementImg,
          dropdownElementCountryName:
            PHONE_MASK_LOCATORS.dropdownElementCountryName,
          dropdownElementLabel: PHONE_MASK_LOCATORS.dropdownElementLabel,
          selectImg: PHONE_MASK_LOCATORS.MASK_SELECT_IMG,
          containerError: PHONE_MASK_LOCATORS.MASK_CONTAINER_ERROR,
          error: `Authorization__user_not_found`
        }}
        testidProps={{ error: "Auth__error" }}
      />
      <Button
        disabled={!canSubmit}
        onClick={handleSubmit}
        fullWidth
        type="submit"
        colorVariant="patient"
        testid={AUTH_LOCATORS.AUTH_LOGIN_BUTTON_SUBMIT_USER}
      >
        Продолжить
      </Button>
    </form>
  );
};

export default Login;
