import React from "react";
import { Text, Box } from "ibolit-ui";
import BlockHeader from "~/components/BlockHeader";
import authLocators from "~/locators/auth";
import styles from "./MessageModal.scss";

const MessageModal = ({ title, message, children }) => (
  <Box className={styles.container}>
    <BlockHeader
      testid={authLocators.AUTH_LOGIN_LOGIN_ERROR_MODAL_TITLE}
      title={title}
    />
    <Text variant="desc" className={styles.messageBody}>
      {message}
    </Text>
    {children}
  </Box>
);

export default MessageModal;
