import React, { useState, useEffect, useCallback } from "react";
import { Button, Dialog, Icon, Label, Scrollbar, Text } from "ibolit-ui";
import { connect, useSelector } from "react-redux";
import cn from "classnames";
import styles from "./NewConsultation.scss";
import DateSelector from "~/modals/NewConsultation/components/DateSelector/DateSelector";
import { NEW_CONSULTATION_CLINICS_MODAL } from "~/modals/NewConsultation/components/SelectClinic/SelectClinic";
import { useCloseModal, useReplaceModal } from "~/hooks/useModal";
import {
  getSelectedClinicPhone,
  getSelectedDate,
  getSelectedDoctor,
  getSelectedSlot,
  getSelectedSlotType
} from "~/store/newConsultation/newConsultationSelectors";
import SelectDoctor from "~/modals/NewConsultation/components/SelectDoctor/SelectDoctor";
import SlotsList from "~/modals/NewConsultation/components/NewConsultation/Slots/SlotsList";
import { DATE_FORMAT, getTzDate } from "~/utils/dateUtils";
import {
  getSlotsLoading,
  getGroupedSlotsByDate,
  getNonWorkingDays
} from "~/store/slots/slotsSelectors";
import newConsultationActions from "../../../../store/newConsultation/newConsultationActions";
import userActions from "../../../../store/users/userActions";
import slotsActions from "~/store/slots/slotsActions";
import {
  getIsUserTariffsLoading,
  getTariffsByUserId,
  getUserById
} from "~/store/users/userSelectors";
import { NEW_CONSULTATION } from "~/locators/newConsultation";
import Loader from "~/components/Loader";
import { NEW_CONSULTATION_TARIFFS_MODAL } from "~/modals/NewConsultation/components/SelectTariff/SelectTariff";
import { formatDurationFrom } from "~/utils/formatDuration";
import { useDispatch } from "react-redux";

export const NEW_CONSULTATION_MODAL = "newConsultationModal";

export const SLOT_TYPES = {
  items: {
    online: {
      id: 0,
      type: "online",
      typeAPI: "av",
      icon: "video",
      title: "Видеоконсультация"
    },
    offline: {
      id: 1,
      type: "offline",
      typeAPI: "offline",
      icon: "hospital",
      title: "Прием в клинике"
    }
  },
  byIds: ["online", "offline"]
};
const onlineType = SLOT_TYPES.items.online.type;
const offlineType = SLOT_TYPES.items.offline.type;

const SelectSlotType = ({
  tariffs,
  selectedDoctor,
  doctorId,
  selectSlotType,
  selectedSlotType,
  selectSlot
}) => {
  const [sortedTariffs, setSelectedTariffs] = useState({});

  const sortTariffByPrice = tariffsList => {
    if (!tariffsList) return;

    let _sortedTariffs = {};
    let _hasOnline = false;
    let _hasOffline = false;

    Object.entries(tariffsList).forEach(([name, tariff]) => {
      if (name !== "chat") {
        if (tariff.length) {
          const sortedByPrice = [...tariff].sort((a, b) => a.price - b.price);
          if (name === "av") {
            _sortedTariffs.online = sortedByPrice;
            _hasOnline = true;
          } else if (name === "offline") {
            _sortedTariffs.offline = sortedByPrice;
            _hasOffline = true;
          }
        }
      }
    });

    if (!_hasOffline && _hasOnline) selectSlotType(onlineType);
    if (!_hasOnline && _hasOffline) selectSlotType(offlineType);
    if (!_hasOnline && !_hasOffline) selectSlotType(null);
    if (_hasOnline && _hasOffline) selectSlotType(offlineType);

    setSelectedTariffs(_sortedTariffs);
  };

  useEffect(() => {
    sortTariffByPrice(tariffs);
  }, [doctorId, tariffs])

  return (
    <Label title="Вид приёма" className={styles.margin_bottom}>
      <div className={styles.box_list}>
        {SLOT_TYPES.byIds.map(id => {
          const { type, icon, title } = SLOT_TYPES.items[id];
          const isDisabled = !sortedTariffs[type];
          const price = !isDisabled ? sortedTariffs[type][0].price : null;
          const minimalTariffDuration = !isDisabled
            ? Math.min(...sortedTariffs[type].map(tariff => tariff.duration))
            : null;
          const isActive = type === selectedSlotType;
          let iconColor;
          if (price === null) iconColor = "var(--silver)";
          if (selectedDoctor && !isActive) iconColor = "var(--grey)";
          if (selectedDoctor && isActive) iconColor = "var(--green)";
          const handleSelectSlotType = () => {
            selectSlot(null);
            selectSlotType(type);
          };

          return (
            <button
              type="button"
              key={type}
              disabled={isDisabled}
              onClick={handleSelectSlotType}
              className={cn(styles.box, {
                [styles.active]: !isDisabled && isActive
              })}
              data-testid={`${NEW_CONSULTATION.SELECT_DOCTOR_INPUT}-${id}`}
            >
              <div className={styles.icon_price}>
                <Icon variant={icon} fill={iconColor} />
                {!isDisabled && (
                  <Text
                    variant="h5"
                    weight="bold"
                    testid={NEW_CONSULTATION.TARIFF_PRICE}
                  >
                    {`От ${price} ₽`}
                  </Text>
                )}
              </div>
              <div className={styles.tariff_data}>
                <Text
                  tag="div"
                  variant="h4"
                  weight="normal"
                  className={cn(styles.text, { [styles.disabled]: isDisabled })}
                >
                  {title}
                </Text>
                {!isDisabled && (
                  <Text
                    variant="sub2"
                    colorVariant="tertiary"
                    testid={NEW_CONSULTATION.MINIMAL_DURATION}
                  >
                    {formatDurationFrom("от", minimalTariffDuration)}
                  </Text>
                )}
              </div>
            </button>
          );
        })}
      </div>
      {selectedDoctor && selectedSlotType === SLOT_TYPES.items.online.type && (
        <div className={styles.box_desc}>
          <Icon variant="info" fill="var(--pink)" />
          <Text
            variant="caption"
            colorVariant="tertiary"
            testid={NEW_CONSULTATION.ONLINE_VIDEO_CAPTION}
          >
            {`Консультация осуществляется через аудио и видео звонок. Текстовый чат не входит в данную услугу.`}
          </Text>
        </div>
      )}
    </Label>
  );
};
// TODO inline style in uikit { Scrollbar }
const SelectSlots = ({ slots, selectedSlot, selectSlot }) => (
  <div className={cn(styles.slotsContainer)}>
    <Scrollbar style={{ width: "103%" }} autoHeight autoHeightMax={200}>
      <SlotsList
        onItemClick={selectSlot}
        slots={slots}
        selected={selectedSlot}
      />
    </Scrollbar>
    {slots.length === 0 && (
      <Text
        variant="desc2"
        colorVariant="tertiary"
        className={styles.no_slots}
        testid={NEW_CONSULTATION.NO_SLOTS_DESC}
      >
        {"Извините, на данную дату нет свободных интервалов для записи"}
      </Text>
    )}
  </div>
);

const SelectDateAndSlot = ({
  groupedSlots,
  nonWorkingDays,
  selectedDoctor,
  selectedDate,
  selectDate,
  selectSlot,
  selectedSlot,
  selectedSlotType,
  selectedClinicPhone
}) => {
  const hasSlots = Object.keys(groupedSlots).length;

  if (
    (selectedDoctor && (!selectedDoctor.self_appointment || Boolean(!hasSlots))) ||
    !selectedSlotType
  ) {
    return (
      <div className={styles.noSlotsText}>
        <Text variant="h5" weight="normal" className={styles.no_slots}>
          У врача отсутсвует самостоятельная запись на прием. Вы можете записаться через регистратуру.
        </Text>
        <Text
          variant="h3"
          colorVariant="error"
          testid={NEW_CONSULTATION.CLINIC_PHONE}
        >
          {selectedClinicPhone}
        </Text>
      </div>
    );
  }

  const slots = groupedSlots[getTzDate(selectedDate).format(DATE_FORMAT)] || [];

  return (
    <Label title="Дата и время" className={styles.margin_bottom}>
      <DateSelector
        disabled={!selectedDoctor}
        date={selectedDate}
        setDate={selectDate}
        disabledDays={nonWorkingDays}
      />
      {selectedDoctor && (
        <SelectSlots
          slots={slots}
          selectedSlot={selectedSlot}
          selectSlot={selectSlot}
        />
      )}
    </Label>
  );
};

const NewConsultation = ({
  selectSlot,
  selectSlotType,
  selectDate,
  isOnlyOneClinic,
  fetchSlots,
  fetchTariffs,
  selectedDoctorProp,
}) => {
  const dispatch = useDispatch();

  const selectedClinicPhone = useSelector(getSelectedClinicPhone);
  let selectedDoctor = useSelector(getSelectedDoctor);
  useEffect(() => {
    if (!selectedDoctor) {
      dispatch(newConsultationActions.selectDoctor(selectedDoctorProp));
    }
    if (selectedDoctorProp) {
      dispatch(userActions.userInfoRequest(selectedDoctorProp.id));
    }
  }, [selectedDoctorProp])
  const selectedSlot = useSelector(getSelectedSlot);
  const selectedSlotType = useSelector(getSelectedSlotType);
  const selectedDate = useSelector(getSelectedDate);
  const groupedSlots = useSelector(getGroupedSlotsByDate);
  const nonWorkingDays = useSelector(getNonWorkingDays);
  const doctorId = selectedDoctor ? selectedDoctor.id : null;
  const memoizedTariffsSelector = useCallback(
    state => getTariffsByUserId(state, { userId: doctorId }),
    [doctorId]
  );
  const tariffs = useSelector(memoizedTariffsSelector);
  const tariffsLoading = useSelector(getIsUserTariffsLoading);
  const slotsLoading = useSelector(getSlotsLoading);
  const handleClose = useCloseModal(NEW_CONSULTATION_MODAL);
  const handleOpenSelectClinic = useReplaceModal(
    NEW_CONSULTATION_MODAL,
    NEW_CONSULTATION_CLINICS_MODAL
  );

  const handleOpenSelectTariff = useReplaceModal(
    NEW_CONSULTATION_MODAL,
    NEW_CONSULTATION_TARIFFS_MODAL
  );

  useEffect(() => {
    if (doctorId) {
      fetchTariffs(doctorId);
    }
  }, [doctorId]);

  useEffect(() => {
    if (Object.keys(groupedSlots).length > 0) {
      selectDate(new Date(Object.keys(groupedSlots)[0]));
    }
  }, [groupedSlots]);

  useEffect(() => {
    if (doctorId && selectedSlotType) {
      fetchSlots(selectedSlotType, doctorId);
    }
  }, [doctorId, selectedSlotType]);

  return (
    <Dialog
      header="Запись на приём"
      onBackClick={
        isOnlyOneClinic
          ? undefined
          : () => {
              handleOpenSelectClinic();
            }
      }
      onCloseClick={handleClose}
      boxClassName={styles.modal}
      testId={NEW_CONSULTATION.MAIN_TITLE}
    >
      <SelectDoctor selectedDoctor={selectedDoctor} />

      <Loader loading={tariffsLoading} className={styles.tariff_loader}>
        <SelectSlotType
          tariffs={tariffs}
          selectedDoctor={selectedDoctor}
          doctorId={doctorId}
          selectedSlotType={selectedSlotType}
          selectSlotType={selectSlotType}
          selectSlot={selectSlot}
        />

        <Loader loading={slotsLoading} className={styles.loader}>
          <SelectDateAndSlot
            groupedSlots={groupedSlots}
            nonWorkingDays={nonWorkingDays}
            selectedDoctor={selectedDoctor}
            selectedClinicPhone={selectedClinicPhone}
            selectedDate={selectedDate}
            selectDate={selectDate}
            selectSlot={selectSlot}
            selectedSlotType={selectedSlotType}
            selectedSlot={selectedSlot}
          />
        </Loader>
      </Loader>

      {!selectedDoctor || !selectedSlotType ? (
        <></>
      ) : (
        <Button
          fullWidth
          colorVariant="patient"
          disabled={!selectedSlot}
          onClick={handleOpenSelectTariff}
          testid={NEW_CONSULTATION.BUTTON_APPOINTMENT}
        >
          Записаться
        </Button>
      )}
    </Dialog>
  );
};

const mapDispatchToProps = {
  selectSlotType: newConsultationActions.selectSlotType,
  selectSlot: newConsultationActions.selectSlot,
  selectDate: newConsultationActions.selectDate,
  fetchSlots: slotsActions.fetchSlots,
  fetchTariffs: userActions.tariffsRequest
};

export default connect(null, mapDispatchToProps)(NewConsultation);
