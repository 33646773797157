import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Label,
  InputPreventAutocomplete,
  Dialog,
  Input
} from "ibolit-ui";
import { ReactMask } from "react-mask-icon";
import styles from "./ChangeCredentialForm.scss";
import FieldError from "~/components/FieldError";
import { getFieldError } from "~/components/FieldError/FieldError";
import Loading from "~/components/Loading/Loading";

import { PHONE_MASK_LOCATORS } from "~/locators/phoneMask";

const ChangeCredentialForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  title,
  loading,
  firstInput,
  secondInput,
  onCloseClick,
  firstInputTestId,
  secondInputTestId,
  submitTestId,
  testIdHeader,
  testIdIconClose,
  testIdIconBack,
  resetForm,
  mask
}) => {
  const firstName = firstInput.name;
  const firstInputValue = values[firstName];
  const firstInputError = errors[firstName];
  const firstInputTouched = touched[firstName];
  const [firstHasError, firstError] = getFieldError(
    firstInputError,
    firstInputTouched
  );

  const secondName = secondInput.name;
  const secondInputValue = values[secondName];
  const secondInputError = errors[secondName];
  const secondInputTouched = touched[secondName];
  const [secondHasError, secondError] = getFieldError(
    secondInputError,
    secondInputTouched
  );

  useEffect(
    () => () => {
      resetForm();
    },
    []
  );

  return (
    <Dialog
      testId="ChangeSettingsModal"
      header={title}
      headerTestId={testIdHeader}
      closeTestId={testIdIconClose}
      backTestId={testIdIconBack}
      onCloseClick={onCloseClick}
      boxClassName={styles.modalBox}
    >
      <form onSubmit={handleSubmit}>
        <Label
          title={firstInput.label}
          className={styles.field}
          testid="ChangeCredentialForm__firstInput"
        >
          {firstInput.type === "password" || firstInput.type === "email" ? (
            <Input
              focus
              nativeChange
              fullWidth
              value={mask(firstInputValue)}
              type={firstInput.type}
              name={firstName}
              onChange={handleChange}
              disabled={loading}
              testidProps={{ input: firstInputTestId }}
              maxlength={firstInput.maxLength}
            />
          ) : (
            <ReactMask
              value={firstInputValue}
              type={firstInput.type}
              name={firstName}
              disabled={loading}
              onChange={newValue =>
                handleChange({
                  target: { value: newValue, name: firstName, id: "" }
                })
              }
              dataTestIds={{
                mainInput: firstInputTestId,
                container: PHONE_MASK_LOCATORS.MASK_CONTAINER,
                select: PHONE_MASK_LOCATORS.MASK_SELECT,
                selectTitle: PHONE_MASK_LOCATORS.MASK_SELECT_TITLE,
                input: PHONE_MASK_LOCATORS.MASK_INPUT,
                dropdown: PHONE_MASK_LOCATORS.MASK_DROPDOWN,
                dropdownMenuElement: PHONE_MASK_LOCATORS.dropdownMenuElement,
                dropdownElementImg: PHONE_MASK_LOCATORS.dropdownElementImg,
                dropdownElementCountryName:
                  PHONE_MASK_LOCATORS.dropdownElementCountryName,
                dropdownElementLabel: PHONE_MASK_LOCATORS.dropdownElementLabel,
                selectImg: PHONE_MASK_LOCATORS.MASK_SELECT_IMG,
                containerError: PHONE_MASK_LOCATORS.MASK_CONTAINER_ERROR
              }}
            />
          )}
          <FieldError error={firstError} showError={firstHasError} />
        </Label>
        <Label
          title={secondInput.label}
          className={styles.field}
          testid="ChangeCredentialForm__secondInput"
        >
          <InputPreventAutocomplete
            nativeChange
            fullWidth
            value={secondInputValue}
            type={secondInput.type}
            name={secondName}
            onChange={handleChange}
            disabled={loading}
            testidProps={{ input: secondInputTestId }}
            maxlength={secondInput.maxLength}
          />
          <FieldError error={secondError} showError={secondHasError} />
        </Label>
        <Button
          testId={submitTestId}
          disabled={loading}
          className={styles.button}
          fullWidth
          colorVariant="patient"
          testid="ChangeCredentialForm__submit"
        >
          Продолжить
        </Button>
      </form>
      <Loading showLoading={loading} />
    </Dialog>
  );
};

ChangeCredentialForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  firstInput: PropTypes.object.isRequired,
  secondInput: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  firstInputTestId: PropTypes.string,
  secondInputTestId: PropTypes.string,
  submitTestId: PropTypes.string,
  testIdHeader: PropTypes.string,
  testIdIconClose: PropTypes.string,
  testIdIconBack: PropTypes.string,
  mask: PropTypes.func
};

ChangeCredentialForm.defaultProps = {
  mask: value => value
};

export default ChangeCredentialForm;
