import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getTzDate } from "~/utils/dateUtils";
import {
  getDocumentById,
  getDocumentConclusion
} from "~/store/documents/documentsSelectors";
import { DOCUMENT_DETAIL } from "~/locators/documents";
import { userSelector } from "~/store/users/userSelectors";
import UserItem from "~/components/UserItem";
import Loading from "~/components/Loading/Loading";
import EntitledBox from "~/components/EntitledBox";
import DocumentFile from "~/layouts/Documents/DocumentFile/DocumentFile";
import styles from "./DocumentDetail.scss";

const EntitledBoxContent = ({ author }) => (
  <UserItem
    className={styles.userItem}
    imageClass={styles.image}
    infoClass={styles.info}
    variant="shape"
    to={`/clinics/doctors/${author.id}`}
    user={author.id}
    testid={DOCUMENT_DETAIL.DOCTOR_CONTAINER}
  />
);

EntitledBoxContent.propTypes = {
  author: PropTypes.shape({
    id: PropTypes.number,
    avatar: PropTypes.string,
    clinicName: PropTypes.string,
    specialties: PropTypes.arrayOf(PropTypes.string),
    full_name: PropTypes.string
  })
};

const FileDownload = ({ file }) => (
  <div className={styles.fileDownload}>
    <h4>Файл</h4>
    <div
      className={styles.fileDownloadOverlay}
      data-testid={DOCUMENT_DETAIL.FILE_CONTAINER}
    >
      <DocumentFile {...file} />
      <a
        href={file.url}
        download
        target="_blank noopener noreferrer"
        data-testid={DOCUMENT_DETAIL.DOWNLOAD_BUTTON}
      >
        <button type="button">Скачать</button>
      </a>
    </div>
  </div>
);

FileDownload.propTypes = {
  file: PropTypes.shape({
    extension: PropTypes.string,
    name: PropTypes.string,
    fileName: PropTypes.string,
    size: PropTypes.number,
    url: PropTypes.string
  })
};

const DocumentDetail = ({ documentId }) => {
  const selectedDocument = useSelector(state =>
    getDocumentById(state, { documentId })
  );
  const conclusion = useSelector(state =>
    getDocumentConclusion(state, {
      conclusionId: selectedDocument ? selectedDocument.conclusion : undefined
    })
  );
  const documentAuthor = useSelector(state =>
    userSelector(state, (selectedDocument || {}).author)
  );

  return selectedDocument ? (
    <div className={styles.container}>
      <div className={styles.titleBlock}>
        <h1 data-testid={DOCUMENT_DETAIL.HEADER}>Врачебное заключение</h1>
        <h4 data-testid={DOCUMENT_DETAIL.DATE}>
          {getTzDate(selectedDocument.created_at).format("DD MMM YYYY в HH:mm")}
        </h4>
      </div>
      <div className={styles.mainInfo}>
        <EntitledBox
          title="Ваш врач"
          titleOffset={8}
          content={<EntitledBoxContent author={documentAuthor} />}
        />
        {selectedDocument.file && <FileDownload file={selectedDocument.file} />}
      </div>
      {conclusion && (
        <div className={styles.conclusion}>
          <h4>Врачебное заключение</h4>
          <p data-testid={DOCUMENT_DETAIL.CONCLUSION}>{conclusion.text}</p>
        </div>
      )}
    </div>
  ) : (
    <Loading />
  );
};

DocumentDetail.propTypes = {
  documentId: PropTypes.number.isRequired
};

export default DocumentDetail;
