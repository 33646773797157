import {
  CHECK_PROMOCODE_ERROR,
  CHECK_PROMOCODE,
  CHECK_PROMOCODE_SUCCESS,
  RESET_PROMOCODE
} from "./actions";

const initialState = {
  newPrice: null,
  error: "",
  isLoading: false,
  promotionCodeId: null
};

export const promocodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_PROMOCODE:
      return {
        ...state,
        isLoading: true,
        error: ""
      };
    case CHECK_PROMOCODE_ERROR:
      return {
        ...state,
        isLoading: false,
        newPrice: null,
        promotionCodeId: null,
        error: action.error
      };
    case CHECK_PROMOCODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        newPrice: action.newPrice,
        promotionCodeId: action.promotionCodeId
      };
    case RESET_PROMOCODE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
