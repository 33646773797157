import moment from "moment-timezone";

export const addZero = value => (value < 10 ? `0${value}` : value);

export const getFormattedTime = time => {
  const res = [];
  const dividerMinutes = time % (60 * 60);
  const minutes = Math.floor(dividerMinutes / 60);
  minutes && res.push(minutes);
  const dividerSeconds = dividerMinutes % 60;
  res.push(addZero(Math.ceil(dividerSeconds)));
  return res.join(":");
};

export const getResendAtTimeout = (last, timeout) => {
  if (!last) return timeout;
  const diff = moment().diff(last, "second");
  if (diff > timeout) return timeout;
  return diff > 0 ? diff : Math.abs(diff) + timeout;
};
