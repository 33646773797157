import cn from "classnames";
import React from "react";
import PropTypes from "prop-types";
import styles from "./Box.scss";
import Icon from "../Icon";
import Text from "../Text";

const Box = ({ testId, className, children, isModalBlock, header }) => (
  <div
    data-testid={testId}
    className={cn(styles.box, isModalBlock && styles.modalBox, className)}
  >
    {header ? (
      <div className={styles.header}>
        {header.onBackClick ? (
          <div
            className={styles.back}
            data-testid={`back_modal_window_${header.test}`}
          >
            <Icon variant="arrow" onClick={header.onBackClick} />
          </div>
        ) : null}
        <Text tag="span" variant="h2" testid="header_date_time_modal_window">
          {header.title}
        </Text>
        {header.onCloseClick ? (
          <div
            className={styles.close}
            data-testid={`close_modal_window_${header.test}`}
          >
            <Icon variant="close" fill="var(--grey)" onClick={header.onCloseClick} />
          </div>
        ) : null}
      </div>
    ) : null}
    {children}
  </div>
);

Box.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Box;
