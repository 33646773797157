import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { Dialog, ImageLoader, Text } from "ibolit-ui";
import { connect, useDispatch, useSelector } from "react-redux";
import newConsultationActions from "~/store/newConsultation/newConsultationActions";
import Loader from "~/components/Loader";
import { useCloseModal } from "~/hooks/useModal";
import styles from "./SelectClinic.scss";
import EmptyStub from "~/components/EmptyStub";
import { getClinicAvatar } from "~/utils/image";
import uiActions from "~/store/ui/uiActions";
import clinicsActions from "~/store/clinics/clinicsActions";
import {
  CLINICS_MODE_SELF_APPOINTMENT,
  clinicsRequestSelector
} from "~/store/clinics/clinicsModel";
import { NEW_CONSULTATION_MODAL } from "~/modals/NewConsultation/components/NewConsultation/NewConsultation";
import { NEW_CONSULTATION } from "~/locators/newConsultation";

export const NEW_CONSULTATION_CLINICS_MODAL = "newConsultationClinicsModal";

const SelectClinic = ({ selectClinic, selectedDoctor }) => {
  const dispatch = useDispatch();
  const { items: clinicList, isFetching, isEmpty } = useSelector(state =>
    clinicsRequestSelector(state, CLINICS_MODE_SELF_APPOINTMENT)
  );

  const clinicListLength = useMemo(() => clinicList.length, [clinicList]);
  const handleClose = useCloseModal(NEW_CONSULTATION_CLINICS_MODAL);
  const handleSelectClinic = clinic => {
    selectClinic(clinic);
    dispatch(
      uiActions.replaceModal(
        NEW_CONSULTATION_CLINICS_MODAL,
        NEW_CONSULTATION_MODAL,
        {
          selectedDoctorProp: selectedDoctor,
        }
      )
    );
  };
  const fetchClinicList = payload => {
    dispatch(clinicsActions.requestSend(payload));
  };

  useEffect(() => {
    fetchClinicList({
      mode: CLINICS_MODE_SELF_APPOINTMENT
    });
  }, []);
  useLayoutEffect(() => {
    if (!isFetching && clinicListLength === 1) {
      // делаем такую проверку т.к. нужно отфиьтровать параметры по is_showcase, а API не готово
      if (clinicList[0].is_showcase) {
        selectClinic(clinicList[0]);
        dispatch(
          uiActions.replaceModal(
            NEW_CONSULTATION_CLINICS_MODAL,
            NEW_CONSULTATION_MODAL,
            {
              isOnlyOneClinic: true,
              selectedDoctorProp: selectedDoctor,
            }
          )
        );
      }
    }
  }, [isFetching, clinicList]);

  // isEmpty здесь не будет работать если в item будут только клиники партнеров/Ситилаб
  return (
    <Loader loading={isFetching}>
      <Dialog
        header="Выберите клинику"
        onCloseClick={handleClose}
        testId={NEW_CONSULTATION.SELECT_CLINIC_MODAL}
      >
        {isEmpty ? (
          <div className={styles.clinic_empty}>
            <EmptyStub centered text="Клиники не найдены" />
          </div>
        ) : (
          <div className={styles.clinic_list}>
            {clinicList.map(clinic => {
              if (!clinic.is_showcase) return <></>;

              return (
                <button
                  key={clinic.id}
                  type="button"
                  onClick={() => handleSelectClinic(clinic)}
                  className={styles.clinic_item}
                  data-testid={NEW_CONSULTATION.SELECT_CLINIC_MODAL_CLINIC}
                >
                  <ImageLoader
                    asBackground
                    src={getClinicAvatar(clinic.avatar)}
                    className={styles.clinic_avatar}
                  />
                  <div className={styles.clinic_content}>
                    <Text
                      className={styles.clinic_title}
                      variant="h4"
                      title={clinic.name}
                    >
                      {clinic.name}
                    </Text>
                    <Text
                      className={styles.clinic_address}
                      title="address"
                      variant="desc"
                      colorVariant="tertiary"
                    >
                      {clinic.street}
                    </Text>
                  </div>
                </button>
              );
            })}
          </div>
        )}
      </Dialog>
    </Loader>
  );
};

const mapDispatchToProps = {
  selectClinic: newConsultationActions.selectClinic
};

export default connect(null, mapDispatchToProps)(SelectClinic);
