import PropTypes from "prop-types";
import React from "react";
import { Route, Switch } from "react-router-dom";
import MainLayout, { Aside, Section } from "~/components/layouts/Main/Main";
import EmptyStub from "~/components/EmptyStub";
import Chat from "./Chat";
import ChatAside from "./ChatAside";

const ChatsLayout = ({ match }) => (
  <MainLayout mode="wide">
    <Aside>
      <ChatAside />
    </Aside>
    <Section>
      <Switch>
        <Route exact path={match.url}>
          <EmptyStub text={"Выберите чат в левой\nколонке"} />
        </Route>
        <Route path={`${match.url}/:chatId`}>
          {({
            match: {
              params: { chatId }
            }
          }) => <Chat chatId={+chatId} />}
        </Route>
      </Switch>
    </Section>
  </MainLayout>
);

ChatsLayout.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  })
};

export default ChatsLayout;
