export default () => {
  try {
    const isWebRTCSupported =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      window.RTCPeerConnection;
    if (window.navigator.userAgent.indexOf("Edge") > -1) {
      return false;
    }
    if (isWebRTCSupported) {
      return true;
    }
    return false;
  } catch (err) {
    console.log("Error in getting WebRTC status", err);
    return false;
  }
};
