import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Button, CodeInput, Dialog, Text } from "ibolit-ui";
import CountDownTextButton from "~/components/CountDownTextButton";
import RP_LOCATORS from "~/locators/resetPassword";
import CodeMessage from "~/components/CodeMessage";
import FieldError from "~/components/FieldError";
import { PASSWORD_CHANGE } from "~/locators/privateProfile";
import { getFieldError } from "~/components/FieldError/FieldError";
import styles from "./ConfirmForm.scss";
import Loading from "~/components/Loading/Loading";

const DEFAULT_TIMEOUT = 60; // 60сек

const ConfirmForm = ({
  loading,
  onCloseClick,
  onBackClick,
  title,
  timeout,
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  handleResend,
  msgHeader,
  msgText,
  msgLabel,
  mask,
  resetForm
}) => {
  const [hasError, error] = getFieldError(errors.code, touched.code);
  useEffect(() => () => resetForm(), []);
  return (
    <Dialog
      testId="ConfirmFormModal"
      header={title}
      onCloseClick={onCloseClick}
      onBackClick={onBackClick}
      headerTestId={PASSWORD_CHANGE.TITLE}
      boxClassName={styles.modalBox}
    >
      <form onSubmit={handleSubmit}>
        <CodeMessage
          header={msgHeader}
          text={msgText}
          testId={RP_LOCATORS.CONFIRM_FORM__MESSAGE}
          textTestId={RP_LOCATORS.CONFIRM_FORM__TITLE}
        />
        <label className={cn(styles.Label)}>
          <Text
            variant="desc"
            weight="bold"
            className={cn(styles.Label_text)}
            testid={RP_LOCATORS.CONFIRM_FORM__CODE_INPUT_LABEL}
          >
            {msgLabel}
          </Text>
          <CodeInput
            textClassName={hasError ? styles.InputError : ""}
            value={mask(values.code)}
            name="code"
            inputBinds={{ onChange: handleChange }}
          />
        </label>
        {hasError && (
          <div className={cn(styles.Error)}>
            <FieldError error={error} showError={hasError} />
          </div>
        )}
        <Button
          className={styles.Confirm}
          colorVariant="patient"
          disabled={hasError || values.code.length !== 4}
          fullWidth
          type="submit"
          testid={RP_LOCATORS.BUTTON_SUBMIT_KEY}
        >
          Продолжить
        </Button>
        <CountDownTextButton
          className={styles.Confirm}
          enabled
          defaultTimeout={timeout}
          type="button"
          text="Запросить код еще раз"
          onClick={handleResend}
          testid={RP_LOCATORS.BUTTON_REQUEST_AGAIN}
        />
      </form>
      <Loading showLoading={loading} />
    </Dialog>
  );
};

ConfirmForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleResend: PropTypes.func.isRequired,
  timeout: PropTypes.number,
  msgHeader: PropTypes.string.isRequired,
  msgText: PropTypes.string.isRequired,
  msgLabel: PropTypes.string.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  mask: PropTypes.func.isRequired
};

ConfirmForm.defaultProps = {
  timeout: DEFAULT_TIMEOUT
};

export default ConfirmForm;
