import React, { useCallback } from "react";
import { Box, Text, Container } from "ibolit-ui";
import { useDispatch, useSelector } from "react-redux";
import styles from "./DoctorsChat.scss";
import { useCloseModal, useReplaceModal } from "~/hooks/useModal";
import { SPECIALIZATION_CHOOSE } from "~/modals/SpecializationChoose/SpecializationChoose";
import { SearchIconInput } from "~/components/SearchIconInput/SearchIconInput";
import { CLINIC_DETAILS_DOCTORS_SEARCH } from "~/locators/clinics";
import { useRequestSearchPagination } from "~/hooks/useRequest";
import clinicsApi from "~/api/clinicsApi";
import Loader from "~/components/Loader";
import PaginationList from "~/modals/DoctorsChat/PagnationList";
import { Header } from "./Header";
import { Doctor } from "./Doctor";
import { CHATS_PRIMARY } from "~/locators/chats";
import uiActions from "~/store/ui/uiActions";
import { CHOOSE_SERVICE_VIEW } from "~/modals/ChooseService/ChooseService";
import { CHAT_SERVICE_TYPE } from "~/store/chat/chatConsts";
import ErrorSwitcher from "~/components/ErrorSwitcher";
import { UndrawDoctorSvg } from "~/components/UndrawDoctorSvg/UndrawDoctorSvg";
import { getClinicById } from "~/store/clinics/clinicsSelectors";
import { selectSpecialization } from "~/store/modals/specializations/selectors";

const defaultTestId = "DoctorChat";

export const DOCTORS_CHAT_VIEW = "doctors_chat";

const DoctorsChat = ({ clinicId }) => {
  const dispatch = useDispatch();
  const onCloseClick = useCloseModal(DOCTORS_CHAT_VIEW);
  const specialtyProps = useSelector(selectSpecialization).specialty;

  const onBackClick = useReplaceModal(
    DOCTORS_CHAT_VIEW,
    SPECIALIZATION_CHOOSE,
    {
      clinicId,
      specialtyProps
    }
  );

  const requestPromise = useCallback(
    params => clinicsApi.getDoctorsByClinicApi(clinicId, params),
    [clinicId]
  );
  const clinicPhone = useSelector(getClinicById(clinicId))?.phone;

  function handleSelectDoctor(doctor) {
    dispatch(
      uiActions.replaceModal(DOCTORS_CHAT_VIEW, CHOOSE_SERVICE_VIEW, {
        onFailure: () => {
          dispatch(uiActions.unsubscribeRelocation(CHAT_SERVICE_TYPE));
        },
        userId: doctor.id,
        doctor,
        clinicId,
        tariffsType: "chat",
        specialtyProps
      })
    );
  }

  const {
    error,
    items,
    canLoadMore,
    initialFetched,
    incrementPage,
    setQuery,
    hasNoItems,
    request
  } = useRequestSearchPagination(requestPromise, {
    limit: 20,
    specialties: [specialtyProps]
  });

  return (
    <ErrorSwitcher hasError={error} repeat={request}>
      <Loader loading={!initialFetched}>
        <Box className={styles.box} isModalBlock>
          <Header onBackClick={onBackClick} onCloseClick={onCloseClick} />
          <SearchIconInput
            placeholder="Поиск по ФИО врача"
            className={styles.search}
            testId={CLINIC_DETAILS_DOCTORS_SEARCH}
            onChange={setQuery}
          />
          <PaginationList
            className={styles.list}
            canLoadMore={canLoadMore}
            loadMore={incrementPage}
            testId={CHATS_PRIMARY.LIST}
          >
            {hasNoItems ? (
              <UndrawDoctor clinicPhone={clinicPhone} />
            ) : (
              <Container
                direction="column"
                justify="center"
                className={styles.list_scrollbar}
              >
                <div className={styles.inside_list}>
                  {items.map(item => {
                    return (
                      <React.Fragment key={`${item.id}-${item.specialty}`}>
                        <Doctor
                          doctor={item}
                          onButtonClick={handleSelectDoctor}
                          testId={CHATS_PRIMARY.ITEM}
                          testButtonId={CHATS_PRIMARY.BUTTON}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </Container>
            )}
          </PaginationList>
        </Box>
      </Loader>
    </ErrorSwitcher>
  );
};

const UndrawDoctor = ({ clinicPhone }) => (
  <Container
    direction="column"
    align="center"
    className={styles.undrawDoctor__container}
  >
    <UndrawDoctorSvg />
    <Text
      className={styles.undrawDoctor__title}
      variant={"h2"}
      color={"black"}
      testid={`${defaultTestId}__undrawDoctor`}
    >
      Никого не нашли
    </Text>
    <Text
      className={styles.undrawDoctor__desc}
      variant={"body1"}
      colorVariant="tertiary"
      testid={`${defaultTestId}__undrawDoctor_text`}
    >
      К сожалению, по вашему запросу врачей не найдено
    </Text>
    <Text
      className={styles.undrawDoctor__phone}
      variant={"body1"}
      colorVariant="tertiary"
      testid={`${defaultTestId}__undrawDoctor_phone-text`}
    >
      Записаться на консультацию можно по телефону
      <Text
        variant={"body1"}
        colorVariant="pink"
        testid={`${defaultTestId}__undrawDoctor_phone`}
      >
        {clinicPhone}
      </Text>
    </Text>
  </Container>
);

export default DoctorsChat;
