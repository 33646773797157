import React from "react";
import { Box, Button, Text } from "ibolit-ui";
import { useCloseModal } from "~/hooks/useModal";
import styles from "./ApproveDeleteModal.scss";
import locators from "~/locators/addingFiles";

export const APPROVE_DELETE_MODAL = "APPROVE_DELETE_MODAL";

const ApproveDeleteModal = ({ handleDeleteFile }) => {
  const handleClose = useCloseModal(APPROVE_DELETE_MODAL);

  return (
    <Box className={styles.box}>
      <div>
        <Text variant="h3" testid={locators.FILE_APPROVE_DELETE_MODAL_TITLE}>
          Удалить файл?
        </Text>
        <Text
          className={styles.desc}
          variant="desc"
          colorVariant="tertiary"
          testid={locators.FILE_APPROVE_DELETE_MODAL_DESC}
        >
          Удаленный файл будет недоступен для просмотра пациенту!
        </Text>
      </div>
      <div className={styles.dialogActions}>
        <Button
          variant="secondary"
          colorVariant="danger"
          fullWidth
          onClick={() => {
            handleDeleteFile();
            handleClose();
          }}
          testid={locators.FILE_APPROVE_DELETE_BUTTON_ACCEPT}
        >
          Да
        </Button>
        <Button
          variant="tertiary"
          fullWidth
          onClick={handleClose}
          testid={locators.FILE_APPROVE_DELETE_BUTTON_DECLINE}
        >
          Нет
        </Button>
      </div>
    </Box>
  );
};

export default ApproveDeleteModal;
