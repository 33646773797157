import React, { useCallback, useState, useRef } from "react";
import PropTypes from "prop-types";
import Scrollbars from "react-custom-scrollbars";
import cn from "classnames";
import styles from "./Scrollbar.scss";

const renderThumbHorizontal = props => (
  <div {...props} className={styles.thumbHorizontal} />
);

const renderThumbVertical = props => (
  <div {...props} className={styles.thumbVertical} />
);

const renderTrackVertical = props => (
  <div {...props} className={styles.trackVertical} />
);

const renderTrackHorizontal = props => (
  <div {...props} className={styles.trackHorizontal} />
);

const Scrollbar = React.forwardRef(
  (
    {
      onUpdate,
      style = {},
      children,
      showHasTopOverflow,
      showHasBottomOverflow,
      showTrack,
      className,
      onToggleScroll,
      onReady,
      ...rest
    },
    forwardedRef
  ) => {
    const hasScroll = useRef(null);
    const isReady = useRef(false);
    const prevClientHeight = useRef(null);
    const [topOpacity, setTopOpacity] = useState(0);
    const [bottomOpacity, setBottomOpacity] = useState(0);

    const handleUpdate = useCallback(values => {
      onUpdate && onUpdate(values);
      const { scrollTop, scrollHeight, clientHeight } = values;
      if (
        !isReady.current &&
        // on start these values equal 1
        values.clientHeight !== 1 &&
        values.clientWidth !== 1
      ) {
        // wait for full render ??
        if (prevClientHeight.current === clientHeight) {
          isReady.current = true;
          onReady && onReady();
        }
      }
      if (onToggleScroll && hasScroll.current !== scrollHeight > clientHeight) {
        const _hasScroll = scrollHeight > clientHeight;
        isReady.current && onToggleScroll(_hasScroll);
        hasScroll.current = _hasScroll;
      }
      const showTop = scrollTop > 0;
      const bottomScrollTop = scrollTop + clientHeight;
      const showBottom = bottomScrollTop < scrollHeight;
      showHasTopOverflow && topOpacity !== showTop && setTopOpacity(+showTop);
      showHasBottomOverflow &&
        bottomOpacity !== showBottom &&
        setBottomOpacity(+showBottom);
      prevClientHeight.current = clientHeight;
    }, []);

    return (
      <>
        <Scrollbars
          hideTracksWhenNotNeeded
          {...rest}
          className={cn(className, { [styles.showTrack]: showTrack })}
          ref={forwardedRef}
          style={{ ...style, overflow: "hidden" }}
          renderThumbHorizontal={renderThumbHorizontal}
          renderThumbVertical={renderThumbVertical}
          renderTrackHorizontal={renderTrackHorizontal}
          renderTrackVertical={renderTrackVertical}
          onUpdate={handleUpdate}
        >
          {children}
        </Scrollbars>
        {showHasTopOverflow && (
          <div
            className={cn(styles.indicator, styles.topOverflow)}
            style={{ opacity: topOpacity }}
          />
        )}
        {showHasBottomOverflow && (
          <div
            className={cn(styles.indicator, styles.bottomOverflow)}
            style={{ opacity: bottomOpacity }}
          />
        )}
      </>
    );
  }
);

Scrollbar.propTypes = {
  onScroll: PropTypes.func,
  onScrollFrame: PropTypes.func,
  onScrollStart: PropTypes.func,
  onScrollStop: PropTypes.func,
  onUpdate: PropTypes.func,
  renderView: PropTypes.func,
  renderTrackHorizontal: PropTypes.func,
  renderTrackVertical: PropTypes.func,
  renderThumbHorizontal: PropTypes.func,
  renderThumbVertical: PropTypes.func,
  tagName: PropTypes.string,
  thumbSize: PropTypes.number,
  thumbMinSize: PropTypes.number,
  hideTracksWhenNotNeeded: PropTypes.bool,
  autoHide: PropTypes.bool,
  autoHideTimeout: PropTypes.number,
  autoHideDuration: PropTypes.number,
  autoHeight: PropTypes.bool,
  autoHeightMin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  autoHeightMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  universal: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
  showHasTopOverflow: PropTypes.bool,
  showHasBottomOverflow: PropTypes.bool
};

export default Scrollbar;
