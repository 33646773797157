import React from "react";
import { Spinner } from "ibolit-ui";
import styles from "./Loader.scss";

const Loader = ({ loading, children, className }) =>
  loading ? (
    <div className={className} data-testid="Spinner">
      <Spinner className={styles.wrap} />
    </div>
  ) : (
    children
  );

export default Loader;
