import { useState, useEffect } from "react";

const useInfiniteLoad = (
  { scrollRef, loading, loadMore, canLoadMore },
  loadOffset = 100
) => {
  const [needLoad, setNeedLoad] = useState();

  const maxBottom = Math.min(loadOffset / 2, 30);

  useEffect(() => {
    if (needLoad && !loading) {
      loadMore && loadMore();
    }
  }, [needLoad]);

  useEffect(() => {
    if (!loading) {
      setNeedLoad(false);
    }
  }, [loading]);

  const handleScroll = ({ scrollTop, scrollHeight, clientHeight }) => {
    if (canLoadMore) {
      const bottom = scrollHeight - scrollTop - clientHeight;
      bottom < loadOffset && !loading && setNeedLoad(true);
      // hook to prevent stick scroll to top when new items loaded
      if (bottom < maxBottom) {
        scrollRef.current.view.scrollTop =
          scrollHeight - clientHeight - maxBottom;
      }
    }
  };
  return [handleScroll];
};

export default useInfiniteLoad;
