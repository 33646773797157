import cn from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import getElementCoords from '../../utils/helpers';
import styles from './Tooltip.scss';

const SPACING = 10;

const getTooltipPosition = (container, tooltip, pos, offset) => {
  const { left: bodyLeft, top: bodyTop } = getElementCoords(container.current);

  const tooltipWidth = tooltip.current.offsetWidth;
  const tooltipHeight = tooltip.current.offsetHeight;

  const containerWidth = container.current.offsetWidth;
  const containerHeight = container.current.offsetHeight;

  let left;
  let top;

  switch (pos) {
    case 'top':
      left = bodyLeft + containerWidth / 2 - tooltipWidth / 2 + offset;
      top = bodyTop - tooltipHeight - SPACING;
      break;

    case 'left':
      left = bodyLeft - 4;
      top = bodyTop - tooltipHeight - SPACING + offset;
      break;

    case 'right':
      left = bodyLeft + containerWidth - tooltipWidth + 4;
      top = bodyTop - tooltipHeight - SPACING + offset;
      break;

    case 'bottom':
      left = bodyLeft + containerWidth / 2 - tooltipWidth / 2 + offset;
      top = bodyTop + containerHeight + SPACING;
      break;

    default:
      left = 0;
      top = 0;
  }

  return { left, top };
};

const Tooltip = ({
  header,
  children,
  variant,
  position,
  clickable,
  offset,
  domRoot,
  initialOpened,
  opened,
  className,
  portalClassName,
  triangleClassName,
}) => {
  const containerRef = useRef();
  const tooltipRef = useRef();

  const [tooltipStyles, setTooltipStyles] = useState({});
  const [show, setShow] = useState(initialOpened);

  useEffect(() => {
    const setCoordinate = function setCoordinate() {
      if (show) {
        const coords = getTooltipPosition(containerRef, tooltipRef, position, offset);
        setTooltipStyles(coords);
      }
    };
    setCoordinate();
    window.addEventListener('resize', setCoordinate);
    return () => {
      window.removeEventListener('resize', setCoordinate);
    };
  }, [show, position, offset]);

  useEffect(() => {
    setShow(opened);
  }, [opened]);

  const behavior = clickable
    ? { onClick: () => setShow(!show) }
    : {
        onMouseLeave: () => {
          setShow(false);
        },
        onMouseEnter: () => {
          setShow(true);
        },
      };

  return (
    <div className={cn(styles.container, className)} {...behavior}>
      <div ref={containerRef} className={styles.header}>
        {show && (
          <div
            className={cn(styles.arrow, styles[position], {
              [styles.arrow_default]: variant === 'default',
              [styles.arrow_warning]: variant === 'warning',
            })}
          />
        )}
        {header}
      </div>
      {show &&
        ReactDom.createPortal(
          <div
            className={cn(styles.tooltip, portalClassName, {
              [styles.tooltip_default]: variant === 'default',
              [styles.tooltip_warning]: variant === 'warning',
            })}
            ref={tooltipRef}
            style={tooltipStyles}
          >
            {triangleClassName && (<div className={cn(styles.triangle, triangleClassName)} />)}
            {children}
          </div>,
          domRoot,
        )}
    </div>
  );
};

Tooltip.defaultProps = {
  position: 'top',
  variant: 'default',
  clickable: false,
  offset: 0,
  initialOpened: false,
  domRoot: document.body,
};

Tooltip.propTypes = {
  initialOpened: PropTypes.bool,
  opened: PropTypes.bool,
  domRoot: PropTypes.instanceOf(Element),
  offset: PropTypes.number,
  variant: PropTypes.oneOf(['default', 'warning']),
  clickable: PropTypes.bool,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  header: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  portalClassName: PropTypes.string,
  triangleClassName: PropTypes.string,
};

export default Tooltip;
