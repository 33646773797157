import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {useShowModal, useCloseModal, useShowVideoModal} from "~/hooks/useModal";

import { CANCEL_CONSULTATION } from "~/modals/CancelConsultation/CancelConsultation";
import { SYMPTOMS_VIEW } from "~/modals/NewConsultation/components/Symptoms/Symptoms";
import { CONSULTATION_VIDEO_VIEW } from "~/layouts/Consultations/Consultation/components/ConsultationVideoModal/ConsultationVideoModal";

import { CONSULTATION_STATUS } from "~/store/consultation/consultationConsts";

import { getIsConsultationInProgress } from "~/store/consultation/consultationSelectors";
import { getClinicById } from "~/store/clinics/clinicsSelectors";

import consultationActions from "~/store/consultation/consultationActions";

import { usePayment } from "~/hooks/usePayment";

import callActions from "~/store/call/callActions";

import {
  ConsultationInfo,
  ConsultationStatus,
  ConsultationRating,
  ConsultationVideoInfo,
  ConsultationClinicData,
  ConsultationComment
} from "ibolit-components";

import { getTariffsByUserId } from "~/store/users/userSelectors";

import styles from "../ConsultationPage.scss";

import {
  returnButtonsData,
  returnConsultationStatusText
} from "./contentCreators";
import { PROMO_MODAL_VIEW } from "~/modals/Promo/PromoModal";

export const ConsultationInfoPage = ({
                                       consultationData,
                                       doctorData,
                                       consultationStatusInfo,
                                       setConclusionTab,
                                       isTariffTypeOffline
                                     }) => {
  const dispatch = useDispatch();

  const isInProgress = getIsConsultationInProgress(
      consultationData.status,
      consultationData.start_at
  );

  const clinicData = useSelector(getClinicById(consultationData.clinic));

  const handleCancelConfirmation = useShowModal(CANCEL_CONSULTATION, {
    handleSubmitCancel: () =>
        dispatch(
            consultationActions.cancelConsultation({
              consultationId: consultationData.id
            })
        )
  });

  const handleStartCall = consultationId => () =>
      dispatch(callActions.callStart({ consultationId }));

  const {
    tariff_price: tariffPrice,
    tariff_id: tariffId,
    id,
    doctor: doctorId,
    status,
    problem,
    type,
    can_apply_promocode,
    media
  } = consultationData;

  const videos = media && ((media.url && [media]) || media.videos);

  const { paid, showPaymentModal } = usePayment(
      false,
      text,
      tariffPrice,
      tariffId,
      id,
      "all",
      doctorId,
      undefined,
      () => null
  );

  const showPromocodeModal = useShowModal(PROMO_MODAL_VIEW, {
    tariffId,
    onSuccess: () => null,
    onFailure: () => null,
    promoModalProps: {
      price: tariffPrice,
      tariffId,
      slotType: "av",
      isFromInfoPage: true
    },
    price: tariffPrice,
    consultationId: consultationData.id
  });

  function handlePaymentClick() {
    if (paid) return null;

    if (can_apply_promocode) {
      return showPromocodeModal();
    }

    return showPaymentModal();
  }

  const handleOpenSymptoms = useShowModal(SYMPTOMS_VIEW, {
    id: String(id),
    doctor: doctorData,
    tariffId: String(tariffId),
    tariffType: type,
    isBackendCreated: consultationData.problem?.status === "auto",
    onClose: useCloseModal(SYMPTOMS_VIEW)
  });

  function handleDescribeProblem() {
    if (problem?.status === "auto") {
      handleOpenSymptoms();
    }
  }

  const closeVideoModal = useCloseModal(CONSULTATION_VIDEO_VIEW);
  const openVideoModal = useShowVideoModal(CONSULTATION_VIDEO_VIEW, {
    patientId: consultationData.patient,
    onClose: closeVideoModal
  });

  const renderConsultationVideos =  () => {
    if (videos && videos.length > 0) {

      return videos.map(video => (
          <ConsultationVideoInfo
              videoUrl={video.url}
              openVideoModal={openVideoModal}
          />
      ));
    }

    return media?.will_be_available &&
    !statusIsCanceled &&
    !isTariffTypeOffline ? (
        <ConsultationVideoInfo />
    ) : null;
  } ;

  const tarrifDescription = useSelector(state =>
      getTariffsByUserId(state, {
        userId: consultationData.doctor
      })
  )?.[consultationData.tariff_type]?.find(
      tariffData => String(tariffData.id) === String(consultationData.tariff_id)
  )?.description;

  if (!clinicData) {
    return null;
  }

  const { text, label, duration } = returnConsultationStatusText(
      consultationData,
      tarrifDescription,
      isInProgress
  );

  if (!clinicData) {
    return null;
  }

  const statusIsCanceled =
      status === CONSULTATION_STATUS.CANCELED ||
      status === CONSULTATION_STATUS.EXPIRED ||
      status === CONSULTATION_STATUS.NOT_APPEARED;

  return (
      <div className={styles.consultationContainer}>
        <ConsultationInfo
            text={consultationStatusInfo.text}
            icon={consultationStatusInfo.icon}
            label={consultationStatusInfo.label}
            textClassName={consultationStatusInfo.textClassName}
            header={consultationStatusInfo.header}
            iconFill={"#FF537C"}
        />
        <ConsultationStatus
            label={label}
            duration={duration}
            text={text}
            buttonsData={returnButtonsData({
              consultationData,
              status,
              isInProgress,
              hCancelConfirmation: handleCancelConfirmation,
              hPay: handlePaymentClick,
              hDescribeProblem: handleDescribeProblem,
              hEnterConsultation: handleStartCall(id),
              hShowTab: setConclusionTab,
              clinicPhone: clinicData.phone,
              isTariffTypeOffline
            })}
            color="pink"
        />
        {consultationData?.comment ? (
            <ConsultationComment
                comment={consultationData.comment}
                title="Ваш комментарий"
            />
        ) : null}
        {consultationData?.rating ? (
            <ConsultationRating
                feedback={consultationData.rating}
                key={consultationData.rating.id}
                title={"Отзыв пациента"}
                withIcon
                noDate
            />
        ) : null}

        {renderConsultationVideos()}

        {!statusIsCanceled && clinicData ? (
            <ConsultationClinicData
                logoImageSrc={clinicData.avatar}
                clinicName={clinicData.name}
                phone={clinicData.phone}
                clinicId={clinicData.id}
            />
        ) : null}
      </div>
  );
};
