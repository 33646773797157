import React from "react";
import { take, put, select, fork, actionChannel } from "redux-saga/effects";
import { toast } from "react-toastify";
import _ from "lodash";
import webSocketTypes from "~/store/webscoket/wsTypes";
import Notification from "~/components/Notification";
import { getTitle, messageTypes } from "~/layouts/Chats/utils/parseMessage";
import { getCurrentUserId } from "~/store/auth/authSelectors";
import { currentRouteSelector } from "~/store/routerSelectors";
import logger from "~/utils/Logger";

function* showNotification(_message) {
  try {
    const [messageProps, options = {}] = _.castArray(_message);
    toast(<Notification {...messageProps} />, {
      containerId: "notification",
      ...options
    });
  } catch (error) {
    yield emitError(error, "showNotification");
  }
}

function* emitError(error, source) {
  yield put({ type: `@@err/NOTIFICATION_ERROR`, source, error: error.message });
}

const getChatNotification = (message, notice = {}) => {
  const { icon, title } = getTitle(message);
  notice.iconVariant = icon;
  notice.title = title;
  switch (message.type) {
    case messageTypes.comment:
      notice.text = "Новое сообщение в чате";
      break;
    case messageTypes.photo:
    case messageTypes.file:
      notice.text = "Новый файл в чате";
      break;
    case messageTypes.system:
      notice.text = message.body;
      break;
    case messageTypes.text:
      notice.iconVariant = "chat";
      notice.title = " Новое сообщение в чате";
      break;
    case messageTypes.service: {
      if (!message.consultation) break;
      notice.title = "Новая консультация назначена";
      break;
    }
    default:
      notice = null;
      break;
  }
  return notice;
};

function* watchSocketMessages() {
  const socketChannel = yield actionChannel(Object.values(webSocketTypes));
  while (true) {
    const { event, type } = yield take(socketChannel);
    const currentUserId = yield select(getCurrentUserId);
    const currentRoute = yield select(currentRouteSelector);
    let notification;
    switch (type) {
      case webSocketTypes.WS_CHAT_MESSAGE: {
        const target = `/chats/${event.id}`;
        if (currentRoute === target) break;
        if (currentUserId === event.messages[0].author_id) break;
        notification = getChatNotification(event.messages[0], { link: target });
        break;
      }
      case webSocketTypes.WS_CONSULTATION_PAID: {
        const target = `/consultations/${event.id}`;
        if (currentRoute === target) break;
        notification = {
          iconVariant: "cart",
          title: `Пациент ${
            _.get(event, "price.price", event.price) === 0
              ? "подтвердил"
              : "оплатил"
          } консультацию`,
          link: target
        };
        break;
      }
      default:
        logger.warn(
          `notificationSaga.watchSocketMessages: Сокет-событие не поддерживается: ${JSON.stringify(
            event
          )}`
        );
    }
    try {
      if (notification && (notification.text || notification.title)) {
        yield showNotification(notification);
      }
    } catch (error) {
      yield emitError(error, "processSocketMessage");
    }
  }
}

export default [fork(watchSocketMessages)];
