import React from "react";
import PropTypes from "prop-types";
import { Icon } from "ibolit-ui";
import styles from "./IconButton.scss";

const IconButton = ({ type, iconVariant, fill, hasState, onClick, testid }) => (
  // eslint-disable-next-line react/button-has-type
  <button
    onClick={onClick}
    type={type}
    className={styles.button}
    data-testid={testid}
  >
    <Icon variant={iconVariant} fill={fill} hasState={hasState} />
  </button>
);

IconButton.defaultProps = {
  type: "submit",
  hasState: false
};

IconButton.propTypes = {
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  iconVariant: PropTypes.string.isRequired,
  fill: PropTypes.string,
  hasState: PropTypes.bool,
  onClick: PropTypes.func,
  testid: PropTypes.string
};

export default IconButton;
