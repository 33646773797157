import types from "./conclusionTypes";
import { actions } from "~/store/conclusions/conclusionModel";

export default {
  ...actions,
  searchRequest: (userId, page, search, mode, limit = 100) => ({
    type: types.SEARCH_REQUEST,
    request: { userId, page, search, mode, limit }
  }),
  searchSuccess: (payload, request = {}, rest) => ({
    type: types.SEARCH_SUCCESS,
    payload,
    request,
    rest
  }),
  searchFailure: (error, request) => ({
    type: types.SEARCH_FAILURE,
    error,
    request
  }),

  getConclusion: id => ({
    type: types.GET_CONCLUSION,
    id
  }),
  getConclusionSuccess: payload => ({
    type: types.GET_CONCLUSION_SUCCESS,
    payload
  }),
  getConclusionFailure: () => ({
    type: types.GET_CONCLUSION_FAILURE
  }),
  
};

export const getConcusionPDF = id => ({
  type: types.GET_CONCLUSION_PDF,
  payload: {
    id,
  }
});