import React from "react";
import { Box, Button, Text } from "ibolit-ui";
import { useCloseModal } from "~/hooks/useModal";
import styles from "./FileAlertModal.scss";
import locators from "~/locators/addingFiles";

export const FILE_ALERT_MODAL = "FILE_ALERT_MODAL";

const FileAlertModal = ({ filesNames, bigFilesCount }) => {
  const handleClose = useCloseModal(FILE_ALERT_MODAL);
  const titleSentence =
    bigFilesCount === 1 ? "Файл слишком большой" : "Файлы слишком большие";
  const infoSentence =
    bigFilesCount === 1
      ? `Файл ${filesNames} не будет загружен, так как превышает размер 100мб`
      : `Файлы ${filesNames} не будут загружены, так как их размер превышает 100мб`;

  return (
    <Box className={styles.box}>
      <div>
        <Text variant="h3" testid={locators.FILE_ALERT_MODAL_TITLE}>
          {titleSentence}
        </Text>
        <Text
          className={styles.desc}
          variant="desc"
          colorVariant="tertiary"
          testid={locators.FILE_ALERT_MODAL_DESC}
        >
          {infoSentence}
        </Text>
      </div>
      <Button
        variant="tertiary"
        fullWidth
        onClick={handleClose}
        testid={locators.FILE_ALERT_MODAL_BUTTON}
      >
        Хорошо
      </Button>
    </Box>
  );
};

export default FileAlertModal;
