import React from "react";
import cn from 'classnames';
import PropTypes from "prop-types";
import classNames from "classnames";
import FadeGroup from "~/components/FadeGroup";
import styles from "./Main.scss";

const Aside = ({ children }) => (
  <aside className={classNames(styles.aside)}>{children}</aside>
);

const Section = ({ children, className }) => (
  <FadeGroup className={cn(styles.section, className)}>{children}</FadeGroup>
);

export const Main = ({ children, className, mode }) => (
  <main className={classNames(styles.main, styles[mode], className)}>
    {children}
  </main>
);

Main.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.oneOf(["wide", "small"])
};

export { Aside, Section };

export default Main;
