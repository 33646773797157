import { all, put, call, takeLatest } from "redux-saga/effects";
import moment from "moment-timezone";
import types from "./appStartedTypes";
import appStartedActions from "./appStartedAction";
import { startUp as authStartUp } from "~/store/auth/authSaga";

function* startSaga() {
  try {
    yield call(authStartUp);

    moment.locale("RU");

    yield put(appStartedActions.appStartedSuccess());
  } catch (error) {
    yield put(appStartedActions.appStartedFailure(error));
  }
}

export default function* appStartedSaga() {
  yield all([takeLatest(types.APP_STARTED, startSaga)]);
}
