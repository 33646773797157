import React from "react";
import { Icon, Text } from "ibolit-ui";
import styles from "./ClinicInfo.scss";
import { getClinicAddress } from "~/layouts/Clinics/utils";
import { ClinicSchedule } from "~/layouts/Clinics/ClinicDetails/ClinicInfo/ClinicSchedule";

const ClinicInfo = ({ clinic }) => (
  <div className={styles.container}>
    <div className={styles.address}>
      <Text variant="h3" colorVariant="tertiary" testid="ClinicInfo__address">
        Адрес
      </Text>
      <Text variant="desc" testid="ClinicInfo__address_name">
        {getClinicAddress(clinic)}
      </Text>
    </div>
    <ClinicSchedule schedule={clinic.schedule} testid="ClinicSchedule" />
    <div className={styles.contacts}>
      <Text variant="h3" colorVariant="tertiary" testid="ClinicInfo__contacts">
        Контакты
      </Text>
      {clinic.phone && (
        <div className={styles.contact}>
          <Icon className={styles.icon} variant="call" fill="var(--pink)" />
          <div>
            <a href={`tel:${clinic.phone}`} className={styles.link}>
              <Text variant="h4" testid="ClinicInfo__clinic-phone">
                {clinic.phone}
              </Text>
            </a>
            <Text
              colorVariant="tertiary"
              variant="desc"
              testid="ClinicInfo__registry"
            >
              Регистратура
            </Text>
          </div>
        </div>
      )}
      {clinic.email && (
        <div className={styles.contact}>
          <Icon className={styles.icon} variant="email" fill="var(--pink)" />
          <div>
            <a href={`mailto:${clinic.email}`} className={styles.link}>
              <Text variant="h4" testid="ClinicInfo__email">
                {clinic.email}
              </Text>
            </a>
            <Text
              colorVariant="tertiary"
              variant="desc"
              testid="ClinicInfo__email_label"
            >
              Электронная почта
            </Text>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default ClinicInfo;
