import PropTypes from 'prop-types';
import cn from 'classnames';
import React from 'react';
import styles from './IconBadge.scss';

const IconBadge = ({ badge }) => {
  const hidden = !badge;
  return (
    <span
      className={
        cn(
          styles.badge,
          {
            [styles.hidden]: hidden,
            [styles.number]: typeof badge === 'number' && badge,
          },
        )
      }
    >
      {typeof badge === 'number' && badge > 0 && badge}
    </span>
  );
};

IconBadge.propTypes = {
  badge: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default IconBadge;
