import React from "react";
import classnames from "classnames";

import { HumanWithPenIcon } from "./HumanWithPenIcon";
import { InfoIcon } from "./InfoIcon";

// @ts-expect-error
import styles from "./ConsultationAnamnesis.scss";

const LabelWithText = ({ label, text }) => (
  <div className={styles.labelWithTextContainer}>
    <div
      className={styles.label}
      data-testid="Receptions__ConsultationAnamnesis__label"
    >
      {label}
    </div>
    <div
      className={styles.text}
      data-testid="Receptions__ConsultationAnamnesis__text"
    >
      {text}
    </div>
  </div>
);

const rateData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const LabelWithDiscomfortRate = ({ label, discomfortLevel, type }) => (
  <div className={styles.labelWithTextContainer}>
    <div
      className={styles.label}
      data-testid="Receptions__ConsultationAnamnesis__label_with_rate"
    >
      {label}
    </div>
    <div className={styles.discomfortRateContainer}>
      {rateData.map((number) => (
        <div
          className={classnames(
            styles.rateData,
            number <= discomfortLevel &&
              Number(discomfortLevel) !== 0 &&
              (type !== "doctor"
                ? styles.activeRateData
                : styles.doctorActiveRate)
          )}
          key={number}
          data-testid="Receptions__ConsultationAnamnesis__rate_number"
        >
          {number}
        </div>
      ))}
    </div>
  </div>
);

export const ConsultationAnamnesis = ({
  daysCount,
  discomfortLevel,
  type,
  problemTitle,
  onEndConsultation,
  isAvailableEndConsultation,
}) => {
  const [isShowInfo, setIsShowInfo] = React.useState(false);

  return (
    <div className={styles.container}>
      <LabelWithText label="Жалоба" text={problemTitle} />
      <LabelWithText
        label="Жалоба наблюдается"
        text={`Количество дней: ${daysCount || "0"}`}
      />
      <LabelWithDiscomfortRate
        label="Уровень дискомфорта"
        discomfortLevel={discomfortLevel}
        type={type}
      />
      <div className={styles.humanIconContainer}>
        <HumanWithPenIcon
          className={classnames(
            type === "doctor" ? styles.doctorColor : styles.patientColor,
            styles.humanIcon
          )}
        />
        {isAvailableEndConsultation ? (
          <>
            <div className={styles.infoIconContainer}>
              <div
                onMouseEnter={() => setIsShowInfo(true)}
                onMouseLeave={() => setIsShowInfo(false)}
              >
                <InfoIcon className={styles.infoIcon} />
              </div>
              {isShowInfo ? (
                <div className={styles.infoText}>
                  Нажав эту кнопку, вы досрочно закроете видео комнату приема
                  {"\n"}и сможете приступить к заполнению заключения.
                </div>
              ) : null}
            </div>
            <div className={styles.endConsultationButtonContainer}>
              <button
                className={styles.endConsultationButton}
                onClick={onEndConsultation}
              >
                Завершить прием
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
