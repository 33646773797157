import React from "react";
import { Text } from "ibolit-ui";
import formatPrice from "~/utils/formatPrice";
import styles from "./TariffsSelector.scss";
import { formatDuration } from "~/utils/formatDuration";

const PriceBlock = ({ price, old_price, discount_text, duration }) => (
  <div>
    <div className={styles.priceRow}>
      <Text tag="span" variant="h2">
        {formatPrice(price)}
      </Text>
      {old_price && (
        <Text
          tag="span"
          variant="h3"
          colorVariant="tertiary"
          className={styles.oldPrice}
        >
          {formatPrice(old_price)}
        </Text>
      )}
    </div>
    {discount_text && (
      <Text variant="caption" colorVariant="error" style={{ marginBottom: 4 }}>
        {discount_text}
      </Text>
    )}
    <Text variant="caption" colorVariant="tertiary">
      {formatDuration("Длительность", duration)}
    </Text>
  </div>
);

export default PriceBlock;
