import React, { useEffect } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tabs } from "ibolit-ui";
import BlockHeader from "~/components/BlockHeader";
import SearchBlock from "~/components/SearchBlock";
import ChatsList from "./ChatsList";
import { CHAT_BUTTON_PRIMARY, CHATS_LIST } from "~/locators/chats";
import {
  CHAT_MODE_ALL,
  CHAT_MODE_NO_READ,
  chatRequestSelector
} from "~/store/chat/chatModel";
import styles from "./ChatAside.scss";
import { useFilteredPaginator } from "~/hooks/usePaginator";
import chatsActions from "~/store/chat/chatActions";
import { getHasPrimaryAcceptance } from "~/store/chat/chatSelectors";
import FadeItem from "~/layouts/Chats/ChatAside/FadeItem";
import { useShowModal } from "~/hooks/useModal";
import { CLINICS_VIEW } from "~/modals/Clinics";
import { CHAT_SEARCH_ITEMS_LIMIT } from "~/store/chat/chatConsts";
import { PROMO_MODAL_VIEW } from "~/modals/Promo/PromoModal";

const TABS_VALUES = {
  [CHAT_MODE_ALL]: "Все",
  [CHAT_MODE_NO_READ]: "Непрочитанные"
};

const ChatAside = () => {
  const listRef = React.createRef();

  const {
    items,
    isFetching,
    initialFetched,
    canLoadMore,
    hasErrors,
    isEmpty,
    setSearch,
    mode,
    setMode,
    incrementPage
  } = useFilteredPaginator({
    initialFetched,
    selector: chatRequestSelector,
    initialMode: CHAT_MODE_ALL,
    ref: listRef,
    limit: CHAT_SEARCH_ITEMS_LIMIT,
    sendAction: chatsActions.requestSend
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(chatsActions.chatMetaRequest());
  }, []);

  const showPrimaryClinics = useShowModal(CLINICS_VIEW);
  const hasPrimaryAcceptance = useSelector(getHasPrimaryAcceptance);
  const onTabChange = React.useCallback(newMode => {
    dispatch(chatsActions.changeMode(newMode));
    setMode(newMode);
  }, []);
  const [isTabsHide, toggleTabs] = React.useState(false);

  const onSearchChange = React.useCallback(value => {
    if (isTabsHide && value === "") {
      toggleTabs(false);
    }
    if (!isTabsHide && value && value.length > 0) {
      toggleTabs(true);
    }
    setSearch(value);
  });

  return (
    <div className={classNames(styles.container)}>
      <BlockHeader title="Сообщения" testid={CHATS_LIST.HEADER} />
      <FadeItem show={hasPrimaryAcceptance}>
        <div
          className={classNames(styles.block, {
            [styles.block.hidden]: isEmpty
          })}
        >
          <Button
            fullWidth
            colorVariant="patient"
            onClick={showPrimaryClinics}
            className={styles.button_icon}
            testid={CHAT_BUTTON_PRIMARY}
          >
            Записаться на консультацию
          </Button>
        </div>
      </FadeItem>
      <div
        className={classNames(styles.block, { [styles.block.hidden]: isEmpty })}
      >
        <SearchBlock
          placeholder="Поиск по чатам"
          testId={CHATS_LIST.INPUT_SEARCH}
          onSearchChange={onSearchChange}
          className={styles.search}
        />
      </div>
      <div
        className={classNames(styles.block, { [styles.block.hidden]: isEmpty })}
      >
        <Tabs
          values={TABS_VALUES}
          value={mode}
          hideTabs={isTabsHide}
          onChange={onTabChange}
          textStyles={{ color: "var(--pink)" }}
          buttonActiveStyles={{ backgroundColor: "rgba(var(--pinkRGB), 0.1)" }}
          textActiveStyles={{ color: "var(--pink)" }}
          className={classNames(styles.tabs, {
            [styles.block.hidden]: isEmpty
          })}
        />
      </div>
      <ChatsList
        ref={listRef}
        items={items}
        incrementPage={incrementPage}
        isFetching={isFetching}
        initialFetched={initialFetched}
        canLoadMore={canLoadMore}
        mode={mode}
        hasErrors={hasErrors}
        isEmpty={isEmpty}
      />
    </div>
  );
};

export default ChatAside;
