import React from "react";
import { Text } from "ibolit-ui";
import PaymentButton from "~/components/Payment/PaymentButton";
import styles from "./ChatPayment.scss";
import { formatReplyDate } from "~/utils/dateUtils";
import { CHAT_FOOTER } from "~/locators/chats";

const ChatPayment = ({ partnerId, replayDate, doctor }) => (
  <div className={styles.container}>
    <div>
      <div className={styles.titles}>
        <Text variant="h4">Оплатите доступ к чату</Text>
        {!!replayDate && (
          <Text
            variant="desc"
            colorVariant="tertiary"
            className={styles.titles_date}
          >
            {`Врач ответит вам ${formatReplyDate(replayDate)}`}
          </Text>
        )}
      </div>
      <Text variant="h5">Выберите подходящий тариф и продолжите общение</Text>
    </div>
    <PaymentButton
      className={styles.button}
      tariffsType="chat"
      testid={CHAT_FOOTER.BUTTON_CHAT_PAID}
      userId={partnerId}
      doctor={doctor}
      text="Оплатить чат"
    />
  </div>
);

export default ChatPayment;
