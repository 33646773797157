import React from "react";

import { imageExtensionsRegExp, videoExtensionsRegExp } from "~/utils/regExps";

export const FilePreview = ({
  previewUrl,
  url,
  extenstion,
  previewContainerClassName,
  previewImgClassName
}) => {
  imageExtensionsRegExp.lastIndex = 0;
  videoExtensionsRegExp.lastIndex = 0;
  const isImage = imageExtensionsRegExp.test(extenstion);
  const isVideo = videoExtensionsRegExp.test(extenstion);

  if (isImage) {
    return (
      <div className={previewContainerClassName}>
        <img className={previewImgClassName} src={previewUrl} alt="" />
      </div>
    );
  }

  if (isVideo) {
    return (
      <div className={previewContainerClassName}>
        <video className={previewImgClassName} src={url} alt="" />
      </div>
    );
  }

  return null;
};
