import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Text from '../Text';
import styles from './ActiveText.scss';

const phonePattern = '[8|\\+7][-]?\\(?\\d{3,4}\\)?[-]?[\\d-]{6,10}[\\d]';
const emailPattern = '[a-zа-я\\d._-]+@[a-zа-я\\d-]+\\.[a-zа-я,\\d]+';
const urlPattern = 'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=$]*)';

const pattern = `(${phonePattern}|${emailPattern}|${urlPattern})`;

const commonPattern = new RegExp(pattern, 'ig');
const phoneReg = new RegExp(phonePattern);
const emailReg = new RegExp(emailPattern, 'i');
const urlReg = new RegExp(urlPattern, 'i');

const replacer = text =>
  text.replace(commonPattern, str => {
    if (phoneReg.test(str)) return `|$phone${str}|`;
    if (emailReg.test(str)) return `|$email${str}|`;
    if (urlReg.test(str)) return `|$https${str}|`;
    return str;
  });

const Phone = ({ phone, className }) => (
  <a className={cn(styles.link, className)} href={`tel:${phone}`}>
    {phone}
  </a>
);
Phone.propTypes = {
  phone: PropTypes.string,
  className: PropTypes.string,
};

const Email = ({ email, className }) => (
  <a className={cn(styles.link, className)} href={`mailto:${email}`}>
    {email}
  </a>
);
Email.propTypes = {
  email: PropTypes.string,
  className: PropTypes.string,
};

const TextLink = ({ url, className }) => (
  <a className={cn(styles.link, className)} href={url}>
    {url}
  </a>
);
TextLink.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
};

const ActiveText = ({ text, activeClassName, textProps }) => {
  const replacedText = replacer(text);
  const textParts = replacedText
    .split('|')
    .filter(Boolean)
    .map((str, index) => {
      const flag = str.slice(0, 6);
      if (flag === '$phone') {
        const phone = str.slice(6);
        return <Phone key={index} phone={phone} className={activeClassName} />;
      }
      if (flag === '$email') {
        const email = str.slice(6);
        return <Email key={index} email={email} className={activeClassName} />;
      }
      if (flag === '$https') {
        const url = str.slice(6);
        return <TextLink key={index} url={url} className={activeClassName} />;
      }
      return (
        <Text key={index} tag="span" {...textProps}>
          {str.split('').map(char => (char === '\n' ? <br /> : char))}
        </Text>
      );
    });

  return <>{textParts}</>;
};

ActiveText.propTypes = {
  /** Текст содержащий активные элементы */
  text: PropTypes.string,
  /** Класс активных элементов */
  activeClassName: PropTypes.string,
  /** Свойтсва для элементов Text */
  textProps: PropTypes.object,
};

ActiveText.defaultProps = {
  textProps: {},
};

export default ActiveText;
