export const CHECK_PROMOCODE = "CHECK_PROMOCODE";
export const CHECK_PROMOCODE_SUCCESS = "CHECK_PROMOCODE_SUCCESS";
export const CHECK_PROMOCODE_ERROR = "CHECK_PROMOCODE_ERROR";
export const RESET_PROMOCODE = "RESET_PROMOCODE";

export const checkPromocodeAction = value => ({
  type: CHECK_PROMOCODE,
  value
});

export const checkPromocodeSuccessAction = (newPrice, promotionCodeId) => ({
  type: CHECK_PROMOCODE_SUCCESS,
  newPrice,
  promotionCodeId
});

export const checkPromocodeErrorAction = error => ({
  type: CHECK_PROMOCODE_ERROR,
  error
});

export const resetPromocodeAction = () => ({
  type: RESET_PROMOCODE
});
