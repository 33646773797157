import React, { forwardRef } from "react";
import { useRouteMatch } from "react-router-dom";
import Link from "~/components/Link";
import Loading from "~/components/Loading/Loading";
import EmptyStub from "~/components/EmptyStub";
import { LOAD_DATA_ERROR } from "~/consts/texts";
import InfiniteList from "~/components/InfiniteList";
import styles from "./ClinicsList.scss";
import Panel from "~/layouts/Clinics/ClinicsAside/ClinicsList/Panel/Panel";
import { getClinicAvatar } from "~/utils/image";
import { getClinicAddress } from "~/layouts/Clinics/utils";
import { CLINICS_LIST_ITEM } from "~/locators/clinics";

const ClinicsList = (
  {
    items,
    isFetching,
    initialFetched,
    canLoadMore,
    hasErrors,
    isEmpty,
    incrementPage
  },
  ref
) => {
  const match = useRouteMatch();
  const loadingProps = isEmpty ? {} : { variant: "row", height: 80 };
  return (
    <InfiniteList
      ref={ref}
      className={styles.list}
      canLoadMore={canLoadMore}
      loadMore={incrementPage}
    >
      {items.map(item => (
        <Link
          key={item.id}
          to={`${match.url}/${item.id}`}
          className={styles.link}
          activeClassName={styles.selected}
          data-testid={CLINICS_LIST_ITEM}
        >
          <Panel
            avatar={getClinicAvatar(item.avatar)}
            title={item.name}
            desc={getClinicAddress(item)}
          />
        </Link>
      ))}
      {isFetching && <Loading {...loadingProps} />}
      {!initialFetched && !hasErrors && (
        <EmptyStub text="Загружаем список клиник" centered />
      )}
      {hasErrors && <EmptyStub text={LOAD_DATA_ERROR} centered />}
      {isEmpty && !isFetching && (
        <EmptyStub centered text="Нет доступных клиник" />
      )}
    </InfiniteList>
  );
};

export default forwardRef(ClinicsList);
