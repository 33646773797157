import React, {
  forwardRef,
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  useCallback,
  useMemo
} from "react";
import PropTypes from "prop-types";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import Scrollbar from "~/components/Scrollbar";
import ItemsList from "./components/ItemsList";

const ItemsListContainer = forwardRef(
  (
    {
      mode,
      filter,
      subMode,
      onReady,
      storeKey,
      onItemClick,
      fetchRequest,
      onToggleScroll,
      autoSelectIfOne,
      itemsMapper,
      ...rest
    },
    ref
  ) => {
    const selectStateByStoreKey = useMemo(stateSelector, []);
    const {
      page,
      params,
      isSuccess,
      isRequest,
      search,
      canLoadMore,
      items
    } = useSelector(state =>
      selectStateByStoreKey(state, { mode, storeKey, itemsMapper, filter })
    );

    const listRef = ref || useRef(null);
    const [isReady, setIsReady] = useState(!!items.length && !!page);

    useEffect(() => {
      // do initial request
      !isReady && fetchRequest(1, search, mode);
    }, []);

    useEffect(() => {
      // reset ready on mode/subMode change
      isReady && setIsReady(!!items.length);
      listRef.current && listRef.current.scrollToTop();
    }, [mode, subMode]);

    useEffect(() => {
      if (!isRequest && page && isSuccess) {
        // начальный fetch прошел
        !isReady && setIsReady(true);
      }
    }, [isRequest]);

    const handleLoadMore = useCallback(() => {
      canLoadMore && fetchRequest(page + 1, search, mode);
    }, [canLoadMore, page, search, mode]);

    useLayoutEffect(() => {
      if (isReady) {
        onReady && onReady(items);
        autoSelectIfOne && items.length === 1 && onItemClick(items[0]);
      }
    }, [isReady]);

    return (
      <ItemsList
        {...rest}
        params={params}
        items={items}
        mode={mode}
        loadMore={handleLoadMore}
        canLoadMore={canLoadMore && !isRequest}
        onItemClick={onItemClick}
        loading={isRequest}
        isReady={isReady}
        listRef={listRef}
        onToggleScroll={onToggleScroll}
      />
    );
  }
);

const checkFilter = (target, needle) => {
  if (!needle || !target) return target;
  if (typeof needle === "string" || Array.isArray(needle)) {
    return (Array.isArray(needle) ? needle : [needle]).reduce(
      (acc, key) => (!acc || !acc[key] ? null : acc),
      target
    );
  }
  if (typeof needle === "object" && Object.entries(needle).length) {
    return Object.entries(needle).reduce(
      (acc, [key, value]) => (!acc || acc[key] !== value ? null : acc),
      target
    );
  }
  if (typeof needle === "function") return needle(target) && target;
  return target;
};

const defaultItemsMapper = (order, items, filter) =>
  order.reduce((acc, itemId) => {
    const item = checkFilter(items[itemId], filter);
    item && acc.push(item);
    return acc;
  }, []);

const stateByModeSelector = createSelector(
  (state, { storeKey }) => state[storeKey] || {},
  (_, { mode }) => mode,
  ({ items, ...state }, mode) => {
    return {
      ...((mode ? state[mode] : state) || {}),
      items
    };
  }
);

const stateSelector = () =>
  createSelector(
    stateByModeSelector,
    (_, { itemsMapper }) => itemsMapper || defaultItemsMapper,
    (_, { filter }) => filter,
    (
      {
        items,
        params,
        order = [],
        page,
        isFetching,
        initialFetched,
        search = null,
        canLoadMore = true
      },
      itemsMapper,
      filter
    ) => ({
      page,
      search,
      params,
      isRequest: isFetching,
      isSuccess: initialFetched,
      canLoadMore,
      items: itemsMapper(order, items, filter)
    })
  );

ItemsListContainer.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  storeKey: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  itemsMapper: PropTypes.func,
  fetchRequest: PropTypes.func,
  mode: PropTypes.string,
  filter: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.func
  ]),
  onReady: PropTypes.func,
  onItemClick: PropTypes.func,
  selectedItemId: PropTypes.number,
  minHeight: Scrollbar.propTypes.autoHeightMin,
  maxHeight: Scrollbar.propTypes.autoHeightMax,
  onToggleScroll: PropTypes.func,
  autoSelectIfOne: PropTypes.bool,
  testid: PropTypes.string
};

export default ItemsListContainer;
