/**
 * ROOT SAGA
 * Attempts to restart provided saga branches automatically on error
 */
import { all, call } from "redux-saga/effects";
import toastifySaga from "./toast/toastSaga";
import notificationSaga from "./webscoket/notificationSaga";
import commonSaga from "./common/commonSaga";
import appStartedSaga from "./appStarted/appStartedSaga";
import profileSaga from "./profile/profileSaga";
import feedbackSaga from "./feedback/feedbackSaga";
import subscriptionsSaga from "./subscriptions/subscriptionsSaga";
import faqSaga from "./faq/faqSaga";
import authSaga from "./auth/authSaga";
import usersSaga from "./users/userSaga";
import chatSaga from "./chat/chatSaga";
import documentsSaga from "~/store/documents/documentsSaga";
import clinicsSaga from "./clinics/clinicsSaga";
import webSocketSaga from "./webscoket/wsSaga";
import messagesSaga from "./messages/messagesSaga";
import toolbarSaga from "./toolbar/toolbarSaga";
import slotsSaga from "./slots/slotsSaga";
import consultationSaga from "./consultation/consultationSaga";
import conclusionsSaga from "./conclusions/conclusionSaga";
import callSaga from "./call/callSaga";
import pushNotificationSaga from "./pushNotifications/pushNotificationSaga";
import servicesSaga from "./services/servicesSaga";
import nativeSocketSaga from "~/store/nativeSocket/nativeSocketSaga";
import uiSaga from "./ui/uiSaga";
import newConsultationSaga from "./newConsultation/newConsultationSaga";
import promocodeSaga from "./promocode/saga";

export default function* rootSaga() {
  yield all([
    ...commonSaga,
    ...toastifySaga,
    ...notificationSaga,
    call(authSaga),
    call(chatSaga),
    call(clinicsSaga),
    call(documentsSaga),
    call(usersSaga),
    call(appStartedSaga),
    call(profileSaga),
    call(feedbackSaga),
    call(subscriptionsSaga),
    call(faqSaga),
    call(messagesSaga),
    call(toolbarSaga),
    call(slotsSaga),
    call(consultationSaga),
    call(conclusionsSaga),
    call(pushNotificationSaga),
    call(servicesSaga),
    call(uiSaga),
    call(newConsultationSaga),
    ...webSocketSaga,
    ...nativeSocketSaga,
    call(callSaga),
    call(promocodeSaga)
  ]);
}
