import axios from "./httpService";

export default {
  // params: { enable_referral: bool, is_showcase: bool, primary_acceptance: bool }
  clinicList: params =>
    axios.get(`/v3/user/clinics`, {
      params
    }),
  // params: { page: number, limit: number, query: string, primary_acceptance: bool }
  getDoctorsByClinicApi: (clinicId, params) =>
    axios.get(`/v3/clinics/${clinicId}/doctors/specialty`, {
      params
    }),

  getSpecialitiesApi: (clinic_id, params) =>
    axios.get(`/v3/clinics/${clinic_id}/specialties`, {
      params
    }),

  // Ветка back IBT-877

  // Список клиник пациента для записи на слот - https://ibolitapiv31.docs.apiary.io/#reference/7/75/0
  // [GET] https://api.ibolit.pro/api/v3/user/clinics-slot?type={tariff_type}&primary_acceptance
  // tariff_type:string - тип тарифа (online|offline|both)
  // primary_acceptance?: string - в клинике есть доктор с первичным приемом
  clinicListForSlots: ({ type = "both", ...params }) =>
    axios.get(`/v3/user/clinics-slot`, { params: { type, ...params } }),

  // Список врачей с слотами - https://ibolitapiv31.docs.apiary.io/#reference/7/722/c
  // [GET] https://api.ibolit.pro/api/v3/clinics/{clinic_id}/doctors?slot_type={type}
  // clinic_id: number - id клиники
  // slot_type: string - фильтр по слотам доктора ['online', 'offline', 'both']
  doctorsByClinicWithSlots: (clinicId, params) =>
    axios.get(`/v3/clinics/${clinicId}/doctors`, { params }),

  // Список клиник пациента для чата - https://ibolitapiv31.docs.apiary.io/#reference/7/74/0
  // [GET] https://api.ibolit.pro/api/v3/user/clinics-chat?primary_acceptance
  // primary_acceptance?: string - в клинике есть доктор с первичным приемом
  clinicListForChat: params => axios.get(`/v3/user/clinics-chat`, { params })
};
