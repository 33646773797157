import React from "react";
import { Text, TextTruncate } from "ibolit-ui";

const TextElement = <Text colorVariant="tertiary" variant="descTitle" />;

const UserInfo = ({ user = {}, infoLines }) => {
  const { specialties = [] } = user;
  const fullInfo = specialties.filter(Boolean).join(", ");
  return infoLines ? (
    <TextTruncate
      line={infoLines}
      textElement={TextElement}
      text={fullInfo}
      title={fullInfo}
    />
  ) : (
    React.cloneElement(TextElement, {}, fullInfo)
  );
};

export default UserInfo;
