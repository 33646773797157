import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./ButtonInput.scss";

const ButtonInput = ({ onClick, children, testId }) => (
  <button
    className={cn(styles.ButtonInput)}
    type="button"
    onClick={onClick}
    testid={testId}
  >
    {children}
  </button>
);

ButtonInput.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node
};

export default ButtonInput;
