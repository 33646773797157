import React from "react";

import cn from "classnames";

import styles from "./ConsultationStatus.scss";

import { CancelIcon } from "./CancelIcon";

export const ConsultationStatus = ({ text, label, duration, buttonsData }) => {
  return (
    <div
      className={cn(styles.container, !!label && styles.containerWithButton)}
    >
      <div className={styles.textContainer}>
        <div
          className={styles.header}
          data-testid="Receptions__ConsultationStatus__label"
        >
          {label}
        </div>
        {duration ? (
          <div className={styles.duration}
          data-testid="Receptions__ConsultationStatus__duration"
        >
            <span
              className={styles.duration__label}
              data-testid="Receptions__ConsultationStatus__duration-label"
            >
              Длительность:
            </span>{" "}
            {duration} мин
          </div>
        ) : null}
        <div
          className={styles.text}
          data-testid="Receptions__ConsultationStatus__text"
        >
          {text}
        </div>
      </div>
      <div className={cn(styles.buttonsContainer)}>
        {buttonsData.map(({ label, type, color, onClick, phone }) =>
          type !== "link" ? (
            <button
              className={cn(
                color === "transparent" && styles.button_transparent,
                color === "gray" && styles.button_gray,
                type === "cancel" && styles.button_cancel,
                color === "pink" && styles.button_pink,
                type === "submit" && styles.button_submit,
                styles.button
              )}
              onClick={onClick}
              key={label}
              disabled={type === "disabled"}
              data-testid="Receptions__ConsultationStatus__button"
            >
              {type === "cancel" ? <CancelIcon /> : null}
              <div>{label}</div>
            </button>
          ) : (
            <a
              className={cn(styles.button, styles.button_submit)}
              href={`tel:${phone}`}
              key={label}
              data-testid="Receptions__ConsultationStatus__button_link"
            >
              <div>{label}</div>
            </a>
          )
        )}
      </div>
    </div>
  );
};
