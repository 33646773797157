import * as Sentry from "@sentry/browser";

const SENTRY_KEY =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "https://e6554c013f6a40bfa034b3bbf9162a82@sentry.ibolit.pro/4"
    : "https://e6554c013f6a40bfa034b3bbf9162a82@sentry.ibolit.pro/4";

// Sentry.init({
//   dsn: SENTRY_KEY
// });
