import types from "~/store/feedback/feedbackTypes";

export default {
  sendRequest: values => ({
    type: types.FEEDBACK_SEND_REQUEST,
    values
  }),
  sendSuccess: values => ({
    type: types.FEEDBACK_SEND_SUCCESS,
    values
  }),
  sendFailure: values => ({
    type: types.FEEDBACK_SEND_FAILURE,
    values
  }),
  setDefaultState: () => ({
    type: types.FEEDBACK_DEFAULT_STATE_SET
  })
};
