import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Switch.scss';

const Switch = ({ id, name, variant = 'patient', checked, onChange, disabled }) => (
  <div className={cn(styles['toggle-switch'], styles[variant])}>
    <input
      type="checkbox"
      name={name}
      className={styles['toggle-switch-checkbox']}
      id={id}
      checked={checked}
      onChange={e => onChange(e.target.checked)}
      disabled={disabled}
    />
    {id ? (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for
      <label className={styles['toggle-switch-label']} htmlFor={id}>
        <span className={styles['toggle-switch-switch']} />
      </label>
      ) : null}
  </div>
  );

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  variant: PropTypes.oneOf(['patient', 'doctor']),
  disabled: PropTypes.bool,
};

export default Switch;
