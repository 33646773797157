import { applyMiddleware, compose, createStore } from "redux";
import { createBrowserHistory as createHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import * as Sentry from "@sentry/browser";
import createSentryMiddleware from "redux-sentry-middleware";
import createSagaMiddleware from "redux-saga";
import createReducer from "./rootReducer";
import rootSaga from "./rootSaga";

export const history = createHistory();

function configureStore(h) {
  const middleware = [];

  const sagaMiddleware = createSagaMiddleware();
  const sentryMiddleware = createSentryMiddleware(Sentry);

  middleware.push(sagaMiddleware);
  middleware.push(routerMiddleware(h));
  if (!__DEV__) {
    middleware.push(sentryMiddleware);
  }

  const enhancers = [applyMiddleware(...middleware)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(h),
    {},
    composeEnhancers(...enhancers)
  );
  sagaMiddleware.run(rootSaga);

  return store;
}

export default configureStore(history);
