import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Text } from "ibolit-ui";
import UserAvatar from "~/components/UserAvatar";
import styles from "./DoctorItem.scss";

export const height = 80;

const DoctorItem = ({
  user,
  testId,
  className,
  avatarClassName,
  contentClassName
}) => {
  return (
    <div data-testid={testId} className={cn(styles.container, className)}>
      <div className={styles.avatar}>
        <UserAvatar
          className={avatarClassName}
          testid={`${testId}-avatar`}
          user={user}
          size="medium"
          showOnlineStatus={false}
        />
      </div>
      <div className={cn(styles.content, contentClassName)}>
        <Text
          variant="h4"
          className={styles.title}
          testid={`${testId}-full-name`}
        >
          {user.full_name}
        </Text>
        <div className={styles.desc}>
          <Text
            variant="desc"
            colorVariant="tertiary"
            testid={`${testId}-speciality`}
          >
            {user.specialty}
          </Text>
          {!!user.is_chat_free && (
            <Text
              colorVariant="primary"
              variant="desc"
              testid={`${testId}-free`}
            >
              Бесплатно
            </Text>
          )}
          {!user.is_chat_free && user.min_price !== undefined && (
            <Text
              colorVariant="primary"
              variant="desc"
              testid={`${testId}-min-price`}
            >{`От ${user.min_price} ₽`}</Text>
          )}
        </div>
      </div>
    </div>
  );
};

DoctorItem.propTypes = {
  testId: PropTypes.string,
  user: PropTypes.shape({
    avatar: PropTypes.string,
    created_at: PropTypes.string,
    date_of_birth: PropTypes.string,
    email: PropTypes.string,
    experience: PropTypes.number,
    full_name: PropTypes.string,
    has_slots: PropTypes.bool,
    id: PropTypes.number,
    min_duration_tariff: PropTypes.number,
    phone: PropTypes.string,
    self_appointment: PropTypes.bool,
    specialty: PropTypes.string,
    tariff: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      price: PropTypes.number
    }),
    updated_at: PropTypes.string,
    working_days: PropTypes.shape({
      [PropTypes.string]: PropTypes.bool
    }),
    is_chat_free: PropTypes.bool,
    min_price: PropTypes.number
  }),
  className: PropTypes.string,
  avatarClassName: PropTypes.string,
  contentClassName: PropTypes.string
};

export default DoctorItem;
