import { createSelector } from "reselect";

const getSelectedDocument = (state, { documentId }) =>
  state.documents.items[documentId];
const getConclusion = (state, { conclusionId }) =>
  state.conclusions.items[conclusionId];

export const getDocumentById = createSelector(
  getSelectedDocument,
  document => document
);
export const getDocumentConclusion = createSelector(
  getConclusion,
  conclusion => conclusion
);
