import { schema } from "normalizr";
import { clinicSchema } from "../clinics/clinicsNomalizer";
import { tariffsNormalizer } from "~/store/tariffs/tariffsNormalizer";

const processStrategy = ({ patient_new, ...value }) => {
  // TODO А если patient_new будет равен null?
  if (patient_new !== undefined) {
    value.is_new = patient_new;
  }
  if (value.position === "Поддержка") {
    value.type = "support";
  }

  return value;
};

export const userSchema = new schema.Entity(
  "users",
  {
    clinic: clinicSchema,
    tariff: tariffsNormalizer
  },
  {
    processStrategy
  }
);
