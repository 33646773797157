import React from "react";
import PayInfo from "./PayInfo";
import PartnerInfo from "./PartnerInfo";
import styles from "./ChatHeader.scss";

const ChatHeader = ({ partner, chat }) => {
  const { tariff, is_read_only } = chat;

  return (
    <div className={styles.container}>
      <PartnerInfo user={partner} />
      {partner && !partner.is_deleted && (
        <>
          {(tariff?.end_at !== null || is_read_only) && (
            <PayInfo user={partner} tariff={tariff} />
          )}
        </>
      )}
    </div>
  );
};

export default ChatHeader;
