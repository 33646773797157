import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Box, Text, Container, Icon } from "ibolit-ui";
import { useDispatch, useSelector } from "react-redux";
import { useCloseModal, useReplaceModal } from "~/hooks/useModal";
import styles from "./ChooseDateTime.scss";
import { getGroupedSlotsByDate } from "~/store/slots/slotsSelectors";

import uiActions from "~/store/ui/uiActions";

import { getSelectedDateTimeData } from "~/store/newConsultation/newConsultationSelectors";

import { CHOOSE_SERVICE_VIEW } from "../ChooseService/ChooseService";

import {
  DateBlock,
  SlotByDayTime,
  getSlotsByTimeOfDay,
  ChangePageButton,
  generatePageSlots,
  getTypeData
} from "./Utils";

import actions from "~/store/newConsultation/newConsultationActions";
import { ModalHeader } from "~/components/ModalHeader/ModalHeader";
import { STATUS_PAY } from "../NewConsultation/components/Statuses/StatusPay";
import slotsActions from "~/store/slots/slotsActions";

const defaultTestId = "ChooseDateTime";

export const CHOOSE_DATE_TIME = "choose_date_time";

const ChooseDateTime = ({ iframeProps }) => {
  const [dateBlockPage, setDateBlockPage] = useState(0);

  const dispatch = useDispatch();

  const slots = useSelector(getGroupedSlotsByDate);

  const onCloseClick = useCloseModal(CHOOSE_DATE_TIME);

  const {
    id,
    tariffId,
    doctor,
    slotType,
    price,
    clinicId,
    specialtyProps,
    noBack
  } = useSelector(getSelectedDateTimeData);
  useEffect(() => {
    dispatch(slotsActions.fetchSlots(slotType, doctor.id));
  }, []);
  const generatedSlots = generatePageSlots(slots[slotType]);
  const selectedDays = generatedSlots[0][dateBlockPage];
  const maxDays = generatedSlots[1];

  const onBackClick = useReplaceModal(CHOOSE_DATE_TIME, CHOOSE_SERVICE_VIEW, {
    doctor,
    clinicId,
    specialtyProps,
    noBack
  });

  const [selectedDay, setSelectedDay] = useState(null);
  const handleSelectDay = key => () => setSelectedDay(key);
  useEffect(
    () => slots[slotType] && setSelectedDay(Object.keys(slots[slotType])[0]),
    [slots[slotType]]
  );

  const slotsByTimeOfDay = getSlotsByTimeOfDay(slots, selectedDay, slotType);

  const handleChangeButton = (decrement = false) => () =>
    setDateBlockPage(page => page + (decrement ? -1 : 1));

  const handleSelectDateTime = slotId => () => {
    const startAt = `${selectedDay} ${
      slots[slotType][selectedDay].find(el => el.id === slotId).start_at
    }`;
    if (slotType !== "chat") {
      dispatch(
        actions.post({
          doctorId: doctor.id,
          tariffId: id || tariffId,
          slotId: slotId,
          tariffType: slotType,
          doctor,
          price,
          startAt
        })
      );
    } else {
      dispatch(
        uiActions.replaceModal(CHOOSE_DATE_TIME, STATUS_PAY, {
          doctorId: doctor.id,
          tariffId: id,
          slotId,
          tariffType: slotType,
          doctor,
          price,
          startAt,
          iframeProps
        })
      );
    }
  };

  return (
    <>
      <Box className={styles.container} isModalBlock>
        <div className={styles.header}>
          <div
            className={styles.back}
            data-testid="back_modal_window_date_time"
          >
            <Icon variant="arrow" onClick={onBackClick} />
          </div>
          <Text tag="span" variant="h2" testid="header_date_time_modal_window">
            Выберите дату и время
          </Text>
          <div
            className={styles.close}
            data-testid="close_date_time_modal_window"
          >
            <Icon variant="close" fill="var(--grey)" onClick={onCloseClick} />
          </div>
        </div>
        <div
          className={cn(styles.item, styles.panel)}
          data-testid={"choose_date_time_doctor_container"}
        >
          <Container className={styles.doctor} direction="column">
            <ModalHeader
              doctor={doctor}
              noItem
              price={price}
              slotType={slotType}
              specialty={doctor.specialty || doctor.specialties[0]}
            />
          </Container>
        </div>
        <Container direction="column" className={styles.blocksContainer}>
          {selectedDays?.length ? (
            <Container direction="column" className={styles.chooseDate}>
              <Text
                colorVariant="tertiary"
                variant="body1"
                testid={`${defaultTestId}-chooseDate-text`}
              >
                Выберите дату и время записи
              </Text>
              <Container>
                <Container
                  className={styles.dateBlockContainer}
                  justify="beetween"
                >
                  {dateBlockPage !== 0 ? (
                    <ChangePageButton
                      handleChangeButton={handleChangeButton(true)}
                      isRotated
                      testid={`${defaultTestId}-ChangePageButton-back`}
                    />
                  ) : null}
                  {selectedDays.map(key => (
                    <DateBlock
                      date={key}
                      active={selectedDay === key}
                      disabled={Boolean(slots[slotType][key])}
                      onClick={handleSelectDay(key)}
                      testid={`${defaultTestId}-DateBlock`}
                    />
                  ))}
                  {dateBlockPage < Math.ceil(maxDays / 7) ? (
                    <ChangePageButton
                      handleChangeButton={handleChangeButton()}
                      testid={`${defaultTestId}-ChangePageButton-forward`}
                    />
                  ) : null}
                </Container>
              </Container>
            </Container>
          ) : null}
          {slotsByTimeOfDay ? (
            <div className={styles.dayTimeList}>
              <Container direction="column">
                <SlotByDayTime
                  slots={slotsByTimeOfDay[0]}
                  dayTimeTitle="Утро"
                  onClick={handleSelectDateTime}
                  testid={`${defaultTestId}-morining-date`}
                />
                <SlotByDayTime
                  slots={slotsByTimeOfDay[1]}
                  dayTimeTitle="День"
                  onClick={handleSelectDateTime}
                  testid={`${defaultTestId}-day-date`}
                />
                <SlotByDayTime
                  slots={slotsByTimeOfDay[2]}
                  dayTimeTitle="Вечер"
                  onClick={handleSelectDateTime}
                  testid={`${defaultTestId}-evening-date`}
                />
              </Container>
            </div>
          ) : null}
        </Container>
      </Box>
    </>
  );
};

export default ChooseDateTime;
