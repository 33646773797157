import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import styles from './Textarea.scss';

const Textarea = ({
  placeholder,
  onChange,
  value,
  fullWidth,
  nativeChange,
  disabled,
  name,
  focus,
  resize,
  className,
  rows,
  maxRows,
  autosize,
  inputBinds: { ref, ...inputBinds },
  testidProps,
  style,
}) => {
  const textareaRef = ref || useRef(null);

  useEffect(() => {
    if (focus) {
      textareaRef.current.focus();
    }
  }, []);

  const onTextareaChange = event => {
    if (nativeChange) {
      onChange(event);
    } else {
      const { target } = event;

      onChange(target.value);
    }
  };

  const _style = { ...style };

  if (resize) {
    _style.resize = resize;
  } else if (fullWidth) {
    _style.resize = 'vertical';
  }

  const Component = autosize ? TextareaAutosize : 'textarea';

  return (
    <Component
      onChange={onTextareaChange}
      value={value}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      {...inputBinds}
      ref={textareaRef}
      rows={rows}
      maxrows={maxRows}
      className={cn(
        styles.textarea,
        {
          [styles.fullWidth]: fullWidth,
        },
        className,
        inputBinds.className,
      )}
      style={_style}
      data-testid={testidProps.textarea}
    />
  );
};

Textarea.defaultProps = {
  fullWidth: false,
  nativeChange: false,
  disabled: false,
  focus: false,
  inputBinds: {},
  testidProps: {},
  style: {},
};

Textarea.propTypes = {
  /** Вытягивает поле ввода на всю ширину родительского контейнера */
  fullWidth: PropTypes.bool,
  /** Устанавливает значение элемента */
  value: PropTypes.string,
  /** Устанавливает обработчик изменения значения элемента */
  onChange: PropTypes.func,
  /** Если true, в обработчик onChange передается аргумент event, вместо value */
  nativeChange: PropTypes.bool,
  /** Если true, блокирует поле ввода */
  disabled: PropTypes.bool,
  /** Определяет уникальное имя элемента формы. */
  name: PropTypes.string,
  /** Устанавливает фоксы при монтировании. */
  focus: PropTypes.bool,
  /** Указывает, можно ли пользователю изменять размеры текстового поля.  */
  resize: PropTypes.oneOf(['none', 'both', 'horizontal', 'vertical']),
  /** Устанавливает внешние бинды для инпута (ref, onFocus, onBlur, onChange etc). */
  inputBinds: PropTypes.object,
  /** Устанавливает для контейнера имя класса. */
  className: PropTypes.string,
  /** Выводит текст внутри поля формы, который исчезает при получении фокуса. */
  placeholder: PropTypes.string,
  /** Использовать react-autosize-textarea. */
  autosize: PropTypes.bool,
  /** Минимальное количество видимых строк. */
  rows: PropTypes.number,
  /** Максимальное количество видимых строк. */
  maxRows: PropTypes.number,
  /** Устанавливает уникальные QA идентификторы. */
  testidProps: PropTypes.shape({
    textarea: PropTypes.string,
  }),
  /** Устанавливает cтили. */
  style: PropTypes.object,
};

export default Textarea;
