import React, { useCallback, useState, useEffect } from "react";
import { Box, Text, Input } from "ibolit-ui";
import { useDispatch } from "react-redux";
import Icon from "ibolit-ui/src/UI/Icon";
import { useCloseModal, useReplaceModal } from "~/hooks/useModal";
import { CLINICS_VIEW } from "~/modals/Clinics";
import { DOCTORS_CHAT_VIEW } from "~/modals/DoctorsChat";
import clinicsApi from "~/api/clinicsApi";
import Loader from "~/components/Loader";
import uiActions from "~/store/ui/uiActions";
import styles from "./SpecializationChoose.scss";
import { LOCATORS } from "~/locators/specialization";
import ErrorSwitcher from "~/components/ErrorSwitcher";
import { useGetRequest, useRequestSearchPagination } from "~/hooks/useRequest";
import { UndrawDoctorSvg } from "~/components/UndrawDoctorSvg/UndrawDoctorSvg";
import debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import { getClinicById } from "~/store/clinics/clinicsSelectors";

import { selectSpecializationAction } from "~/store/modals/specializations/actions";
import Loading from "~/components/Loading/Loading";

const defaultTestId = "Specialization";

export const SPECIALIZATION_CHOOSE = "specialization_choose";

export const SpecializationChoose = ({ clinicId }) => {
  const clinicData = useGetRequest(clinicsApi.clinicListForChat, {
    primary_acceptance: true
  });
  const noBackButton = !clinicData.loading && clinicData.items.length === 1;

  const requestPromise = useCallback(
    params => clinicsApi.getSpecialitiesApi(clinicId, params),
    [clinicId]
  );
  const { items, isLoading } = useRequestSearchPagination(requestPromise, {
    primary_acceptance: true,
    limit: 20
  });
  const [specialities, setSpecialities] = useState([]);
  const dispatch = useDispatch();
  const onCloseClick = useCloseModal(SPECIALIZATION_CHOOSE);
  const onBackClick = useReplaceModal(SPECIALIZATION_CHOOSE, CLINICS_VIEW);

  const clinicPhone = useSelector(getClinicById(clinicId))?.phone;

  useEffect(() => {
    setSpecialities(items);

    // return () => setSpecialities([]);
  }, [items]);

  const handleChangeQuery = debounce(newValue => {
    if (newValue.length >= 3) {
      const filteredSpecialities = items.reduce((acc, value) => {
        if (value.title.toLowerCase().includes(newValue.toLowerCase())) {
          acc.push(value);
        }

        return acc;
      }, []);

      setSpecialities(filteredSpecialities);
    }

    if (newValue === "") {
      setSpecialities(items);
    }
  }, 1000);

  return (
    <ErrorSwitcher hasError={false} repeat={() => null}>
      <Loader loading={false}>
        <Box isModalBlock>
          <div className={styles.header}>
            {!noBackButton ? (
              <div
                className={styles.back}
                data-testid="back_modal_window_specialization"
              >
                <Icon variant="arrow" onClick={onBackClick} />
              </div>
            ) : null}
            <Text tag="span" variant="h2" testid="header_modal_window">
              Выберите специализацию
            </Text>
            <div className={styles.close} data-testid="close_modal_window">
              <Icon variant="close" fill="var(--grey)" onClick={onCloseClick} />
            </div>
          </div>
          <div className={styles.searchContainer}>
            <Input
              placeholder="Поиск по специализации"
              className={styles.search}
              onChange={handleChangeQuery}
              containerClass={styles.searchWrapper}
              testidProps={{
                input: `${defaultTestId}__search`
              }}
            />
            <Icon size="s" className={styles.searchIcon} variant="search11" />
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <div className={styles.list}>
              {specialities.length > 0 ? (
                specialities.map(({ title, specialty }, index) => {
                  const onClick = () => {
                    dispatch(
                      selectSpecializationAction({
                        specialty,
                        title
                      })
                    );
                    dispatch(
                      uiActions.replaceModal(
                        SPECIALIZATION_CHOOSE,
                        DOCTORS_CHAT_VIEW,
                        { clinicId, specialty }
                      )
                    );
                  };

                  return (
                    <div
                      onClick={onClick}
                      className={styles.item}
                      data-testid={`SPECIALIZATION_SPECIALTIES_ITEM`}
                      key={title}
                    >
                      <Text variant={"h3"} color={"black"}>
                        {title}
                      </Text>
                    </div>
                  );
                })
              ) : (
                <div
                  className={styles.isEmpty}
                  data-testid={LOCATORS.SPECIALTIES_NOT_FOUND}
                >
                  <div className={styles.image}>
                    <UndrawDoctorSvg />
                  </div>
                  <Text
                    className={styles.title}
                    variant={"h2"}
                    color={"black"}
                    testid={`${defaultTestId}__noOneToFind`}
                  >
                    Никого не нашли
                  </Text>
                  <Text
                    className={styles.desc}
                    variant={"body1"}
                    colorVariant="tertiary"
                    testid={`${defaultTestId}__unfortunately`}
                  >
                    К сожалению, по вашему запросу врачей данной специальности
                    <br />
                    не найдено
                  </Text>
                  <Text
                    className={styles.desc}
                    variant={"body1"}
                    colorVariant="tertiary"
                    testid={`${defaultTestId}__phone-label`}
                  >
                    Записаться на консультацию можно по телефону
                    <Text
                      variant={"body1"}
                      colorVariant="pink"
                      testid={`${defaultTestId}__phone-text`}
                    >
                      {clinicPhone}
                    </Text>
                  </Text>
                </div>
              )}
            </div>
          )}
        </Box>
      </Loader>
    </ErrorSwitcher>
  );
};
