import React from "react";
import { Dialog, Scrollbar } from "ibolit-ui";
import styles from "./ConnectionCheck.scss";
import { CONNECTION_CHECK } from "~/locators/privateProfile";

const ConnectionCheck = ({ onBackClick, onCloseClick, style }) => (
  <Dialog
    testId="ConnectionCheckModal"
    boxClassName={styles.modalContainer}
    onBackClick={onBackClick}
    onCloseClick={onCloseClick}
    backTestId={CONNECTION_CHECK.ICON_BACK}
    closeTestId={CONNECTION_CHECK.ICON_CLOSE}
  >
    <Scrollbar style={{ width: "350px", height: "680px" }}>
      <iframe
        title="health.ibolit.pro"
        src={`https://health.ibolit.pro`}
        className={styles.iframeContainer}
        scrolling="no"
        seamless
        allow="microphone; camera"
        style={style}
      />
    </Scrollbar>
  </Dialog>
);

export default ConnectionCheck;
