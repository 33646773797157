import { all, takeLatest, put, call, select } from "redux-saga/effects";
import * as VoxImplant from "voximplant-websdk";
import actions from "~/store/call/callActions";
import uiActions from "~/store/ui/uiActions";
import callTypes from "~/store/call/callTypes";
import api from "~/api/callApi";
import CALL_STATUS from "~/components/CallRoom/constants/callStatus";
import { CALL_ROOM_VIEW } from "~/components/CallRoom/components/CallRoomModal/CallRoomModal";
import { CALL_CONTAINERS_IDS } from "~/components/CallRoom/components/Room/Room";
import { CallLogEvents, createCallEventLogger } from "~/store/call/callUtils";
import { getCurrentUserId } from "~/store/auth/authSelectors";

// VoxImplant Stuff
const isConnected = state =>
  [VoxImplant.ClientState.CONNECTED, VoxImplant.ClientState.LOGGED_IN].includes(
    state
  );

const voxInstanceConfig = {
  videoContainerId: CALL_CONTAINERS_IDS.publisher,
  localVideoContainerId: CALL_CONTAINERS_IDS.publisher,
  remoteVideoContainerId: CALL_CONTAINERS_IDS.subscriber,
  micRequired: true, // force microphone/camera access request
  videoSupport: true,
  // debug
  enableTrace: false
};

function* callStart({ consultationId }) {
  try {
    yield put(actions.setCallStatus(CALL_STATUS.START));
    yield put(
      uiActions.showModal(CALL_ROOM_VIEW, {
        consultationId,
        withVideo: true,
        preventClick: true
      })
    );
  } catch (error) {
    yield put(uiActions.hideModal(CALL_ROOM_VIEW));
    yield put(actions.callError(error));
  }
}

const loginToVoxImplant = async username => {
  // We try catch blocks to narrow down the possible error.
  const voxImplant = VoxImplant.getInstance();
  // It never throws with bad username, so we do a 10s race here.
  const authResult = await Promise.any([
    voxImplant.requestOneTimeLoginKey(username),
    new Promise(resolve => setTimeout(resolve, 10 * 1000))
  ]);
  if (!authResult) {
    throw new Error(
      "Error in voxImplant.requestOneTimeLoginKey, 10s timeout reached"
    );
  }
  let activatedTokenResponse;
  try {
    activatedTokenResponse = await api.getOneTimeToken({
      oneTimeKey: authResult.key
    });
  } catch (error) {
    throw new Error(
      `Error in api.getOneTimeToken, Error message: ${error.message}`
    );
  }
  try {
    await voxImplant.loginWithOneTimeKey(
      username,
      activatedTokenResponse.data.token
    );
  } catch (error) {
    throw new Error(
      `Error in voxImplant.loginWithOneTimeKey; Error code: ${error.code}`
    );
  }
};

function* callJoin({ consultationId }) {
  const currentUserId = yield select(getCurrentUserId);
  const logger = createCallEventLogger(consultationId, currentUserId);
  try {
    // Reset previous callIds so CDU in Room will work.
    yield put(actions.setVoxImplantParams(null));
    yield put(actions.setCallStatus(CALL_STATUS.ACTIVE));
    const voxImplant = VoxImplant.getInstance();
    if (!voxImplant.alreadyInitialized) {
      yield voxImplant.init(voxInstanceConfig);
      logger({ event: CallLogEvents.initializedVox });
    }
    const clientState = yield voxImplant.getClientState();
    const {
      data: { username }
    } = yield call(api.getUsername, {});

    if (!isConnected(clientState)) {
      yield voxImplant.connect(false);
      logger({ event: CallLogEvents.connectedToVox });
    }

    if (clientState !== VoxImplant.ClientState.LOGGED_IN) {
      yield loginToVoxImplant(username);
      logger({ event: CallLogEvents.loggedIn });
    }

    yield put(actions.setVoxImplantParams(consultationId));
  } catch (error) {
    yield put(uiActions.hideModal(CALL_ROOM_VIEW));
    logger({ event: CallLogEvents.joinFailure, eventData: error.message });
    // Логгер расскажет нам в чем ошибка, клиенту не нужно знать в чем конкретно проблема.
    yield put(
      actions.callError({
        message: "Произошла ошибка при входе в комнату, попробуйте позже"
      })
    );
  }
}

function* callLeave({ consultationId }) {
  try {
    yield call(api.callLeave, { call_id: consultationId });
  } catch (error) {
    yield put(actions.callError(error));
  }
}

export default function* callSaga() {
  yield all([
    takeLatest(callTypes.CALL_START, callStart),
    takeLatest(callTypes.CALL_JOIN, callJoin),
    takeLatest(callTypes.CALL_LEAVE, callLeave)
  ]);
}
