import React from "react";
import { Dialog } from "ibolit-ui";
import SubscriptionsList from "./SubscriptionsList";
import SubscriptionInfo from "./SubscriptionInfo";
import styles from "./Subscriptions.scss";

const Subscriptions = ({
  selected,
  onBackClick,
  onCloseClick,
  onDeleteClick,
  onListItemClick,
  fetchSubscriptions
}) => (
  <Dialog
    testId="SubscriptionsModal"
    header="Мои подписки"
    onBackClick={onBackClick}
    onCloseClick={onCloseClick}
    boxClassName={styles.modalBox}
  >
    {selected ? (
      <SubscriptionInfo onDeleteClick={onDeleteClick} selected={selected} />
    ) : (
      <SubscriptionsList
        onItemClick={onListItemClick}
        fetchRequest={fetchSubscriptions}
      />
    )}
  </Dialog>
);

export default Subscriptions;
