import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { ImageLoader, Text } from "ibolit-ui";
import styles from "./Panel.scss";

const Panel = ({ className, avatar, title, desc }) => (
  <div className={cn(styles.panel, className)}>
    <ImageLoader
      asBackground
      src={avatar}
      className={styles.avatar}
      testid="Panel__imageLoader"
    />
    <div className={styles.content}>
      <Text variant="h4" className={styles.title} testid="Panel__title">
        {title}
      </Text>
      <Text
        variant="desc"
        colorVariant="tertiary"
        className={styles.desc}
        testid="Panel__desc"
      >
        {desc}
      </Text>
    </div>
  </div>
);

Panel.propTypes = {
  avatar: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string
};

export default Panel;
