import { connect } from "react-redux";
import Profile from "./components/Profile";
import uiActions from "~/store/ui/uiActions";
import { getUser } from "~/store/profile/profileSelectors";

export const PROFILE_VIEW = "profile";

export default connect(
  state => ({
    user: getUser(state)
  }),
  {
    replaceModal: uiActions.replaceModal,
    hideModal: uiActions.hideModal
  }
)(Profile);
