import React from "react";
import { useSelector } from "react-redux";
import { getConsultationById } from "~/store/consultation/consultationSelectors";
import Consultation from "./Consultation";

const ConsultationContainer = props => {
  const consultation = useSelector(state => getConsultationById(state, props));
  const doctor = useSelector(state => state.users.items[props.userId]);

  return <Consultation {...props} {...consultation} doctor={doctor} />;
};

export default ConsultationContainer;
