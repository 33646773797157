import axios from "./httpService";

export const getUserDataApi = () => axios.get("/v3/user");

export const sendAvatarApi = file => {
  const formData = new FormData();

  formData.append("file", file);

  return axios.post("/v3/upload-public-image", formData);
};

export const editUserDataApi = data => axios.put("/v3/user", data);

export const changePasswordApi = data => axios.put("/v3/user/password", data);

export const changeLoginApi = data => axios.put("/v3/user/login", data);

export const confirmSettingsChange = data =>
  axios.post("/v3/user/confirm-code", data);

export const resendCode = data => axios.post("/v3/user/resend-code", data);
