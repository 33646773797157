import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { Button, Icon } from "ibolit-ui";
import { usePayment } from "~/hooks/usePayment";
import styles from "./Payment.scss";

const PaymentButton = ({
  serviceId,
  consultationId,
  userId,
  doctor,
  text,
  className,
  testid,
  price,
  tariffsType,
  variant,
  isPaid = false,
  onSuccess
}) => {
  const { paid, currentText, showPaymentModal } = usePayment(
    isPaid,
    text,
    price,
    serviceId,
    consultationId,
    tariffsType,
    userId,
    doctor,
    onSuccess
  );

  function handleButtonClick() {
    if (paid) return null;

    return showPaymentModal();
  }

  if (variant === "prolong") {
    return (
      <Button
        fixedWidth={false}
        className={cn(styles.button, className)}
        size="small"
        colorVariant="success"
        onClick={showPaymentModal}
        testid={testid}
      >
        Продлить
      </Button>
    );
  }

  return (
    <Button
      colorVariant="success"
      variant={paid ? "tertiary" : "primary"}
      onClick={handleButtonClick}
      disabled={!!paid}
      className={cn(styles.button, className)}
      fullWidth
      testid={testid}
    >
      <Icon
        fill={paid ? "var(--green)" : "var(--white)"}
        variant={paid ? "success" : "cart"}
        className={styles.buttonIcon}
      />
      {currentText}
    </Button>
  );
};

PaymentButton.defaultProps = {
  variant: "default",
  tariffsType: "all",
  isPaid: false
};

PaymentButton.propTypes = {
  serviceId: PropTypes.number,
  className: PropTypes.string,
  userId: PropTypes.number,
  variant: PropTypes.oneOf(["default", "prolong"]),
  tariffsType: PropTypes.oneOf(["chat", "av", "all"]),
  text: PropTypes.string,
  isPaid: PropTypes.bool
};

export default PaymentButton;
