import { Box, Button, Container, Input, Text } from "ibolit-ui";
import React, { useEffect, useState } from "react";
import { useCloseModal, useReplaceModal } from "~/hooks/useModal";
import cn from "classnames";

import {
  checkPromocodeAction,
  resetPromocodeAction
} from "~/store/promocode/actions";
import {
  selectPromocodeLoading,
  selectNewPrice,
  selectPromocodeError,
  selectPromotionCodeId
} from "~/store/promocode/selectors";
import styles from "./PromoModal.scss";
import { useDispatch, useSelector } from "react-redux";
import newConsultationActions from "~/store/newConsultation/newConsultationActions";
import { CHOOSE_DATE_TIME } from "../ChooseDateTime/ChooseDateTime";
import { PAYMENT_IFRAME_VIEW } from "~/components/Payment/InvoicedPayment";

export const PROMO_MODAL_VIEW = "promo_modal";

export const PromoModal = ({
  promoModalProps,
  price,
  tariffId,
  onSuccess,
  onFailure,
  consultationId
}) => {
  const [codeValue, setCodeValue] = useState("");
  const [message, setMessage] = useState({});

  const dispatch = useDispatch();

  const error = useSelector(selectPromocodeError);
  const newPrice = useSelector(selectNewPrice);
  const isLoading = useSelector(selectPromocodeLoading);
  const promotion_code_id = useSelector(selectPromotionCodeId);

  const onPayChat = useReplaceModal(PROMO_MODAL_VIEW, PAYMENT_IFRAME_VIEW, {
    serviceId: tariffId,
    consultationId,
    onSuccess,
    onFailure,
    promotion_code_id,
    promoModalProps
  });

  const replaceDateTimeModal = useReplaceModal(
    PROMO_MODAL_VIEW,
    CHOOSE_DATE_TIME,
    {
      iframeProps: {
        promotion_code_id
      }
    }
  );
  const onPay = () => {
    dispatch(
      newConsultationActions.fillDateTimeData({
        ...promoModalProps,
        price: newPrice !== null ? newPrice : promoModalProps.price
      })
    );
    replaceDateTimeModal();
  };

  const onCloseClick = useCloseModal(PROMO_MODAL_VIEW);
  const handleCloseClick = () => {
    onCloseClick();
    dispatch(resetPromocodeAction());
  };

  const handleChangeCode = value => setCodeValue(value);

  useEffect(() => {
    switch (true) {
      case newPrice !== null:
        setMessage({
          text: "Промокод применен успешно",
          variant: "success"
        });
        break;
      case error !== "":
        setMessage({
          text: error,
          variant: "error"
        });
        break;
      default:
        setMessage({});
        break;
    }
  }, [newPrice, error]);

  const handleApplyCode = () => {
    dispatch(checkPromocodeAction({ code: codeValue, service_id: tariffId }));
  };

  const handlePay = () => {
    if (promoModalProps.slotType === "chat" || promoModalProps.isFromInfoPage) {
      onPayChat();
    } else {
      onPay();
    }
    // dispatch(resetPromocodeAction());
  };

  return (
    <Box
      className={styles.box}
      header={{
        title: "Введите промокод",
        onCloseClick: handleCloseClick
      }}
    >
      <Text
        testid="PromoModal__text"
        colorVariant="tertiary"
        variant="body1"
        className={styles.text}
      >
        Если у вас есть промокод, введите его в поле ниже
      </Text>
      <Container direction="column">
        <Container justify="beetween" className={styles.inputContainer}>
          <Input
            fullWidth
            value={codeValue}
            maxlength={15}
            onChange={handleChangeCode}
            testidProps={{
              input: "PromoModal__input"
            }}
          />
          <Button
            className={cn(
              styles.applyButton,
              codeValue !== "" && styles.applyButton_active
            )}
            onClick={handleApplyCode}
            disabled={isLoading}
            colorVariant="patient"
          >
            <Text colorVariant="secondary" variant="h4">
              Применить
            </Text>
          </Button>
        </Container>
        {message.text ? (
          <Text
            colorVariant={message.variant}
            variant="sub2"
            className={styles.successMessage}
          >
            {message.text}
          </Text>
        ) : null}
      </Container>
      <Container justify="beetween" className={styles.totalPrice}>
        <Text variant="body1" testid="PromoModal__totalText">
          Итого:
        </Text>
        <Container>
          <Text
            variant="h4"
            colorVariant={newPrice !== null ? "tertiary" : "primary"}
            className={cn(newPrice !== null && styles.price_old, styles.price)}
            testid="PromoModal__price"
          >
            {price} ₽
          </Text>
          {newPrice !== null ? (
            <Text variant="h4" className={styles.newPrice}>
              {newPrice} ₽
            </Text>
          ) : null}
        </Container>
      </Container>
      <Button
        colorVariant="success"
        fullWidth
        size="medium"
        onClick={handlePay}
        disabled={isLoading}
        testid="PromoModal__payButton"
      >
        Оплатить
      </Button>
    </Box>
  );
};
