import React from "react";
import PropTypes from "prop-types";
import { Text } from "ibolit-ui";
import styles from "./CodeMessage.scss";

const CodeMessage = React.memo(({ text, header, testId, textTestId }) => (
  <Text
    variant="desc"
    colorVariant="tertiary"
    className={styles.CodeMessage}
    tag="p"
    testid={textTestId}
  >
    {text}
    <br />
    <Text
      className={styles.header}
      variant="desc"
      colorVariant="tertiary"
      tag="span"
      weight="bold"
      testid={testId}
    >
      {header}
    </Text>
  </Text>
));

CodeMessage.propTypes = {
  text: PropTypes.string,
  header: PropTypes.string,
  testId: PropTypes.string
};

export default CodeMessage;
