import Immutable from "seamless-immutable";
import Types from "~/store/auth/authTypes";

const TIMEOUT = 60; // 60sec

export const Step = {
  TOKEN: "TOKEN",
  SUCCESS: "SUCCESS",
  LOGIN: "LOGIN",
  PASSWORD: "PASSWORD",
  CODE: "CODE",
  RESET_PASSWORD: "RESET_PASSWORD",
  ACTIVATION: "ACTIVATION",
  CLINICS: "CLINICS",
  GET_USER_INFO: "GET_USER_INFO"
};

export const Status = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  NONE: "none"
};

const initialState = Immutable({
  status: Status.NONE,
  step: Step.LOGIN,
  isFetching: false,
  login: "",
  password: "",
  code: "",
  gender: "",
  dateOfBirth: "",
  token: null,
  user: null,
  currentUserId: undefined,
  timeout: TIMEOUT
});

const resetAuthorization = state => state.merge(initialState);

export const getPrevStepState = ({ step, status, login }) => {
  if (step === Step.CODE && status !== Status.INACTIVE) {
    return { step: Step.PASSWORD, code: "" };
  }
  return { ...initialState, login };
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ******************************** start request ******************************** //
    case Types.LOGIN_CHECK_REQUEST:
    case Types.PASSWORD_CHECK_REQUEST:
    case Types.PASSWORD_RESET_REQUEST:
    case Types.USER_ACTIVATE_REQUEST:
    case Types.USER_AUTHORIZE_REQUEST:
      return state.merge({
        isFetching: true,
        error: null
      });

    case Types.CODE_SEND_REQUEST:
    case Types.CODE_VALIDATE_REQUEST:
      return state.merge({
        isFetching: true,
        error: null,
        code: action.request.code
      });

    // ******************************** successful request ******************************** //
    case Types.CODE_SEND_SUCCESS:
    case Types.CODE_VALIDATE_SUCCESS:
    case Types.LOGIN_CHECK_SUCCESS:
    case Types.PASSWORD_CHECK_SUCCESS:
    case Types.PASSWORD_RESET_SUCCESS:
    case Types.USER_CHANGE_INFO:
    case Types.USER_ACTIVATE_SUCCESS:
      return state.merge({
        isFetching: false,
        error: null,
        ...(action.payload || {})
      });

    case Types.USER_AUTHORIZE_SUCCESS:
      return state.merge({
        isFetching: false,
        error: null,
        currentUserId: action.payload.id,
        user: action.payload
      });

    // ******************************** failed request ******************************** //
    case Types.CODE_SEND_FAILURE:
    case Types.CODE_VALIDATE_FAILURE:
    case Types.LOGIN_CHECK_FAILURE:
    case Types.PASSWORD_CHECK_FAILURE:
    case Types.PASSWORD_RESET_FAILURE:
    case Types.USER_ACTIVATE_FAILURE:
      return state.merge({
        isFetching: false,
        error: action.error,
        ...(action.payload || {})
      });

    case Types.LOGIN_CHECK_TIMEOUT:
    case Types.PASSWORD_CHECK_TIMEOUT:
      return state.merge({
        isFetching: false
      });

    case Types.USER_AUTHORIZE_FAILURE:
      return resetAuthorization(state, action);

    // ******************************** other reducers ********************************//
    case Types.LOGOUT:
      return resetAuthorization(state);

    case Types.STEP_BACK:
      return state.merge(getPrevStepState(state));

    case Types.SET_STEP:
      return state.merge({ isFetching: false, step: action.step });

    default:
      return state;
  }
};
