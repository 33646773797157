import React, { useEffect, useRef } from "react";
import cn from "classnames";
import { Spinner } from "ibolit-ui";
import styles from "./PaginationList.scss";

const PaginationList = ({
  testId,
  className,
  loadMore,
  canLoadMore,
  children
}) => {
  const listRef = useRef();
  const loaderRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            loadMore();
          }
        });
      },
      {
        root: listRef.current,
        rootMargin: "10px",
        threshold: 1
      }
    );
    observer.observe(loaderRef.current);
    return () => observer.unobserve(loaderRef.current);
  }, []);
  return (
    <div ref={listRef} className={className} data-testid={testId}>
      {children}
      <div
        ref={loaderRef}
        className={cn(styles.row, { [styles.hidden]: !canLoadMore })}
      >
        <Spinner className={styles.loader} size={24} />
      </div>
    </div>
  );
};

export default PaginationList;
