import cn from "classnames";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Text } from "ibolit-ui";
import getFormattedFileSize from "~/utils/getFormattedFileSize";
import FileIcon from "../FileIcon";

import { LightBoxContext } from "~/layouts/App/LightBoxProvider/LightBoxProvider";
import { FilePreview } from "~/components/FilePreview";
import { imageExtensionsRegExp, videoExtensionsRegExp } from "~/utils/regExps";

import styles from "./File.scss";

const File = ({ preview_url, name, size, extension, url }) => {
  const { showImagesPreview, showVideosPreview } = useContext(LightBoxContext);
  const onClick = event => {
    if (url) {
      imageExtensionsRegExp.lastIndex = 0;
      videoExtensionsRegExp.lastIndex = 0;
      if (imageExtensionsRegExp.test(extension)) {
        event.preventDefault();
        showImagesPreview([
          {
            preview_url,
            name,
            size,
            extension,
            url
          }
        ]);
      } else if (videoExtensionsRegExp.test(extension)) {
        event.preventDefault();
        showVideosPreview([
          {
            preview_url,
            name,
            size,
            extension,
            url
          }
        ]);
      }
    }
  };

  return (
    <a
      href={url}
      onClick={onClick}
      className={styles.container}
      download={!preview_url}
    >
      <FilePreview
        previewUrl={preview_url}
        url={url}
        extenstion={extension}
        previewContainerClassName={styles.preview}
        previewImgClassName={styles.previewImg}
      />
      <div
        className={cn(styles.main, { [styles.mainWithPreview]: preview_url })}
      >
        <div className={styles.iconExtension}>
          <FileIcon extension={extension} />
        </div>
        <div className={styles.right}>
          <Text className={styles.filename} variant="h4">
            {name}
          </Text>
          <Text
            className={styles.fileOptions}
            variant="desc"
            colorVariant="tertiary"
          >
            {extension}
            {` `}
            {getFormattedFileSize(size)}
          </Text>
        </div>
      </div>
    </a>
  );
};

File.propTypes = {
  preview_url: PropTypes.string,
  extension: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  url: PropTypes.string
};

export default File;
