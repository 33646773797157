import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog } from "ibolit-ui";
import { useDispatch, useSelector } from "react-redux";
import TariffsList from "./TariffsList";
import Loading from "~/components/Loading/Loading";
import {
  getIsUserTariffsLoading,
  getTariffsByUserId
} from "~/store/users/userSelectors";
import usersActions from "~/store/users/userActions";
import uiActions from "~/store/ui/uiActions";
import styles from "./Tariffs.scss";

export const TARIFFS_VIEW = "tariffs";

const TariffsModal = ({ userId }) => {
  const memoizedTariffsSelector = useCallback(
    state => getTariffsByUserId(state, { userId }),
    [userId]
  );
  const tariffs = useSelector(memoizedTariffsSelector);
  const isFetching = useSelector(getIsUserTariffsLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersActions.tariffsRequest(userId)); // tariffsRequest(userId, tariffsType)
  }, []);

  const hideModal = useCallback(() => {
    dispatch(uiActions.hideModal(TARIFFS_VIEW));
  }, []);

  return (
    <Dialog
      testId="TariffsModal"
      boxClassName={styles.boxModal}
      header="Стоимость услуг"
      onCloseClick={hideModal}
    >
      <TariffsList tariffs={tariffs} userId={userId} loading={isFetching} />
      <Loading showLoading={isFetching} />
    </Dialog>
  );
};

TariffsModal.propTypes = {
  userId: PropTypes.number.isRequired
};

export default TariffsModal;
