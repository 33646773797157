import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.scss';

const ModalBG = ({ onClick }) => {
  const el = useRef();

  const handleClick = useCallback(event => {
    if (event.target !== el.current) {
      return;
    }
    onClick(event);
  });

  return <div ref={el} className={styles.bg} onClick={handleClick} />;
};

ModalBG.propTypes = {
  onClick: PropTypes.func,
};

export default ModalBG;
