import types from "./toolbarTypes";

export default {
  getToolbar: () => ({
    type: types.GET_TOOLBAR
  }),
  setToolbar: data => ({
    type: types.SET_TOOLBAR,
    data
  }),
  updateToolbar: data => ({
    type: types.UPDATE_TOOLBAR,
    data
  })
};
