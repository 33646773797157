import React, { useCallback } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./TransparentButton.scss";
import { usePayment } from "~/hooks/usePayment";
import { useHistory } from "react-router-dom";

const TransparentButton = ({
  serviceId,
  consultationId,
  userId,
  text,
  className,
  testid,
  price,
  tariffsType,
  isPaid,
  doctor,
  onSuccess
}) => {
  const { paid, currentText } = usePayment(
    isPaid,
    text,
    price,
    serviceId,
    consultationId,
    tariffsType,
    userId,
    doctor,
    onSuccess
  );
  const history = useHistory();

  const handleClick = useCallback(e => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    history.push(`/consultations/${consultationId}`);
  });

  return (
    !paid && (
      <button
        type="button"
        className={cn(styles.button, className)}
        data-testid={testid}
      >
        <div onClick={handleClick} className={styles.touchable} />
        {currentText}
      </button>
    )
  );
};

TransparentButton.defaultProps = {
  tariffsType: "all",
  isPaid: false
};

TransparentButton.propTypes = {
  serviceId: PropTypes.number,
  className: PropTypes.string,
  userId: PropTypes.number,
  tariffsType: PropTypes.oneOf(["chat", "av", "all"]),
  text: PropTypes.string,
  isPaid: PropTypes.bool
};

export default TransparentButton;
