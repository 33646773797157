import React, { forwardRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';

const Scrollbar = forwardRef(
  ({ children, onScroll, style, onStyleChanged: styleChanged, onMount: mount, ...props }, ref) => {
    const getScrollbarValues = () => ref.current.getValues();

    useLayoutEffect(() => {
      if (mount) {
        mount(getScrollbarValues());
      }
    }, []);

    useLayoutEffect(() => {
      if (ref && styleChanged) {
        styleChanged(getScrollbarValues());
      }
    }, [style, ref]);

    return (
      <Scrollbars {...props} onScroll={onScroll} ref={ref} style={style}>
        {children}
      </Scrollbars>
    );
  },
);

Scrollbar.propTypes = {
  style: PropTypes.object,
  onScroll: PropTypes.func,
  onStyleChanged: PropTypes.func,
  onMount: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Scrollbar;
