import { createSelector } from "reselect";
import get from "lodash/get";

export const getUsers = state => state.users.items;

export const userSelector = (state, user) =>
  getUsers(state)[get(user, "id", user)];

export const getTariffsByUserId = (state, { userId }) =>
  get(state, ["users", "items", userId, "tariffs"]);

export const getUserTariffsByType = createSelector(
  getTariffsByUserId,
  (state, props) => props.tariffsType,
  (tariffs, tariffsType) =>
    Object.entries(tariffs || {}).reduce((acc, [type, array]) => {
      if (type === tariffsType || tariffsType === "all") {
        acc = acc.concat(array);
      }
      return acc;
    }, [])
);

export const getIsUserTariffsLoading = state => state.users.tariffsFetching;

export const getSelectedTariff = state => state.users.selectedTariff;

const getUserState = state => state.users;
const getItems = createSelector(getUserState, targetState => targetState.items);

export const getDoctorClinicModeState = createSelector(
  getUserState,
  getItems,
  (_, mode) => mode,
  (targetState, mapItems, mode) => {
    const {
      order,
      initialFetched,
      isFetching,
      canLoadMore,
      page,
      errors
    } = targetState[mode];
    const hasErrors = !!Object.keys(errors).length;
    const items = order.map(curr => ({
      ...mapItems[curr.id],
      specialty: curr.specialty
    }));
    return {
      items,
      isFetching,
      initialFetched,
      canLoadMore: !isFetching && canLoadMore,
      page,
      hasErrors,
      isEmpty: !items.length && !hasErrors
    };
  }
);

export const getUserById = (id) => (state) => state.users.items[id];