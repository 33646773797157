import cn from 'classnames';
import * as React from 'react';
import PropTypes from 'prop-types';
import useHover from '../../utils/useHover';
import Text from '../Text';
import Icon from '../Icon';
import styles from './IconWithText.scss';

const IconWithText = ({
  iconSize,
  textVariant,
  iconVariant,
  textColorVariant,
  text,
  padding,
  onClick,
  type,
  children,
  iconColor,
  className,
  badge,
  desc,
  hasHoverEffect,
  iconVerticalAlign,
  testid,
  textStyle,
  dataTestid,
}) => {
  const { isHover, onMouseOver, onMouseOut } = useHover(hasHoverEffect);

  const _iconSize = iconSize || (type === 'primary' ? 'm' : 's');
  const _textVariant = textVariant || (type === 'primary' ? 'h4' : 'desc');
  const _textColorVariant = textColorVariant || (type === 'primary' ? 'primary' : 'tertiary');

  const Tag = desc && (children || text) ? 'div' : React.Fragment;

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      className={cn(
        styles.textWithIcon,
        styles[type],
        {
          [styles.padding]: padding,
          [styles.click]: !!onClick,
        },
        className,
      )}
      data-testid={dataTestid}
    >
      <Icon
        variant={iconVariant}
        size={_iconSize}
        className={styles.icon}
        fill={iconColor}
        stroke={iconColor}
        badge={badge}
        isHover={isHover}
        style={{
          alignSelf: iconVerticalAlign,
        }}
      />
      <Tag>
        {children || (
          <Text
            testid={testid}
            variant={_textVariant}
            isHover={isHover}
            colorVariant={_textColorVariant}
            style={textStyle}
          >
            {text}
          </Text>
        )}
        {desc && (
          <Text variant="h5" colorVariant="tertiary">
            {desc}
          </Text>
        )}
      </Tag>
    </div>
  );
};

IconWithText.defaultProps = {
  padding: false,
  type: 'primary',
  iconVerticalAlign: 'center',
  hasHoverEffect: false,
};

IconWithText.propTypes = {
  /** Вариант иконки, например 'cart' из коллекции иконок */
  iconVariant: Icon.propTypes.variant,
  /** Размер иконки */
  iconSize: Icon.propTypes.size,
  /** Вариант отображения текста. */
  textVariant: Text.propTypes.variant,
  /** Цветовой вариант текста */
  textColorVariant: Text.propTypes.colorVariant,
  /** Бейдж для иконки */
  badge: Icon.propTypes.badge,
  /** Текст справа от иконки */
  text: PropTypes.string,
  /** Если true добавляет padding снизу/сверху */
  padding: PropTypes.bool,
  /** Добавляет обработчика клика */
  onClick: PropTypes.func,
  /** Устанавливает тип */
  type: PropTypes.oneOf(['primary', 'secondary']),
  /** Устанавливает цвет иконки */
  iconColor: PropTypes.string,
  /** Устанавливает класс на контейнер */
  className: PropTypes.string,
  /** Устанавливает текст описания под основным текстом */
  desc: PropTypes.string,
  /** Устанавливает верстикальное выравние иконки относительно текста */
  iconVerticalAlign: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** Устанавливает уникальный QA идентификтор. */
  testid: PropTypes.string,
  /** Добавляет изменение цвета элементов при наведении */
  hasHoverEffect: PropTypes.bool,
  /** Устанавливает стили для текста */
  textStyle: PropTypes.object,
};

export default IconWithText;
