import Immutable from "seamless-immutable";
import Types from "~/store/toolbar/toolbarTypes";

const initialState = Immutable({});

export default (state = initialState, { type, ...action }) => {
  switch (type) {
    case Types.SET_TOOLBAR: {
      return initialState.merge({
        ...action.data
      });
    }

    case Types.UPDATE_TOOLBAR: {
      return state.merge({
        ...action.data
      });
    }

    default:
      return state;
  }
};
