import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "~/store/users/userActions";
import { USERS_MODE_ALL } from "~/store/users/userModel";
import DoctorsList from "./DoctorsList";
import styles from "./DoctorsList.scss";
import SearchBlock from "~/components/SearchBlock";
import { CLINIC_DETAILS_DOCTORS_SEARCH } from "~/locators/clinics";
import { getDoctorClinicModeState } from "~/store/users/userSelectors";

const CLINIC_DOCTORS_ITEMS_LIMIT = 20;

const DoctorsListContainer = ({ clinicId }) => {
  const listRef = React.createRef();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const fetchItems = useCallback(
    payload => {
      dispatch(actions.requestSend({ ...payload, clinicId }));
    },
    [clinicId]
  );

  const {
    items,
    isFetching,
    initialFetched,
    canLoadMore,
    hasErrors,
    isEmpty
  } = useSelector(state => getDoctorClinicModeState(state, USERS_MODE_ALL));

  useEffect(() => {
    fetchItems({
      value: search,
      mode: USERS_MODE_ALL,
      page,
      limit: CLINIC_DOCTORS_ITEMS_LIMIT
    });
  }, [search, page]);

  useEffect(() => {
    setPage(1);
    // listRef.current && listRef.current?.scrollToTop();
  }, [search]);

  const incrementPage = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  return (
    <>
      <SearchBlock
        className={styles.search}
        placeholder="Поиск врача по имени"
        testId={CLINIC_DETAILS_DOCTORS_SEARCH}
        onSearchChange={setSearch}
      />
      <DoctorsList
        ref={listRef}
        items={items}
        isFetching={isFetching}
        initialFetched={initialFetched}
        canLoadMore={canLoadMore}
        mode={USERS_MODE_ALL}
        hasErrors={hasErrors}
        isEmpty={isEmpty}
        incrementPage={incrementPage}
      />
    </>
  );
};

export default DoctorsListContainer;
