/* eslint-disable no-plusplus, react/destructuring-assignment */
/*
  forked from https://github.com/ShinyChang/React-Text-Truncate
  todo: refactor, implement expand/collapse
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const getFont = scope => {
  const style = window.getComputedStyle(scope);
  return [style['font-style'], style['font-weight'], style['font-size'], style['font-family']].join(
    ' ',
  );
};

class TextTruncate extends Component {
  componentDidMount() {
    const canvas = document.createElement('canvas');
    const docFragment = document.createDocumentFragment();

    docFragment.appendChild(canvas);
    this.canvas = canvas.getContext('2d');
    this.canvas.font = getFont(this.scope);
    this.forceUpdate();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    if (this.rafId) {
      window.cancelAnimationFrame(this.rafId);
    }
  }

  getRenderText() {
    const {
      containerClassName,
      element,
      line,
      onCalculated,
      onTruncated,
      onToggled,
      text,
      textElement,
      textTruncateChild,
      truncateText,
      maxCalculateTimes,
      offset,
      ...props
    } = this.props;

    const elementCreator =
      typeof textElement === 'string' ? React.createElement : React.cloneElement;

    const scopeWidth = this.scope.getBoundingClientRect().width - offset;

    // return if display:none
    if (scopeWidth === 0) {
      return null;
    }

    // return if all of text can be displayed
    if (scopeWidth >= this.measureWidth(text)) {
      this.onToggled(false);
      return elementCreator(textElement, props, text);
    }

    let childText = '';
    if (textTruncateChild && typeof textTruncateChild.props.children === 'string') {
      childText = textTruncateChild.props.children;
    }

    let currentPos = 1;
    const maxTextLength = text.length;
    let truncatedText = '';
    let splitPos = 0;
    let startPos = 0;
    let displayLine = line;
    let width = 0;
    let lastIsEng = false;
    let isPrevLineWithoutSpace = false;
    let lastPos = 0;
    let lastSpaceIndex = -1;
    let ext = '';

    while (displayLine-- > 0) {
      let loopCnt = 0;
      ext = displayLine ? '' : truncateText + (childText ? ` ${childText}` : '');
      while (currentPos <= maxTextLength) {
        truncatedText = text.substr(startPos, currentPos);
        width = this.measureWidth(truncatedText + ext);

        if (width < scopeWidth) {
          splitPos = text.indexOf(' ', currentPos + 1);
          if (splitPos === -1) {
            currentPos += 1;
            lastIsEng = false;
          } else {
            lastIsEng = true;
            currentPos = splitPos;
          }
        } else {
          do {
            if (loopCnt++ >= maxCalculateTimes) {
              break;
            }
            truncatedText = text.substr(startPos, currentPos);
            if (!displayLine) {
              currentPos--;
            }
            if (truncatedText[truncatedText.length - 1] === ' ') {
              truncatedText = text.substr(startPos, currentPos - 1);
            }
            if (lastIsEng) {
              lastSpaceIndex = truncatedText.lastIndexOf(' ');
              if (lastSpaceIndex > -1) {
                currentPos = lastSpaceIndex;
                if (displayLine) {
                  currentPos++;
                }
                truncatedText = text.substr(startPos, currentPos);
              } else {
                currentPos--;
                truncatedText = text.substr(startPos, currentPos);
              }
            } else {
              currentPos--;
              truncatedText = text.substr(startPos, currentPos);
            }
            width = this.measureWidth(truncatedText + ext);
          } while (width >= scopeWidth && truncatedText.length > 0);
          startPos += currentPos;
          break;
        }
      }

      if (currentPos >= maxTextLength) {
        startPos = maxTextLength;
        break;
      }

      if (
        lastIsEng &&
        !isPrevLineWithoutSpace &&
        text.substr(lastPos, currentPos).indexOf(' ') === -1
      ) {
        isPrevLineWithoutSpace = text.substr(lastPos, currentPos).indexOf(' ') === -1;
        displayLine--;
      }
      lastPos = currentPos + 1;
    }

    if (startPos === maxTextLength) {
      this.onToggled(false);
      return elementCreator(textElement, props, text);
    }

    this.onTruncated();
    this.onToggled(true);
    return elementCreator(
      textElement,
      props,
      <>
        {`${text.substr(0, startPos)}${truncateText}  `}
        {textTruncateChild}
      </>,
    );
  }

  onResize = () => {
    if (this.rafId) {
      window.cancelAnimationFrame(this.rafId);
    }
    this.rafId = window.requestAnimationFrame(this.update.bind(this));
  };

  onToggled = truncated => {
    typeof this.props.onToggled === 'function' &&
      setTimeout(() => this.props.onToggled(truncated), 0);
  };

  onTruncated = () => {
    typeof this.props.onTruncated === 'function' && setTimeout(() => this.props.onTruncated(), 0);
  };

  onCalculated = () => {
    typeof this.props.onCalculated === 'function' && setTimeout(() => this.props.onCalculated(), 0);
  };

  update = () => {
    this.canvas.font = getFont(this.scope);
    this.forceUpdate();
  };

  measureWidth(text) {
    return Math.ceil(this.canvas.measureText(text).width);
  }

  render() {
    const {
      element,
      text,
      style = {},
      containerClassName,
      line,
      onCalculated,
      onTruncated,
      onToggled,
      textElement,
      textTruncateChild,
      truncateText,
      maxCalculateTimes,
      offset,
      children,
      ...props
    } = this.props;

    const { fontWeight, fontStyle, fontSize, fontFamily } = style;

    const renderText =
      this.scope && line ? this.getRenderText() : React.cloneElement(textElement, props, text);

    const rootProps = {
      ref: el => {
        this.scope = el;
      },
      className: containerClassName,
      style: {
        overflow: 'hidden',
        fontWeight,
        fontStyle,
        fontSize,
        fontFamily,
      },
    };

    this.scope && this.onCalculated();
    return React.createElement(
      element,
      rootProps,
      <>
        {renderText}
        {children}
      </>,
    );
  }
}

TextTruncate.propTypes = {
  containerClassName: PropTypes.string,
  element: PropTypes.string,
  line: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  onCalculated: PropTypes.func,
  onTruncated: PropTypes.func,
  onToggled: PropTypes.func,
  text: PropTypes.string,
  textElement: PropTypes.node,
  textTruncateChild: PropTypes.node,
  truncateText: PropTypes.string,
  maxCalculateTimes: PropTypes.number,
  offset: PropTypes.number,
  children: PropTypes.node,
  style: PropTypes.object,
};

TextTruncate.defaultProps = {
  element: 'div',
  line: 1,
  text: '',
  textElement: 'span',
  truncateText: '…',
  maxCalculateTimes: 10,
  offset: 0,
};

export default TextTruncate;
