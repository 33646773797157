import React, { useMemo, useRef, useState } from "react";
import { Input, Label } from "ibolit-ui";
import moment from "moment-timezone";
import locators from "~/locators/auth";
import DatePicker from "~/components/DatePicker";
import { getFormatDateOfBirth } from "~/utils/dateUtils";
import styles from "./UserInfo.scss";
// TODO сделать один DatePicker для профиля и активации
const minDateOfBirth = moment()
  .endOf("day")
  .subtract(150, "year");

const maxDateOfBirth = moment().endOf("day");

const DateOfBirthSelector = ({ dateOfBirth, setDateOfBirth }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const inputDateOfBirthRef = useRef();
  const [minDate, maxDate] = useMemo(
    () => [moment(minDateOfBirth).toDate(), moment(maxDateOfBirth).toDate()],
    [minDateOfBirth, maxDateOfBirth]
  );
  const isClickOnDatePickContainerRef = useRef(false);
  const datePickerValue = useMemo(() => {
    const formatDate = moment(dateOfBirth, "DD.MM.YYYY", true);

    if (
      formatDate.isValid() &&
      formatDate > minDateOfBirth &&
      formatDate < maxDateOfBirth
    ) {
      return formatDate.toDate();
    }
  }, [dateOfBirth, minDateOfBirth, maxDateOfBirth]);

  const handleDayPickerContainerMouseDown = () => {
    isClickOnDatePickContainerRef.current = true;
  };

  const handleDateOfBirthFocus = () => {
    setShowDatePicker(true);
  };

  const handleDateOfBirthBlur = () => {
    if (!isClickOnDatePickContainerRef.current) {
      setShowDatePicker(false);
    } else {
      inputDateOfBirthRef.current.focus();
      isClickOnDatePickContainerRef.current = false;
    }
  };

  const handleDayChange = date => {
    setShowDatePicker(false);
    setDateOfBirth(date);
  };

  const isPlaceholder = dateOfBirth === "";

  return (
    <Label
      title="Дата рождения"
      className={styles.date_of_birth}
      testid="DateOfBirthSelector__DATE_OF_BIRTH"
    >
      <Input
        nativeChange
        fullWidth
        value={
          isPlaceholder
            ? "Укажите дату рождения"
            : getFormatDateOfBirth(dateOfBirth)
        }
        className={isPlaceholder ? styles.date_placeholder : ""}
        type="text"
        name="date_of_birth"
        autocomplete="off"
        inputBinds={{
          onFocus: handleDateOfBirthFocus,
          onBlur: handleDateOfBirthBlur,
          ref: inputDateOfBirthRef
        }}
        testidProps={{
          input: locators.AUTH_DATE_OF_BIRTH_INPUT
        }}
      />
      <div
        onMouseDown={handleDayPickerContainerMouseDown}
        className={styles.date_picker_wrapper}
      >
        {showDatePicker && (
          <div className={styles.date_picker}>
            <DatePicker
              switchBetweenYearsAndMonths
              onDayClick={handleDayChange}
              value={datePickerValue}
              maxDate={maxDate}
              minDate={minDate}
              selectedDays={datePickerValue}
            />
          </div>
        )}
      </div>
    </Label>
  );
};

export default DateOfBirthSelector;
