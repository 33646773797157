import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./FileReader.scss";

const InputFileReader = ({
  render,
  onChange: change,
  maxFileSize,
  fileExtensions
}) => {
  useEffect(() => {
    InputFileReader.errorsText = getErrorsText({
      maxFileSize,
      fileExtensions
    });
  }, [maxFileSize, fileExtensions]);
  const [readedFile, setReadedFile] = useState();
  const [error, setError] = useState();
  const inputFileRef = useRef();

  const onChildrenClick = () => {
    inputFileRef.current.click();
  };

  const checkFileBeforeReads = file => {
    if (file.size > maxFileSize) {
      throw new Error(InputFileReader.errorsText.maxFileSize);
    }

    if (!fileExtensions.includes(getExtension(file.name))) {
      throw new Error(InputFileReader.errorsText.fileExtensions);
    }
  };

  const readFile = file => {
    const reader = new FileReader();

    reader.addEventListener("load", ({ target }) => {
      setReadedFile(target);
      change(file);
    });

    reader.addEventListener("error", err => {
      setError(err.message);
    });

    reader.readAsDataURL(file);
  };

  const onInputChange = () => {
    const file = inputFileRef.current.files[0];

    if (file) {
      try {
        checkFileBeforeReads(file);
        readFile(file);
      } catch (err) {
        setError(err.message);
      }
    }
  };

  return (
    <div className={styles.fileLoader}>
      <input
        className={styles.inputFile}
        ref={inputFileRef}
        onChange={onInputChange}
        type="file"
      />
      {render({
        onClick: onChildrenClick,
        error,
        readedFile
      })}
    </div>
  );
};

function getErrorsText({ maxFileSize, fileExtensions }) {
  return {
    maxFileSize: `Ваш файл слишком большого размера, разамер не должен быть больше ${maxFileSize}kb`,
    fileExtensions: `Не корректный формат данных, доступный(ые) фоматы: ${fileExtensions.join()}`
  };
}

function getExtension(filename) {
  return filename
    .split(".")
    .pop()
    .toLowerCase();
}

InputFileReader.defaultProps = {
  maxFileSize: 10000000
};

InputFileReader.propTypes = {
  onChange: PropTypes.func.isRequired,
  fileExtensions: PropTypes.array.isRequired,
  maxFileSize: PropTypes.number
};

export default InputFileReader;
