import React from "react";
import cn from "classnames";
import styles from "./header.module.scss";

import videoPlaceholderImage from "./video.png";
import offlinePlaceholderImage from "./offline.png";

const renderHeaderStatus = (status, isInProgress) => {
  switch (true) {
    case status === "pending":
      return "Не подтверждена";
    case status === "wait_payment":
      return "Ожидает оплаты";
    case isInProgress:
      return "Идёт приём";
    case status === "approved":
      return "Подтверждена";
    case status === "completed":
      return "Состоялась";
    case status === "canceled":
      return "Отменена";
    case status === "expired":
    case status === "not_appeared":
      return "Не состоялась";
    default:
      return "";
  }
};

export const Header = ({
  role,
  name,
  birthDate,
  consultationDate,
  duration,
  price,
  humanImage,
  status,
  tariffName,
  isTariffTypeOffline,
  isInProgress,
  specialties,
  Component,
  to,
}) => {
  const Wrapper = Component ? Component : "div";

  return (
    <header className={styles.header}>
      <div
        className={styles.headerName}
        data-testid={"Receptions__header_tariffName"}
      >
        {tariffName}
      </div>
      <div
        className={cn(
          styles.status,
          status === "expired" ||
            status === "canceled" ||
            status === "not_appeared"
            ? styles.status_error
            : null,
          isInProgress ? styles.statusOnAir : null
        )}
        data-testid={"Receptions__header_status"}
      >
        {renderHeaderStatus(status, isInProgress)}
      </div>
      <div className={styles.cards}>
        <Wrapper className={styles.human_info} to={to} target={"_blank"}>
          <img
            className={styles.image}
            alt="doctor_or_patient"
            src={humanImage || videoPlaceholderImage}
          />
          <div className={styles.info_container}>
            <div
              className={styles.role}
              data-testid={"Receptions__header_role"}
            >
              {role}
            </div>
            <div
              className={cn(
                styles.human_name,
                role === "Врач" ? styles.human_name_doctor : null
              )}
              data-testid={"Receptions__header_human_name"}
            >
              {name}
            </div>
            {role === "Врач" ? (
              <div
                className={styles.specialties}
                data-testid={"Receptions__header_specialties"}
              >
                {specialties.join(", ")}
              </div>
            ) : (
              <div
                className={styles.birthDate}
                data-testid={"Receptions__header_birthDate"}
              >
                {birthDate}
              </div>
            )}
          </div>
        </Wrapper>

        <div className={styles.consultation_info}>
          <div className={styles.image_container}>
            <img
              className={styles.image}
              alt="video_icon"
              src={
                isTariffTypeOffline
                  ? offlinePlaceholderImage
                  : videoPlaceholderImage
              }
            />
          </div>
          <div className={styles.info_container}>
            <div
              className={styles.consultationDateHeader}
              data-testid={"Receptions__header_date-header"}
            >
              Дата
            </div>
            <div
              className={styles.consultationDate}
              data-testid={"Receptions__header_date-value"}
            >
              {consultationDate}
            </div>
            <div
              className={styles.videoconsultationHeader}
              data-testid={"Receptions__header_videoConsultationHeader"}
            >
              {isTariffTypeOffline ? "Прием в клинике" : "Видеоконсультация"}
            </div>
            <div className={styles.consultation_data}>
              <div
                className={styles.duration}
                data-testid={"Receptions__header_duration"}
              >
                {duration}
              </div>
              <div
                className={styles.price}
                data-testid={"Receptions__header_price"}
              >
                {price}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
