export default {
  HEADER: "modal__header",
  BUTTON_CLOSE: "modal__button-close"
};

export const ERROR_MODAL = {
  BOX: "errorModal__box",
  TITLE: "errorModal__header",
  BUTTON: "errorModal__button"
};
