import React from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { Text } from "ibolit-ui";
import styles from "./FieldError.scss";

export const getFieldError = (error, touched) => {
  const showError = touched && !!error;

  return [showError, error];
};

const FieldError = ({ showError, error, className = "" }) => (
  <CSSTransition
    timeout={300}
    in={showError}
    classNames={{ ...styles }}
    unmountOnExit
  >
    <Text variant="desc" colorVariant="error" className={className}>
      {error}
    </Text>
  </CSSTransition>
);

FieldError.propTypes = {
  error: PropTypes.string,
  showError: PropTypes.bool,
  className: PropTypes.string
};

export default FieldError;
