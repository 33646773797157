import React from 'react';
import cn from 'classnames';

import { NoConclusionIcon } from './NoConclusionIcon';

import styles from './ConsultationConclusion.scss';

export const NoConclusionPlacehoder = ({
  fill="#ff537c",
  isDoctor,
  withPadding,
}) => (
  <div className={cn(
    styles.placeholderContainer,
    fill === "#ff537c" && styles.pinkScrollbar,
    withPadding && styles.placeholderWithPadding,
    !isDoctor && styles.patientPlaceholderContainer
  )}>
    <div className={styles.icon}>
      <NoConclusionIcon fill={fill} />
    </div>
    <div className={styles.header} data-testid="Receptions__NoConclusionPlaceholder-header">
      У вас пока нет заключения по данной консультации
    </div>
    {isDoctor ? <div className={styles.text} data-testid="Recptions__NoConclusionPlaceholder-doctor_text">
      Вы сможете оставить заключение после успешного завершения консультации
    </div> : (
      <div className={styles.text} data-testid="Recptions__NoConclusionPlaceholder-patient_text">
        Врач сможет оставить заключение после успешного завершения консультации
      </div>
    )}
  </div>
);
