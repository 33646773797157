import {
  pluralizeDays,
  pluralizeHours,
  pluralizeMinutes
} from "~/utils/pluralize";

export const formatDurationFrom = (string, duration) => {
  let days = Math.floor(duration / 1440);
  let hours = Math.floor((duration - days * 1400) / 60);
  let minutes = Math.floor(duration % 60);
  return `${string}${days === 0 ? "" : ` ${days} д`}${
    hours === 0 ? "" : ` ${hours} ч`
  }${minutes === 0 ? "" : ` ${minutes} мин`}`;
};

export const formatDuration = (string, duration) => {
  let days = Math.floor(duration / 1440);
  let hours = Math.floor((duration - days * 1440) / 60);
  let minutes = Math.floor(duration % 60);
  return `${string}${days === 0 ? "" : ` ${pluralizeDays(days)}`}${
    hours === 0 ? "" : ` ${pluralizeHours(hours)}`
  }${minutes === 0 ? "" : ` ${pluralizeMinutes(minutes)}`}`;
};
