import React from "react";
import { Text } from "ibolit-ui";
import AUTH_LOCATORS from "~/locators/auth";

export default React.memo(() => (
  <div>
    <Text
      testid={AUTH_LOCATORS.AUTH_LOGIN_LINK_SUPPORT}
      variant="caption"
      colorVariant="tertiary"
    >
      Если у вас возникли трудности со входом в приложение —
      <br />
      свяжитесь с&nbsp;
      <a href="mailto:support@ibolit.pro">support@ibolit.pro</a>
    </Text>
  </div>
));
