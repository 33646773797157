import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import styles from './Select.scss';
import useOutsideClickHandler from '../../utils/useOutsideClickHandler';

const Select = ({
  disabled,
  placeholder,
  onChange,
  className,
  items,
  value,
  defaultValue,
  renderItem,
  inputClassName,
  style,
  testId,
}) => {
  const bodyRef = useRef(null);
  const inputRef = useRef(null);
  const overlayRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [inputDimensions, setInputDimensions] = useState(null);
  const [inputValue, setInputValue] = useState(defaultValue);
  useOutsideClickHandler(overlayRef, () => setIsOpen(false));

  useEffect(() => {
    bodyRef.current = document.querySelector('body');
    document.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const matchingItem = items.find(item => item.value === value);
    if (!matchingItem) return;

    setInputValue(matchingItem.value);
  }, [items, value]);

  function getInputPosition() {
    const input = inputRef.current.getBoundingClientRect();
    const { scrollTop, scrollLeft } = document.documentElement;

    return {
      top: scrollTop + input.top,
      left: scrollLeft + input.left,
      width: input.width,
      height: input.height,
    };
  }

  function handleResize() {
    setInputDimensions(getInputPosition());
  }

  function handleInputClick() {
    if (disabled) return;

    if (!isOpen) {
      setInputDimensions(getInputPosition());
    }

    setIsOpen(prevState => !prevState);
  }

  function handleItemClick(item) {
    return () => {
      setIsOpen(false);
      setInputValue(item.value);

      if (onChange) {
        onChange(item);
      }
    };
  }

  return (
    <div className={cn(styles.container, className)} style={style} ref={overlayRef}>
      <input
        className={cn(styles.input, inputClassName, {
          [styles.open]: isOpen,
          [styles.isDisabled]: disabled,
        })}
        placeholder={placeholder}
        readOnly
        value={items.find(item => item.value === inputValue)?.label ?? ''}
        ref={inputRef}
        onClick={handleInputClick}
        data-testid={testId}
      />
      {isOpen && (
        <div className={cn(styles.itemsContainer)}>
          <Scrollbars style={{ width: inputDimensions.width }} autoHeight autoHeightMax={180}>
            {items.map((item, index) => {
              if (renderItem) {
                return (
                  <div
                    key={item.value || index}
                    onClick={handleItemClick(item)}
                    data-testid={`${testId}-selected`}
                  >
                    {renderItem(item, index)}
                  </div>
                );
              }

              return (
                <div
                  key={item.value || index}
                  className={styles.selectItem}
                  onClick={handleItemClick(item)}
                  data-testid={`${testId}-${item.value || index}`}
                >
                  {item.label}
                </div>
              );
            })}
          </Scrollbars>
        </div>
      )}
    </div>
  );
};

Select.defaultProps = {
  items: [],
};

Select.propTypes = {
  /* Disabled select */
  disabled: PropTypes.bool,
  /* Styles for input */
  inputClassName: PropTypes.string,
  /* Additional classname to be passed to the container element */
  className: PropTypes.string,
  /* The identifier for the data-testid attribute */
  testId: PropTypes.string,
  /* Additional styles to apply to the container */
  style: PropTypes.object,
  /* The list of values to render */
  items: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  /* The current value of Select. must be one of the values in items */
  value: PropTypes.string,
  /* Default value of the Select which is used only for the initial render */
  defaultValue: PropTypes.string,
  /* Function which overrides the default render logic */
  renderItem: PropTypes.func,
  /* Placeholder value for the input */
  placeholder: PropTypes.string,
  /* Callback function which triggers when an item from the list was picked */
  onChange: PropTypes.func,
};

export default Select;
