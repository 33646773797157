import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import VerySadIcon from "../../assets/img/sad-af.png";
import SadIcon from "../../assets/img/sad.png";
import NeutralIcon from "../../assets/img/neutral.png";
import HappyIcon from "../../assets/img/happy.png";
import VeryHappyIcon from "../../assets/img/happy-af.png";
import styles from "./ConsultationFeedbackRanking.scss";

export const RANKINGS = [
  { value: 1, img: VerySadIcon },
  { value: 2, img: SadIcon },
  { value: 3, img: NeutralIcon },
  { value: 4, img: HappyIcon },
  { value: 5, img: VeryHappyIcon }
];

function ConsultationFeedbackRanking({ ranking, onClick }) {
  function handleClick(rank) {
    return () => {
      if (onClick) {
        onClick(rank);
      }
    };
  }

  return (
    <div className={styles.container}>
      {RANKINGS.map(({ value, img }) => (
        <div
          key={value}
          data-testid="image-container"
          className={cn(styles.rank, {
            [styles.activeRank]: ranking !== undefined && ranking === value,
            [styles.inactiveRank]: ranking !== undefined && ranking !== value
          })}
          onClick={handleClick(value)}
        >
          <img src={img} alt="satisfaction icon" />
        </div>
      ))}
    </div>
  );
}

ConsultationFeedbackRanking.propTypese = {
  ranking: PropTypes.number,
  onClick: PropTypes.func
};

export default ConsultationFeedbackRanking;
