import Immutable from "seamless-immutable";
import Types from "~/store/subscriptions/subscriptionsTypes";
import {
  searchSuccessReducer,
  searchRequestReducer,
  searchFailureReducer
} from "~/store/utils";

const initialState = Immutable({
  items: {},
  selected: null,
  isFetching: false,
  isDeleting: false,
  error: null,
  all: {
    order: []
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.SEARCH_REQUEST:
      return searchRequestReducer(state, action);

    case Types.SEARCH_SUCCESS:
      return searchSuccessReducer(state, action);

    case Types.SEARCH_FAILURE:
      return searchFailureReducer(state, action);

    case Types.SELECT_ITEM:
      return state.set("selected", action.item);

    case Types.DELETE_REQUEST:
      return state.set("isDeleting", true);

    case Types.DELETE_SUCCESS:
      return state.merge(
        {
          isDeleting: false,
          items: { [action.request.id]: { deleted: true } }
        },
        { deep: true }
      );

    case Types.DELETE_FAILURE:
      return state.merge({ isDeleting: false, error: action.error });

    default:
      return state;
  }
};
