import React from "react";
import cn from "classnames";
import { Text } from "ibolit-ui";
import PriceBlock from "./PriceBlock";
import styles from "./TariffsSelector.scss";
import {
  NO_DESCRIPTION,
  ONETIME_PAYMENT_CAN_CLOSE_DESCRIPTION,
  ONETIME_PAYMENT_DESCRIPTION,
  SUBSCRIPTION_DESCRIPTION
} from "~/consts/tariffs";

const TariffItem = ({
  tariff: { name, ...tariff },
  isSelected,
  bordered,
  onClick,
  dataTestid,
}) => (
  <div
    onClick={onClick}
    className={cn(styles.item, {
      [styles.bordered]: bordered,
      [styles.selected]: isSelected
    })}
    data-testid={dataTestid}
  >
    <Text variant="h4">{name}</Text>
    <Text
      variant="caption"
      colorVariant="tertiary"
      className={styles.description}
    >
      {getTariffDescription(tariff)}
    </Text>
    <PriceBlock {...tariff} />
  </div>
);

function getTariffDescription(tariff) {
  if (tariff.description) {
    return tariff.description;
  }

  if (tariff.is_subscription) {
    return SUBSCRIPTION_DESCRIPTION;
  }
  if (tariff.can_close) {
    return ONETIME_PAYMENT_CAN_CLOSE_DESCRIPTION;
  }

  if (tariff.duration) {
    return ONETIME_PAYMENT_DESCRIPTION;
  }
  return NO_DESCRIPTION;
}

export default TariffItem;
