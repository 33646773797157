export const DOCUMENTS_ASIDE = {
  HEADER: "documents__documentsAside__header",
  LIST_ITEM: "documents__documentsAside__listItem",
  LIST_ITEM_DATE: "documents__documentsAside__listItemDate",
  LIST_ITEM_CONCLUSION: "documents__documentsAside__listItemConclusion",
  LIST_ITEM_NAME: "documents__documentsAside__listItemName"
};

export const DOCUMENT_DETAIL = {
  HEADER: "documents__documentDetail__header",
  DATE: "documents__documentDetail__date",
  DOCTOR_CONTAINER: "documents__documentDetail__doctorContainer",
  CONCLUSION: "documents__documentDetail__conclusion",
  FILE_CONTAINER: "documents__documentDetail__fileContainer",
  DOWNLOAD_BUTTON: "documents__documentDetail__downloadButton"
};
