import cn from "classnames";
import React, { useRef, useState, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import VMasker from "vanilla-masker";
import { Input, Text, Label, Button } from "ibolit-ui";
import { checkValidFullName, FULL_NAME_MAX_LENGTH } from "~/utils/validations";
import DatePicker from "~/components/DatePicker";
import { SETTINGS } from "~/locators/privateProfile";
import styles from "./Form.scss";
import { getFormatDateOfBirth } from "~/utils/dateUtils";

const Form = ({
  handleSubmit,
  errors,
  values,
  dirty,
  isValid,
  loadingUserData,
  resetForm,
  setFieldValue,
  minDate: minDateOfBirth,
  maxDate: maxDateOfBirth
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const inputDateOfBirthRef = useRef();
  const isClickOnDatePickContainerRef = useRef(false);

  const datePickerValue = useMemo(() => {
    const formatDate = moment(values.date_of_birth, "DD.MM.YYYY", true);

    if (
      formatDate.isValid() &&
      formatDate > minDateOfBirth &&
      formatDate < maxDateOfBirth
    ) {
      return formatDate.toDate();
    }
  }, [values.date_of_birth, minDateOfBirth, maxDateOfBirth]);

  const [minDate, maxDate] = useMemo(
    () => [moment(minDateOfBirth).toDate(), moment(maxDateOfBirth).toDate()],
    [minDateOfBirth, maxDateOfBirth]
  );

  const onDateOfBirthBlur = () => {
    if (!isClickOnDatePickContainerRef.current) {
      setShowDatePicker(false);
    } else {
      inputDateOfBirthRef.current.focus();
      isClickOnDatePickContainerRef.current = false;
    }
  };

  const onDayPickerContainerMouseDown = () => {
    isClickOnDatePickContainerRef.current = true;
  };

  const onDateOfBirthFocus = () => {
    setShowDatePicker(true);
  };

  const onDateOfBirthChange = event => {
    const { value } = event.target;

    if (value.length <= 10) {
      setFieldValue("date_of_birth", value);
    }
  };

  const onFullNameChange = event => {
    const { value } = event.target;

    if (checkValidFullName(value) || value === "") {
      setFieldValue("full_name", value);
    }
  };

  const handleDayChange = date => {
    setShowDatePicker(false);
    const formattedDate = getFormatDateOfBirth(date);
    setFieldValue("date_of_birth", formattedDate);
  };

  const onCancel = () => {
    resetForm();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Label
        title="ФИО"
        className={styles.field}
        testid="SETTINGS__FULL_NAME_LABEL"
      >
        <Input
          nativeChange
          fullWidth
          value={values.full_name}
          onChange={onFullNameChange}
          name="full_name"
          disabled={loadingUserData}
          autocomplete="off"
          maxlength={FULL_NAME_MAX_LENGTH}
          onClick={onDateOfBirthFocus}
          testidProps={{
            input: SETTINGS.INPUT_FULL_NAME
          }}
        />
        {dirty && errors.full_name && (
          <Text variant="desc" colorVariant="error">
            {errors.full_name}
          </Text>
        )}
      </Label>
      <div
        className={cn(styles.buttonsList, {
          [styles.buttonsListShow]: dirty && isValid
        })}
      >
        <Button
          className={styles.formButton}
          type="button"
          onClick={onCancel}
          variant="secondary"
          disabled={loadingUserData}
          testid={SETTINGS.BUTTON_CANCEL}
        >
          Отмена
        </Button>
        <Button
          testid={SETTINGS.BUTTON_SAVE}
          className={styles.formButton}
          colorVariant="patient"
          disabled={loadingUserData}
        >
          Сохранить
        </Button>
      </div>
      <Label
        title="Дата рождения"
        className={styles.field}
        testid="SETTINGS__BIRTHDAY"
      >
        <Input
          nativeChange
          fullWidth
          value={VMasker.toPattern(values.date_of_birth, "99.99.9999")}
          type="text"
          onChange={onDateOfBirthChange}
          name="date_of_birth"
          disabled={loadingUserData}
          autocomplete="off"
          inputBinds={{
            onFocus: onDateOfBirthFocus,
            onBlur: onDateOfBirthBlur,
            ref: inputDateOfBirthRef
          }}
          testidProps={{
            input: SETTINGS.INPUT_DATE_OF_BIRTH
          }}
        />
        {showDatePicker && (
          <div
            onMouseDown={onDayPickerContainerMouseDown}
            className={styles.datePicker}
          >
            <DatePicker
              switchBetweenYearsAndMonths
              onDayClick={handleDayChange}
              value={datePickerValue}
              maxDate={maxDate}
              minDate={minDate}
              selectedDays={datePickerValue}
            />
          </div>
        )}
        {dirty && errors.date_of_birth && (
          <Text variant="desc" colorVariant="error">
            {errors.date_of_birth}
          </Text>
        )}
      </Label>
    </form>
  );
};

Form.propTypes = {
  loadingUserData: PropTypes.bool.isRequired,
  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired
};

export default Form;
