import { eventChannel } from "redux-saga";
import { takeLatest, call, take, put } from "redux-saga/effects";
import { getToken } from "~/utils/localStorageHelper";
import authTypes from "~/store/auth/authTypes";
import actions from "~/store/nativeSocket/nativeSocketActions";
import NativeSocket from "~/utils/nativeSocket";

const parse2PushMsg = socketEvent => {
  const { event, data } = JSON.parse(socketEvent.data);
  return {
    data: {
      type: event,
      data
    }
  };
};

const nativeSocketChannel = ({ token }) =>
  eventChannel(emitter => {
    const nativeSocket = new NativeSocket(token, event => {
      const pushEvent = parse2PushMsg(event);
      return emitter(actions.newMessage(pushEvent));
    });

    return () => {
      nativeSocket.close();
    };
  });

let socketChannel = null;
function* nativeSocketSaga() {
  const token = getToken();

  socketChannel = yield call(nativeSocketChannel, { token });

  while (true) {
    try {
      const action = yield take(socketChannel);
      yield put(action);
    } catch (err) {
      yield put(actions.error(err));
    }
  }
}

function nativeSocketDisconnect() {
  socketChannel.close();
  socketChannel = null;
}

export default [
  takeLatest(authTypes.USER_AUTHORIZE_SUCCESS, nativeSocketSaga),
  takeLatest(authTypes.LOGOUT, nativeSocketDisconnect)
];
