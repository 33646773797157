import Immutable from "seamless-immutable";
import types from "~/store/appStarted/appStartedTypes";

const initialState = Immutable({
  appReady: null,
  errors: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.APP_STARTED_SUCCESS: {
      return state.merge({ appReady: true });
    }

    case types.APP_STARTED_FAILURE: {
      return state.merge({
        appReady: false,
        errors: action.errors
      });
    }

    default:
      return state;
  }
};
