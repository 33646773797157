/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import CheckBox from './assets/CheckBox.svg';
import RadioBox from './assets/RadioBox.svg';
import CheckMark from './assets/CheckMark.svg';
import Text from '../Text';

import styles from './CheckInput.scss';

const TYPES = {
  checkbox: CheckBox,
  radio: RadioBox,
};

const CheckInput = React.forwardRef(
  ({ className, onChange, name, text, type, disabled, checked, value, testidProps }, ref) => {
    const inputRef = ref || useRef(null);

    useEffect(() => {
      if (checked && inputRef.current) {
        inputRef.current.checked = true;
      }
    }, []);

    const handleChange = () => {
      const isChecked = inputRef.current.checked;
      const result = value !== undefined && isChecked ? value : isChecked;
      if (onChange) onChange(result);
    };

    const Icon = TYPES[type];
    return (
      <div className={cn(styles.container, className)}>
        <label
          data-testid={testidProps.label}
          className={cn(styles.labelContainer, { [styles.disabled]: disabled })}
        >
          <input
            type={type}
            ref={inputRef}
            name={name}
            onChange={handleChange}
            disabled={disabled}
            data-testid={testidProps.input}
          />
          <svg viewBox="0 0 38 38" className={cn(styles.boxContainer)}>
            <use xlinkHref={`#${Icon.id}`} className={cn(styles.icon)} />
            <use xlinkHref={`#${CheckMark.id}`} className={cn(styles.checkMark)} />
          </svg>
          {typeof text === 'string' ? (
            <Text variant="h4" tag="span" className={styles.text}>
              {text}
            </Text>
          ) : (
            text
          )}
        </label>
      </div>
    );
  },
);

CheckInput.defaultProps = {
  type: 'checkbox',
  disabled: false,
  testidProps: {},
};

CheckInput.propTypes = {
  /** Вид элемента */
  type: PropTypes.oneOf(['radio', 'checkbox']),
  /** Класс основного контейнера */
  className: PropTypes.any,
  /** Определяет уникальное имя элемента формы. */
  name: PropTypes.string,
  /** Устанавливает обработчик изменения значения элемента */
  onChange: PropTypes.any,
  /** Запрещает ставить/снимать отметку */
  disabled: PropTypes.bool,
  /** Выбран ли элемент по умолчанию */
  checked: PropTypes.bool,
  /** Лейбл для элемента */
  text: PropTypes.node,
  /** если указано передается в onChange */
  value: PropTypes.any,
  /** Устанавливает уникальные QA идентификторы. */
  testidProps: PropTypes.shape({
    input: PropTypes.string,
    label: PropTypes.string,
  }),
};

export default CheckInput;
