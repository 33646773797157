export const PATIENT_PROBLEM_STATUSES = [
  {
    icon: "primary-appointment",
    title: "Первичная консультация",
    value: "primary_appointment"
  },
  {
    icon: "transcript-of-analyzes",
    title: "Расшифровка анализов",
    value: "transcript_of_analyzes"
  },
  {
    icon: "repeated-appointment",
    title: "Повторная консультация",
    value: "repeated_appointment"
  },
  {
    icon: "second-opinion",
    title: "Второе мнение",
    value: "second_opinion"
  }
];
