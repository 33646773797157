import React from "react";
import { Separator, Button } from "ibolit-ui";
import UserItem from "~/components/UserItem";
import EntitledBox from "~/components/EntitledBox";
import formatPrice from "~/utils/formatPrice";
import { formatDuration } from "~/modals/Tariffs/tariffsUtils";
import styles from "./SubscriptionInfo.scss";
import { getTzDate } from "~/utils/dateUtils";

const SubscriptionInfo = ({ selected, testid, onDeleteClick }) => {
  const { id, doctor, next_payment, service, card_last_four } = selected;
  const { type, duration, price, is_subscription } = service;
  return (
    <div className={styles.container} data-testid={`${testid}__info-container`}>
      <UserItem user={doctor} variant="shape" />
      <EntitledBox
        className={styles.box}
        title="Тип подписки"
        content={`Консультация в чате за ${formatPrice(price)} ${formatDuration(
          {
            duration,
            is_subscription,
            type
          }
        )}`}
      />
      {next_payment && (
        <EntitledBox
          className={styles.box}
          title="Дата следующей оплаты"
          content={getTzDate(next_payment).format("DD MMMM YYYY")}
        />
      )}
      {card_last_four && (
        <EntitledBox
          className={styles.box}
          title="Способ оплаты"
          content={`Банковская карта *${card_last_four}`}
        />
      )}
      <div style={{ flex: 1, minHeight: 24 }} />
      <Separator style={{ flexShrink: 0 }} />
      <Button
        variant="secondary"
        colorVariant="patient"
        onClick={() => onDeleteClick(id)}
        fullWidth
        className={styles.button}
        testid={`${testid}__info__button-cancel`}
      >
        Отменить подписку
      </Button>
    </div>
  );
};

export default SubscriptionInfo;
