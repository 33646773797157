import { useDispatch, useSelector } from "react-redux";
import isNaN from "lodash/isNaN";
import PropTypes from "prop-types";
import React, { useRef, useEffect, useCallback } from "react";
import MainLayout, { Aside, Section } from "~/components/layouts/Main/Main";
import EmptyStub from "~/components/EmptyStub";
import ConsultationsList from "./ConsultationsList";
import { ConsultationPage } from "./ConsultationPage";
import styles from './ConsultationPage.scss';

import actions from "~/store/consultation/consultationActions";
import { getConsultationsItems } from "~/store/consultation/consultationSelectors";
import { getUsers } from "~/store/users/userSelectors";
import userActions from "~/store/users/userActions";

const ConsultationsLayout = ({ match, history }) => {
  const consultationRef = useRef();
  let { consultationId } = match.params;
  consultationId !== undefined && (consultationId = Number(consultationId));
  consultationRef.current = consultationId;
  const dispatch = useDispatch();

  useEffect(() => {
    isNaN(consultationId) && history.replace("/consultations/");
    if (consultationId) {
      dispatch(actions.getInfo(consultationId));
    }
  }, [consultationId]);

  const consultationData = useSelector(getConsultationsItems)[consultationId];
  const doctorData = useSelector(getUsers)[consultationData?.doctor];

  useEffect(() => {
    if (consultationData?.doctor) {
      dispatch(userActions.tariffsRequest(consultationData.doctor))
      dispatch(userActions.userInfoRequest(consultationData.doctor));
    }
  }, [consultationData])

  const selectConsultation = useCallback(consultation => {
    const selected =
      consultation.id === consultationRef.current ? null : consultation.id;
    history.push(`/consultations/${selected || ""}`);
  }, []);

  return (
    <MainLayout mode="wide" className={styles.height880}>
      <Aside>
        <ConsultationsList
          id={+consultationId}
          onItemClick={selectConsultation}
        />
      </Aside>
      <Section key={consultationId || "stub"} className={styles.section}>
        {!consultationId ? (
          <EmptyStub text={"Выберите консультацию для\nпросмотра деталей"} />
        ) : (
          <ConsultationPage
            consultationData={consultationData}
            doctorData={doctorData}
          />
        )}
      </Section>
    </MainLayout>
  );
};

ConsultationsLayout.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  })
};

export default ConsultationsLayout;
