import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Box } from "ibolit-ui";
import styles from "./Payment.scss";
import { useCloseModal } from "~/hooks/useModal";
import { PAYMENT_IFRAME_VIEW } from "./InvoicedPayment";

/**
  Модалка должна открываться с width: 'auto' - showModal(PaymentModal, { ... }, { width: 'auto' });

  Visa с 3-D Secure           4242 4242 4242 4242 Успешный результат/Успешный результат
  MasterCard с 3-D Secure     5555 5555 5555 4444 Успешный результат/Успешный результат
  Visa с 3-D Secure           4012 8888 8888 1881 Недостаточно средств на карте/—
  MasterCard с 3-D Secure     5105 1051 0510 5100 Недостаточно средств на карте/—
  Visa без 3-D Secure         4111 1111 1111 1111 Успешный результат/Недостаточно средств на карте
  MasterCard без 3-D Secure   5200 8282 8282 8210 Успешный результат/Недостаточно средств на карте
  Visa без 3-D Secure         4000 0566 5566 5556 Недостаточно средств на карте/—
  MasterCard без 3-D Secure   5404 0000 0000 0043 Недостаточно средств на карте/—
 */

const PaymentIFrame = ({ uri, onSuccess, onFailure, autoClose }) => {
  const hideModal = useCloseModal(PAYMENT_IFRAME_VIEW);

  useEffect(() => {
    const messageListener = ({ data }) => {
      if (data === "success" || data === "fail") {
        data === "success" && onSuccess && onSuccess();
        data === "fail" && onFailure && onFailure();
        autoClose && hideModal();
      }
    };

    window.addEventListener("message", messageListener, false);
    return () => window.removeEventListener("message", messageListener, false);
  }, []);

  return (
    <Box>
      <iframe title="payment" src={uri} width={596} className={styles.iframe} />
    </Box>
  );
};

PaymentIFrame.defaultProps = {
  autoClose: true
};

PaymentIFrame.propTypes = {
  uri: PropTypes.string.isRequired,
  autoClose: PropTypes.bool,
  onFailure: PropTypes.func,
  onSuccess: PropTypes.func
};

export default PaymentIFrame;
