export const CONSULTATIONS_LIST = {
  HEADER: "consultations__consultationsList__header",
  NEW_CONSULTATION: "consultations__consultationsList__button-new-consultation",
  ITEM: "consultations__consultationsList__consultation_item",
  TABS: "consultations__consultationsList__tabs",
  CONTAINER: "consultations__consultationsList__container",
  TOOLTIP: "consultations__consultationsList__tooltip"
};

export const HEADER = {
  WRAPPER_TITLE: "consultations__consultation__wrapper-title",
  TIME: "consultations__consultation__time",
  STATUS: "consultations__consultation__status"
};

export const CONSULTATION = {
  STATUS: "consultations__consultation__status",
  CALL_CLINIC: "consultations__consultation__call-clinic",
  BUTTON_AUDIO_CALL: "consultations__consultation__button-audio-call",
  BUTTON_VIDEO_CALL: "consultations__consultation__button-video-call",
  BUTTON_PAY: "consultations__consultation__button-pay",
  DOCTOR: "consultations__consultation__doctor",
  SERVICE_TYPE: "consultations__consultation__service-type",
  PRICE: "consultations__consultation__price",
  HEALTH_CHECK: "consultations__consultation__button-health-check",
  RESCHEDULE: "consultations__consultation__button-reschedule"
};

export const DATE_SELECTOR = {
  SELECTED_DATE: "dateSelector__selected-date"
};
