import Immutable from "seamless-immutable";
import types from "./newConsultationTypes";
import { SLOT_TYPES } from "~/modals/NewConsultation/components/NewConsultation/NewConsultation";

const initialState = Immutable({
  selectedClinic: null,
  selectedDoctor: null,
  selectedSlot: null,
  selectedSlotType: SLOT_TYPES.items.online.type,
  selectedDate: null,
  postingConsultation: false,
  postConsultationError: null,
  isFetchingSymptoms: false,
  symptomsError: null,
  createSymptomError: null,
  updateSymptomError: null,
  symptoms: [],
  currentSymptom: null,
  selectedDateTimeData: {
    id: -1,
    doctor: null,
    slotType: "",
    price: "",
    clinicId: -1,
    specialtyProps: {}
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.NEW_CONSULTATION_SELECT_CLINIC: {
      return state.merge({
        selectedClinic: action.selectedClinic,
        selectedDoctor: null,
        selectedDate: null
      });
    }

    case types.NEW_CONSULTATION_SELECT_DOCTOR: {
      return state.merge({
        selectedDoctor: action.selectedDoctor,
        selectedDate: null
      });
    }

    case types.NEW_CONSULTATION_SELECT_SLOT: {
      return state.merge({
        selectedSlot: action.selectedSlot
      });
    }

    case types.NEW_CONSULTATION_SELECT_SLOT_TYPE: {
      return state.merge({
        selectedSlotType: action.selectedSlotType
      });
    }

    case types.NEW_CONSULTATION_SELECT_DATE: {
      return state.merge({
        selectedDate: action.selectedDate,
        selectedSlot: null
      });
    }

    case types.NEW_CONSULTATION_SYMPTOMS_REQUEST:
      return state.merge({ isFetchingSymptoms: true, symptomsError: null });

    case types.NEW_CONSULTATION_SYMPTOMS_SUCCESS:
      return state.merge({ isFetchingSymptoms: false, symptoms: action.data });

    case types.NEW_CONSULTATION_SYMPTOMS_FAILURE:
      return state.merge({
        isFetchingSymptoms: false,
        symptomsError: action.error
      });

    case types.NEW_CONSULTATION_SYMPTOM_CREATE_REQUEST:
      return state.merge({ isFetchingSymptoms: true });

    case types.NEW_CONSULTATION_SYMPTOM_CREATE_SUCCESS:
      return state.merge({
        isFetchingSymptoms: false,
        currentSymptom: action.data
      });

    case types.NEW_CONSULTATION_SYMPTOM_CREATE_FAILURE:
      return state.merge({
        isFetchingSymptoms: false,
        createSymptomError: action.error
      });

    case types.NEW_CONSULTATION_SYMPTOM_UPDATE_REQUEST:
      return state.merge({ isFetchingSymptoms: true });

    case types.NEW_CONSULTATION_SYMPTOM_UPDATE_SUCCESS:
      return state.merge({
        isFetchingSymptoms: false,
        currentSymptom: action.data
      });

    case types.NEW_CONSULTATION_SYMPTOM_UPDATE_FAILURE:
      return state.merge({
        isFetchingSymptoms: false,
        updateSymptomError: action.error
      });

    case types.CONSULTATIONS_POST_REQUEST: {
      return state.merge({
        postingConsultation: true,
        postConsultationError: null
      });
    }

    case types.CONSULTATIONS_POST_SUCCESS: {
      return state.merge({
        postingConsultation: false
      });
    }

    case types.CONSULTATIONS_POST_FAILURE: {
      return state.merge({
        postingConsultation: false,
        postConsultationError: action.error
      });
    }

    case types.FILL_DATE_TIME_DATA: {
      return state.merge({
        selectedDateTimeData: action.payload
      });
    }

    default:
      return state;
  }
};
