import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getTzDate } from "~/utils/dateUtils";
import { getDocumentConclusion } from "~/store/documents/documentsSelectors";
import { userSelector } from "~/store/users/userSelectors";
import { DOCUMENTS_ASIDE } from "~/locators/documents";
import UserAvatar from "~/components/UserAvatar";
import DocumentFile from "~/layouts/Documents/DocumentFile/DocumentFile";
import styles from "./DocumentsListItem.scss";

const Conclusion = ({ created_at, conclusion }) => (
  <div>
    <div className={styles.content}>
      <div className={styles.conclusionTitle}>Заключение</div>
      <div
        className={styles.lightText}
        data-testid={DOCUMENTS_ASIDE.LIST_ITEM_DATE}
      >
        {getTzDate(created_at).format("DD MMM YYYY в HH:mm")}
      </div>
    </div>
    <div
      className={styles.conclusionDescription}
      data-testid={DOCUMENTS_ASIDE.LIST_ITEM_CONCLUSION}
    >
      {conclusion.text}
    </div>
  </div>
);

Conclusion.propTypes = {
  created_at: PropTypes.string,
  conclusion: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string
  })
};

const File = ({ file, created_at }) => (
  <div className={styles.content}>
    <DocumentFile {...file} />
    <div
      className={styles.lightText}
      data-testid={DOCUMENTS_ASIDE.LIST_ITEM_DATE}
    >
      {getTzDate(created_at).format("DD MMM YYYY в HH:mm")}
    </div>
  </div>
);

File.propTypes = {
  file: PropTypes.shape({
    extension: PropTypes.string,
    name: PropTypes.string,
    fileName: PropTypes.string,
    size: PropTypes.number,
    url: PropTypes.string
  }),
  created_at: PropTypes.string
};

const DocumentsListItem = props => {
  const { id, type, file, conclusionId, author, created_at } = props;
  const conclusion =
    useSelector(state => getDocumentConclusion(state, { conclusionId })) || {};
  const documentAuthor = useSelector(state => userSelector(state, author));

  return (
    <div className={styles.container} data-testid={DOCUMENTS_ASIDE.LIST_ITEM}>
      {type === "conclusion" ? (
        <Conclusion conclusion={conclusion} created_at={created_at} />
      ) : (
        <File file={file} created_at={created_at} />
      )}
      {documentAuthor && (
        <div className={styles.author}>
          <div>
            <UserAvatar
              className={styles.avatar}
              user={{
                full_name: documentAuthor.full_name,
                avatar: documentAuthor.avatar,
                id
              }}
            />
          </div>
          <div className={styles.authorInfo}>
            <div className={styles.authorClinicName}>
              {documentAuthor.clinicName}
            </div>
            <div
              className={styles.authorName}
              data-testid={DOCUMENTS_ASIDE.LIST_ITEM_NAME}
            >
              {documentAuthor.full_name}
            </div>
            <div className={styles.specialties}>
              {documentAuthor.specialties.join(", ")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DocumentsListItem.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["conclusion", "file", "image"]).isRequired,
  file: PropTypes.shape({
    extension: PropTypes.string,
    name: PropTypes.string,
    fileName: PropTypes.string,
    size: PropTypes.number,
    url: PropTypes.string
  }),
  conclusionId: PropTypes.number,
  created_at: PropTypes.string
};

export default DocumentsListItem;
