import React, { useCallback, useRef, useEffect } from "react";
import { Text } from "ibolit-ui";
import { connect, useSelector } from "react-redux";
import ImageLoader from "ibolit-ui/src/UI/ImageLoader";
import styles from "./SelectDoctor.scss";
import SearchBlock from "~/components/SearchBlock";
import clinicsApi from "~/api/clinicsApi";
import { getSelectedClinicId } from "~/store/newConsultation/newConsultationSelectors";
import { useRequestSearchPagination } from "~/hooks/useRequest";
import EmptyStub from "~/components/EmptyStub";
import { getDoctorInfoText } from "~/modals/DoctorsChat/Doctor";
import newConsultationActions from "~/store/newConsultation/newConsultationActions";
import { useSelectorModal } from "~/hooks/useSelectorModal/useSelectorModal";
import ShapeButton from "~/components/ShapeButton";
import InfiniteList from "~/components/InfiniteList";
import { NEW_CONSULTATION } from "~/locators/newConsultation";

const SelectDoctor = ({ selectedDoctor, selectDoctor }) => {
  const [SelectorModal, toggleSelector, isOpen] = useSelectorModal();
  const clinicId = useSelector(getSelectedClinicId);
  const requestPromise = useCallback(
    params => clinicsApi.doctorsByClinicWithSlots(clinicId, params),
    [clinicId]
  );

  const {
    items: doctorList,
    canLoadMore,
    initialFetched,
    incrementPage,
    setQuery,
    hasNoItems,
    error
  } = useRequestSearchPagination(requestPromise, {
    slot_type: "both",
    limit: 10
  });
  const handleSelectDoctor = doctor => {
    selectDoctor(doctor);
    toggleSelector(false);
  };
  const specialtyRef = useRef("");
  const listRef = useRef();

  useEffect(() => {
    setQuery("");
  }, [isOpen]);

  return (
    <div className={styles.margin_bottom}>
      <Text variant="h6" weight="bold" className={styles.title}>
        {"Врач"}
      </Text>
      <div>
        {selectedDoctor ? (
          <ShapeButton
            textColorVariant="pale"
            onClick={toggleSelector}
            className={styles.doctor_selected}
            testid={NEW_CONSULTATION.SELECTED_DOCTOR}
          >
            <ImageLoader
              src={selectedDoctor.avatar}
              alt={selectedDoctor.full_name}
              className={styles.doctor_selected_image}
            />
            <div className={styles.doctor_info}>
              <Text variant="h6" weight="bold" className={styles.doctorText}>
                {selectedDoctor.full_name}
              </Text>
              <Text colorVariant="tertiary" className={styles.doctorText}>
                {selectedDoctor
                .specialties ? 
                  selectedDoctor
                  .specialties.join(',')
                : selectedDoctor.specialty}
              </Text>
            </div>
          </ShapeButton>
        ) : (
          <ShapeButton
            textColorVariant="pale"
            onClick={toggleSelector}
            text="Выберите врача"
            testid={NEW_CONSULTATION.SELECT_DOCTOR_INPUT}
          />
        )}
        <SelectorModal className={styles.doctor_modal}>
          <SearchBlock
            onSearchChange={setQuery}
            placeholder="Поиск по ФИО"
            className={styles.search}
            testId={NEW_CONSULTATION.SEARCH_DOCTOR}
          />
          <InfiniteList
            ref={listRef}
            canLoadMore={canLoadMore}
            loadMore={incrementPage}
            className={styles.list_container}
          >
            <div className={styles.inside_infinite_list}>
              {doctorList.map((doctor, index) => {
                let showSpeciality = true;
                const text = doctor.specialty;
                if (specialtyRef.current === text) {
                  showSpeciality = false;
                }
                specialtyRef.current = text;
                if (index === doctorList.length - 1) {
                  specialtyRef.current = "";
                }

                return (
                  <div key={doctor.id}>
                    {showSpeciality && (
                      <Text
                        variant="h5"
                        weight="bold"
                        className={styles.doctor_type_h}
                        testid={NEW_CONSULTATION.DOCTOR_SPECIALITY}
                      >
                        {text}
                      </Text>
                    )}
                    <button
                      type="button"
                      onClick={() => handleSelectDoctor(doctor)}
                      className={styles.doctor}
                      data-testid={NEW_CONSULTATION.DOCTOR}
                    >
                      <ImageLoader
                        src={doctor.avatar}
                        alt={doctor.full_name}
                        className={styles.doctor_image}
                      />
                      <div className={styles.doctor_info}>
                        <Text colorVariant="tertiary">
                          {getDoctorInfoText(
                            doctor.experience,
                            doctor.specialty
                          )}
                        </Text>
                        <Text>{doctor.full_name}</Text>
                      </div>
                    </button>
                  </div>
                );
              })}
              {!initialFetched && !error && (
                <EmptyStub text={"Загружаем список\nврачей"} centered />
              )}
              {error && <EmptyStub text={"Ошибка загрузки"} centered />}
              {hasNoItems && <EmptyStub centered text={"Врачи не найдены"} />}
            </div>
          </InfiniteList>
        </SelectorModal>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  selectDoctor: newConsultationActions.selectDoctor
};

export default connect(null, mapDispatchToProps)(SelectDoctor);
