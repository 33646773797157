import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { Button, Icon } from "ibolit-ui";
import RowWithImage from "~/components/RowWithImage";
import { CONNECTION_CHECK_VIEW } from "./ConnectionCheckModal";
import uiActions from "~/store/ui/uiActions";
import styles from "./ConnectionCheck.scss";

const [ROW] = ["row"];

const CheckHealthButton = ({ className, variant, modalProps = {}, testid }) => {
  const title = "Проверка качества связи";
  const dispatch = useDispatch();

  const buttonProps = {
    onClick: () =>
      dispatch(
        uiActions.showModal(CONNECTION_CHECK_VIEW, {
          userId: modalProps.userId,
          hasNoBackStep: modalProps.hasNoBackStep,
        })
      ),
    className: cn(styles.container, className)
  };

  if (variant === ROW) {
    return (
      <RowWithImage
        {...buttonProps}
        image={<Icon variant="check" />}
        title={title}
        hasHoverEffect
        testid={testid}
        hoveredTextClassName={styles.hoveredTextClassName}
      />
    );
  }
  return <Button {...buttonProps}>{title}</Button>;
};

CheckHealthButton.propTypes = {
  className: PropTypes.string,
  onComplete: PropTypes.func,
  variant: PropTypes.oneOf([ROW])
};

CheckHealthButton.defaultProps = {
  variant: ROW
};

export default CheckHealthButton;
