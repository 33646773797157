export const getDayName = dayNumber => {
  const daysByIndex = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
  const daysByIndexFull = [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота"
  ];

  return [daysByIndex[dayNumber], daysByIndexFull[dayNumber]];
};
