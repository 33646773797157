export const getNewConsultationStateData = state => state.newConsultation;
export const getSelectedClinicPhone = state =>
  getNewConsultationStateData(state).selectedClinic?.phone;
export const getSelectedClinicId = state =>
  getNewConsultationStateData(state).selectedClinic?.id;
export const getSelectedDoctor = state =>
  getNewConsultationStateData(state).selectedDoctor;
export const getSelectedSlot = state =>
  getNewConsultationStateData(state).selectedSlot;
export const getSelectedSlotType = state =>
  getNewConsultationStateData(state).selectedSlotType;
export const getSelectedDate = state =>
  getNewConsultationStateData(state).selectedDate;
export const getIsSymptomsFetching = state =>
  getNewConsultationStateData(state).isFetchingSymptoms;
export const getIsPostingConsultation = state =>
  getNewConsultationStateData(state).postingConsultation;
export const getSymptoms = state => getNewConsultationStateData(state).symptoms;
export const getCurrentSymptom = state =>
  getNewConsultationStateData(state).currentSymptom;
export const getSelectedDateTimeData = state =>
  getNewConsultationStateData(state).selectedDateTimeData;
