/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { Icon } from "ibolit-ui";
import TextInput from "~/components/inputs/TextInput/TextInput";
import useHover from "~/hooks/useHover";
import styles from "./SearchBox.scss";

const MINIMAL_SEARCH_LENGTH = 3;

const SearchBox = ({
  className,
  placeholder,
  style,
  onChange,
  handleSearch,
  onClear,
  onFocusChange,
  inputRef: extInputRef,
  variant,
  minLength,
  testid
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRef = extInputRef || useRef(null);

  const [clearButton, toggleClearButton] = useState(false);
  const [value, setValue] = useState();
  const [isFocused, setFocused] = useState();

  useEffect(() => {
    onFocusChange && onFocusChange(isFocused);
  }, [isFocused]);

  useEffect(() => {
    onChange && onChange(value);
    if (value !== undefined && value.length === 0) {
      onClear && onClear();
    } else if (value && value.length >= minLength) {
      handleSearch(value);
    }
    const showClearButton = isFocused && value && value.length > 0;
    clearButton !== showClearButton && toggleClearButton(showClearButton);
  }, [value]);

  const { isHover, onMouseOver, onMouseOut } = useHover();

  const changeHandler = () => {
    const _value = inputRef.current.value;
    value !== _value && setValue(_value);
  };

  const clearSearch = () => {
    inputRef.current.focus();
    inputRef.current.value = "";
    setValue("");
    return null;
  };

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  return (
    <div
      className={cn(styles.container, className, styles[variant], {
        [styles.focused]: isFocused || isHover
      })}
      style={style}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {variant === "full" && (
        <div className={styles.icon}>
          <Icon variant="search" fill="var(--silver)" />
        </div>
      )}
      <TextInput
        placeholder={placeholder}
        ref={inputRef}
        noFrame
        focusHandler={handleFocus}
        blurHandler={handleBlur}
        className={styles.input}
        onChange={changeHandler}
        testidProps={{
          input: testid
        }}
      />
      <Icon
        testid={testid && `${testid}-icon-clear`}
        variant="fail"
        fill="var(--silver)"
        onClick={clearSearch}
        className={cn(styles.icon, { [styles.hidden]: !clearButton })}
      />
    </div>
  );
};

SearchBox.defaultProps = {
  variant: "full",
  minLength: MINIMAL_SEARCH_LENGTH
};

SearchBox.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onFocusChange: PropTypes.any,
  handleSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  variant: PropTypes.oneOf(["full", "light"]),
  minLength: PropTypes.number,
  testid: PropTypes.string
};

export default SearchBox;
