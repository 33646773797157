import React, { forwardRef } from "react";
import cn from 'classnames';
import styles from './Container.scss';

export const Container = forwardRef(({
  className,
  justify,
  children,
  direction,
  wrap,
  onClick,
  gap,
  align,
  testid
}, ref) => {

  return (
    <div 
      className={cn(
        styles.containerCommon,
        className,
        styles[`justify-${justify}`],
        styles[`direction-${direction}`],
        styles[`flex-${wrap}`],
        styles[`gap-${gap}`],
        styles[`align-${align}`]
      )}
      onClick={onClick}
      testid={testid}
      ref={ref}
    >
      {children}
    </div>
  );
});
