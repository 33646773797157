import React, { useState } from "react";
import PropTypes from "prop-types";
import { Label } from "ibolit-ui";
import cn from "classnames";
import styles from "./PreviousSymptoms.scss";

function PreviousSymptoms(props) {
  // Show only the first four symptoms, when "show more" is clicked, show the rest
  const [symptoms, setSymptoms] = useState(props.symptoms.slice(0, 4));
  const [selectedSymptom, setSelectedSymptom] = useState({ id: 0 });

  function handleSymptomClick(id) {
    return () => {
      const clickedSymptom =
        id === 0 ? { id: 0 } : symptoms.find(symptom => symptom.id === id);
      setSelectedSymptom(clickedSymptom);

      if (props.onSymptomClick) {
        props.onSymptomClick(clickedSymptom);
      }
    };
  }

  function handleShowMoreClick() {
    setSymptoms(props.symptoms);
  }

  return (
    <Label testid="PreviousSymptoms" title="Выбрать ранее созданную проблему">
      <div className={styles.symptomsContainer}>
        <div
          className={cn(styles.symptom, styles.new, {
            [styles.selected]: selectedSymptom.id === 0
          })}
          onClick={handleSymptomClick(0)}
          data-testid="symptoms_new_problem"
        >
          Новая проблема
        </div>
        <>
          {symptoms.map(symptom => (
            <div
              key={symptom.id}
              data-testid="PreviousSymptoms__symptom"
              className={cn(styles.symptom, {
                [styles.selected]: selectedSymptom.id === symptom.id
              })}
              onClick={handleSymptomClick(symptom.id)}
            >
              {symptom.title}
            </div>
          ))}
        </>
        {props.symptoms.length !== symptoms.length && (
          <div
            data-testid="PreviousSymptoms__more"
            className={cn(styles.symptom, styles.more)}
            onClick={handleShowMoreClick}
          >
            Еще
          </div>
        )}
      </div>
    </Label>
  );
}

PreviousSymptoms.propTypes = {
  symptoms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      days: PropTypes.number,
      // Subject to change. the possible statuses might be fetched from the backend
      status: PropTypes.oneOf([
        "primary_appointment",
        "repeated_appointment",
        "transcript_of_analyzes",
        "appointed-offline-reception",
        "second_opinion",
        "auto"
      ]),
      created_at: PropTypes.string,
      updated_at: PropTypes.string
    })
  ),
  onSymptomClick: PropTypes.func
};

export default PreviousSymptoms;
