import React from "react";
import { Text } from "ibolit-ui";
import styles from "./ClinicSchedule.scss";
import cn from "classnames";

// We pad one empty element because backend data is not zero-based.
const daysByIndex = ["", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
const daysByIndexFull = [
  "",
  "Понедельник",
  "Вторник",
  "Среда",
  "Четверг",
  "Пятница",
  "Суббота",
  "Воскресенье"
];

export const groupSchedule = schedule => {
  // Replace nulls with 'Выходной' + format each day.
  const formattedMap = Object.keys(schedule).reduce((acc, key) => {
    const itemData = schedule[key];
    if (itemData) {
      acc[key] = `${itemData.from} - ${itemData.to}`;
    } else {
      acc[key] = "Выходной";
    }
    return acc;
  }, {});

  // Combine the result map in format:
  /*
   * '0-2' : 'x-y',
   * '3': 'Выходной'
   * '4-5': 'x-y',
   */
  const result = {};

  for (let i = 1; i <= 7; i++) {
    const data = formattedMap[i];

    let lastEqualDayIndex = null;
    for (let j = i + 1; j <= 7; j++) {
      if (data === formattedMap[j]) {
        lastEqualDayIndex = j;
      } else {
        break;
      }
    }

    if (!lastEqualDayIndex) {
      result[i] = data;
    } else {
      let key = `${i}-${lastEqualDayIndex}`;
      result[key] = data;
      i = lastEqualDayIndex;
      lastEqualDayIndex = null;
    }
  }

  return result;
};

export const ClinicSchedule = ({ schedule, testid }) => {
  const groupedSchedule = groupSchedule(schedule);

  const getBubbleTitle = key => {
    return key.includes("-")
      ? key
          .split("-")
          .map(item => daysByIndex[Number(item)])
          .join(" - ")
      : daysByIndexFull[Number(key)];
  };

  const noSetSchedule = Object.keys(schedule).every(
    key => schedule[key] === null
  );

  if (noSetSchedule) {
    return null;
  }

  return (
    <div className={styles.scheduleWrapper}>
      <Text variant="h3" colorVariant="tertiary" testid={`${testid}-time`}>
        Время работы
      </Text>
      {Object.keys(groupedSchedule)
        .sort((a, b) => (a[0] > b[0] ? 1 : -1))
        .map((key, index) => {
          return (
            <div
              key={key}
              className={cn(styles.day, index === 0 && styles.firstDay)}
            >
              <Text
                className={styles.dayTitle}
                variant="desc"
                testid={`${testid}-buble-title-${index}`}
              >
                {`${getBubbleTitle(key)}:`}
              </Text>
              <Text variant="desc" testid={`${testid}-schedule-${index}`}>
                {groupedSchedule[key]}
              </Text>
            </div>
          );
        })}
    </div>
  );
};
