import React, { useMemo } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Text, Badge } from "ibolit-ui";
import UserAvatar from "~/components/UserAvatar";
import RowWithImage from "~/components/RowWithImage";
import { CONTACT } from "~/locators/contacts";
import styles from "./PublicProfile.scss";

const Header = ({ user }) => {
  const { full_name, online, specialties = [] } = user;
  const rowProps = useMemo(
    () => ({
      image: <UserAvatar testid={CONTACT.AVATAR} size="large" user={user} />,
      title: (
        <Text
          variant="h2"
          tag="h2"
          className={styles.title}
          testid={CONTACT.NAME}
        >
          {full_name}
        </Text>
      ),
      info: [...specialties].filter(Boolean).join(", "),
      testid: CONTACT.HEADER_DESCRIPTION,
      ...(online && {
        titleRight: (
          <Badge fill variant="secondary">
            в сети
          </Badge>
        )
      })
    }),
    [user]
  );

  return (
    <RowWithImage
      className={cn(styles.header, styles.block)}
      contentClass={styles.headerContentClass}
      {...rowProps}
    />
  );
};

Header.propTypes = {
  user: PropTypes.shape({
    active: PropTypes.bool,
    avatar: PropTypes.string,
    category: PropTypes.string,
    chat_id: PropTypes.number,
    clinic: PropTypes.number,
    code: PropTypes.string,
    date_of_birth: PropTypes.number,
    default_price: PropTypes.number,
    description: PropTypes.string,
    education: PropTypes.string,
    email: PropTypes.string,
    experience: PropTypes.number,
    full_name: PropTypes.string,
    has_patient_chat: PropTypes.bool,
    id: PropTypes.number,
    is_chat_free: PropTypes.bool,
    is_new: PropTypes.bool,
    phone: PropTypes.string,
    position: PropTypes.string,
    primary_acceptance: PropTypes.bool,
    self_appointment: PropTypes.bool,
    specialties: PropTypes.arrayOf(PropTypes.string)
  })
};

export default Header;
