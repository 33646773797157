import React from "react";
import PropTypes from "prop-types";
import ScrollbarAutoSized from "~/components/Scrollbar/ScrollbarAutoSized";
import ChatPayment from "../ChatPayment";
import Message from "../Message";
import styles from "./MessagesList.scss";
import { getDayOrDateText } from "~/utils/dateUtils";
import { CHAT } from "~/locators/chats";
import { useChatBubble } from "~/hooks/useChatBubble";

const MessagesList = ({
  onScroll,
  items,
  partner,
  onFileClick,
  onReady,
  scrollRef,
  canLoadMore,
  partnerId,
  isSending,
  readOnly,
  isDisabled
}) => {
  useChatBubble(scrollRef, items.length);
  const listDate = React.useRef("");
  return (
    <ScrollbarAutoSized
      ref={scrollRef}
      fixBottomOnResize
      onScrollFrame={onScroll}
      onReady={onReady}
      className={styles.scrollbar}
    >
      {items.length > 0 && canLoadMore && <div style={{ height: 50 }} />}
      <div className={styles.container}>
        {items.filter(Boolean).map((message, index) => {
          let showDate = true;
          const textDate = getDayOrDateText(message.created_at);
          if (listDate.current === textDate) {
            showDate = false;
          }
          listDate.current = textDate;
          if (index === items.length - 1) {
            listDate.current = "";
          }
          return (
            <React.Fragment key={message.id}>
              {showDate && (
                <div data-observer="target" className={styles.bubble}>
                  {textDate}
                </div>
              )}
              <Message
                testId={CHAT.MESSAGES_CONTAINER}
                partnerId={partnerId}
                message={message}
                onFileClick={onFileClick}
                isSending={isSending}
              />
            </React.Fragment>
          );
        })}
      </div>
      {readOnly && !isDisabled && (
        <ChatPayment
          partnerId={partnerId}
          doctor={partner}
          replayDate={partner.next_activity_at}
          clinicId={partner.clinic_id}
        />
      )}
    </ScrollbarAutoSized>
  );
};

MessagesList.propTypes = {
  onScroll: PropTypes.any,
  items: PropTypes.any,
  partner: PropTypes.any,
  onFileClick: PropTypes.any,
  onReady: PropTypes.any,
  scrollRef: PropTypes.any,
  canLoadMore: PropTypes.any,
  partnerId: PropTypes.any,
  readOnly: PropTypes.any,
  isDisabled: PropTypes.any
};

export default MessagesList;
