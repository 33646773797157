import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./SlotsList.scss";
import SlotItem from "~/modals/NewConsultation/components/NewConsultation/Slots/SlotItem";
import { NEW_CONSULTATION } from "~/locators/newConsultation";

const SlotsList = ({ slots, onItemClick, selected }) => (
  <ul className={cn(styles.list)} data-testid={NEW_CONSULTATION.SLOTS}>
    {slots.map(slot => (
      <li
        key={slot.start_at}
        className={cn(styles.item)}
        data-testid={NEW_CONSULTATION.SLOTS_ITEM}
      >
        <SlotItem
          onClick={() => onItemClick(slot)}
          text={slot.start_at}
          active={selected && slot.id === selected.id}
        />
      </li>
    ))}
  </ul>
);

SlotsList.propTypes = {
  slots: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
  selected: PropTypes.object
};

export default SlotsList;
