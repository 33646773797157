import React from "react";
import classNames from "classnames";
import { Icon } from "ibolit-ui";
import Logo from "~/components/Logo";
import VIEWBOX_LOCATORS from "~/locators/viewbox";
import styles from "./Header.scss";

export default React.memo(({ fullLogo, canGoBack, backClickHandler }) => {
  const backButtonClassName = classNames(styles.back, {
    [styles.hidden]: !canGoBack
  });
  return (
    <header className={classNames(styles.header, { [styles.big]: fullLogo })}>
      <Icon
        testid={VIEWBOX_LOCATORS.VIEWBOX_BUTTON_BACK}
        variant="arrow"
        className={backButtonClassName}
        onClick={backClickHandler}
      />
      <Logo
        className={styles.logo}
        variant={fullLogo ? "full" : "default"}
        testid="auth_logo"
      />
    </header>
  );
});
