import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import authActions from "~/store/auth/authActions";
import {
  getAuthRoute,
  getChatsRoute,
  getConsultationRoute
} from "~/utils/routes/getRoutes";
import publicApi from "~/api/publicApi";
import { useShowModal } from "~/hooks/useModal";
import {
  FROM_WIDGET_NEW_PASSWORD,
  FROM_WIDGET_USER_INFO_VIEW
} from "~/modals/FromWidget";
import { getUser } from "~/store/auth/authSelectors";
import Loading from "~/components/Loading/Loading";

const getQueryStringParams = query =>
  query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};

const AuthFromWidget = props => {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const { onetime_token, tariff_type, consultation_id } = getQueryStringParams(
    props.location.search
  );
  const showUserInfoModal = useShowModal(FROM_WIDGET_USER_INFO_VIEW);
  const showPasswordChangeModal = useShowModal(FROM_WIDGET_NEW_PASSWORD);

  useEffect(() => {
    publicApi
      .login({ token: onetime_token })
      .then(({ data }) => {
        dispatch(authActions.checkPasswordSuccess({ token: data.token }));
      })
      .catch(err => {
        setRedirectUrl(getAuthRoute());
      });

    if (tariff_type === "chat") {
      setRedirectUrl(getChatsRoute({}));
    } else {
      setRedirectUrl(getConsultationRoute({ consultationId: consultation_id }));
    }
  }, []);

  if (redirectUrl) {
    if (user) {
      if (!user.date_of_birth || !user.gender) {
        showUserInfoModal();
      } else if (user.date_of_birth && user.gender && !user.is_password_set) {
        showPasswordChangeModal();
      }

      return <Redirect to={redirectUrl} />;
    }
  }

  return <Loading showLoading={!!user} />;
};

export default AuthFromWidget;
