import { useEffect, useState, useRef } from "react";
import _ from "lodash";

const checkPrevError = (value, { error: prevError, value: badValue }) => {
  if (prevError && (value === badValue || !badValue)) return [false, prevError];
  return [true];
};

const useValidator = (inputRef, validator, externalError, initialValue) => {
  const [isValid, setIsValid] = useState();
  const [error, setError] = useState();
  const [errorSeverity, setErrorSeverity] = useState();
  const prev = useRef();
  const showError = useRef(false);

  useEffect(() => {
    // Снаружи прилетела/сброшена ошибка
    if (inputRef && inputRef.current) {
      prev.current = {
        value: isValid === undefined ? initialValue : inputRef.current.value,
        error: externalError
      };
      setError(externalError);
      setIsValid(!externalError);
    }
  }, [externalError]);

  const validate = () => {
    let valid;
    let severity;
    if (!inputRef || !inputRef.current) return;

    const { value } = inputRef.current;

    try {
      // Проверяем, что текущее значение не вызывало предыдущую ошибку
      let [_isValid, _error, forceShow = false] = checkPrevError(
        value,
        prev.current
      );
      if (_isValid && validator) {
        // небыло ошибки или текущее значение не то, что вызвало ошибку
        // валидируем текущее значение
        // severity - уровень ошибки. notice/warning/error
        [_isValid, _error, forceShow, severity] = _.castArray(validator(value));

        setErrorSeverity(severity || "error");
      }
      if (forceShow) {
        showError.current = true;
      }
      // Если требуется отобразить/сбросить ошибку
      showError.current && setError(_error || null);
      valid = _isValid;
      // eslint-disable-next-line no-empty
    } catch (e) {}
    setIsValid(valid);
    valid && (showError.current = true);
  };

  const resetValidation = () => {
    setIsValid(undefined);
    setError(undefined);
  };

  if (inputRef && inputRef.current) {
    inputRef.current.isValid = isValid;
    inputRef.current.validate = validate;
    inputRef.current.error = externalError;
  }

  return {
    isValid,
    error,
    errorSeverity,
    validate,
    resetValidation
  };
};

export default useValidator;
