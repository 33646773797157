import Immutable from "seamless-immutable";
import types from "~/store/faq/faqTypes";

const initialState = Immutable({
  loading: false,
  data: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FAQ_DATA_REQUEST: {
      return state.merge({
        loading: true
      });
    }

    case types.FAQ_DATA_SUCCESS: {
      return state.merge({
        loading: false,
        data: action.data
      });
    }

    default:
      return state;
  }
};
