import React from "react";
import moment from "moment-timezone";
import ShapeButton from "~/components/ShapeButton";
import DatePicker from "~/components/DatePicker";
import { getDateText } from "~/utils/dateUtils";
import { DATE_SELECTOR } from "~/locators/consultations";
import { useSelectorModal } from "~/hooks/useSelectorModal/useSelectorModal";
import styles from "./DateSelector.scss";

export const MIN_DATE = moment()
  .startOf("day")
  .toDate();

export const MAX_DATE = moment()
  .add(14, "days")
  .startOf("day")
  .toDate();

export default ({ date, setDate, disabled, disabledDays }) => {
  const [SelectorModal, toggleSelector] = useSelectorModal();

  const handleDayClick = (day, { selected }) => {
    setDate(selected ? undefined : day);
    !selected && toggleSelector();
  };

  return (
    <div className={styles.dateSelectorContainer}>
      <ShapeButton
        disabled={disabled}
        testid={DATE_SELECTOR.SELECTED_DATE}
        textColorVariant={date ? "primary" : "pale"}
        onClick={toggleSelector}
        text={date ? getDateText(date) : "Выберите дату и время"}
      />
      <SelectorModal className={styles.datePickerContainer} position="right">
        <DatePicker
          selectedDays={date}
          disabledDays={disabledDays}
          minDate={MIN_DATE}
          maxDate={MAX_DATE}
          onDayClick={handleDayClick}
        />
      </SelectorModal>
    </div>
  );
};
