import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Input, Label } from "ibolit-ui";
import _ from "lodash";
import styles from "./NewSymptoms.scss";

function NewSymptoms({
  symptomValue,
  daysValue,
  painLevel,
  disabled,
  onSymptomValueChange,
  onPainLevelChange,
  onDaysValueChange
}) {
  function handlePainLevelChange(level) {
    return () => {
      onPainLevelChange(level);
    };
  }

  return (
    <div data-testid="NewSymptoms">
      <Label
        testid="NewSymptoms__symptom-description"
        title="Какая у вас жалоба?"
      >
        <Input
          className={styles.input}
          placeholder="Болит голова"
          fullWidth
          maxlength={30}
          disabled={disabled}
          value={symptomValue}
          onChange={onSymptomValueChange}
          testidProps={{
            input: "symptoms_what_is_your_complaint_input"
          }}
        />
      </Label>
      <Label testid="NewSymptoms__pain-scale" title="Уровень дискомфорта">
        <div className={styles.painScale}>
          {_.range(11).map(number => (
            <div
              key={number}
              className={cn(styles.painLevel, {
                [styles.selectedPainLevel]:
                  painLevel !== null && number <= painLevel
              })}
              onClick={handlePainLevelChange(number)}
              data-testid={`painScale_lelvel${number}`}
            >
              {number}
            </div>
          ))}
        </div>
      </Label>
      <Label
        testid="NewSymptoms__days-count"
        title="Как давно она наблюдается?"
      >
        <Input
          className={styles.input}
          placeholder="Введите количество дней"
          fullWidth
          disabled={disabled}
          maxlength={30}
          value={daysValue === null ? "Не указано" : daysValue}
          onChange={onDaysValueChange}
          testidProps={{
            input: "symptoms_how_long_its_been_observed_input"
          }}
        />
      </Label>
    </div>
  );
}

NewSymptoms.propTypes = {
  symptomValue: PropTypes.string,
  daysValue: PropTypes.string,
  painLevel: PropTypes.number,
  disabled: PropTypes.bool,
  onSymptomValueChange: PropTypes.func,
  onPainLevelChange: PropTypes.func,
  onDaysValueChange: PropTypes.func
};

export default NewSymptoms;
