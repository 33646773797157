const temp = "new_consultation";

export const NEW_CONSULTATION = {
  OPEN_MODAL: `${temp}_button`,
  SELECT_CLINIC_MODAL: `${temp}_select_clinic_modal`,
  SELECT_CLINIC_MODAL_TITLE: `${temp}_select_clinic_modal_title`, // header_modal_window
  CLOSE_SELECT_CLINIC_MODAL: `${temp}_close_select_clinic_modal`, // close_modal_window
  SELECT_CLINIC_MODAL_CLINIC: `${temp}_select_clinic_modal_clinic`,

  CLINIC_PHONE: `${temp}_clinic_phone`,
  NO_SLOTS_DESC: `${temp}_no_slots_desc`,
  ONLINE_VIDEO_CAPTION: `${temp}_video_caption`,
  SELECTED_DOCTOR: `${temp}_selected_doctor`,
  TARIFF_PRICE: `${temp}_tariff_price`,
  MINIMAL_DURATION: `${temp}_tariff_minimal_duration`,

  SLOTS: `${temp}_slots`,
  SLOTS_ITEM: `${temp}_slots_item`,

  SEARCH_DOCTOR: `${temp}_search_doctor`,
  DOCTOR_SPECIALITY: `${temp}_doctor_speciality`,
  DOCTOR: `${temp}_doctor`,

  MAIN_TITLE: `${temp}_main_title`,
  SELECT_DOCTOR_INPUT: `${temp}_select_doctor_input`,
  BUTTON_APPOINTMENT: `${temp}_button_appointment`,

  SELECT_TARIFF_MODAL: `${temp}_tariff_modal`,
  TARIFF_CLICKABLE_BOX: `${temp}_tariff_clickable_box`,
  BUTTON_ACCEPT_APPOINTMENT: `${temp}_button_accept_appointment`,

  SUCCESS_STATUS_MODAL: `${temp}_success_status_modal`,
  SUCCESS_STATUS_DESC: `${temp}_success_status_desc`,
  SUCCESS_STATUS_BUTTON: `${temp}_success_status_button`,

  OCCUPIED_STATUS_MODAL: `${temp}_occupied_status_modal`,
  OCCUPIED_STATUS_DESC: `${temp}_occupied_status_desc`,
  OCCUPIED_STATUS_BUTTON: `${temp}_occupied_status_button`,

  ERROR_STATUS_MODAL: `${temp}_error_status_modal`,
  ERROR_STATUS_DESC: `${temp}_error_status_desc`,
  ERROR_STATUS_BUTTON: `${temp}_error_status_button`
};
