export const tariffSourceTypes = {
  online: "online",
  admin: "admin",
  free: "free",
  onSpot: "on_spot",
  trial: "trial",
  autoResume: "auto_resume"
};

export const isTariffNotPurchased = source =>
  [
    tariffSourceTypes.trial,
    tariffSourceTypes.admin,
    tariffSourceTypes.autoResume
  ].includes(source);
