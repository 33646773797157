import types from "./appStartedTypes";

export default {
  setAppStarted: () => ({
    type: types.APP_STARTED
  }),
  appStartedSuccess: () => ({
    type: types.APP_STARTED_SUCCESS
  }),
  appStartedFailure: errors => ({
    type: types.APP_STARTED_FAILURE,
    errors
  })
};
