import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import uiActions from "~/store/ui/uiActions";
import actions from "~/store/profile/profileActions";
import FormSwitcher from "./FormSwitcher";
import { PROFILE_CHANGE_SETTINGS_VIEW } from "~/consts/profile";
import { getProfileSettingsScene } from "~/store/profile/profileSelectors";

export default function ChangeSettings() {
  const dispatch = useDispatch();
  const onCloseClick = useCallback(() => {
    dispatch(actions.resetLoginSettings());
    dispatch(uiActions.hideModal(PROFILE_CHANGE_SETTINGS_VIEW));
  });
  const scene = useSelector(getProfileSettingsScene);

  return <FormSwitcher scene={scene} onCloseClick={onCloseClick} />;
}
