import { createSelector } from "reselect";
import { getSlotsLoading } from "~/store/slots/slotsSelectors";
import { getTariffsLoading } from "~/store/profile/profileSelectors";
import {
  CONSULTATION_STATUS,
  CONSULTATIONS_FILTER_STATUS_OPEN
} from "~/store/consultation/consultationConsts";
import { TARGET_KEY } from "~/consts/app";
import { getUsers } from "~/store/users/userSelectors";
import { getIsDateAfter } from "~/utils/dateUtils";

export const getIsConsultationsInitialFetched = state =>
  state.consultations[CONSULTATIONS_FILTER_STATUS_OPEN].initialFetched;
export const getConsultationsItems = state => state.consultations.items;
export const getHasConsultationsItems = createSelector(
  getConsultationsItems,
  items => !!Object.keys(items).length
);
const getAddAppointmentLoading = state => state.consultations.posting;
export const getAddConsultationFormLoading = createSelector(
  getAddAppointmentLoading,
  getSlotsLoading,
  getTariffsLoading,
  (consultations, slots, tariffs) => consultations || slots || tariffs
);

const getUserId = (state, props) => props.item[TARGET_KEY];

export const getUserById = createSelector(
  getUserId,
  getUsers,
  (id, users) => users[id]
);

export const getConsultationById = (state, props) =>
  state.consultations.items[props?.id];

export const getConsultationDoctorInfo = (state, props) => {
  const doctorId = state.consultations.items[props.id]?.doctor;

  return state.users.items[doctorId];
};

export const getConsultationInfoLoading = state =>
  state.consultations.consultationInfoLoading;
export const getConsultationInProgress = (state, props) => {
  const consultation = getConsultationById(state, props);
  if (!consultation) return;

  return getIsConsultationInProgress(
    consultation.status,
    consultation.start_at
  );
};

// @param type {string} - tariff's type 'offline' | other
export const getConsultationIcon = type =>
  type === "offline" ? "hospital" : "chat-video";

export const getIsConsultationInProgress = (type, date) =>
  type === CONSULTATION_STATUS.APPROVED && getIsDateAfter(date);

export const isConsultationExpired = status =>
  [
    CONSULTATION_STATUS.CANCELED,
    CONSULTATION_STATUS.NOT_APPEARED,
    CONSULTATION_STATUS.EXPIRED
  ].includes(status);

export const isConsultationCompleted = status =>
  CONSULTATION_STATUS.COMPLETED === status;

export const isConsultationApproved = status =>
  CONSULTATION_STATUS.APPROVED === status;

export const isConsultationOpened = status =>
  [
    CONSULTATION_STATUS.APPROVED,
    CONSULTATION_STATUS.PENDING,
    CONSULTATION_STATUS.WAIT_CLIENT_CONFIRMATION,
    CONSULTATION_STATUS.WAIT_PAYMENT
  ].includes(status);

export const isConsultationWaitPatientAction = status =>
  [
    CONSULTATION_STATUS.WAIT_PAYMENT,
    CONSULTATION_STATUS.WAIT_CLIENT_CONFIRMATION
  ].includes(status);

export const isConsultationClosed = status =>
  isConsultationExpired(status) || isConsultationCompleted(status);

export const getConsultationsOpenPage = state =>
  state.consultations[CONSULTATIONS_FILTER_STATUS_OPEN].page;

export const getConsultationFiles = state => state.consultations.files;
