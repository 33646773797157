import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cn from "classnames";
import { Icon } from "ibolit-ui";
import moment from "moment";
import * as VoxImplant from "voximplant-websdk";
import callActions from "~/store/call/callActions";
import uiActions from "~/store/ui/uiActions";
import consultationsActions from "~/store/consultation/consultationActions";
import useOutsideClickHandler from "~/hooks/useOutsideClickHandler";
import Loading from "~/components/Loading/Loading";
import UserAvatar from "~/components/UserAvatar";
import RoomControls from "~/components/CallRoom/components/RoomControls/RoomControls";
import CALL_STATUS from "~/components/CallRoom/constants/callStatus";
import { CALL_ROOM_VIEW } from "~/components/CallRoom/components/CallRoomModal/CallRoomModal";
import { CALL_ROOM_EXIT_VIEW } from "~/components/CallRoom/components/CallRoomExitModal/CallRoomExitModal";
import styles from "./Room.scss";
import videoStyles from "../VoxImplant/VoxImplant.scss";
import metricsApi from "~/api/metricsApi";
import {
  addPeerConnectionStateLogging,
  CallLogEvents,
  createCallEventLogger
} from "~/store/call/callUtils";

export const CALL_CONTAINERS_IDS = {
  publisher: "cameraPublisherContainer",
  subscriber: "cameraSubscriberContainer"
};

const CALL_STATE = {
  NOT_CONNECTED: "NOT_CONNECTED",
  CONNECTED: "CONNECTED",
  DISCONNECTED: "DISCONNECTED"
};

const PermissionsScreen = props => {
  useOutsideClickHandler(props.overlayRef, props.onRoomExit);

  return (
    <>
      <div className={styles.permissionsOverlay} />
      <div className={styles.permissionsMessage}>
        <h3>Камера и микрофон заблокированы</h3>
        <p>
          Приложению iBolit нужен доступ к камере и микрофону. Нажмите на значок
          заблокированной камеры в адресной строке
        </p>
      </div>
    </>
  );
};

PermissionsScreen.propTypes = {
  onRoomExit: PropTypes.func,
  overlayRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

const InformationOverlay = ({
  peerName,
  peerLeft,
  peerJoined,
  secondsRemaining
}) => {
  const [seconds, setSeconds] = useState(
    secondsRemaining > 0 ? secondsRemaining : 0
  );

  useEffect(() => {
    if (secondsRemaining <= 0) return;

    const interval = window.setInterval(() => {
      setSeconds(prevSeconds => {
        if (prevSeconds === 0) {
          window.clearInterval(interval);
          return 0;
        }

        return prevSeconds - 1;
      });
    }, 1000);

    return () => {
      window.clearInterval(interval);
    };
  }, [secondsRemaining]);

  const formattedSeconds =
    seconds > 0 ? moment.utc(seconds * 1000).format("HH:mm:ss") : "00:00:00";
  return (
    <div className={styles.infoOverlay}>
      <div>
        <div className={styles.mainInfoText}>Онлайн-консультация</div>
        {!peerJoined && !peerLeft && (
          <div className={cn(styles.notice, styles.willJoinNotice)}>
            <Icon
              className={styles.noticeIcon}
              variant="info"
              size="s"
              fill="var(--white)"
            />
            Врач скоро появится на приеме
          </div>
        )}
        {peerJoined && !peerLeft && (
          <div className={styles.peerName}>{peerName}</div>
        )}
        {peerLeft && (
          <div className={cn(styles.notice, styles.peerLeftNotice)}>
            <Icon
              className={styles.noticeIcon}
              variant="info"
              size="s"
              fill="var(--white)"
            />
            Ваш собеседник покинул прием
          </div>
        )}
      </div>
      <div>
        <div
          className={cn(styles.notice, styles.durationNotice, {
            [styles.overdue]: seconds === 0
          })}
        >
          {formattedSeconds}
        </div>
      </div>
    </div>
  );
};

InformationOverlay.propTypes = {
  callState: PropTypes.oneOf(["NOT_CONNECTED", "CONNECTED", "DISCONNECTED"]),
  peerName: PropTypes.string,
  secondsRemaining: PropTypes.number
};

class Room extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      peerLeft: false,
      peerJoined: false,
      permissions: { video: "prompt", audio: "prompt" },
      localStreams: { video: null, audio: null },
      isExitModalVisible: false,
      isLocalVideoActive: true,
      isLocalAudioActive: true,
      isScreenSharingActive: false,
      callState: CALL_STATE.NOT_CONNECTED,
      isContactVideoPlaceholderVisible: false
    };
    this.call = null;
    this.logger = createCallEventLogger(
      this.props.consultationId,
      this.props.currentUserId
    );

    this.overlayRef = React.createRef();
  }

  async componentDidMount() {
    const {
      getConsultationById,
      consultation,
      consultationId,
      joinCall
    } = this.props;

    let localVideoStream = null;
    let localAudioStream = null;

    // Request permissions for video/audio separately in order to allow the participant to join with
    // either one
    try {
      localVideoStream = await navigator.mediaDevices.getUserMedia({
        video: true
      });
    } catch (error) {
      this.setState({ isLocalVideoActive: false });
    }

    try {
      localAudioStream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });
    } catch (error) {
      this.setState({ isLocalAudioActive: false });
    }

    // Forcefully reload the page if the permissions change. Firefox doesn't accept
    // camera/microphone for the name parameter, that's why the try/catch wrapping is required
    try {
      const cameraPermissions = await navigator.permissions.query({
        name: "camera"
      });
      const microphonePermissions = await navigator.permissions.query({
        name: "microphone"
      });

      cameraPermissions.addEventListener("change", this.reloadPage, {
        once: true
      });
      microphonePermissions.addEventListener("change", this.reloadPage, {
        once: true
      });
    } catch (error) {
      console.warn(error);
    }

    this.setState({
      permissions: {
        video: localVideoStream ? "granted" : "denied",
        audio: localAudioStream ? "granted" : "denied"
      },
      localStreams: { video: localVideoStream, audio: localAudioStream }
    });

    getConsultationById(consultationId);

    if (localAudioStream || localVideoStream) {
      joinCall({ consultationId });

      metricsApi.sendMetrics({
        event: "Entered room",
        event_properties: {
          userId: consultation.patient,
          consultationId
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.callStatus !== CALL_STATUS.END &&
      this.props.callStatus === CALL_STATUS.END
    ) {
      this.callEnd();
    }
    // TODO: probably a shitty way to start a call.
    if (prevProps.callId === null && this.props.callId) {
      // Login flow for vox finished,
      this.setupCallListeners();
    }
  }

  async componentWillUnmount() {
    const { localStreams } = this.state;
    const voxImplant = VoxImplant.getInstance();
    await voxImplant.showLocalVideo(false);
    try {
      this.call.hangup();
    } catch (error) {
      console.log("ComponentWillUnmount Cleanup", error);
    }
    // Try to remove green-video-icon
    if (localStreams.video) {
      localStreams.video.getTracks().forEach(track => {
        track.stop();
      });
    }
    if (localStreams.audio) {
      localStreams.audio.getTracks().forEach(track => {
        track.stop();
      });
    }
    this.removeCallListeners();
    this.props.setVoxImplantParams(null);
  }

  reloadPage = () => {
    window.location.reload();
  };

  subscribeToEndpointEvents = endpoint => {
    endpoint.on(VoxImplant.EndpointEvents.RemoteMediaAdded, endpointEvent => {
      this.logger({
        event: CallLogEvents.callRemoteVideoAdded,
        eventData: {
          endpointId: endpointEvent.endpoint.id,
          displayName: endpointEvent.endpoint.displayName,
          userName: endpointEvent.endpoint.userName
        }
      });
      const { mediaRenderer } = endpointEvent;
      // Actually render this stuff.
      mediaRenderer.render(
        document.getElementById(CALL_CONTAINERS_IDS.subscriber)
      );
      // Apply this logic only when reconnect process is finished.
      this.setState({
        isContactVideoPlaceholderVisible: false
      });
    });
    endpoint.on(VoxImplant.EndpointEvents.RemoteMediaRemoved, endpointEvent => {
      this.logger({
        event: CallLogEvents.callRemoteVideoRemoved,
        eventData: {
          endpointId: endpointEvent.endpoint.id,
          displayName: endpointEvent.endpoint.displayName,
          userName: endpointEvent.endpoint.userName
        }
      });
      // TODO: probably should remove the remote video element is case it stayed.
      // Apply this logic only when reconnect process is finished.
      this.setState({
        isContactVideoPlaceholderVisible: true
      });
    });
  };

  subscribeToCallEvents = () => {
    const { contacts, consultation, currentUserId } = this.props;
    // TODO: fix for patient app.
    const contact = contacts[consultation.doctor];
    if (!this.call) return;

    this.call.on(VoxImplant.CallEvents.Connected, () => {
      this.logger({ event: CallLogEvents.callConnected });
      this.setState({
        callState: CALL_STATE.CONNECTED
      });
      try {
        addPeerConnectionStateLogging(
          this.call.peerConnection.impl,
          this.logger
        );
      } catch (error) {
        console.log("Error in setting up PC logging, whatever");
      }
    });

    // These two events are marked as deprecated in docs, so are kinda useless.
    this.call.on(VoxImplant.CallEvents.ICECompleted, e => {
      this.logger({ event: CallLogEvents.callIceCompleted });
    });
    this.call.on(VoxImplant.CallEvents.ICETimeout, e => {
      this.logger({ event: CallLogEvents.callIceTimeout });
    });

    this.call.on(VoxImplant.CallEvents.EndpointAdded, callEvent => {
      // Minimum length for endpoint.userName is 3 symbols, so userId 6 will be '006'
      // Handle that.
      const formattedCurrentUserId = String(currentUserId).padStart(3, "0");
      const formattedContactId = String(contact.id).padStart(3, "0");
      // TODO: check if stuff below applies only for mobile app.
      // Maybe this is not needed on web.
      // Sometimes, here's endpoint with displayName === currentUserId,
      // Sometimes, it's called with displayName === consultationid (???)
      // All this events break our brittle flow of peerLeft/peerJoined, so I have to ignore them manually.
      // Why they even appear here???
      const isRecorderEndpoint = callEvent.endpoint.displayName === "";
      const isOldOwnEndpoint =
        callEvent.endpoint.userName &&
        callEvent.endpoint.userName === formattedCurrentUserId;
      const isPeerEndpoint =
        callEvent.endpoint.userName &&
        callEvent.endpoint.userName === formattedContactId;
      const isValidNewEndpoint =
        !isRecorderEndpoint && !isOldOwnEndpoint && isPeerEndpoint;
      this.logger({
        event: CallLogEvents.callEndpointAdded,
        eventData: {
          endpointId: callEvent.endpoint.id,
          displayName: callEvent.endpoint.displayName,
          userName: callEvent.endpoint.userName,
          isValidNewEndpoint
        }
      });
      if (isValidNewEndpoint) {
        // Reset flags.
        this.setState(prevState => ({
          peerJoined: true,
          peerLeft: false
        }));
        this.subscribeToEndpointEvents(callEvent.endpoint);
      }
    });

    this.call.on(VoxImplant.CallEvents.EndpointRemoved, callEvent => {
      this.logger({
        event: CallLogEvents.callEndpointRemoved,
        eventData: {
          endpointId: callEvent.endpoint.id,
          displayName: callEvent.endpoint.displayName,
          userName: callEvent.endpoint.userName
        }
      });

      const formattedCurrentUserId = String(currentUserId).padStart(3, "0");
      const isPeerEndpoint =
        callEvent.endpoint.userName !== formattedCurrentUserId;
      if (isPeerEndpoint) {
        this.setState({
          peerLeft: true
        });
      }
    });

    this.call.on(VoxImplant.CallEvents.Disconnected, e => {
      this.logger({ event: CallLogEvents.callDisconnected });
      this.setState({
        callState: CALL_STATE.DISCONNECTED
      });
    });

    this.call.on(VoxImplant.CallEvents.Failed, e => {
      // TODO: показывать тут ошибки, если эта не ошибка выше.
      this.logger({ event: CallLogEvents.callFailed });
      this.setState({
        callState: CALL_STATE.DISCONNECTED
      });
    });
  };

  removeCallListeners = () => {
    const voxImplant = VoxImplant.getInstance();
    if (this.call) {
      this.call.off(VoxImplant.CallEvents.Connected);
      this.call.off(VoxImplant.CallEvents.Disconnected);
      this.call.off(VoxImplant.CallEvents.Failed);
      this.call.off(VoxImplant.CallEvents.ProgressToneStart);
      this.call.off(VoxImplant.CallEvents.EndpointAdded);
    }
    // Final Straw.
    voxImplant.disconnect();
  };

  makeCall = async () => {
    const { callId } = this.props;
    const voxImplant = VoxImplant.getInstance();
    const callSettings = {
      number: callId,
      video: {
        sendVideo: true,
        receiveVideo: true
      }
    };
    this.call = await voxImplant.callConference(callSettings);
    this.subscribeToCallEvents();
  };

  setupCallListeners = async () => {
    const voxImplant = VoxImplant.getInstance();
    // Handle local video somehow???
    await voxImplant.showLocalVideo(true, true);
    // Add listener for reconnecting for our logger
    voxImplant.on(VoxImplant.Events.Reconnecting, () => {
      this.logger({ event: CallLogEvents.callTryReconnect });
    });
    // Join the room.
    this.makeCall();
  };

  callEnd = () => {
    const { hideModal } = this.props;

    try {
      this.call.hangup();
    } catch (error) {
      console.log("Call hangup error...", error);
    }
    this.logger({ event: CallLogEvents.callLeave });

    hideModal(CALL_ROOM_EXIT_VIEW);
    hideModal(CALL_ROOM_VIEW);
  };

  handleToggleCamera = async () => {
    const { isLocalVideoActive, isScreenSharingActive } = this.state;
    // Camera is always disabled during screen share.
    if (isScreenSharingActive) return;
    if (this.state.permissions.video === "denied") return;
    if (!this.call) return;
    await this.call.sendVideo(!isLocalVideoActive);
    this.setState(prevState => ({
      isLocalVideoActive: !prevState.isLocalVideoActive
    }));
  };

  handleToggleMicrophone = async () => {
    if (this.state.permissions.audio === "denied") return;
    if (!this.call) return;
    const newMicrophoneStatus = !this.state.isLocalAudioActive;
    if (newMicrophoneStatus) {
      this.call.unmuteMicrophone();
    } else {
      this.call.muteMicrophone();
    }
    this.setState(prevState => ({
      isLocalAudioActive: !prevState.isLocalAudioActive
    }));
  };

  handleScreenShare = async () => {
    if (!this.call) return;
    const newScreenSharingStatus = !this.state.isScreenSharingActive;
    if (newScreenSharingStatus) {
      // Need to wrap this in try/catch block because it reject if users clicks cancel
      // in source selection.
      try {
        await this.call.shareScreen(true, true);
        // Code copied from web-sdk example app.
        // Screen sharing can be closed not only with an interface button
        // but also via native browser component
        // the code below is to track if user closes screen sharing via native browser component
        // get transceivers of current peer connection
        const transceivers = this.call.peerConnection.getTransceivers();
        // find the transceiver with the sharing stream track
        const transceiverSharing = transceivers.find(transceiver => {
          return (
            transceiver.sender.track !== null &&
            transceiver.sender.track.label.includes("screen")
          );
        });
        // Add a listener to the transceiver's track "ended" event
        // in order to enable sharing button to start a new screen share
        transceiverSharing &&
          transceiverSharing.sender.track.addEventListener("ended", () => {
            this.setState({
              isScreenSharingActive: false
            });
          });
        // We also need to turn the camera off.
        // await this.call.sendVideo(false);
        // this.setState({ isLocalVideoActive: false });
      } catch (error) {
        console.log("Failed to start screen share", error);
        return;
      }
    } else {
      await this.call.stopSharingScreen();
    }

    this.setState(prevState => ({
      isScreenSharingActive: !prevState.isScreenSharingActive
    }));
  };

  handlePositiveClick = () => {
    this.props.setCallStatus(CALL_STATUS.END);
  };

  handleNegativeClick = () => {
    this.props.hideModal(CALL_ROOM_EXIT_VIEW);
    this.setState({ isExitModalVisible: false });
  };

  handleRoomExit = () => {
    this.setState({ isExitModalVisible: true });
    this.props.showModal(CALL_ROOM_EXIT_VIEW, {
      onPositiveClick: this.handlePositiveClick,
      onNegativeClick: this.handleNegativeClick
    });
  };

  render() {
    const {
      peers,
      permissions,
      isExitModalVisible,
      isLocalVideoActive,
      isLocalAudioActive,
      isScreenSharingActive,
      isContactVideoPlaceholderVisible,
      callState,
      peerLeft,
      peerJoined
    } = this.state;
    const { callStatus, consultation, contacts } = this.props;

    if (!consultation) {
      return (
        <div>
          <Loading showLoading />
        </div>
      );
    }

    const permissionsDenied =
      permissions.video === "denied" && permissions.audio === "denied";
    const peer = contacts[consultation.doctor];
    const secondsRemaining = moment(consultation.tariff_end_at).diff(
      moment(),
      "seconds"
    );
    const hasActivePeer = peerJoined && !peerLeft;
    const showContactPlaceholder =
      hasActivePeer &&
      isContactVideoPlaceholderVisible &&
      !isScreenSharingActive;
    return (
      <div className={styles.overlay} ref={this.overlayRef}>
        <div
          className={cn(styles.room, {
            [styles.noPermissions]: permissionsDenied
          })}
        >
          <InformationOverlay
            peerLeft={peerLeft}
            peerJoined={peerJoined}
            callStatus={callStatus}
            peerName={contacts[consultation.patient].full_name}
            peers={peers}
            secondsRemaining={secondsRemaining}
          />
          {permissionsDenied && (
            <PermissionsScreen
              onRoomExit={this.handleRoomExit}
              overlayRef={this.overlayRef}
            />
          )}
          {showContactPlaceholder && (
            <div className={styles.noVideoPlaceholder}>
              <UserAvatar
                className={styles.peerAvatar}
                user={peer}
                size="xlarge"
              />
            </div>
          )}
          <div
            id={CALL_CONTAINERS_IDS.subscriber}
            className={cn(videoStyles.videoContainer, {
              [videoStyles.hidden]: isScreenSharingActive
            })}
          />
          <div
            id={CALL_CONTAINERS_IDS.publisher}
            className={cn(videoStyles.videoContainer, videoStyles.local, {
              [videoStyles.sharing]: isScreenSharingActive,
              [videoStyles.hidden]:
                !isScreenSharingActive && !isLocalVideoActive,
              [videoStyles.small]: hasActivePeer && !isScreenSharingActive
            })}
          />
          <RoomControls
            connected={callState === CALL_STATE.CONNECTED}
            callStatus={callStatus}
            permissions={permissions}
            isExitModalVisible={isExitModalVisible}
            isCameraEnabled={isLocalVideoActive}
            isMicrophoneEnabled={isLocalAudioActive}
            onCameraToggle={this.handleToggleCamera}
            onMicrophoneToggle={this.handleToggleMicrophone}
            onRoomExit={this.handleRoomExit}
            onScreenShare={this.handleScreenShare}
          />
        </div>
      </div>
    );
  }
}

Room.propTypes = {
  consultationId: PropTypes.number,
  consultation: PropTypes.shape({
    can_close: PropTypes.bool,
    clinic: PropTypes.number,
    conclusion_id: PropTypes.number,
    doctor: PropTypes.number,
    end_at: PropTypes.string,
    id: PropTypes.number,
    is_viewed: PropTypes.bool,
    patient: PropTypes.number,
    service_patient_id: PropTypes.number,
    slot_id: PropTypes.number,
    start_at: PropTypes.string,
    status: PropTypes.string,
    tariff_id: PropTypes.string,
    tariff_is_subscription: PropTypes.bool,
    tariff_name: PropTypes.string,
    tariff_price: PropTypes.number,
    tariff_type: PropTypes.string,
    tariff_end_at: PropTypes.string,
    type: PropTypes.string
  }),
  callStatus: PropTypes.oneOf(["IDLE", "START", "ACTIVE", "ERROR", "END"]),
  contacts: PropTypes.objectOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      date_of_birth: PropTypes.string,
      full_name: PropTypes.string,
      id: PropTypes.number
    })
  ),
  callId: PropTypes.string,
  getConsultationById: PropTypes.func,
  setCallStatus: PropTypes.func,
  joinCall: PropTypes.func,
  leaveCall: PropTypes.func,
  hideModal: PropTypes.func,
  showModal: PropTypes.func
};

const mapStateToProps = (
  {
    call: { callStatus, callId },
    consultations,
    users: { items },
    auth: { currentUserId }
  },
  { consultationId }
) => ({
  callStatus,
  callId,
  consultation: consultations.items[consultationId],
  currentUserId,
  contacts: items
});

const mapDispatchToProps = {
  setCallStatus: callActions.setCallStatus,
  joinCall: callActions.callJoin,
  leaveCall: callActions.callLeave,
  getConsultationById: consultationsActions.getInfo,
  setVoxImplantParams: callActions.setVoxImplantParams,
  hideModal: uiActions.hideModal,
  showModal: uiActions.showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Room);
