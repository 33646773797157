import cn from 'classnames';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './Spinner.scss';

const Spinner = ({ scale, className, style, size, fill, colorVariant }) => {
  const _style = useMemo(
    () => ({
      ...(scale !== undefined && scale !== 1 && { transform: `scale(${scale})` }),
      width: `${size}px`,
      height: `${size}px`,
      borderWidth: `${size / 8}px`,
      ...(fill && { borderColor: fill }),
      ...style,
    }),
    [],
  );
  return (
    <div
      className={cn(styles.container, { [styles[colorVariant]]: !fill }, className)}
      style={_style}
    />
  );
};

Spinner.propTypes = {
  /** Устанавливает для контейнера имя класса. */
  className: PropTypes.string,
  /** Устанавливает масштаб. */
  scale: PropTypes.number,
  /** Устанавливает размер. */
  size: PropTypes.number,
  /** Устанавливает цвет */
  fill: PropTypes.string,
  /** Устанавливает стиль */
  style: PropTypes.object,
  /** Устанавливает цвет */
  colorVariant: PropTypes.oneOf(['pink', 'blue', 'green', 'danger', 'white']),
};

Spinner.defaultProps = {
  style: {},
  size: 50,
  colorVariant: 'pink',
};

export default Spinner;
