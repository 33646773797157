export const ANDROID = "Android";
export const IOS = "iOS";

export const appLinks = {
  [ANDROID]:
    "https://play.google.com/store/apps/details?id=pro.ibolit.iBolitPatient",
  [IOS]: "https://itunes.apple.com/ru/app/ibolit-пациент/id1345515799?mt=8"
};

export const getMobileOperatingSystem = () => {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return ANDROID;
  }

  if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return IOS;
  }

  return null;
};
