import makeModel from "../../utils/redux/requestModel";

const name = "clinics";
export const CLINICS_MODE_ALL = "all";
export const CLINICS_MODE_SELF_APPOINTMENT = "selfAppointment";
export const modes = [CLINICS_MODE_ALL, CLINICS_MODE_SELF_APPOINTMENT];

export const {
  types,
  state,
  actions,
  reducer,
  selector: clinicsRequestSelector
} = makeModel(name, modes);
