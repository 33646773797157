import React from "react";
import { Icon, Text } from "ibolit-ui";

export const messageTypes = {
  text: "text",
  photo: "photo",
  file: "file",
  comment: "comment",
  conclusion: "conclusion",
  mockUpload: "mockUpload",
  system: "system",
  service: "service"
};

export const getTitle = ({ type }) => {
  let icon;
  let title;
  switch (type) {
    case messageTypes.comment:
      icon = "chat";
      title = " Коментарий";
      break;
    case messageTypes.file:
      icon = "file";
      title = " Файл";
      break;
    case messageTypes.photo:
      icon = "photo";
      title = " Фото";
      break;
    case messageTypes.system:
    case messageTypes.service:
      icon = "phonendoscope";
      title = " Консультация";
      break;
    case messageTypes.conclusion:
      icon = "phonendoscope";
      title = " Заключение";
      break;
    default:
      return {};
  }
  return { icon, title };
};

export default (msg, isOwn) => {
  const prefix = isOwn && (
    <Text variant="desc" colorVariant="pale" key="own" tag="span">
      Вы:{" "}
    </Text>
  );
  const { icon, title: text } = getTitle(msg);
  const content = [];
  prefix && content.push(prefix);
  if (!text) {
    if (msg.type === messageTypes.text) {
      content.push(msg.body);
    } else {
      content.push("Сообщение");
    }
  } else {
    content.push(<Icon variant={icon} size="s" key="icon" />);
    content.push(text);
  }
  return content;
};
