import PropTypes from "prop-types";
import React, { useState, useCallback, useEffect } from "react";
import cn from "classnames";
import _ from "lodash";
import "moment/locale/ru";
import DayPicker from "react-day-picker";
import { Text, Icon } from "ibolit-ui";
import MomentLocaleUtils from "react-day-picker/moment";

import styles from "./DatePicker.scss";

const upperFirst = str => `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;

const YearMonthSwitch = ({
  date,
  localeUtils,
  onChange,
  locale,
  maxDate,
  minDate
}) => {
  const months = localeUtils.getMonths(locale);
  const years = [];

  for (let i = maxDate.getFullYear(); i >= minDate.getFullYear(); i -= 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <div>
      <select
        name="month"
        onChange={handleChange}
        value={date.getMonth()}
        className={styles.select}
        data-testid={"month-select"}
      >
        {months.map((month, i) => (
          <option
            key={month}
            value={i}
            data-testid={`month-select-item-${month}`}
          >
            {upperFirst(month)}
          </option>
        ))}
      </select>
      <select
        name="year"
        onChange={handleChange}
        value={date.getFullYear()}
        className={styles.select}
        data-testid={"year-select"}
      >
        {years.map(year => (
          <option
            key={year}
            value={year}
            data-testid={`year-select-item-${year}`}
          >
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

const Caption = ({
  date,
  localeUtils,
  locale,
  showYear,
  switchBetweenYearsAndMonths,
  onChange,
  maxDate,
  minDate
}) => {
  if (switchBetweenYearsAndMonths) {
    return (
      <div className={styles.captionSwitch}>
        <YearMonthSwitch
          date={date}
          localeUtils={localeUtils}
          onChange={onChange}
          locale={locale}
          maxDate={maxDate}
          minDate={minDate}
        />
      </div>
    );
  }

  const months = localeUtils.getMonths(locale);

  return (
    <Text variant="h4" className={styles.caption}>
      {upperFirst(months[date.getMonth()])}
      {showYear && (
        <div className={styles.captionYear}>{date.getFullYear()}</div>
      )}
    </Text>
  );
};

const Weekday = ({ weekday, className, localeUtils, locale }) => {
  const weekdayName = localeUtils.formatWeekdayShort(weekday, locale);
  return (
    <Text variant="h5" className={cn(className)}>
      {upperFirst(weekdayName)}
    </Text>
  );
};

const Navbar = ({
  onPreviousClick,
  onNextClick,
  showNextButton,
  showPreviousButton
}) => (
  <div className={styles.navBar}>
    <Icon
      variant="arrow-right"
      {...(showPreviousButton && { onClick: () => onPreviousClick() })}
      className={cn(styles.navButton, styles.navButtonPrev, {
        [styles.disabled]: !showPreviousButton
      })}
    />
    <Icon
      variant="arrow-right"
      {...(showNextButton && { onClick: () => onNextClick() })}
      className={cn(styles.navButton, styles.navButtonNext, {
        [styles.disabled]: !showNextButton
      })}
    />
  </div>
);

const renderDay = (day, ...modifiers) => {
  const date = day.getDate();
  return (
    <Text
      variant="h5"
      className={cn([styles.day, ...modifiers])}
      data-testid={`calendar-day-${date}`}
    >
      {date}
    </Text>
  );
};

const getInitialMonth = (selected, min) => {
  const minSelected = selected.reduce(
    (acc, date) => (date < acc ? date : acc),
    selected[0]
  );
  return minSelected < min ? min : minSelected;
};

const DatePicker = ({
  className,
  style,
  date,
  value,
  minDate,
  maxDate,
  disabledDays,
  selectedDays,
  onDayClick,
  showYearInCaption,
  switchBetweenYearsAndMonths,
  ...props
}) => {
  const [month, setMonth] = useState(value);

  const onYearsAndMonthChange = useCallback(_date => {
    setMonth(_date);
  }, []);

  useEffect(() => {
    if (value !== undefined) {
      setMonth(value);
    }
  }, [value]);

  const modifiers = {
    selected: [..._.castArray(selectedDays)],
    [styles.disabled]: [
      ...disabledDays,
      (minDate || maxDate) && {
        before: minDate,
        after: maxDate
      }
    ]
  };

  return (
    <DayPicker
      classNames={styles}
      showOutsideDays
      locale="ru"
      localeUtils={MomentLocaleUtils}
      weekdayElement={<Weekday />}
      captionElement={
        <Caption
          onChange={onYearsAndMonthChange}
          showYear={showYearInCaption}
          switchBetweenYearsAndMonths={switchBetweenYearsAndMonths}
          minDate={minDate}
          maxDate={maxDate}
        />
      }
      navbarElement={<Navbar />}
      renderDay={renderDay}
      fromMonth={minDate}
      toMonth={maxDate}
      initialMonth={getInitialMonth(modifiers.selected, minDate)}
      modifiers={modifiers}
      onDayClick={onDayClick}
      month={month}
      value={value}
      {...props}
    />
  );
};

DatePicker.propTypes = {
  className: PropTypes.string,
  selectedDays: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    PropTypes.instanceOf(Date)
  ]),
  disabledDays: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onDayClick: PropTypes.func,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  style: PropTypes.object,
  showYearInCaption: PropTypes.bool,
  switchBetweenYearsAndMonths: PropTypes.bool
};

DatePicker.defaultProps = {
  disabledDays: [],
  selectedDays: [],
  showYearInCaption: false,
  switchBetweenYearsAndMonths: false
};

export default DatePicker;
