import React from "react";
import PropTypes from "prop-types";
import ErrorBox from "~/components/ErrorSwitcher/ErrorBox";

const ErrorSwitcher = ({ hasError, repeat, children }) =>
  hasError ? <ErrorBox onRepeatClick={repeat} /> : children;

ErrorSwitcher.propTypes = {
  hasError: PropTypes.bool,
  repeat: PropTypes.func,
  children: PropTypes.node
};

export default ErrorSwitcher;
