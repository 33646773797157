import React from "react";
import PropTypes from "prop-types";
import { Text } from "ibolit-ui";
import styles from "./FileIcon.scss";

const FileIcon = ({ extension }) => (
  <div className={styles.container}>
    <Text variant="caption" colorVariant="pressing">
      {extension}
    </Text>
  </div>
);

FileIcon.propTypes = {
  extension: PropTypes.string.isRequired
};

export default FileIcon;
