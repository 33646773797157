import Types from "./userTypes";
import { actions } from "~/store/users/userModel";

export default {
  ...actions,

  tariffsRequest: (userId, tariffsType) => ({
    type: Types.TARIFFS_REQUEST,
    payload: {
      userId,
      tariffsType
    }
  }),
  tariffsSuccess: () => ({
    type: Types.TARIFFS_SUCCESS
  }),
  tariffsFailure: errors => ({
    type: Types.TARIFFS_FAILURE,
    errors
  }),

  userInfoRequest: id => ({
    type: Types.USER_INFO_REQUEST,
    payload: { id }
  }),
  userInfoSuccess: () => ({
    type: Types.USER_INFO_SUCCESS
  }),
  userInfoFailure: errors => ({
    type: Types.USER_INFO_FAILURE,
    errors
  }),

  userCheckNewRequest: id => ({
    type: Types.USER_CHECK_NEW_REQUEST,
    request: { id }
  }),
  userCheckNewSuccess: (payload, request, rest) => ({
    type: Types.USER_CHECK_NEW_SUCCESS,
    payload,
    request,
    rest
  }),
  userCheckNewFailure: (error, request) => ({
    type: Types.USER_CHECK_NEW_FAILURE,
    error,
    request
  }),

  selectTariff: tariff => ({
    type: Types.TARIFF_SELECT,
    tariff
  })
};
