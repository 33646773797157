import React from "react";
import TextInput from "~/components/inputs/TextInput";
import { checkPassword } from "~/utils/validations";

const MIN_LENGTH = 6;
const MAX_LENGTH = 16;

const validator = (min = MIN_LENGTH) => value => {
  let isValid;
  let severity;

  try {
    isValid = checkPassword(value, min, MAX_LENGTH);
    severity = "notice";
  } catch (e) {
    isValid = false;
    severity = "error";
  }

  return (
    isValid || [
      false,
      `Длина пароля должна быть не менее ${min} символов`,
      true,
      severity
    ]
  );
};

const PasswordInput = ({ secured = false, minLength, ...props }, inputRef) => (
  <TextInput
    type="password"
    autocomplete="new-password"
    secured={secured}
    validator={validator(minLength)}
    ref={inputRef}
    {...props}
    maxLength={MAX_LENGTH}
  />
);

export default React.forwardRef(PasswordInput);
