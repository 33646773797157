import PropTypes from "prop-types";
import cn from "classnames";
import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect
} from "react";
import ReactDom from "react-dom";
import styles from "./UseSelectorModal.scss";

const SelectorModal = ({
  toggle,
  isOpen,
  onClose,
  onOpen,
  position,
  className,
  children,
  minHeight,
  style
}) => {
  const wrapperRef = useRef();
  const parent = useRef(null);
  const self = useRef();
  const [rect, setRect] = useState({});

  const getParentRect = () => {
    setRect(parent.current.getBoundingClientRect());
  };

  useEffect(() => {
    window.addEventListener("resize", getParentRect);
    return () => window.removeEventListener("resize", getParentRect);
  }, []);

  useEffect(() => {
    isOpen && onOpen && onOpen();
    !isOpen && onClose && onClose();
  }, [isOpen]);

  const refSetter = useCallback(node => {
    if (node !== null && node.parentNode) {
      parent.current = node.parentNode;
      getParentRect();
    }
  }, []);

  const _style = useMemo(() => {
    const result = {
      left: rect.left,
      top: rect.top
    };
    if (self.current) {
      const selfRect = self.current.getBoundingClientRect();
      const { origin } = self.current;
      switch (position) {
        case "right":
          result.left = rect.right - selfRect.width;
          break;
        case "bottom":
          result.top = rect.bottom;
          break;
        default:
          break;
      }
      const bottom = result.top + selfRect.height;
      if (bottom > window.innerHeight) {
        !origin && (self.current.origin = selfRect);
        if (minHeight === undefined) {
          result.top = window.innerHeight - selfRect.height;
        } else {
          const newHeight = window.innerHeight - result.top;
          if (newHeight >= minHeight) {
            result.height =
              origin && newHeight >= origin.height ? origin.height : newHeight;
          } else {
            result.height = minHeight;
            result.top = window.innerHeight - minHeight;
          }
        }
      }
    }
    return {
      ...result,
      ...style
    };
  }, [position, rect]);

  return (
    <div ref={refSetter}>
      {isOpen &&
        ReactDom.createPortal(
          <div
            className={styles.selectorOutside}
            onClick={toggle}
            ref={wrapperRef}
          >
            <div
              ref={self}
              className={cn(styles.selectorContainer, className, {
                [styles.shown]: isOpen
              })}
              style={_style}
              onClick={event => event.stopPropagation()}
            >
              {children}
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

SelectorModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  position: PropTypes.oneOf(["top", "right", "bottom"]),
  style: PropTypes.object,
  toggle: PropTypes.func.isRequired
};

SelectorModal.defaultProps = {
  position: "top"
};

export const useSelectorModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = state =>
    setIsOpen(typeof state === "boolean" ? state : !isOpen);

  const renderSelectorModal = useCallback(
    props => <SelectorModal {...props} toggle={toggle} isOpen={isOpen} />,
    [isOpen]
  );

  return [renderSelectorModal, toggle, isOpen];
};
