import React from "react";
import PropTypes from "prop-types";
import { Text } from "ibolit-ui";
import formatPrice from "~/utils/formatPrice";
import { formatDuration } from "~/modals/Tariffs/tariffsUtils";

const TariffItem = ({ is_subscription, price, duration, type, name }) => (
  <div>
    <Text variant="h6">{name}</Text>
    <Text variant="h5">
      {`${formatPrice(price)} ${formatDuration({
        is_subscription,
        duration,
        type
      })}`}
    </Text>
  </div>
);

TariffItem.propTypes = {
  is_subscription: PropTypes.bool,
  price: PropTypes.number,
  duration: PropTypes.number,
  type: PropTypes.string,
  name: PropTypes.string
};

export default TariffItem;
