import cn from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import Icon from '../Icon';
import styles from './Collapse.scss';

const Collapse = ({ initialIndex, children, headers, className }) => {
  const [index, setIndex] = useState(initialIndex);

  const onHeaderClick = (event, headerIndex) => {
    event.preventDefault();

    setIndex(headerIndex !== index ? headerIndex : undefined);
  };

  return (
    <ul className={cn(styles.ul, className)}>
      {headers.map((title, headerIndex) => {
        const open = headerIndex === index;

        return (
          <li className={styles.li} key={title}>
            <div onClick={event => onHeaderClick(event, headerIndex)} className={styles.header}>
              <Text className={styles.title} variant="h4">
                {title}
              </Text>
              <Icon variant="arrow-bottom" className={cn({ [styles.open]: open })} />
            </div>
            {open && <div className={styles.body}>{children[index]}</div>}
          </li>
        );
      })}
    </ul>
  );
};

Collapse.propTypes = {
  /** Открытый индекс при инициализации */
  initialIndex: PropTypes.number,
  /** Текстовый список пунктов меню */
  headers: PropTypes.arrayOf(PropTypes.string),
  /** Устанавливает для контейнера имя класса. */
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Collapse;
