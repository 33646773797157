import React from "react";
import { Route, Switch } from "react-router-dom";
import MainLayout, { Aside, Section } from "~/components/layouts/Main/Main";
import EmptyStub from "~/components/EmptyStub";
import DocumentsList from "./DocumentsList/DocumentsList";
import DocumentDetail from "./DocumentDetail/DocumentDetail";
import { DOCUMENTS_ASIDE } from "~/locators/documents";
import BlockHeader from "~/components/BlockHeader";

const DocumentsLayout = ({ match }) => (
  <MainLayout mode="wide">
    <Aside>
      <BlockHeader title="Мои документы" testid={DOCUMENTS_ASIDE.HEADER} />
      <DocumentsList />
    </Aside>
    <Switch>
      <Route exact path={match.url}>
        <EmptyStub
          key="stub"
          centered
          text={"Выберите документ для\nпросмотра деталей"}
        />
      </Route>
      <Route exact path={`${match.url}/:documentId`}>
        {({
          match: {
            params: { documentId }
          }
        }) => (
          <Section>
            <DocumentDetail documentId={Number(documentId)} />
          </Section>
        )}
      </Route>
    </Switch>
  </MainLayout>
);

export default DocumentsLayout;
