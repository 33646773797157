const temp = "new_consultations";

export default {
  NEW_CONSULTATION_SELECT_CLINIC: `${temp}/SELECT_CLINIC`,
  NEW_CONSULTATION_SELECT_DOCTOR: `${temp}/SELECT_DOCTOR`,
  NEW_CONSULTATION_SELECT_SLOT: `${temp}/SELECT_SLOT`,
  NEW_CONSULTATION_SELECT_SLOT_TYPE: `${temp}/SELECT_SLOT_TYPE`,
  NEW_CONSULTATION_SELECT_DATE: `${temp}/SELECT_DATE`,

  CONSULTATIONS_POST_REQUEST: `${temp}/POST_REQUEST`,
  CONSULTATIONS_POST_SUCCESS: `${temp}/POST_SUCCESS`,
  CONSULTATIONS_POST_FAILURE: `${temp}/POST_FAILURE`,

  NEW_CONSULTATION_SYMPTOMS_REQUEST: `${temp}/SYMPTOMS_REQUEST`,
  NEW_CONSULTATION_SYMPTOMS_SUCCESS: `${temp}/SYMPTOMS_SUCCESS`,
  NEW_CONSULTATION_SYMPTOMS_FAILURE: `${temp}/SYMPTOMS_FAILURE`,

  NEW_CONSULTATION_SYMPTOM_CREATE_REQUEST: `${temp}/SYMPTOM_CREATE_REQUEST`,
  NEW_CONSULTATION_SYMPTOM_CREATE_SUCCESS: `${temp}/SYMPTOM_CREATE_SUCCESS`,
  NEW_CONSULTATION_SYMPTOM_CREATE_FAILURE: `${temp}/SYMPTOM_CREATE_FAILURE`,

  NEW_CONSULTATION_SYMPTOM_UPDATE_REQUEST: `${temp}/SYMPTOM_UPDATE_REQUEST`,
  NEW_CONSULTATION_SYMPTOM_UPDATE_SUCCESS: `${temp}/SYMPTOM_UPDATE_SUCCESS`,
  NEW_CONSULTATION_SYMPTOM_UPDATE_FAILURE: `${temp}/SYMPTOM_UPDATE_FAILURE`,

  FILL_DATE_TIME_DATA: `${temp}/FILL_DATE_TIME_DATA`
};
