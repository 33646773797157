import React, { useCallback, useEffect, useMemo } from "react";
import { Box, ImageLoader, Text } from "ibolit-ui";
import { useDispatch } from "react-redux";
import Icon from "ibolit-ui/src/UI/Icon";
import { useCloseModal } from "~/hooks/useModal";
import { DOCTORS_CHAT_VIEW } from "~/modals/DoctorsChat";
import clinicsApi from "~/api/clinicsApi";
import { getClinicAvatar } from "~/utils/image";
import Loader from "~/components/Loader";
import { useGetRequest } from "~/hooks/useRequest";
import uiActions from "~/store/ui/uiActions";
import styles from "./Clinics.scss";
import { SPECIALIZATION_CHOOSE } from "../SpecializationChoose/SpecializationChoose";
import {
  CLINICS_PRIMARY_LIST,
  CLINICS_PRIMARY_LIST_ITEM,
  CLINICS_PRIMARY_LIST_ITEM_BUTTON
} from "~/locators/clinics";
import EmptyStub from "~/components/EmptyStub";
import ErrorSwitcher from "~/components/ErrorSwitcher";

const defaultTestId = "Clinics";

export const CLINICS_VIEW = "clinics";

const Clinics = () => {
  const onCloseClick = useCloseModal(CLINICS_VIEW);
  const { loading, items, error, request } = useGetRequest(
    clinicsApi.clinicListForChat,
    {
      primary_acceptance: true
    }
  );
  const hasNoItems = useMemo(() => !loading && items.length === 0, [
    loading,
    items
  ]);

  const dispatch = useDispatch();
  const onClinicClick = useCallback(id => {
    dispatch(
      uiActions.replaceModal(CLINICS_VIEW, SPECIALIZATION_CHOOSE, {
        clinicId: id
      })
    );
  }, []);

  useEffect(() => {
    if (!loading && items.length === 1) {
      dispatch(
        uiActions.replaceModal(CLINICS_VIEW, SPECIALIZATION_CHOOSE, {
          clinicId: items[0].id,
          noBackButton: true
        })
      );
    }
  }, [loading, items]);

  return (
    <ErrorSwitcher hasError={error} repeat={request}>
      <Loader loading={loading}>
        <Box isModalBlock>
          <div className={styles.header}>
            <Text tag="span" variant="h2" testid="header_modal_window">
              Выберите клинику
            </Text>
            <div className={styles.close} data-testid="close_modal_window">
              <Icon variant="close" fill="var(--grey)" onClick={onCloseClick} />
            </div>
          </div>
          {hasNoItems && (
            <div className={styles.emptyStub}>
              <EmptyStub centered text="Клиники не найдены" />
            </div>
          )}
          <ul className={styles.list} data-testid={CLINICS_PRIMARY_LIST}>
            {items.map(item => (
              <li
                key={item.id}
                className={styles.item}
                data-testid={CLINICS_PRIMARY_LIST_ITEM}
              >
                <button
                  type="button"
                  className={styles.clinicPanel}
                  onClick={() => onClinicClick(item.id)}
                  data-testid={CLINICS_PRIMARY_LIST_ITEM_BUTTON}
                >
                  <ImageLoader
                    asBackground
                    src={getClinicAvatar(item.avatar)}
                    className={styles.avatar}
                    testid={`${defaultTestId}__item-avatar`}
                  />
                  <div className={styles.content}>
                    <Text
                      className={styles.title}
                      variant="h4"
                      title={item.name}
                      testid={`${defaultTestId}__item-name`}
                    >
                      {item.name}
                    </Text>
                    <Text
                      className={styles.address}
                      tag="address"
                      title="address"
                      variant="desc"
                      colorVariant="tertiary"
                      testid={`${defaultTestId}__item-address`}
                    >
                      {item.street}
                    </Text>
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </Box>
      </Loader>
    </ErrorSwitcher>
  );
};

export default Clinics;
