import { types } from "~/store/users/userModel";

export default {
  ...types,

  TARIFFS_REQUEST: "users/TARIFFS_REQUEST",
  TARIFFS_SUCCESS: "users/TARIFFS_SUCCESS",
  TARIFFS_FAILURE: "users/TARIFFS_FAILURE",

  USER_INFO_REQUEST: "users/USER_INFO_REQUEST",
  USER_INFO_SUCCESS: "users/USER_INFO_SUCCESS",
  USER_INFO_FAILURE: "users/USER_INFO_FAILURE",

  TARIFF_SELECT: "users/SELECT_TARIFF",

  USER_CHECK_NEW_REQUEST: "users/USER_CHECK_NEW_REQUEST",
  USER_CHECK_NEW_SUCCESS: "users/USER_CHECK_NEW_SUCCESS",
  USER_CHECK_NEW_FAILURE: "users/USER_CHECK_NEW_FAILURE"
};
