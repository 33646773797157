import moment from 'moment-timezone';

import 'moment/locale/ru';

const userTz = moment.tz.guess();
const serverTz = 'Europe/Moscow';

// hardcode server timezone to Moscow
export const SERVER_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const SERVER_DATE_FORMAT = SERVER_DATETIME_FORMAT.split(' ')[0];
export const HOURS_MINUTES_FORMAT = 'HH:mm';
export const NOTIFICATION_DATE_FORMAT = 'DD MMMM в HH:mm';
export const DATE_FORMAT_DMY = 'DD.MM.YYYY';
export const DATE_FORMAT = 'YYYY-MM-DD';

export const getDate = date => {
  if (typeof date === 'string') {
    return getTzDate(date.replace(/(\d{2})\.(\d{2})\.(\d{4})(.*)/, '$3-$2-$1$4'));
  }
  if (date instanceof Date) {
    return getTzDate(date);
  }
  if (typeof date === 'number') {
    return moment.unix(date);
  }
  return getTzDate(date);
};

export const getDateDiffs = date => {
  const target = moment(date);
  const now = moment();
  return {
    days: target.startOf('d').diff(now.startOf('d'), 'd'),
    weeks: target.startOf('w').diff(now.startOf('w'), 'w'),
    months: target.startOf('M').diff(now.startOf('M'), 'M'),
    years: target.startOf('y').diff(now.startOf('y'), 'y'),
  };
};

export const getDaysDiff = (date, targetDate) =>
  date.startOf('d').diff(targetDate.startOf('d'), 'd');

export const getDayOrDateText = date => {
  const tzDate = getTzDate(date);
  const days = getDaysDiff(tzDate, moment());
  if (days === 0) return 'Сегодня';
  if (days === -1) return 'Вчера';
  return tzDate.format('DD MMMM YYYY');
};

export const getDateDiffsCategory = date => {
  const { days, weeks, months } = getDateDiffs(date);
  if (months < -1) {
    return { type: 'earlier', text: 'Ранее' };
  }
  if (months === -1) {
    return { type: 'monthAgo', text: 'В прошлом месяце' };
  }
  if (months === 0 && weeks < -1) {
    return { type: 'thisMonth', text: 'В этом месяце' };
  }
  if (weeks === -1) {
    return { type: 'weekAgo', text: 'На прошлой неделе' };
  }
  if (weeks === 0 && days < 0) {
    return { type: 'thisWeek', text: 'На этой неделе' };
  }
  if (days === 0) {
    return { type: 'today', text: 'Сегодня' };
  }

  return { type: 'undefined', text: '' };
};

export const getDaysDiffText = (date, fallback = 'dddd', today = 'сегодня') => {
  const { days } = getDateDiffs(date);
  switch (days) {
    case -1:
      return 'вчера';
    case 0:
      return today;
    case 1:
      return 'завтра';
    default:
      return fallback;
  }
};

export const getDateText = date => {
  const _date = getDate(date);
  const dayTextFormat = getDaysDiffText(_date);
  return _date.format(`D MMMM (${dayTextFormat})`);
};

export const getFormattedStringDateWithTime = (date) => {
  const _date = getDate(date);
  const dayTextFormat = getDaysDiffText(_date);
  return _date.format(`D MMMM (${dayTextFormat}) HH:mm`);
}

export const getFormatDateOfBirth = date => {
  let result;

  if (typeof date === 'number') {
    result = moment.unix(date).format('DD.MM.YYYY');
  } else if (date) {
    result = moment(date, SERVER_DATE_FORMAT).format('DD.MM.YYYY');
  } else {
    result = '';
  }

  return result;
};

const getFormattedStringRange = (start, end) => `
  ${start.format('HH:mm')} – ${end.format('HH:mm')},
  ${start.format('D MMMM (dddd)')} `;

// @param {string} date
// @return {moment.tz}
export const getTzDate = date => moment.tz(date, SERVER_DATETIME_FORMAT, serverTz).tz(userTz);

// @param { Date } date - Date object (client tz)
// return { moment.tz }
export const dateToServer = date => moment(date).tz(serverTz);

export const getFormattedRange = (start, end) => {
  const startDate = getTzDate(start);
  const endDate = getTzDate(end);

  return getFormattedStringRange(startDate, endDate);
};

export const getConsultationMsgFormattedRange = (start, end) => {
  const startDate = getTzDate(start);
  const endDate = getTzDate(end);

  return `${startDate.format('D MMMM')} ${startDate.format('HH:mm')} - ${endDate.format('HH:mm')}`;
};

export const getIsDateAfter = target => moment().isAfter(getTzDate(target));

export const passMoreThanHours = (date, hours) => moment().diff(getTzDate(date), 'h') >= hours;
