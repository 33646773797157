import makeModel from "~/utils/redux/requestModel";

const name = "documents";
export const DOCUMENT_MODE_ALL = "all";
export const modes = [DOCUMENT_MODE_ALL];

export const {
  types,
  state,
  actions,
  reducer,
  selector: documentRequestSelector
} = makeModel(name, modes);
