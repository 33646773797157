import React from "react";
import { Text } from "ibolit-ui";
import MessageIcon from "./MessageIcon";
import styles from "./ChatsListItem.scss";

export const ChatStat = ({ isOwn, count_new, message }) => (
  <div className={styles.rightPanel}>
    {!!count_new && (
      <Text
        tag="span"
        variant="desc"
        colorVariant="secondary"
        className={styles.badge}
      >
        {count_new}
      </Text>
    )}
    <MessageIcon show={isOwn && !count_new} message={message} />
  </div>
);

export default ChatStat;
