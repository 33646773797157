import React, { useMemo } from "react";
import { Text } from "ibolit-ui";
import UserItem from "~/components/UserItem/UserItem";
import { CHAT_HEADER } from "~/locators/chats";
import styles from "./ChatHeader.scss";
import { getTzDate } from "~/utils/dateUtils";

const PartnerInfo = ({ user: { id, online, last_entered } }) => {
  const lastEntered = useMemo(() => {
    if (!online && last_entered) {
      return getTzDate(last_entered).format("DD.MM HH:mm");
    }
  }, [last_entered]);

  return (
    <UserItem
      testid={CHAT_HEADER.DOCTOR}
      user={id}
      noPadding
      className={styles.userItem}
      hasHoverEffect
      imageClass={styles.avatarIcon}
      sizeVariant="small"
      showOnlineStatus
      to={`/clinics/doctors/${id}`}
      disableBackgroundHover
      info={
        <Text
          testid={CHAT_HEADER.DOCTOR_ONLINE_STATUS}
          variant="caption"
          colorVariant={online ? "success" : "tertiary"}
        >
          {online
            ? "Врач на связи"
            : `Не в сети${lastEntered ? ` с ${lastEntered}` : ""}`}
        </Text>
      }
    />
  );
};

export default PartnerInfo;
