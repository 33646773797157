import React, {
  useCallback,
  useEffect,
  useState,
  useLayoutEffect
} from "react";
import cn from "classnames";
import { Box, Text, Container, Icon, Button } from "ibolit-ui";
import { useDispatch, useSelector } from "react-redux";
import { getTariffsByUserId } from "~/store/users/userSelectors";
import { useCloseModal, useReplaceModal } from "~/hooks/useModal";
import { DOCTORS_CHAT_VIEW } from "~/modals/DoctorsChat";
import styles from "./ChooseService.scss";
import userActions from "~/store/users/userActions";
import { CHOOSE_DATE_TIME } from "../ChooseDateTime/ChooseDateTime";
import Loader from "~/components/Loader";
import chatsActions from "~/store/chat/chatActions";
import { formatDurationFrom } from "~/utils/formatDuration";
import {
  getGroupedSlotsByDate,
  getSlotsLoading
} from "~/store/slots/slotsSelectors";
import { ModalHeader } from "~/components/ModalHeader/ModalHeader";
import newConsultationActions from "~/store/newConsultation/newConsultationActions";
import { getClinicById } from "~/store/clinics/clinicsSelectors";
import { STATUS_PAY } from "../NewConsultation/components/Statuses/StatusPay";

const defaultTestId = "ChooseService";

export const CHOOSE_SERVICE_VIEW = "choose_service_view";

const ChooseService = ({ doctor, clinicId, specialtyProps, noBack }) => {
  const [activeType, setActiveType] = useState("");
  const [isLoading, setLoading] = useState(false);
  const onCloseClick = useCloseModal(CHOOSE_SERVICE_VIEW);
  const onBackClick = useReplaceModal(CHOOSE_SERVICE_VIEW, DOCTORS_CHAT_VIEW, {
    clinicId,
    specialty: specialtyProps
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.userInfoRequest(doctor.id));
  }, []);

  const slots = useSelector(getGroupedSlotsByDate);

  const isSlotsLoading = useSelector(getSlotsLoading);

  const isShowPlaceholder = useCallback(() => {
    if (activeType === "offline" || activeType === "av") {
      const realActiveType = activeType === "av" ? "online" : activeType;
      if (Object.keys(slots[realActiveType] || {}).length === 0) {
        return false;
      }
      if (
        !doctor.self_appointment ||
        slots[realActiveType].length !== undefined
      ) {
        return false;
      }
    }
    if (tarrifs?.[activeType] && tarrifs[activeType].length === 0) {
      return false;
    }

    return true;
  }, [slots, activeType]);

  const hasPatientChat = useSelector(
    state => state.users.items[doctor.id]?.has_patient_chat
  );

  const isChatFree = useSelector(
    state => state.users.items[doctor.id]?.is_chat_free
  );

  const tarrifs = useSelector(state =>
    getTariffsByUserId(state, { userId: doctor.id })
  );

  const handleSelect = id => () => {
    setActiveType(id);
  };

  useLayoutEffect(() => {
    dispatch(userActions.tariffsRequest(doctor.id));
  }, []);

  const clinicPhone =
    useSelector(getClinicById(clinicId))?.phone || doctor.phone;

  if (!doctor || isLoading) {
    return <Loader loading={true} />;
  }

  const handleOpenChat = () => {
    setLoading(true);
    if (!hasPatientChat) {
      dispatch(chatsActions.createChat(doctor.id, true));
    } else {
      dispatch(chatsActions.checkChat(doctor));
    }
  };

  const renderServices = () => {
    const text = () => {
      if (isChatFree) return "Вы всегда можете написать этому врачу бесплатно";
      if (hasPatientChat) return "С этим врачом у вас уже открыт чат";
    };
    if ((isChatFree || hasPatientChat) && activeType === "chat") {
      return (
        <Container direction="column" className={styles.services}>
          <Text
            variant="body1"
            colorVariant="tertiary"
            className={styles.renderServicesText}
            testid={`${defaultTestId}-renderServicesText`}
          >
            {text()}
          </Text>
          <Button
            colorVariant="patient"
            className={styles.openChatButton}
            onClick={handleOpenChat}
            testid={`${defaultTestId}-openChatButton`}
          >
            Открыть чат
          </Button>
        </Container>
      );
    }

    return (
      <>
        {activeType ? (
          <Container direction="column" className={styles.services}>
            {isShowPlaceholder() ? (
              <Text
                variant="body1"
                colorVariant="tertiary"
                testid={`${defaultTestId}-service-title`}
              >
                Услуги
              </Text>
            ) : null}
            <Container direction="column">
              {isShowPlaceholder() ? (
                tarrifs[activeType].map((data, index) => (
                  <Service
                    {...data}
                    doctor={doctor}
                    slotType={activeType === "av" ? "online" : activeType}
                    clinicId={clinicId}
                    specialtyProps={specialtyProps}
                    noBack={noBack}
                    index={index}
                    key={data.id}
                  />
                ))
              ) : (
                <>
                  <Text
                    variant="h3"
                    colorVariant="primary"
                    className={styles.text}
                    testid={`${defaultTestId}-text`}
                  >
                    К врачу можно записаться только через регистратуру.
                  </Text>
                  <Text
                    variant="h3"
                    colorVariant="primary"
                    testid={`${defaultTestId}-text2`}
                  >
                    Вы можете позвонить в клинику и записаться на
                  </Text>
                  <Text
                    variant="h3"
                    colorVariant="primary"
                    testid={`${defaultTestId}-text3`}
                  >
                    консультацию или оставить заявку у администратора
                  </Text>
                  <Text
                    variant="h3"
                    colorVariant="pink"
                    testid={`${defaultTestId}-phone`}
                  >
                    {clinicPhone}
                  </Text>
                </>
              )}
            </Container>
          </Container>
        ) : null}
      </>
    );
  };

  return (
    <Box className={styles.container} isModalBlock>
      <div className={styles.header}>
        {!noBack && clinicId ? (
          <div
            className={styles.back}
            data-testid="back_modal_window_specialization"
          >
            <Icon variant="arrow" onClick={onBackClick} />
          </div>
        ) : null}
        <Text tag="span" variant="h2" testid="header_modal_window">
          Выберите услугу
        </Text>
        <div className={styles.close} data-testid="close_modal_window">
          <Icon variant="close" fill="var(--grey)" onClick={onCloseClick} />
        </div>
      </div>
      <ModalHeader
        doctor={doctor}
        specialty={doctor.specialty || doctor.specialties[0]}
      />
      <ReceptionsTypesContainer
        tarrifs={tarrifs}
        handleSelect={handleSelect}
        activeType={activeType}
        selfAppointment={doctor.self_appointment}
        isChatFree={isChatFree}
        isSlotsLoading={isSlotsLoading}
      />
      {renderServices()}
    </Box>
  );
};

const ReceptionsType = ({
  iconName,
  price,
  title,
  active,
  onClick,
  isDisabled,
  index
}) => (
  <Container
    direction="column"
    className={cn(
      styles.receptionsTypeContainer,
      active && styles["receptionsTypeContainer-active"]
    )}
    onClick={onClick}
  >
    <Container justify="beetween">
      <Icon
        variant={iconName}
        className={styles.icon}
        fill={
          isDisabled
            ? active
              ? "var(--silver)"
              : "var(--grey)"
            : "var(--pink)"
        }
        testid={`${defaultTestId}-ReceptionsType-icon-${index}`}
      />
      {isDisabled ? null : (
        <Text
          variant="h5"
          className={styles.servicePrice}
          testid={`${defaultTestId}-ReceptionsType-servicePrice-${index}`}
        >
          от {price} ₽
        </Text>
      )}
    </Container>
    <Text
      className={styles.receptionsTypeTitle}
      variant="sub2"
      colorVariant={isDisabled ? (active ? "silver" : "tertiary") : "primary"}
      testid={`${defaultTestId}-ReceptionsType-receptionsTypeTitle-${index}`}
    >
      {title}
    </Text>
  </Container>
);

export const receptionsTypes = [
  {
    id: "chat",
    iconName: "message-bubble-double",
    title: "Написать в чат"
  },
  {
    id: "av",
    iconName: "meeting-camera",
    title: "Видеоконсультация"
  },
  {
    id: "offline",
    iconName: "hospital",
    title: "Прием в клинике"
  }
];

const ReceptionsTypesContainer = ({
  tarrifs,
  activeType,
  handleSelect,
  selfAppointment,
  isChatFree,
  isSlotsLoading
}) => {
  const slots = useSelector(getGroupedSlotsByDate);

  if (!tarrifs) {
    return null;
  }

  return (
    <Container direction="column" className={styles.receptionsTypes}>
      <Text
        variant="body1"
        colorVariant="tertiary"
        className={styles.receptionsTypeText}
        testid={`${defaultTestId}-receptionsTypeText`}
      >
        Типы приема
      </Text>
      <Container>
        {receptionsTypes.map((data, i) => (
          <ReceptionsType
            {...data}
            active={activeType === data.id}
            onClick={handleSelect(data.id)}
            price={
              isChatFree
                ? 0
                : tarrifs[data.id].reduce((acc, cur) => {
                    if (cur.price < acc) {
                      return cur.price;
                    }
                    return acc;
                  }, Number.MAX_SAFE_INTEGER)
            }
            isDisabled={
              (data.id !== "chat" &&
                (!selfAppointment ||
                  Object.keys(
                    slots[data.id === "av" ? "online" : data.id] || {}
                  ).length === 0)) ||
              (!tarrifs[data.id].length && !isChatFree)
            }
            index={i}
            key={data.id}
          />
        ))}
      </Container>
    </Container>
  );
};

const Service = ({
  name,
  description,
  duration,
  id,
  doctor,
  price,
  slotType,
  clinicId,
  specialtyProps,
  noBack,
  can_apply_promocode
}) => {
  const dispatch = useDispatch();
  const replaceDateTimeModal = useReplaceModal(
    CHOOSE_SERVICE_VIEW,
    CHOOSE_DATE_TIME
  );
  const onSelectService = () => {
    dispatch(
      newConsultationActions.fillDateTimeData({
        id,
        doctor,
        slotType,
        price,
        clinicId,
        specialtyProps,
        noBack
      })
    );
    replaceDateTimeModal();
  };

  const showPayModal = useReplaceModal(CHOOSE_SERVICE_VIEW, STATUS_PAY, {
    doctor,
    tariffId: id,
    slotType,
    clinicId,
    price,
    noBack,
    promoModalProps: {
      price,
      tariffId: id,
      slotType
    },
    canApplyPromocode: can_apply_promocode
  });

  const handleSelectService = () => {
    if (slotType === "chat") {
      showPayModal();
    } else {
      onSelectService();
    }
  };

  return (
    <Container
      direction="column"
      className={styles.sericeContainer}
      onClick={handleSelectService}
      testid={`${defaultTestId}-Service-sericeContainer`}
    >
      <Text variant="h3" testid={`${defaultTestId}-Service-name`}>
        {name}
      </Text>
      <Text
        variant="body1"
        colorVariant="tertiary"
        testid={`${defaultTestId}-Service-description`}
      >
        {description}
      </Text>
      <Text
        variant="body1"
        colorVariant="tertiary"
        className={styles.serviceDuration}
        testid={`${defaultTestId}-Service-serviceDuration`}
      >
        {formatDurationFrom("Длительность", duration)}
      </Text>
      <Text
        variant="h3"
        colorVariant="pink"
        className={styles.servicePrice}
        testid={`${defaultTestId}-Service-servicePrice`}
      >
        {price} ₽
      </Text>
    </Container>
  );
};

export default ChooseService;
