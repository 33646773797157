import types from "./pushNotificationTypes";

export default {
  registration: () => ({
    type: types.PUSH_NOTIFICATION_REGISTRATION
  }),
  registrationSuccess: () => ({
    type: types.PUSH_NOTIFICATION_REGISTRATION_SUCCESS
  }),
  error: error => ({
    type: types.PUSH_NOTIFICATION_ERROR,
    msg: error.message
  })
};
