import { types } from "~/store/chat/chatModel";

export default {
  ...types,
  INFO_REQUEST: "chats/INFO_REQUEST",
  INFO_SUCCESS: "chats/INFO_SUCCESS",
  INFO_FAILURE: "chats/INFO_FAILURE",
  UP_CHAT: "chats/UP_CHAT",
  CHAT_META_REQUEST: "chats/META_REQUEST",
  CHAT_META_REQUEST_SUCCESS: "chats/META_REQUEST_SUCCESS",

  CHATS_CREATE_CHAT: "chats/CREATE_CHAT",
  CHATS_CHECK_CHAT: "chats/CHECK_CHAT",
  ATTACH_SYMPTOMS: "chats/ATTACH_SYMPTOMS"
};
