import React from "react";
import { connect } from "react-redux";
import { getIsUserAuthorized } from "~/store/auth/authSelectors";
import IBolitAnimationLoader from "~/layouts/App/IBolitAnimationLoader";
import NavigationBar from "~/layouts/App/NavigationBar";
import LightBoxProvider from "~/layouts/App/LightBoxProvider";
import InMaintain from "./InMaintain";
import styles from "./AppContainer.scss";

const AppContainer = ({
  isAuthorized,
  pageName,
  children,
  isReady,
  inMaintain
}) => {
  const hidden = !isAuthorized || pageName === "notfound";
  return (
    <>
      {inMaintain ? (
        <InMaintain />
      ) : (
        <LightBoxProvider>
          {isReady && (
            <div className={styles.base}>
              {!hidden && (
                <div className={styles.header}>
                  <NavigationBar />
                </div>
              )}
              <div className={styles.main}>{children}</div>
            </div>
          )}
          {!isReady && <IBolitAnimationLoader fill="var(--pink)" />}
        </LightBoxProvider>
      )}
    </>
  );
};

const appStateSelector = ({ auth, appStarted, router }) => {
  const path = router.location.pathname || "chats";
  const [pageName] = path.split("/");
  return {
    isAuthorized: getIsUserAuthorized({ auth }),
    pageName,
    isReady: appStarted.appReady,
    inMaintain: appStarted.getIn(["errors", "time", "status"]) === 500
  };
};

export default connect(appStateSelector)(AppContainer);
