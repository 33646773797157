const format = (value, unit) => `${value.toFixed(1)} ${unit}`;

export const getFormattedFileSize = bytes => {
  if (bytes < 1024) {
    return format(bytes, "Bytes");
  }

  if (bytes < 1048576) {
    return format(bytes / 1024, "KB");
  }

  if (bytes < 1073741824) {
    return format(bytes / 1048576, "MB");
  }

  return format(bytes / 1073741824, "GB");
};

export default getFormattedFileSize;
