import React from "react";

const ErrorSvg = ({ className }) => (
  <svg
    className={className}
    width="279"
    height="183"
    viewBox="0 0 279 183"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip-ufo)">
      <g opacity="0.8">
        <path
          opacity="0.8"
          d="M143.485 81.6306C170.352 81.6306 192.131 64.7585 192.131 43.9457C192.131 23.1329 170.352 6.26074 143.485 6.26074C116.619 6.26074 94.8398 23.1329 94.8398 43.9457C94.8398 64.7585 116.619 81.6306 143.485 81.6306Z"
          fill="url(#paint0_linear)"
        />
      </g>
      <path
        d="M143.486 80.7935C169.756 80.7935 191.052 64.2961 191.052 43.9456C191.052 23.595 169.756 7.09766 143.486 7.09766C117.216 7.09766 95.9194 23.595 95.9194 43.9456C95.9194 64.2961 117.216 80.7935 143.486 80.7935Z"
        fill="#FF537C"
      />
      <path
        opacity="0.1"
        d="M103.609 48.0412C103.609 27.6908 124.902 11.1933 151.171 11.1933C159.327 11.1484 167.402 12.8151 174.873 16.0858C166.513 10.4393 155.576 7.0083 143.605 7.0083C117.336 7.0083 96.0391 23.5058 96.0391 43.8562C96.0391 57.5206 105.641 69.4479 119.907 75.8117C109.919 69.0559 103.609 59.1215 103.609 48.0412Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M143.486 58.7559L196.217 150.089L248.948 241.418H143.486H38.0239L90.7549 150.089L143.486 58.7559Z"
        fill="#FF537C"
      />
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M155.997 188.066L160.093 175.913C161.501 171.68 161.512 167.108 160.125 162.869C158.738 158.629 156.025 154.948 152.387 152.367L152.42 152.347C150.809 151.213 149.043 150.318 147.176 149.69L147.119 149.67L147.056 149.65C145.244 149.037 143.354 148.682 141.443 148.594C140.767 148.559 140.089 148.559 139.413 148.594C138.241 148.655 137.075 148.817 135.929 149.076C135.597 149.149 135.265 149.232 134.963 149.321C134.661 149.411 134.322 149.507 134.006 149.61C133.375 149.82 132.754 150.055 132.146 150.324C131.841 150.457 131.538 150.597 131.243 150.746C130.349 151.188 129.489 151.694 128.669 152.261C128.393 152.45 128.124 152.646 127.858 152.845C124.575 155.342 122.117 158.767 120.804 162.677L114.367 181.825C113.354 184.828 113.049 188.025 113.476 191.165C113.543 191.646 113.626 192.125 113.722 192.6C113.819 193.075 113.938 193.543 114.054 194.008C114.144 194.317 114.237 194.626 114.34 194.931C114.493 195.386 114.672 195.841 114.842 196.286C114.964 196.582 115.094 196.878 115.23 197.17C115.499 197.751 115.798 198.319 116.12 198.874C116.442 199.428 116.785 199.97 117.17 200.495C117.449 200.887 117.744 201.272 118.05 201.644C118.462 202.142 118.894 202.64 119.352 203.082C121.336 205.082 123.71 206.653 126.327 207.696L126.44 207.742C126.58 207.493 126.736 207.252 126.905 207.021C127.901 205.656 129.635 204.696 131.286 205.055C131.406 205.082 131.525 205.118 131.645 205.155C132.482 205.45 133.305 205.978 134.112 206.237C134.406 206.369 134.725 206.436 135.047 206.432C135.369 206.428 135.686 206.353 135.976 206.214C137.59 205.354 136.992 202.64 138.377 201.451C138.442 201.396 138.509 201.345 138.58 201.298C138.56 201.274 138.542 201.249 138.527 201.222C139.596 200.558 141.078 201 142.31 201.428C143.638 201.896 145.372 202.212 146.295 201.126C146.92 200.382 146.873 199.249 147.468 198.469C148.159 197.572 149.461 197.472 150.587 197.632L150.643 197.648C152.802 198.538 155.16 197.06 154.994 194.732C154.798 191.626 155.775 188.7 155.997 188.066Z"
          fill="url(#paint1_linear)"
        />
      </g>
      <path
        d="M132.056 173.312C133.026 171.983 134.69 171.053 136.284 171.415C137.879 171.777 139.39 173.295 140.808 172.534C142.748 171.498 141.373 167.688 144.521 167.406C145.178 167.363 145.835 167.477 146.438 167.738C147.767 168.28 149.846 168.891 150.869 167.672C151.47 166.954 151.427 165.862 152.001 165.118C152.666 164.254 153.931 164.144 155.011 164.31L155.064 164.327C157.146 165.187 159.425 163.759 159.262 161.514C159.053 158.525 160.003 155.708 160.209 155.097L164.154 143.369C165.833 138.341 165.453 132.854 163.097 128.105C160.741 123.357 156.602 119.735 151.583 118.03C146.556 116.353 141.069 116.735 136.323 119.091C131.576 121.448 127.955 125.587 126.25 130.605L120.029 149.048C118.412 153.899 118.709 159.184 120.859 163.824C123.008 168.463 126.848 172.106 131.594 174.009C131.735 173.768 131.889 173.535 132.056 173.312Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M139.41 130.924C140.604 130.924 141.572 129.956 141.572 128.761C141.572 127.567 140.604 126.599 139.41 126.599C138.216 126.599 137.248 127.567 137.248 128.761C137.248 129.956 138.216 130.924 139.41 130.924Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M151.706 135.058C152.9 135.058 153.868 134.09 153.868 132.896C153.868 131.702 152.9 130.734 151.706 130.734C150.512 130.734 149.543 131.702 149.543 132.896C149.543 134.09 150.512 135.058 151.706 135.058Z"
        fill="black"
      />
      <path
        d="M144.857 139.737C145.428 138.04 144.974 136.355 143.842 135.975C142.71 135.594 141.33 136.66 140.759 138.357C140.188 140.054 140.642 141.738 141.774 142.119C142.906 142.5 144.286 141.433 144.857 139.737Z"
        fill="#E0E0E0"
      />
      <path
        d="M183.519 165.168C185.309 165.168 186.761 163.717 186.761 161.926C186.761 160.136 185.309 158.685 183.519 158.685C181.729 158.685 180.277 160.136 180.277 161.926C180.277 163.717 181.729 165.168 183.519 165.168Z"
        fill="white"
      />
      <path
        d="M193.789 158.449C194.684 158.449 195.41 157.723 195.41 156.828C195.41 155.933 194.684 155.207 193.789 155.207C192.894 155.207 192.168 155.933 192.168 156.828C192.168 157.723 192.894 158.449 193.789 158.449Z"
        fill="white"
      />
      <g opacity="0.5">
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M160.315 57.5337L164.411 45.3806C165.819 41.1483 165.83 36.5755 164.443 32.3363C163.055 28.0971 160.343 24.4158 156.705 21.835L156.738 21.815C155.127 20.681 153.361 19.786 151.494 19.1579L151.437 19.138L151.374 19.118C149.562 18.505 147.672 18.1495 145.761 18.0618C145.085 18.0269 144.407 18.0269 143.731 18.0618C142.558 18.1232 141.393 18.2843 140.247 18.5434C139.915 18.6165 139.583 18.6995 139.281 18.7892C138.978 18.8789 138.64 18.9752 138.324 19.0782C137.693 19.2874 137.072 19.5232 136.464 19.7923C136.158 19.9251 135.856 20.0646 135.561 20.2141C134.667 20.6554 133.806 21.1616 132.987 21.7287C132.711 21.918 132.442 22.114 132.176 22.3132C128.893 24.8099 126.435 28.235 125.121 32.1447L118.684 51.2927C117.671 54.2957 117.367 57.4923 117.794 60.6326C117.861 61.1142 117.944 61.5924 118.04 62.0674C118.136 62.5424 118.256 63.0107 118.372 63.4757C118.462 63.7846 118.555 64.0935 118.658 64.3991C118.811 64.8541 118.99 65.3091 119.159 65.7542C119.282 66.0498 119.412 66.3454 119.548 66.6377C119.817 67.219 120.116 67.7869 120.438 68.3416C120.76 68.8963 121.102 69.4377 121.488 69.9624C121.767 70.3544 122.062 70.7397 122.368 71.1117C122.78 71.6099 123.212 72.0915 123.67 72.5498C125.654 74.5499 128.028 76.1203 130.645 77.1633L130.758 77.2098C130.898 76.9605 131.054 76.7198 131.223 76.4891C132.219 75.1239 133.953 74.1641 135.604 74.5228C135.723 74.5493 135.843 74.5859 135.963 74.6224C136.8 74.918 137.623 75.4461 138.43 75.7052C138.724 75.8371 139.043 75.9034 139.365 75.8994C139.686 75.8953 140.003 75.8211 140.294 75.6819C141.908 74.8217 141.31 72.1081 142.695 70.919C142.76 70.8642 142.827 70.8131 142.898 70.7662C142.878 70.7421 142.86 70.7166 142.845 70.6898C143.914 70.0256 145.395 70.4673 146.628 70.8958C147.956 71.3641 149.69 71.6796 150.613 70.5935C151.238 69.8495 151.191 68.7169 151.786 67.9364C152.477 67.0396 153.779 66.9399 154.905 67.0994H154.961C157.12 67.9895 159.478 66.5115 159.312 64.1832C159.116 61.0942 160.093 58.1681 160.315 57.5337Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <path
          opacity="0.5"
          d="M131.598 75.4658C132.568 74.1372 134.232 73.2072 135.826 73.5693C137.421 73.9313 138.932 75.4492 140.35 74.6886C142.29 73.6523 140.915 69.8426 144.063 69.5603C144.72 69.5169 145.377 69.6308 145.98 69.8925C147.309 70.4338 149.388 71.045 150.411 69.826C151.012 69.1086 150.969 68.0158 151.543 67.2718C152.208 66.4083 153.473 66.2987 154.553 66.4647L154.606 66.4813C156.688 67.3416 158.967 65.9134 158.804 63.6681C158.595 60.6788 159.545 57.8622 159.751 57.2511L163.696 45.5231C165.375 40.4955 164.995 35.0078 162.639 30.2596C160.283 25.5115 156.144 21.889 151.125 20.1839C146.098 18.5078 140.611 18.8895 135.865 21.2457C131.118 23.602 127.497 27.7411 125.792 32.7589L119.571 51.2028C117.954 56.0536 118.251 61.3387 120.401 65.9781C122.55 70.6174 126.39 74.2607 131.136 76.1633C131.277 75.922 131.431 75.6891 131.598 75.4658Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M138.952 33.0779C140.146 33.0779 141.114 32.1099 141.114 30.9157C141.114 29.7215 140.146 28.7534 138.952 28.7534C137.758 28.7534 136.79 29.7215 136.79 30.9157C136.79 32.1099 137.758 33.0779 138.952 33.0779Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M151.248 37.2132C152.442 37.2132 153.41 36.2451 153.41 35.0509C153.41 33.8567 152.442 32.8887 151.248 32.8887C150.054 32.8887 149.085 33.8567 149.085 35.0509C149.085 36.2451 150.054 37.2132 151.248 37.2132Z"
          fill="black"
        />
      </g>
      <path
        opacity="0.1"
        d="M189.162 33.0249C189.78 35.4115 190.091 37.8669 190.089 40.332C190.089 60.6824 168.795 77.18 142.526 77.18C121.026 77.1667 102.861 66.1196 96.9858 50.9606C101.35 67.8235 120.568 80.5213 143.622 80.5213C169.891 80.5213 191.188 64.0238 191.188 43.6734C191.181 40.0287 190.494 36.4174 189.162 33.0249Z"
        fill="black"
      />
      <g opacity="0.8">
        <path
          opacity="0.8"
          d="M143.486 85.7226C197.816 85.7226 241.86 74.2114 241.86 60.0115C241.86 45.8116 197.816 34.3003 143.486 34.3003C89.1554 34.3003 45.1118 45.8116 45.1118 60.0115C45.1118 74.2114 89.1554 85.7226 143.486 85.7226Z"
          fill="url(#paint3_linear)"
        />
      </g>
      <path
        d="M143.486 35.1509C90.9475 35.1509 48.3535 46.281 48.3535 60.0151H238.618C238.618 46.281 196.024 35.1509 143.486 35.1509Z"
        fill="white"
      />
      <path
        d="M143.486 84.8755C196.024 84.8755 238.618 73.7454 238.618 60.0146H48.3535C48.3535 73.7454 90.9475 84.8755 143.486 84.8755Z"
        fill="white"
      />
      <path
        opacity="0.05"
        d="M143.486 84.8755C196.024 84.8755 238.618 73.7454 238.618 60.0146H48.3535C48.3535 73.7454 90.9475 84.8755 143.486 84.8755Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M183.17 53.5249H103.801C100.642 53.5249 98.0815 56.0856 98.0815 59.2444V60.7789C98.0815 63.9377 100.642 66.4984 103.801 66.4984H183.17C186.329 66.4984 188.889 63.9377 188.889 60.7789V59.2444C188.889 56.0856 186.329 53.5249 183.17 53.5249Z"
        fill="black"
      />
      <path
        opacity="0.5"
        d="M44.4707 19.2013C44.1963 19.0816 43.9753 18.8652 43.8498 18.5934C43.7243 18.3216 43.703 18.0131 43.7898 17.7266C43.8037 17.6823 43.8126 17.6367 43.8164 17.5904C43.8248 17.459 43.7906 17.3283 43.7188 17.2179C43.647 17.1075 43.5414 17.0232 43.4178 16.9777C43.2942 16.9321 43.1592 16.9277 43.0329 16.9651C42.9067 17.0025 42.7958 17.0797 42.717 17.1852C42.6904 17.2224 42.6681 17.2625 42.6505 17.3048C42.5314 17.5797 42.3151 17.801 42.0431 17.9266C41.7711 18.0522 41.4623 18.0732 41.1758 17.9857C41.1315 17.9721 41.0858 17.9632 41.0396 17.9591C40.9082 17.9506 40.7775 17.9848 40.6671 18.0566C40.5567 18.1285 40.4724 18.234 40.4269 18.3576C40.3813 18.4812 40.3769 18.6162 40.4143 18.7425C40.4517 18.8688 40.5289 18.9796 40.6344 19.0585C40.6711 19.0868 40.7113 19.1102 40.754 19.1282C41.0284 19.248 41.2493 19.4643 41.3748 19.7361C41.5003 20.008 41.5217 20.3164 41.4349 20.603C41.4213 20.6462 41.4124 20.6907 41.4083 20.7358C41.399 20.8675 41.4327 20.9988 41.5042 21.1098C41.5757 21.2208 41.6813 21.3057 41.8051 21.3517C41.9289 21.3977 42.0642 21.4023 42.1909 21.365C42.3176 21.3276 42.4287 21.2502 42.5077 21.1443C42.535 21.1064 42.5573 21.0651 42.5741 21.0215C42.6962 20.7496 42.9137 20.5319 43.1854 20.4095C43.4572 20.2871 43.7643 20.2685 44.0488 20.3572C44.0932 20.3708 44.1388 20.3797 44.185 20.3837C44.3165 20.3922 44.4471 20.358 44.5576 20.2862C44.668 20.2144 44.7522 20.1088 44.7978 19.9852C44.8433 19.8616 44.8478 19.7266 44.8103 19.6003C44.7729 19.474 44.6957 19.3632 44.5902 19.2843C44.5543 19.2514 44.5141 19.2234 44.4707 19.2013Z"
        fill="#4D8AF0"
      />
      <path
        opacity="0.5"
        d="M213.531 120.837C213.26 120.714 213.043 120.497 212.92 120.225C212.798 119.954 212.779 119.647 212.867 119.362C212.881 119.318 212.89 119.272 212.893 119.226C212.902 119.095 212.868 118.964 212.796 118.854C212.724 118.743 212.619 118.659 212.495 118.613C212.371 118.568 212.236 118.563 212.11 118.601C211.984 118.638 211.873 118.715 211.794 118.821C211.767 118.858 211.745 118.898 211.728 118.941C211.609 119.215 211.392 119.437 211.12 119.562C210.848 119.688 210.539 119.709 210.253 119.621C210.209 119.608 210.163 119.599 210.117 119.595C209.985 119.586 209.855 119.621 209.744 119.692C209.634 119.764 209.55 119.87 209.504 119.993C209.458 120.117 209.454 120.252 209.491 120.378C209.529 120.505 209.606 120.615 209.712 120.694C209.748 120.723 209.788 120.746 209.831 120.764C210.103 120.887 210.32 121.104 210.442 121.376C210.564 121.647 210.583 121.954 210.495 122.239C210.482 122.282 210.473 122.326 210.469 122.372C210.46 122.503 210.493 122.634 210.565 122.746C210.636 122.857 210.742 122.941 210.866 122.987C210.989 123.033 211.125 123.038 211.251 123.001C211.378 122.963 211.489 122.886 211.568 122.78C211.596 122.742 211.618 122.701 211.635 122.657C211.757 122.385 211.974 122.168 212.246 122.045C212.518 121.923 212.825 121.904 213.109 121.993C213.154 122.006 213.199 122.015 213.246 122.019C213.377 122.028 213.508 121.994 213.618 121.922C213.729 121.85 213.813 121.745 213.858 121.621C213.904 121.497 213.908 121.362 213.871 121.236C213.833 121.11 213.756 120.999 213.651 120.92C213.615 120.887 213.575 120.859 213.531 120.837Z"
        fill="#4D8AF0"
      />
      <path
        opacity="0.5"
        d="M26.8672 168.001C26.5927 167.881 26.3718 167.665 26.2463 167.393C26.1208 167.121 26.0994 166.813 26.1863 166.526C26.2002 166.482 26.2091 166.436 26.2128 166.39C26.2213 166.259 26.1871 166.128 26.1153 166.018C26.0435 165.907 25.9379 165.823 25.8143 165.777C25.6907 165.732 25.5557 165.728 25.4294 165.765C25.3031 165.802 25.1923 165.879 25.1134 165.985C25.0868 166.022 25.0646 166.062 25.047 166.105C24.9278 166.379 24.7116 166.601 24.4396 166.726C24.1676 166.852 23.8588 166.873 23.5723 166.785C23.528 166.772 23.4823 166.763 23.4361 166.759C23.3047 166.75 23.174 166.785 23.0636 166.856C22.9532 166.928 22.8689 167.034 22.8234 167.157C22.7778 167.281 22.7734 167.416 22.8108 167.542C22.8482 167.669 22.9254 167.779 23.0309 167.858C23.0676 167.887 23.1078 167.91 23.1505 167.928C23.4249 168.048 23.6458 168.264 23.7713 168.536C23.8968 168.808 23.9182 169.116 23.8314 169.403C23.8177 169.446 23.8088 169.491 23.8048 169.536C23.7955 169.667 23.8291 169.799 23.9007 169.91C23.9722 170.021 24.0778 170.105 24.2016 170.151C24.3253 170.197 24.4607 170.202 24.5874 170.165C24.714 170.127 24.8252 170.05 24.9042 169.944C24.9315 169.906 24.9538 169.865 24.9706 169.821C25.0927 169.549 25.3102 169.332 25.5819 169.209C25.8537 169.087 26.1608 169.068 26.4453 169.157C26.4897 169.171 26.5353 169.179 26.5815 169.184C26.713 169.192 26.8436 169.158 26.954 169.086C27.0645 169.014 27.1487 168.909 27.1943 168.785C27.2398 168.661 27.2442 168.526 27.2068 168.4C27.1694 168.274 27.0922 168.163 26.9867 168.084C26.9508 168.051 26.9106 168.023 26.8672 168.001Z"
        fill="#4D8AF0"
      />
      <path
        opacity="0.5"
        d="M8.59909 58.0617C8.32771 57.939 8.11045 57.7214 7.98814 57.4499C7.86582 57.1783 7.84685 56.8715 7.9348 56.5869C7.94874 56.5427 7.95766 56.497 7.96137 56.4508C7.96985 56.3193 7.93563 56.1887 7.86382 56.0782C7.79201 55.9678 7.68644 55.8836 7.56285 55.838C7.43926 55.7925 7.30426 55.788 7.17797 55.8254C7.05167 55.8628 6.94084 55.94 6.86198 56.0456C6.83538 56.0828 6.8131 56.1229 6.79555 56.1651C6.67638 56.44 6.46014 56.6614 6.18813 56.787C5.91611 56.9126 5.60736 56.9336 5.32084 56.846C5.2765 56.8324 5.23085 56.8235 5.18466 56.8195C5.05321 56.811 4.92255 56.8452 4.81213 56.917C4.70171 56.9888 4.61744 57.0944 4.57189 57.218C4.52634 57.3416 4.52193 57.4766 4.55934 57.6029C4.59674 57.7292 4.67394 57.84 4.77944 57.9188C4.81613 57.9471 4.85632 57.9706 4.89901 57.9886C5.17343 58.1083 5.39435 58.3247 5.51986 58.5965C5.64536 58.8683 5.66673 59.1768 5.57991 59.4633C5.56628 59.5065 5.55737 59.551 5.55334 59.5962C5.54405 59.7279 5.57768 59.8591 5.6492 59.9701C5.72073 60.0811 5.82629 60.166 5.95009 60.212C6.07388 60.258 6.20925 60.2627 6.33592 60.2253C6.46258 60.1879 6.57373 60.1105 6.65273 60.0047C6.68002 59.9667 6.70235 59.9254 6.71916 59.8818C6.84127 59.6099 7.05874 59.3922 7.33047 59.2698C7.6022 59.1474 7.90935 59.1288 8.19387 59.2175C8.23821 59.2311 8.28385 59.24 8.33005 59.2441C8.4615 59.2526 8.59216 59.2183 8.70258 59.1465C8.813 59.0747 8.89726 58.9692 8.94281 58.8456C8.98837 58.722 8.99277 58.587 8.95537 58.4607C8.91797 58.3344 8.84077 58.2236 8.73527 58.1447C8.69425 58.1104 8.64835 58.0824 8.59909 58.0617Z"
        fill="#4D8AF0"
      />
      <path
        opacity="0.5"
        d="M210.21 2.26186C209.938 2.13918 209.721 1.92163 209.599 1.65009C209.476 1.37855 209.457 1.07168 209.545 0.787145C209.559 0.742894 209.568 0.697211 209.572 0.650965C209.58 0.51952 209.546 0.388854 209.474 0.278434C209.403 0.168015 209.297 0.0837517 209.173 0.0381989C209.05 -0.00735394 208.915 -0.0117578 208.789 0.0256427C208.662 0.0630432 208.551 0.140246 208.473 0.24575C208.446 0.282966 208.424 0.32308 208.406 0.365322C208.284 0.637181 208.067 0.854887 207.795 0.977287C207.523 1.09969 207.216 1.1183 206.931 1.02961C206.887 1.01602 206.841 1.00711 206.795 1.00304C206.652 0.973002 206.503 0.995321 206.375 1.06594C206.247 1.13655 206.148 1.25078 206.097 1.38786C206.046 1.52495 206.046 1.6758 206.096 1.81302C206.147 1.95023 206.245 2.06471 206.373 2.13564C206.41 2.16395 206.45 2.18739 206.493 2.20539C206.764 2.32807 206.982 2.54562 207.104 2.81716C207.226 3.08871 207.245 3.39557 207.157 3.68011C207.144 3.72331 207.135 3.76785 207.131 3.81297C207.117 3.94973 207.149 4.08724 207.223 4.20326C207.297 4.31929 207.407 4.40705 207.537 4.45236C207.667 4.49768 207.808 4.4979 207.938 4.453C208.068 4.40809 208.179 4.32068 208.253 4.2049C208.281 4.16693 208.303 4.12563 208.32 4.082C208.442 3.81014 208.659 3.59244 208.931 3.47004C209.203 3.34764 209.51 3.32902 209.794 3.41772C209.839 3.4313 209.884 3.44021 209.931 3.44429C210.062 3.45276 210.193 3.41855 210.303 3.34673C210.414 3.27492 210.498 3.16936 210.543 3.04577C210.589 2.92218 210.593 2.78718 210.556 2.66088C210.519 2.53458 210.441 2.42375 210.336 2.34489C210.298 2.31142 210.255 2.28346 210.21 2.26186Z"
        fill="#4D8AF0"
      />
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M37.5326 85.6431H36.5361V91.2895H37.5326V85.6431Z"
          fill="#47E6B1"
        />
        <path
          opacity="0.5"
          d="M39.8574 88.9647V87.9683H34.211V88.9647H39.8574Z"
          fill="#47E6B1"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M276.675 59.0718H275.679V64.7182H276.675V59.0718Z"
          fill="#47E6B1"
        />
        <path
          opacity="0.5"
          d="M279 62.3934V61.397H273.354V62.3934H279Z"
          fill="#47E6B1"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M243.461 151.739H242.464V157.386H243.461V151.739Z"
          fill="#47E6B1"
        />
        <path
          opacity="0.5"
          d="M245.786 155.061V154.064H240.139V155.061H245.786Z"
          fill="#47E6B1"
        />
      </g>
      <path
        opacity="0.5"
        d="M240.139 29.1786C241.24 29.1786 242.132 28.2864 242.132 27.1857C242.132 26.0851 241.24 25.1929 240.139 25.1929C239.039 25.1929 238.146 26.0851 238.146 27.1857C238.146 28.2864 239.039 29.1786 240.139 29.1786Z"
        fill="#F55F44"
      />
      <path
        opacity="0.5"
        d="M1.99286 6.2611C3.09348 6.2611 3.98571 5.36887 3.98571 4.26825C3.98571 3.16762 3.09348 2.27539 1.99286 2.27539C0.892233 2.27539 0 3.16762 0 4.26825C0 5.36887 0.892233 6.2611 1.99286 6.2611Z"
        fill="#F55F44"
      />
      <path
        opacity="0.5"
        d="M61.7785 120.85C62.8791 120.85 63.7714 119.958 63.7714 118.857C63.7714 117.756 62.8791 116.864 61.7785 116.864C60.6779 116.864 59.7856 117.756 59.7856 118.857C59.7856 119.958 60.6779 120.85 61.7785 120.85Z"
        fill="#F55F44"
      />
      <path
        opacity="0.05"
        d="M77.7216 53.6178C83.9584 53.6178 89.0144 52.5768 89.0144 51.2928C89.0144 50.0087 83.9584 48.9678 77.7216 48.9678C71.4847 48.9678 66.4287 50.0087 66.4287 51.2928C66.4287 52.5768 71.4847 53.6178 77.7216 53.6178Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M77.7216 71.2213C83.9584 71.2213 89.0144 70.1804 89.0144 68.8963C89.0144 67.6122 83.9584 66.5713 77.7216 66.5713C71.4847 66.5713 66.4287 67.6122 66.4287 68.8963C66.4287 70.1804 71.4847 71.2213 77.7216 71.2213Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M206.261 53.6178C212.498 53.6178 217.553 52.5768 217.553 51.2928C217.553 50.0087 212.498 48.9678 206.261 48.9678C200.024 48.9678 194.968 50.0087 194.968 51.2928C194.968 52.5768 200.024 53.6178 206.261 53.6178Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M206.261 71.2213C212.498 71.2213 217.553 70.1804 217.553 68.8963C217.553 67.6122 212.498 66.5713 206.261 66.5713C200.024 66.5713 194.968 67.6122 194.968 68.8963C194.968 70.1804 200.024 71.2213 206.261 71.2213Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M143.486 43.6534C149.723 43.6534 154.779 42.6125 154.779 41.3284C154.779 40.0444 149.723 39.0034 143.486 39.0034C137.249 39.0034 132.193 40.0444 132.193 41.3284C132.193 42.6125 137.249 43.6534 143.486 43.6534Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M151.789 19.4073C158.026 19.4073 163.082 18.3664 163.082 17.0823C163.082 15.7983 158.026 14.7573 151.789 14.7573C145.553 14.7573 140.497 15.7983 140.497 17.0823C140.497 18.3664 145.553 19.4073 151.789 19.4073Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M130.2 29.3712C136.437 29.3712 141.493 28.3303 141.493 27.0462C141.493 25.7621 136.437 24.7212 130.2 24.7212C123.963 24.7212 118.907 25.7621 118.907 27.0462C118.907 28.3303 123.963 29.3712 130.2 29.3712Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M171.718 33.6891C177.955 33.6891 183.011 32.6481 183.011 31.3641C183.011 30.08 177.955 29.0391 171.718 29.0391C165.481 29.0391 160.425 30.08 160.425 31.3641C160.425 32.6481 165.481 33.6891 171.718 33.6891Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M143.486 78.1964C149.723 78.1964 154.779 77.1554 154.779 75.8714C154.779 74.5873 149.723 73.5464 143.486 73.5464C137.249 73.5464 132.193 74.5873 132.193 75.8714C132.193 77.1554 137.249 78.1964 143.486 78.1964Z"
        fill="black"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="143.486"
        y1="81.6339"
        x2="143.486"
        y2="6.26074"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808080" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
        <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="-15643.4"
        y1="114342"
        x2="-12132.2"
        y2="103918"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808080" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
        <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="-13604.5"
        y1="91316.2"
        x2="-10093.3"
        y2="80892.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808080" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
        <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="85040.3"
        y1="13305.9"
        x2="85040.3"
        y2="5344.69"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808080" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
        <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
      </linearGradient>
      <clipPath id="clip-ufo">
        <rect width="279" height="183" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ErrorSvg;
