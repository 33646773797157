import React from "react";
import PropTypes from "prop-types";
import ChangePhoneFormContainer from "../containers/ChangePhoneFormContainer";
import ChangeEmailFormContainer from "../containers/ChangeEmailFormContainer";
import ChangePasswordFormContainer from "../containers/ChangePasswordFormContainer";
import ConfirmForm from "../containers/ConfirmFormContainer";
import { formScenes } from "../types";

const sceneComponentMap = {
  [formScenes.email]: ChangeEmailFormContainer,
  [formScenes.phone]: ChangePhoneFormContainer,
  [formScenes.password]: ChangePasswordFormContainer,
  [formScenes.confirm]: ConfirmForm
};

const FormSwitcher = ({ scene, onCloseClick }) => {
  const Component = sceneComponentMap[scene];
  return <Component onCloseClick={onCloseClick} />;
};

FormSwitcher.propTypes = {
  scene: PropTypes.oneOf([
    formScenes.confirm,
    formScenes.phone,
    formScenes.email,
    formScenes.password
  ]).isRequired,
  onCloseClick: PropTypes.func.isRequired
};

export default FormSwitcher;
