import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Icon, Separator } from "ibolit-ui";
import Article from "~/components/Article";
import { CONTACT } from "~/locators/contacts";
import Header from "./Header";
import styles from "./PublicProfile.scss";
import TariffsButton from "~/modals/Tariffs/TariffsButton";
import RowWithImage from "~/components/RowWithImage";
import { pluralizeYears } from "~/utils/pluralize";

const PublicProfile = ({ user, variant }) => (
  <div className={cn(styles.container, styles[variant])}>
    <Header user={user} variant={variant} />
    <Separator className={styles.separator} />
    {user.experience && (
      <RowWithImage
        className={styles.info}
        image={<Icon variant="phonendoscope" />}
        info={`Опыт работы: ${pluralizeYears(user.experience)}`}
        testid={CONTACT.EXPERIENCE}
      />
    )}
    {user.category && (
      <RowWithImage
        image={<Icon variant="grade" />}
        info={user.category}
        className={styles.info}
        testid={CONTACT.CATEGORY}
      />
    )}
    {user.education && (
      <RowWithImage
        key="education"
        image={<Icon variant="school" />}
        info={user.education}
        className={styles.info}
        testid={CONTACT.EDUCATION}
      />
    )}
    <TariffsButton
      className={styles.action}
      testid={CONTACT.BUTTON_TARIFFS}
      userId={user.id}
    />
    <Separator className={styles.separator} />
    {user.description && (
      <Article
        testid={CONTACT.DESCRIPTION}
        className={styles.article}
        title="О враче"
        text={user.description}
      />
    )}
  </div>
);

PublicProfile.propTypes = {
  clinic: PropTypes.shape({
    avatar: PropTypes.string,
    city: PropTypes.string,
    created_at: PropTypes.string,
    description: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
    is_showcase: PropTypes.bool,
    name: PropTypes.string,
    phone: PropTypes.string,
    schedule: PropTypes.shape({
      [PropTypes.number]: PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string
      })
    }),
    site: PropTypes.string,
    street: PropTypes.string,
    updated_at: PropTypes.string
  }),
  user: PropTypes.shape({
    active: PropTypes.bool,
    avatar: PropTypes.string,
    category: PropTypes.string,
    chat_id: PropTypes.number,
    clinic: PropTypes.number,
    code: PropTypes.string,
    date_of_birth: PropTypes.number,
    default_price: PropTypes.number,
    description: PropTypes.string,
    education: PropTypes.string,
    email: PropTypes.string,
    experience: PropTypes.number,
    full_name: PropTypes.string,
    has_patient_chat: PropTypes.bool,
    id: PropTypes.number,
    is_chat_free: PropTypes.bool,
    is_new: PropTypes.bool,
    phone: PropTypes.string,
    position: PropTypes.string,
    primary_acceptance: PropTypes.bool,
    self_appointment: PropTypes.bool,
    specialties: PropTypes.arrayOf(PropTypes.string)
  }),
  variant: PropTypes.string
};
export default PublicProfile;
