import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Icon } from "ibolit-ui";
import RowWithImage from "~/components/RowWithImage";
import { TARIFFS_VIEW } from "./TariffsModal";
import uiActions from "~/store/ui/uiActions";

const TariffsButton = ({ userId, className, testid }) => {
  const dispatch = useDispatch();

  const showTariffsModal = useCallback(() => {
    dispatch(uiActions.showModal(TARIFFS_VIEW, { userId }));
  }, [userId]);

  return (
    <RowWithImage
      className={className}
      image={<Icon variant="cart" sizeValue="32px" />}
      title="Стоимость услуг"
      onClick={showTariffsModal}
      hasHoverEffect
      testid={testid}
    />
  );
};

export default React.memo(TariffsButton);
