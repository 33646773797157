import React from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Link.scss";

const Link = ({ to, className, activeClassName, ...rest }) => (
  <NavLink
    to={to}
    className={cn(styles.link, className)}
    activeClassName={activeClassName}
    {...rest}
  />
);

export default Link;
