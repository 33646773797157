import React from 'react';

import styles from './ConsultationComment.scss';

export const ConsultationComment = ({
  comment,
  title
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.header}>
          {title}
        </div>
        <div className={styles.text}>
          {comment}
        </div>
      </div>
    </div>
  )
};
