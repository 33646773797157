import React from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";

import { getConcusionPDF } from "~/store/conclusions/conclusionActions";

import {
  ConsultationInfo,
  NoConclusionPlacehoder,
  ConsultationConclusion
} from "ibolit-components";

import styles from "../ConsultationPage.scss";

export const ConsultationConslusionPage = ({
  consultationData,
  consultationStatusInfo,
  isTariffTypeOffline
}) => {
  const dispatch = useDispatch();

  if (!consultationData.conclusion) {
    return <NoConclusionPlacehoder fill="#ff537c" />;
  }

  const handleDownloadPDF = () =>
    dispatch(getConcusionPDF(consultationData.conclusion.id));

  return (
    <div className={styles.conclusionContainer}>
      <ConsultationInfo {...consultationStatusInfo} iconFill={"#FF537C"} />

      <ConsultationConclusion
        consultationData={consultationData}
        onDownloadPDF={handleDownloadPDF}
        color="var(--pink)"
        isPatient
      />
    </div>
  );
};
