import React from "react";
import { Text } from "ibolit-ui";
import Bubble from "../Bubble";
import File from "../File";
import Row from "../Row";
import Consultation from "../Consultation";
import Conclusion from "../Conclusion";
import SystemText from "~/layouts/Chats/Chat/ChatMessagesList/components/SystemText";
import Voice from "~/layouts/Chats/Chat/ChatMessagesList/components/Voice/Voice";
import { PatientProblem } from "ibolit-components";
import { RatingChatMessage } from "ibolit-components";
import { Rate } from "../Rate/Rate";

const Message = ({ testId, onFileClick, message, partnerId }) => {
  const position = getPosition(message, partnerId);
  const { type, created_at, client_message_key, error } = message;
  return (
    <Row
      testId={testId}
      sent={false}
      isRead={false}
      position={position}
      showFooter={type !== "bundle" && type !== "evaluation"}
      showChecksIcon={position === "right"}
      created_at={created_at}
      isSending={client_message_key}
      isSendFailure={!!error}
    >
      {getRow(message, position, { onFileClick, partnerId })}
    </Row>
  );
};

function getPosition(row, partnerId) {
  const { author_id, type } = row;

  switch (type) {
    case "system":
    case "service":
    case "conclusion":
    case "patient_problem":
    case "evaluation":
    case "rating":
      return "center";

    default:
      return author_id !== partnerId ? "right" : "left";
  }
}

function getRow(row, position, { onFileClick, partnerId }) {
  switch (row.type) {
    case "bot":
    case "text":
      return (
        <Bubble
          variant={position === "left" ? "primary" : "secondary"}
          text={row.body}
        />
      );

    case "file":
    case "photo":
      return <File onFileClick={onFileClick} {...row.file} />;

    case "voice":
      return <Voice voiceSrc={row.file?.url} duration={row.file?.duration} />;

    case "system":
      return <SystemText text={row.body} />;

    case "conclusion":
      return <Conclusion {...row} />;

    case "evaluation":
      return <Rate {...row} />;

    case "rating":
      return <RatingChatMessage {...row} />;

    case "service": {
      const { consultation } = row;
      // todo check diff services types
      return <Consultation id={consultation} userId={partnerId} />;
    }

    case "patient_problem":
      return <PatientProblem message={row} />;

    default:
      return (
        <Text variant="desc" key={row.id} colorVariant="error">
          Неизвестный тип сообщения
        </Text>
      );
  }
}

export default Message;
