import React from "react";
import { Icon, Text, Button } from "ibolit-ui";
import cn from "classnames";
import { useShowModal } from "~/hooks/useModal";
import { useSelector } from "react-redux";
import { getUserById } from "~/store/users/userSelectors";
import {
  getConsultationIcon,
  isConsultationApproved
} from "~/store/consultation/consultationSelectors";
import { getTariffTextByType } from "~/store/profile/profileSelectors";
import { CONSULTATION_FEEDBACK_VIEW } from "~/modals/ConsultationFeedback/ConsultationFeedback";

import styles from "./Rate.scss";

export const Rate = ({
  tariff_name,
  type,
  status,
  author_id,
  evaluation_consultation,
  id
}) => {
  const icon = getConsultationIcon(type);
  const tariffText = getTariffTextByType(type);

  const doctorInfo = useSelector(getUserById(author_id));

  const handleRateConsultation = useShowModal(CONSULTATION_FEEDBACK_VIEW, {
    consultationId: evaluation_consultation.id,
    doctorInfo,
    rating: evaluation_consultation.rating,
    externalId: evaluation_consultation.external_id,
    messageId: id,
    isFromChat: true
  });

  return (
    <div className={styles.consultationChat}>
      <div className={styles.bubble}>
        <div
          className={cn(
            styles.consultationChat_row,
            styles.consultationChat_rowTop
          )}
        >
          <span>
            <Icon
              variant={icon}
              fill="var(--pink)"
              size="s"
              className={styles.consultationChat_icon}
            />
            <Text
              tag="span"
              variant="caption"
              className={cn(styles.consultationChat_type)}
            >
              {tariffText}
            </Text>
          </span>
          <Text
            tag="span"
            variant="caption"
            colorVariant="tertiary"
            className={cn({
              [styles.consultationChat_status__approved]: isConsultationApproved(
                status
              )
            })}
          >
            Завершена
          </Text>
        </div>
        <div className={styles.consultationChat_row}>
          <Text variant="h4">{tariff_name || "Название консультации"}</Text>
        </div>
      </div>
      <Button
        className={styles.button}
        size="small"
        textClassName={styles.buttonText}
        onClick={handleRateConsultation}
        disabled={evaluation_consultation.rating?.has_reply}
      >
        Оцените консультацию 😊
      </Button>
    </div>
  );
};
