import {
  all,
  put,
  throttle,
  takeLatest,
  call,
  select
} from "redux-saga/effects";
import { normalize } from "normalizr";
import { push } from "connected-react-router";
import api from "~/api/chatsApi";
import consultationsApi from "~/api/consultationsApi";
import Types from "~/store/chat/chatTypes";
import Actions from "~/store/chat/chatActions";
import commonActions from "~/store/common/commonActions";
import uiActions from "~/store/ui/uiActions";
import toastActions from "~/store/toast/toastActions";
import newConsultationActions from "../newConsultation/newConsultationActions";
import wsTypes from "~/store/webscoket/wsTypes";
import messagesTypes from "~/store/messages/messagesTypes";
import { chatSchema } from "~/store/chat/chatNormalizer";
import { ERROR_REQUEST_DEFAULT } from "~/consts/texts";
import { CHATS_ROUTE } from "~/consts/routes";
import { CHAT_SEARCH_ITEMS_LIMIT } from "~/store/chat/chatConsts";
import { CHAT_MODE_ALL } from "~/store/chat/chatModel";
import { PAYMENT_VIEW } from "~/components/Payment/PaymentModal";

import { getUserById } from "~/store/users/userSelectors";
import { SYMPTOMS_VIEW } from "~/modals/NewConsultation/components/Symptoms/Symptoms";
import { CHOOSE_SERVICE_VIEW } from "~/modals/ChooseService/ChooseService";

function* getChatInfoSaga({ request }) {
  try {
    const { data } = yield call(api.getChatApi, request);
    const {
      entities: { chats: items, ...rest }
    } = normalize(data, chatSchema);
    yield put(Actions.infoSuccess({ items }, request));
    yield put(commonActions.updateItems(rest));
  } catch (error) {
    yield put(Actions.infoFailure(error));
  }
}

function* updateChatSaga({ event }) {
  const { entities } = normalize(event, chatSchema);
  if (typeof entities === "object") {
    yield put(commonActions.updateItems(entities));
  }
}

function* updateChatOrder({ payload }) {
  for (const messageId in payload) {
    if (payload.hasOwnProperty(messageId)) {
      const { chatId } = payload[messageId];
      if (chatId) {
        yield put(Actions.upChat(chatId));
      }
    }
  }
}

export function* sendRequestSaga({ payload }) {
  const { page, limit, mode, value } = payload;
  try {
    const params = value
      ? { page, limit, query: value, filter: mode }
      : { page, limit, filter: mode };
    const { data } = yield call(api.chatsSearchApi, params);
    const { result: order, entities } = normalize(data, [chatSchema]);
    yield put(commonActions.updateItems(entities));
    yield put(Actions.requestSuccess({ page, limit, order, mode }));
  } catch (error) {
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
    yield put(Actions.requestFailed({ page, limit, mode, errors: error }));
  }
}

function* getMeta() {
  try {
    const { data: meta } = yield call(api.getMetadata);
    yield put(Actions.chatMetaRequestSuccess(meta));
  } catch (e) {
    // do nothing
  }
}

function* checkChat({ doctor, isTryToCreate = true }) {
  try {
    const { data, status } = yield call(api.getChatByPartnerId, doctor.id);
    const { id, is_read_only } = data;
    const isChatExist = status === 200;
    if (isChatExist && !is_read_only) {
      yield put(uiActions.hideModal(PAYMENT_VIEW));
      yield put(uiActions.hideModal(SYMPTOMS_VIEW));
      yield put(uiActions.hideModal(CHOOSE_SERVICE_VIEW));
    }
    yield put(Actions.upChat(id));
    if (isChatExist) {
      yield put(push(`${CHATS_ROUTE}/${id}`));
    }
  } catch (e) {
    if (isTryToCreate) {
      yield put(Actions.createChat(doctor.id));
    }
  }
}

function* createChat({ doctorId, needCloseModal }) {
  try {
    const { is_chat_free } = yield select(getUserById(doctorId));
    if (is_chat_free) {
      const {
        data: { id }
      } = yield call(api.createChat, { partner_id: doctorId });
      yield* sendRequestSaga({
        payload: {
          page: 1,
          limit: CHAT_SEARCH_ITEMS_LIMIT,
          mode: CHAT_MODE_ALL
        }
      });
      yield put(push(`${CHATS_ROUTE}/${id}`));
      if (needCloseModal) {
        yield put(uiActions.hideModal(PAYMENT_VIEW));
        yield put(uiActions.hideModal(SYMPTOMS_VIEW));
        yield put(uiActions.hideModal(CHOOSE_SERVICE_VIEW));
      }
    }
  } catch (e) {
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
  }
}

function* attachSymptomsSaga({
  data: { servicePatient, symptomId, painLevel, symptom, onClose }
}) {
  let patient_problem_id = null;
  try {
    if (symptomId === null) {
      const {
        data: { id }
      } = yield call(consultationsApi.createSymptom, symptom);
      patient_problem_id = id;
    } else {
      const {
        data: { id }
      } = yield call(consultationsApi.updateSymptom, {
        symptomId,
        params: { status: symptom.status }
      });
      patient_problem_id = id;
    }
    yield call(api.attachProblem, {
      servicePatient,
      data: {
        patient_problem_id,
        discomfort_level: painLevel
      }
    });
    onClose();
  } catch (error) {
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
  }
}

export default function* chatSaga() {
  yield all([
    takeLatest(Types.INFO_REQUEST, getChatInfoSaga),
    takeLatest(wsTypes.WS_CHAT_UPDATE, updateChatSaga),
    takeLatest(messagesTypes.UPDATE_CHATS_MESSAGES, updateChatOrder),
    takeLatest(Types.CHAT_META_REQUEST, getMeta),
    throttle(2000, Types.REQUEST_SEND, sendRequestSaga),
    takeLatest(Types.CHATS_CREATE_CHAT, createChat),
    takeLatest(Types.CHATS_CHECK_CHAT, checkChat),
    takeLatest(Types.ATTACH_SYMPTOMS, attachSymptomsSaga)
  ]);
}
