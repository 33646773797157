import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Text from '../Text';
import styles from './Notice.scss';

const Notice = ({ text, style, qaId, closeToast, children, iconVariant, title, onClick }) => {
  const handleClose = event => {
    event.preventDefault();
    event.stopPropagation();
    closeToast();
  };

  return (
    <div
      style={style}
      className={cn(styles.notice, { [styles.clickable]: onClick })}
      onClick={onClick}
      data-qa={qaId}
    >
      {iconVariant && <Icon variant={iconVariant} fill="#fff" className={styles.noticeIcon} />}
      <div className={styles.content}>
        <div className={styles.header}>
          <Text
            variant={title ? 'descTitle' : 'caption'}
            colorVariant="secondary"
            className={cn(styles.title, { [styles.text]: !title })}
          >
            <Icon
              variant="close-tiny"
              fill="#fff"
              className={styles.closeIcon}
              onClick={handleClose}
            />
            {title || text}
          </Text>
        </div>
        {title && text && (
          <Text variant="caption" colorVariant="secondary" className={styles.text}>
            {text}
          </Text>
        )}
        {children}
      </div>
    </div>
  );
};

Notice.defaultProps = {
  style: {},
};

Notice.propTypes = {
  /** Заголовок уведомления. */
  title: PropTypes.string,
  /** Текст который отображается внутри контейнера. */
  text: PropTypes.string,
  children: PropTypes.node,
  /** Иконка сообщения. */
  iconVariant: Icon.propTypes.variant,
  /** Определение стиля элемента с помощью правил CSS. */
  style: PropTypes.object,
  /** Устанавливает уникальный QA идентификтор. */
  qaId: PropTypes.string,
  /** Функция закрытия тоста, приходит из react-toastify */
  closeToast: PropTypes.func,
  /** click handler */
  onClick: PropTypes.func,
};

export default Notice;
