export default {
  WS_CHAT_MESSAGE: "@@ws/CHAT_MESSAGE",
  WS_TOOLBAR_UPDATE: "@@ws/TOOLBAR_UPDATE",
  WS_CONCLUSION_ADD: "@@ws/CONCLUSION_ADD",
  WS_SLOT_CANCELED: "@@ws/SLOT_CANCELED",
  WS_CONSULTATION_STARTED: "@@ws/CONSULTATION_STARTED",
  WS_CONSULTATION_ENDED: "@@ws/CONSULTATION_ENDED",
  WS_CONSULTATION_PAID: "@@ws/CONSULTATION_PAID",
  WS_SLOT_MOVED: "@@ws/SLOT_MOVED",
  WS_SLOT_ENROLLED: "@@ws/SLOT_ENROLLED",
  WS_CHAT_UPDATE: "@@ws/CHAT_UPDATE"
};
