import React, { useRef, useState, useCallback } from "react";
import { Button, Dialog } from "ibolit-ui";
import { useDispatch } from "react-redux";
import { useCloseModal } from "~/hooks/useModal";
import uiActions from "~/store/ui/uiActions";
import PasswordInput from "~/components/inputs/PasswordInput/PasswordInput";
import auth from "~/locators/auth";
import Field from "~/components/Field";
import profileActions from "~/store/profile/profileActions";
import styles from "./NewPassword.scss";

export const FROM_WIDGET_NEW_PASSWORD = "from_widget_new_password";

const NewPasswordModal = () => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const inputRef = useRef(null);
  const onCloseClick = useCloseModal(FROM_WIDGET_NEW_PASSWORD);
  const handleSubmit = () => {
    dispatch(
      profileActions.changePasswordRequest({
        values: { password: inputRef.current.value.trim() },
        setErrors
      })
    );
    dispatch(uiActions.hideModal(FROM_WIDGET_NEW_PASSWORD));
  };
  const [canSubmit, setCanSubmit] = useState(false);
  const checkCanSubmit = useCallback(() => {
    try {
      setCanSubmit(inputRef.current.isValid);
    } catch (e) {
      setCanSubmit(false);
    }
  }, []);

  return (
    <Dialog
      header={"Придумайте пароль"}
      onCloseClick={onCloseClick}
      boxClassName={styles.box}
    >
      <Field
        testidProps={{
          input: auth.AUTH_ACTIVATION_PASSWORD_INPUT
        }}
        Component={PasswordInput}
        initialValue={""}
        errors={errors}
        errorKey="password"
        minLength={8}
        ref={inputRef}
        autoFocus
        secured
        submitHandler={handleSubmit}
        label="Установите пароль для входа"
        validHandler={checkCanSubmit}
      />
      <Button
        testid={auth.AUTH_ACTIVATION_BUTTON_SUBMIT}
        disabled={!canSubmit}
        onClick={handleSubmit}
        fullWidth
        type="submit"
        colorVariant="patient"
      >
        Продолжить
      </Button>
    </Dialog>
  );
};

export default NewPasswordModal;
