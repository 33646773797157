export const CHATS_LIST = {
  HEADER: "chats__chatsList__header",
  INPUT_SEARCH: "chats__chatsList__input-search",
  CONTAINER: "chats__chatsList__container"
};

export const CHAT_BUTTON_PRIMARY = "chats__button_primary";

export const CHAT = {
  MESSAGES_CONTAINER: "chats__chat__container-messages"
};

export const CHAT_FOOTER = {
  MENU_BUTTON: "chats__chatFooter__menu-button",
  TEXTAREA: "chats__chatFooter__textarea",
  BUTTON_SEND_MESSAGE: "chats__chatFooter__button-send-message",
  BUTTON_CONSULTATION: "chats__chatFooter__button-menu-consultation",
  BUTTON_SEND_FILE: "chats__chatFooter__button-menu-send-file",
  BUTTON_CHAT_PAID: "chats__chatFooter__button-chat-paid"
};

export const CHAT_HEADER = {
  DOCTOR: "chats__chatHeader__doctor",
  DOCTOR_ONLINE_STATUS: "chats__chatHeader__doctor-online-status",
  STATUS: "chats__chatHeader__chat-status"
  // BUTTON_PAYMENT: 'chats__chatHeader__payment-button',
};

export const CHATS_PRIMARY = {
  LIST: "chats__primary_list",
  ITEM: "chats__primary_list_item",
  BUTTON: "chats__primary_list_item_button"
};
