import * as Sentry from "@sentry/browser";
import RobustWebSocket from "robust-websocket";

export default class NativeSocket {
  socket = null;

  constructor(token, onNewMessage) {
    this.socket = new RobustWebSocket(
      `${(__MS__ || "https://empty").replace(
        "https",
        "wss"
      )}/api/v1/stream?token=${token}`
    );

    this.socket.addEventListener("message", onNewMessage);
    this.socket.addEventListener("error", this.onError);
    this.socket.addEventListener("close", this.onClose);
  }

  onError = event => {
    Sentry.captureException(`[WS] Ошибка ${JSON.stringify(event)}`);
  };

  onClose = event => {
    this.socket = null;
    Sentry.captureException(
      `[WS] Соединение закрыто код=${event.code} причина=${event.reason}`
    );
  };

  close = (reason = "Работа завершена") => {
    if (this.socket !== null) {
      this.socket.close(1000, reason);
      this.socket = null;
    }
  };
}
