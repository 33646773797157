import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Icon } from "ibolit-ui";
import styles from "./ConsultationVideoModal.scss";
import metricsApi from "~/api/metricsApi";

function ConsultationVideoModal({ patientId, videoSrc, onClose }) {
  const videoRef = useRef();
  useEffect(() => {
    if (videoRef) {
      videoRef.current.addEventListener("play", e => {
        metricsApi.sendMetrics({
          event: "Started playback",
          event_properties: {
            userId: patientId,
            recordName: videoSrc,
            videoTime: e.srcElement.currentTime
          }
        });
      });

      const stoppedPayback = e => {
        metricsApi.sendMetrics({
          event: "Stopped playback",
          event_properties: {
            userId: patientId,
            recordName: videoSrc,
            videoTime: e.srcElement.currentTime
          }
        });
      };

      videoRef.current.addEventListener("pause", e => {
        stoppedPayback(e);
      });
      videoRef.current.addEventListener("ended", e => {
        stoppedPayback(e);
      });
    }
  }, [videoRef]);

  useEffect(() => {
    metricsApi.sendMetrics({
      event: "Opened record",
      event_properties: {
        userId: patientId,
        recordName: videoSrc
      }
    });

    return () => {
      metricsApi.sendMetrics({
        event: "Closed record",
        event_properties: {
          userId: patientId,
          recordName: videoSrc
        }
      });
    };
  }, []);

  return (
    <Box className={styles.consultationVideoModal}>
      <Icon
        className={styles.consultationClose}
        variant="close"
        fill="black"
        onClick={onClose}
      />
      <video ref={videoRef} width={900} height={500} src={videoSrc} controls>
        Видео недоступно в вашем браузере
      </video>
    </Box>
  );
}

ConsultationVideoModal.propTypes = {
  videoSrc: PropTypes.string,
  onClose: PropTypes.func
};

export const CONSULTATION_VIDEO_VIEW = "consultationVideoModal";
export default ConsultationVideoModal;
