import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { ImageLoader, Tabs, Text } from "ibolit-ui";
import { CLINICS_DETAILS_TABS } from "~/locators/clinics";
import { getClinicAvatar } from "~/utils/image";
import image from "~/assets/images/clinic_details.png";
import styles from "./ClinicDetails.scss";
import ClinicInfo from "./ClinicInfo";
import DoctorsList from "~/layouts/Clinics/ClinicDetails/DoctorsList";

const DOCTORS = "doctors";
const CLINIC = "clinic";
const tabs = { [DOCTORS]: "Врачи", [CLINIC]: "О клинике" };

const ClinicDetails = ({ clinic }) => {
  const params = useLocation();

  const defaultTab = tabs[new URLSearchParams(params.search).get("tab")];

  const [tab, setTab] = useState(defaultTab || DOCTORS);

  return (
    <div className={styles.container}>
      <div>
        <div
          className={styles.header}
          style={{ backgroundImage: `url(${image})` }}
        >
          <ImageLoader
            asBackground
            src={getClinicAvatar(clinic.avatar)}
            className={styles.image}
            testid="ClinicDetails__image"
          />
        </div>
        <Text
          className={styles.title}
          variant="h2"
          testid="ClinicDetails__name"
        >
          {clinic.name}
        </Text>
        <Tabs
          testid={CLINICS_DETAILS_TABS}
          className={styles.tabs}
          onChange={setTab}
          values={tabs}
          value={tab}
          shift
          textStyles={{ color: "var(--pink)" }}
          buttonActiveStyles={{ backgroundColor: "rgba(var(--pinkRGB), 0.1)" }}
          textActiveStyles={{ color: "var(--pink)" }}
        />
      </div>
      <div>
        {tab === DOCTORS ? (
          <DoctorsList clinicId={clinic.id} />
        ) : (
          <ClinicInfo clinic={clinic} />
        )}
      </div>
    </div>
  );
};

export default ClinicDetails;
