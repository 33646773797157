import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { Spinner } from "ibolit-ui";
import styles from "./Loading.scss";

const Loading = ({
  className,
  style,
  showLoading,
  variant,
  height,
  color,
  scale
}) => (
  <div
    className={cn(
      { [styles.hidden]: !showLoading },
      styles[variant],
      className
    )}
    style={{ height, ...style }}
  >
    <Spinner
      fill={color}
      scale={scale}
      {...(variant === "row" && !scale && { size: 24 })}
    />
  </div>
);

Loading.propTypes = {
  height: PropTypes.number,
  showLoading: PropTypes.bool,
  style: PropTypes.object,
  color: PropTypes.string,
  variant: PropTypes.oneOf(["overlay", "row"])
};

Loading.defaultProps = {
  showLoading: true,
  variant: "overlay",
  style: {}
};

export default Loading;
