import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Box, Icon } from "ibolit-ui";
import styles from "./SymptomsStatus.scss";

export const PATIENT_PROBLEM_STATUSES = [
  {
    icon: "primary-appointment",
    title: "Первичная консультация",
    value: "primary_appointment"
  },
  {
    icon: "transcript-of-analyzes",
    title: "Расшифровка анализов",
    value: "transcript_of_analyzes"
  },
  {
    icon: "repeated-appointment",
    title: "Повторная консультация",
    value: "repeated_appointment"
  },
  {
    icon: "second-opinion",
    title: "Второе мнение",
    value: "second_opinion"
  }
];

function SymptomsStatus({ selectedStatus, onGoBack, onStatusChange }) {
  function handleStatusClick(type) {
    return () => {
      onStatusChange(type);
    };
  }

  return (
    <Box testId="SymptomsStatus">
      <div className={styles.header}>
        <Icon
          variant="arrow"
          onClick={onGoBack}
          className={styles.arrowIcon}
          testid="SymptomStatus__goBackButton"
        />
        <h3
          className={styles.headerText}
          data-testid="SymptomStatus__headerText"
        >
          Какой тип консультации вам нужен?
        </h3>
      </div>
      <div className={styles.statusesContainer}>
        {PATIENT_PROBLEM_STATUSES.map(p => (
          <div
            key={p.value}
            data-testid={`SymptomsStatus__${p.icon}`}
            className={cn(styles.status, {
              [styles.selected]: selectedStatus === p.value
            })}
            onClick={handleStatusClick(p.value)}
          >
            <Icon
              sizeValue="40px"
              variant={p.icon}
              fill={selectedStatus === p.value ? "var(--white)" : "var(--pink)"}
            />
            <span
              className={styles.statusText}
              data-testid="SymptomStatus__statusText"
            >
              {p.title}
            </span>
          </div>
        ))}
      </div>
    </Box>
  );
}

SymptomsStatus.propTypes = {
  // Subject to change. the possible statuses might be fetched from the backend
  selectedStatus: PropTypes.oneOf([
    "primary_appointment",
    "repeated_appointment",
    "transcript_of_analyzes",
    "second_opinion"
  ]),
  onGoBack: PropTypes.func,
  onStatusChange: PropTypes.func
};

export default SymptomsStatus;
