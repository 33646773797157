import { useDispatch } from "react-redux";
import { useCallback } from "react";

export const useAction = callback => {
  if (typeof callback !== "function") {
    throw new Error(`useAction: callback is not a function: ${callback}`);
  }
  const dispatch = useDispatch();
  return useCallback((...args) => dispatch(callback(...args)), [callback]);
};
