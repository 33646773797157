import React from "react";
import cn from "classnames";
import { Input, InputPreventAutocomplete } from "ibolit-ui";
import useInput from "~/hooks/useInput";
import { checkNotEmpty } from "~/utils/validations";
import styles from "./TextInput.scss";

const defaultValidator = (validator, required) => value => {
  if (!checkNotEmpty(value) && required)
    return [false, "Поле должно быть заполнено"];
  return typeof validator === "function" ? validator(value) : true;
};

const TextInput = (
  {
    placeholder,
    noFrame = false,
    required = true,
    secured,
    validator,
    autoFocus,
    fullWidth = true,
    type,
    testidProps,
    autocomplete,
    ...props
  },
  inputRef
) => {
  props.validator = defaultValidator(validator, required);
  const [input, className] = useInput({ inputRef, autoFocus, ...props });

  const InputComponent = type === "password" ? InputPreventAutocomplete : Input;

  return (
    <InputComponent
      autocomplete={autocomplete}
      type={type}
      placeholder={placeholder}
      inputBinds={input}
      fullWidth={fullWidth}
      focus={autoFocus}
      secured={secured}
      testidProps={testidProps}
      className={cn({ [styles.noFrame]: noFrame }, className)}
    />
  );
};

export default React.forwardRef(TextInput);
