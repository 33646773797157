import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Text.scss';

const Text = ({
  tag,
  className,
  weight,
  children,
  variant,
  colorVariant,
  onClick,
  isHover,
  testid,
  ...rest
}) =>
  React.createElement(
    tag,
    {
      ...rest,
      'data-testid': testid,
      onClick,
      className: cn(
        styles.text,
        styles[variant],
        styles[colorVariant],
        {
          [styles.hovered]: isHover,
          [styles[weight]]: weight,
        },
        className,
      ),
    },
    children,
  );

Text.defaultProps = {
  tag: 'div',
  variant: 'desc',
  colorVariant: 'primary',
};

Text.propTypes = {
  /** Вариант отображения текста. */
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    // UI-Kit
    'body1',
    'body2',
    'sub1',
    'sub2',
    'allCaps',
    // Legacy
    'desc',
    'descTitle',
    'desc2',
    'caption',
    'captionUpper',
  ]),
  /** Цветовой вариант текста. */
  colorVariant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'error',
    'success',
    'pressing',
    'inherit',
    'pale',
    'pink',
    'silver',
  ]),
  /** Устанавливает насыщенность шрифта. */
  weight: PropTypes.oneOf(['light', 'normal', 'normal', 'bold']),
  /** Устанавливает тег контейнер текста. */
  tag: PropTypes.string,
  /** Обработчик события при щелчке левой кнопкой мыши на элементе. */
  onClick: PropTypes.func,
  /** Устанавливает для контейнера имя класса. */
  className: PropTypes.string,
  /** Устанавливет text в :hover state */
  isHover: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** Устанавливает уникальный QA идентификтор. */
  testid: PropTypes.string,
};

export default Text;
