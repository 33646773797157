import React from 'react';

export const FullTimeAppointment = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 37.0067C2.44772 37.0067 2 36.559 2 36.0067V21.5067C2 20.9544 2.44772 20.5067 3 20.5067H9V28.7567V37.0067H7H4.5H3ZM5 28.7567H8C8.55228 28.7567 9 28.309 9 27.7567V26.2567C9 25.7044 8.55228 25.2567 8 25.2567H5C4.44772 25.2567 4 25.7044 4 26.2567V27.7567C4 28.309 4.44772 28.7567 5 28.7567Z" fill="#00B5CE"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M32 20.5067H38C38.5523 20.5067 39 20.9544 39 21.5067V36.0067C39 36.559 38.5523 37.0067 38 37.0067H37H34.5H32V20.5067ZM33 28.7567H36C36.5523 28.7567 37 28.309 37 27.7567V26.2567C37 25.7044 36.5523 25.2567 36 25.2567H33C32.4477 25.2567 32 25.7044 32 26.2567V27.7567C32 28.309 32.4477 28.7567 33 28.7567Z" fill="#00B5CE"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 7C9.44772 7 9 7.44772 9 8V22V26.125V30.25V37.5C9 38.0523 9.44772 38.5 10 38.5H16.5C17.0523 38.5 17.5 38.0523 17.5 37.5V29.5C17.5 28.9477 17.9477 28.5 18.5 28.5H23C23.5523 28.5 24 28.9477 24 29.5V37.5C24 38.0523 24.4477 38.5 25 38.5H31C31.5523 38.5 32 38.0523 32 37.5V22V8C32 7.44772 31.5523 7 31 7H26.4488C25.9169 7 25.4893 7.42105 25.362 7.93745C24.8673 9.94398 23.1584 11.3864 21 11.5C18.699 11.6211 16.694 10.0859 16.1428 7.93652C16.0107 7.42133 15.5831 7 15.0512 7H10ZM27.5 24.5C27.5 25.0523 27.0523 25.5 26.5 25.5H23.5C22.9477 25.5 22.5 25.0523 22.5 24.5V23C22.5 22.4477 22.9477 22 23.5 22H26.5C27.0523 22 27.5 22.4477 27.5 23V24.5ZM13.5 24.5C13.5 25.0523 13.9477 25.5 14.5 25.5H17.5C18.0523 25.5 18.5 25.0523 18.5 24.5V23C18.5 22.4477 18.0523 22 17.5 22H14.5C13.9477 22 13.5 22.4477 13.5 23V24.5ZM27.5 16.5C27.5 17.0523 27.0523 17.5 26.5 17.5H23.5C22.9477 17.5 22.5 17.0523 22.5 16.5V15C22.5 14.4477 22.9477 14 23.5 14H26.5C27.0523 14 27.5 14.4477 27.5 15V16.5ZM13.5 16.5C13.5 17.0523 13.9477 17.5 14.5 17.5H17.5C18.0523 17.5 18.5 17.0523 18.5 16.5V15C18.5 14.4477 18.0523 14 17.5 14H14.5C13.9477 14 13.5 14.4477 13.5 15V16.5Z" fill="#00B5CE"/>
        <path d="M22 2C22 1.17157 21.3284 0.5 20.5 0.5C19.6716 0.5 19 1.17157 19 2H22ZM19 7C19 7.82843 19.6716 8.5 20.5 8.5C21.3284 8.5 22 7.82843 22 7H19ZM23 6C23.8284 6 24.5 5.32843 24.5 4.5C24.5 3.67157 23.8284 3 23 3V6ZM18 3C17.1716 3 16.5 3.67157 16.5 4.5C16.5 5.32843 17.1716 6 18 6V3ZM19 2V4.5H22V2H19ZM19 4.5V7H22V4.5H19ZM23 3H18V6H23V3Z" fill="#00B5CE"/>
    </svg>
    
)