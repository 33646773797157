import { v4 as uuidv4 } from "uuid";
import { CookieList, getCookie, setCookie } from "~/utils/cookies";
import axios from "./httpService";

const getOrSetUuid = () => {
  let uuid = getCookie(CookieList.UUID);

  if (!uuid) {
    uuid = uuidv4();
    setCookie(CookieList.UUID, uuid, { expires: 30 });
  }

  return uuid;
};

export default {
  sendMetrics: data =>
    axios.post(`/v3/metrics`, {
      ...data,
      uuid: getOrSetUuid(),
      platform: "web"
    })
};
