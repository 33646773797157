import React from "react";
import cn from "classnames";
import { Text, ImageLoader } from "ibolit-ui";
import placeholder from "~/assets/images/pic-placeholder.png";
import locators from "~/locators/common";
import styles from "./EmptyStub.scss";

const EmptyStub = ({ className, text, children, centered }) => (
  <div
    data-testid={locators.EMPTY_STUB}
    className={cn(styles.container, className, { [styles.centered]: centered })}
  >
    <ImageLoader asBackground className={styles.pic} src={placeholder} />
    {text && (
      <Text tag="pre" variant="h4" className={styles.text}>
        {text}
      </Text>
    )}
    {children}
  </div>
);

export default EmptyStub;
