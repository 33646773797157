import Types from "./subscriptionsTypes";

export default {
  searchRequest: (page, mode, limit = 100) => ({
    type: Types.SEARCH_REQUEST,
    request: { page, mode, limit }
  }),
  searchSuccess: (payload, request = {}, rest) => ({
    type: Types.SEARCH_SUCCESS,
    payload,
    request,
    rest
  }),
  searchFailure: (error, request) => ({
    type: Types.SEARCH_FAILURE,
    error,
    request
  }),
  deleteRequest: id => ({
    type: Types.DELETE_REQUEST,
    request: { id }
  }),
  deleteSuccess: (payload, request, rest) => ({
    type: Types.DELETE_SUCCESS,
    payload,
    request,
    rest
  }),
  deleteFailure: (error, request) => ({
    type: Types.DELETE_FAILURE,
    request,
    error
  }),
  selectItem: item => ({
    type: Types.SELECT_ITEM,
    item
  })
};
