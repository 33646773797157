export const STATUS = {
  ERROR_BAD_REQUEST: 400,
  ERROR_AUTHORIZATION: 401,
  ERROR_PAYMENT: 402,
  ERROR_FORBIDDEN: 403,
  ERROR_NOT_FOUND: 404,
  ERROR_METHOD_NOT_ALLOWED: 405,
  ERROR_TIMEOUT: 408,
  ERROR_LARGE_ENTITY: 413,
  ERROR_LARGE_URI: 414,
  ERROR_VALIDATION: 422,
  ERROR_MAX_REQUESTS: 429,
  ERROR_SERVER: 500,
  ERROR_SERVICE_UNAVAILABLE: 503,
  OK: 200,
  CREATED: 201
};
