import cn from "classnames";
import * as React from "react";
import PropTypes from "prop-types";
import { Avatar, Text } from "ibolit-ui";
import splitUserName from "~/utils/splitUserName";
import isDefaultAvatar from "~/utils/isDefaultAvatar";
import { PRIVATE_PROFILE } from "~/locators/privateProfile";
import styles from "./ShortUserData.scss";

const ShortUserData = ({ userName, srcAvatar, userId, className, desc }) => {
  const [firstName, lastName] = splitUserName(userName);

  return (
    <div className={cn(styles.shortUserData, className)}>
      <div>
        <Avatar
          testid={PRIVATE_PROFILE.AVATAR}
          srcImg={isDefaultAvatar(srcAvatar) ? undefined : srcAvatar}
          id={userId}
          firstName={firstName}
          lastName={lastName}
          size="large"
        />
      </div>
      <div className={styles.right}>
        <Text testid={PRIVATE_PROFILE.NICKNAME} variant="h3">
          {userName}
        </Text>
        <Text
          className={styles.birth}
          colorVariant="tertiary"
          variant="desc"
          testid={PRIVATE_PROFILE.BIRTHDAY}
        >
          {desc}
        </Text>
      </div>
    </div>
  );
};

ShortUserData.propTypes = {
  userName: PropTypes.string.isRequired,
  srcAvatar: PropTypes.string,
  userId: PropTypes.number.isRequired,
  className: PropTypes.string,
  desc: PropTypes.string.isRequired
};

export default ShortUserData;
