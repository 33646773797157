import React from "react";
import cn from "classnames";

import { Container, Text } from "ibolit-ui";

import styles from "./ChooseDateTime.scss";

import { getDayName } from "~/utils/getDayName";
import { receptionsTypes } from "../ChooseService/ChooseService";

export const DateBlock = ({ active, date, onClick, disabled, testid }) => {
  const dateType = new Date(date);

  return (
    <Container
      direction="column"
      className={cn(
        styles.dateBlock,
        active && styles.dateBlockActive,
        !disabled && styles.dateBlockDisabled
      )}
      onClick={disabled && onClick}
      testid={testid}
    >
      <Text variant="h6">{getDayName(dateType.getDay())[0]}</Text>
      <Text variant="sub2">{dateType.getDate()}</Text>
    </Container>
  );
};

export const TimeBlock = ({ time, onClick, testid }) => {
  return (
    <div className={styles.timeBlock} onClick={onClick} testid={testid}>
      <Text variant="body1" colorVariant="secondary">
        {time}
      </Text>
    </div>
  );
};

export const SlotByDayTime = ({ slots, dayTimeTitle, onClick, testid }) =>
  slots.length ? (
    <Container direction="column" className={styles.slotByDayTimeContainer}>
      <Text
        colorVariant="tertiary"
        variant="body1"
        className={styles.dateTimeTitle}
        testid={`${testid}-dayTimeTitle`}
      >
        {dayTimeTitle}
      </Text>
      <Container wrap="wrap" gap="10">
        {slots.map(data => {
          return (
            <TimeBlock
              key={data.id}
              time={data.start_at}
              onClick={onClick(data.id)}
              testid={`${testid}-timeBlock`}
            />
          );
        })}
      </Container>
    </Container>
  ) : null;

export const getSlotsByTimeOfDay = (slots, selectedDay, slotType) =>
  slots?.[slotType]?.[selectedDay]?.reduce(
    (acc, data) => {
      let morning = acc[0];
      let day = acc[1];
      let evening = acc[2];

      if (data.start_at < "13:00") {
        morning.push(data);
        return [morning, day, evening];
      }
      if (data.start_at < "18:00") {
        day.push(data);
        return [morning, day, evening];
      }
      evening.push(data);
      return [morning, day, evening];
    },
    [[], [], []]
  );

export const ChangePageButton = ({ handleChangeButton, isRotated, testid }) => (
  <button
    className={styles.changePageButon}
    onClick={handleChangeButton}
    testid={testid}
  >
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      transform={isRotated && "scale(-1 1)"}
    >
      <path
        d="M13.3311 8.01858C13.3314 8.23664 13.2901 8.45225 13.21 8.65102C13.13 8.84979 13.013 9.02714 12.8669 9.17125L6.22955 15.7119C6.00961 15.9186 5.72618 16.0208 5.43988 15.9965C5.15359 15.9722 4.88714 15.8234 4.69751 15.5819C4.50789 15.3404 4.41014 15.0254 4.42517 14.7042C4.4402 14.3831 4.56681 14.0812 4.77793 13.8633L10.582 8.14392C10.598 8.12827 10.6107 8.109 10.6195 8.08739C10.6282 8.06577 10.6328 8.04231 10.6328 8.01858C10.6328 7.99485 10.6282 7.9714 10.6195 7.94978C10.6107 7.92817 10.598 7.90889 10.582 7.89325L4.77793 2.17392C4.66569 2.06843 4.57342 1.93887 4.50652 1.79286C4.43963 1.64686 4.39947 1.48734 4.3884 1.3237C4.37733 1.16006 4.39557 0.995586 4.44206 0.839955C4.48855 0.684324 4.56234 0.540671 4.65911 0.417443C4.75587 0.294214 4.87365 0.193896 5.00552 0.122384C5.13739 0.0508709 5.28069 0.00960732 5.427 0.00101852C5.57331 -0.00757027 5.71968 0.0166874 5.8575 0.0723686C5.99532 0.128049 6.12182 0.214026 6.22955 0.32525L12.8645 6.86392C13.011 7.00828 13.1283 7.1859 13.2088 7.38499C13.2892 7.58407 13.3309 7.80005 13.3311 8.01858Z"
        fill="#FF537C"
      />
    </svg>
  </button>
);

export const generatePageSlots = avaliableSlots => {
  const days = Object.keys(avaliableSlots);

  const daysWithSlots = [];

  const date1 = new Date(days[0]);
  const date2 = new Date(days[days.length - 1]);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  let currentDay = date1;

  const generateNextDay = () => {
    const nextDay = new Date(currentDay);
    nextDay.setDate(currentDay.getDate() + 1);
    currentDay = nextDay;
    return nextDay.toISOString().substring(0, 10);
  };

  for (let i = 0; i < diffDays; i++) {
    daysWithSlots[i] = [
      i !== 0 ? generateNextDay() : currentDay.toISOString().substring(0, 10),
      generateNextDay(),
      generateNextDay(),
      generateNextDay(),
      generateNextDay(),
      generateNextDay(),
      generateNextDay()
    ];
  }

  if (diffDays === 0) {
    daysWithSlots[0] = [
      currentDay.toISOString().substring(0, 10),
      generateNextDay(),
      generateNextDay(),
      generateNextDay(),
      generateNextDay(),
      generateNextDay(),
      generateNextDay()
    ];
  }

  return [daysWithSlots, diffDays];
};

export const getTypeData = slotType =>
  receptionsTypes.find(
    type => type.id === (slotType === "online" ? "av" : slotType)
  );
