import React from 'react';

export const FolderIcon = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M77.1667 63.3333C77.1667 65.1014 76.4503 66.7971 75.1751 68.0474C73.8998 69.2976 72.1702 70 70.3667 70H15.9667C14.1633 70 12.4337 69.2976 11.1584 68.0474C9.88317 66.7971 9.16675 65.1014 9.16675 63.3333V16.6667C9.16675 14.8986 9.88317 13.2029 11.1584 11.9526C12.4337 10.7024 14.1633 10 15.9667 10H32.9667L39.7667 20H70.3667C72.1702 20 73.8998 20.7024 75.1751 21.9526C76.4503 23.2029 77.1667 24.8986 77.1667 26.6667V63.3333Z" fill="#FFB800" stroke="#111111" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.20506 34.8968C4.65638 31.2671 7.4667 28 11.1377 28H65.8383C68.8577 28 71.4068 30.2438 71.7898 33.2388L75.4991 62.2388C75.9579 65.8254 73.1635 69 69.5476 69H15.5214C12.554 69 10.0323 66.8308 9.58878 63.8968L5.20506 34.8968Z" fill="#FFDB5C" stroke="black" strokeWidth="2"/>
  </svg>
)


