import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useState
} from "react";

const initialState = {
  loading: true,
  error: false,
  items: []
};

const REQUEST = "request";
const SUCCESS = "success";
const FAILURE = "failure";

const reducer = (state, action) => {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        items: action.items
      };
    case FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};

export function useGetRequest(requestPromise, params) {
  const [{ loading, error, items }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const request = useCallback(() => {
    dispatch({ type: REQUEST });
    requestPromise(params)
      .then(({ data }) => {
        dispatch({
          type: SUCCESS,
          items: data
        });
      })
      .catch(() => {
        dispatch({ type: FAILURE });
      });
  }, []);

  useEffect(() => {
    request();
  }, []);

  return {
    loading,
    items,
    error,
    request
  };
}

const paginationState = {
  ...initialState,
  initialFetched: false,
  canLoadMore: true
};

const paginationReducer = (state, action) => {
  switch (action.type) {
    case REQUEST:
      return { ...state, loading: true, error: false };
    case SUCCESS:
      const { items, limit, page } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        initialFetched: true,
        canLoadMore: items.length >= limit,
        items: [...state.items.slice(0, (page - 1) * limit), ...items]
      };
    case FAILURE:
      return { ...state, loading: false, error: true, canLoadMore: false };
    default:
      return state;
  }
};

export function useRequestSearchPagination(
  requestPromise,
  params = {},
  filter
) {
  const [{ error, items, canLoadMore, initialFetched }, dispatch] = useReducer(
    paginationReducer,
    paginationState
  );
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const hasNoItems = useMemo(() => initialFetched && items.length === 0, [
    initialFetched,
    items
  ]);

  useEffect(() => {
    setPage(1);
  }, [query]);

  const request = useCallback(() => {
    dispatch({ type: REQUEST });
    const requestParams = {
      ...params,
      ...(query ? { query } : {}),
      page
    };
    setIsLoading(true);
    requestPromise(requestParams)
      .then(({ data }) => {
        if (filter) {
          data = data.filter(filter);
        }

        dispatch({
          type: SUCCESS,
          payload: {
            limit: params.limit,
            page,
            items: data
          }
        });
        setIsLoading(false);
      })
      .catch(() => {
        dispatch({ type: FAILURE });
        setIsLoading(false);
      });
  }, [page, query]);

  useLayoutEffect(() => {
    if (query.length === 0 || query.length >= 3) {
      request();
    }
  }, [page, query]);

  const incrementPage = () => {
    setPage(page => page + 1);
  };

  return {
    error,
    items,
    canLoadMore,
    initialFetched,
    incrementPage,
    setQuery,
    hasNoItems,
    request,
    isLoading
  };
}
