import _ from "lodash";

export const FULL_NAME_MAX_LENGTH = 40;

export const checkNotEmpty = value =>
  value === undefined || value === null || !!`${value}`.trim();

const regexpChecker = rules => value =>
  _.castArray(rules)
    .map(regExp => regExp.test(value.trim()))
    .reduce((acc, current) => acc || current);

export const checkValidPhone = regexpChecker([
  /^\+?([0-9]{1,2})\)?[-.\s(]?([0-9]{3,4})[-.\s)]?([0-9]{3,4})[-. ]?([0-9]{2})[-. ]?([0-9]{2})$/,
  /^\+?\d{10,13}$/ // +XXXXXXXXXXX
]);

export const checkValidEmail = regexpChecker(
  /^[a-zа-яA-ZА-Я0-9._-]+@[a-zа-яA-ZА-Я0-9-]+\.[a-zа-яA-ZА-Я]+(?:\.[a-zа-яA-ZА-Я0-9]+)*$/i
);

export const checkValidFullName = regexpChecker(
  /^[а-яА-ЯA-Za-z0-9]+(?:[ _-][а-яА-ЯA-Za-z0-9]+)*\s?$/i
);

export const checkPassword = (value, minLength, maxLength) => {
  const regExp = new RegExp(`^\\S{${minLength},${maxLength || ""}}$`);
  return regExp.test(value);
};

export const getIsEmail = login => /@/.test(login);
