export const PHONE_MASK_LOCATORS = {
  MASK_CONTAINER: 'phoneMask__mask-container',
  MASK_SELECT: 'phoneMask__mask-select',
  MASK_SELECT_TITLE: 'phoneMask__mask-select-title',
  MASK_INPUT: 'phoneMask__mask-input',
  MASK_DROPDOWN: 'phoneMask__mask-dropdown',
  MASK_SELECT_IMG: 'phoneMask__mask-select-img',
  MASK_CONTAINER_ERROR: 'phoneMask__mask-container-error',
  dropdownMenuElement: index => `phoneMask__mask-dropdown-menu-element-${index}`,
  dropdownElementImg: index => `phoneMask__mask-dropdown-menu-element-image-${index}`,
  dropdownElementCountryName: index =>
    `phoneMask__mask-dropdown-menu-element-country-name-${index}`,
  dropdownElementLabel: index => `phoneMask__mask-dropdown-menu-element-label-${index}`,
};