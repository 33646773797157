import * as Sentry from "@sentry/browser";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  dropPushToken,
  dropToken,
  getPushToken,
  setToken
} from "~/utils/localStorageHelper";
import { getLogin, getStatus } from "~/store/auth/authSelectors";
import { Step, Status } from "~/store/auth/authReducer";
import Actions from "~/store/auth/authActions";
import uiActions from "~/store/ui/uiActions";
import toastActions from "~/store/toast/toastActions";
import Types from "~/store/auth/authTypes";
import api from "~/api/authApi";
import pushNotificationApi from "~/api/pushNotification";
import { getMessaging } from "~/store/pushNotifications/pushNotificationSaga";
import isWebRTCSupported from "~/utils/isWebRTCSupported";
import { AUTH_LOGIN_ERROR_VIEW } from "~/layouts/Authorization/containers/LoginErrorModal";

export function* startUp() {
  yield authorizeUserSaga();
}

const setSentryContext = profile => {
  Sentry.configureScope(scope => {
    scope.setUser({
      email: profile.email ? `${profile.email}` : "",
      phone: profile.phone ? `${profile.phone}` : "",
      id: profile.id ? `${profile.id}` : "",
      username: profile.full_name ? `${profile.full_name}` : "",
      extra: {
        date_of_birth: profile.date_of_birth ? `${profile.date_of_birth}` : "",
        avatar: profile.avatar ? `${profile.avatar}` : "",
        active: profile.active ? `${profile.active}` : ""
      }
    });
  });
};

function* authorizeUserSaga() {
  try {
    const { data: user } = yield api.authorizeUserApi();
    setSentryContext(user);
    // TODO: move to other place.
    const canVideo = yield isWebRTCSupported();
    if (!canVideo) {
      yield put(
        toastActions.showErrorToast([
          "Ваш браузер не поддерживает технологию WebRTC, видеозвонки будут недоступны!",
          { autoClose: false }
        ])
      );
    }
    yield put(Actions.userAuthorizeSuccess(user));
  } catch (e) {
    yield put(Actions.userAuthorizeFailure({ password: "Неверный пароль" }));
  }
}

function* checkLoginSaga({ request }) {
  try {
    const {
      data: { id: currentUserId, ...response }
    } = yield api.loginCheckApi(request);
    switch (response.status) {
      case Status.ACTIVE:
        yield put(
          Actions.checkLoginSuccess({
            currentUserId,
            ...response,
            ...request
          })
        );
        yield put(Actions.goToStep(Step.PASSWORD));
        return;
      case Status.INACTIVE:
        yield put(
          Actions.checkLoginSuccess({
            currentUserId,
            ...response,
            ...request
          })
        );
        // yield put(Actions.goToStep(Step.GET_USER_INFO));
        yield put(Actions.goToStep(Step.CODE));

        return;
      case Status.NONE:
        throw "Пользователь не найден";
      default:
        throw "Ошибка";
    }
  } catch (error) {
    if (error.code === "ECONNABORTED") {
      yield put(Actions.checkLoginTimeout());
      yield put(
        toastActions.showErrorToast("Произошла ошибка, попробуйте позже")
      );
    } else {
      const payload = { login: request.login };
      yield put(
        Actions.checkLoginFailure({ login: "Пользователь не найден" }, payload)
      );
      yield put(uiActions.showModal(AUTH_LOGIN_ERROR_VIEW));
    }
  }
}

function* checkPasswordSaga({ request }) {
  try {
    const {
      data: { token }
    } = yield api.passwordCheckApi(request);
    yield put(Actions.checkPasswordSuccess({ token }));
  } catch (error) {
    if (error.code === "ECONNABORTED") {
      yield put(Actions.checkPasswordTimeout());
      yield put(
        toastActions.showErrorToast("Произошла ошибка, попробуйте позже")
      );
    } else {
      yield put(Actions.checkPasswordFailure({ password: "Неверный пароль" }));
    }
  }
}

function* passwordResetSaga({ request }) {
  try {
    const {
      data: { token }
    } = yield api.passwordResetApi(request);
    yield put(Actions.passwordResetSuccess({ token }));
  } catch (e) {
    yield put(Actions.passwordResetFailure({ password: "Неверный пароль" }));
  }
}

function* sendCodeSaga({ request }) {
  try {
    const { data } = yield api.codeSendApi(request);
    yield put(
      Actions.sendCodeSuccess({
        timeout: data.time_out,
        code: null
      })
    );
  } catch (e) {
    yield put(Actions.sendCodeFailure(e.message));
  }
}

function* validateCodeSaga({ request }) {
  try {
    const {
      data: { status }
    } = yield api.codeValidateApi(request);
    const loginStatus = yield select(getStatus);

    if (status === false) {
      throw null;
    }

    yield put(Actions.validateCodeSuccess({ ...request }));

    if (loginStatus === Status.INACTIVE) {
      yield put(Actions.goToStep(Step.ACTIVATION));
    } else {
      yield put(Actions.goToStep(Step.RESET_PASSWORD));
    }
  } catch (e) {
    yield put(Actions.validateCodeFailure({ code: "Неверный код" }));
  }
}

function* activateUserSaga({ request }) {
  try {
    const {
      data: { token, user }
    } = yield api.userActivateApi(request);
    yield put({
      type: Types.USER_ACTIVATE_SUCCESS,
      payload: { token, user }
    });
  } catch (e) {
    yield put({
      type: Types.USER_ACTIVATE_FAILURE,
      error: { password: "Неверный пароль" }
    });
  }
}

function* onSetStep({ step }) {
  if (step === Step.CODE) {
    const login = yield select(getLogin);
    yield put(Actions.sendCodeRequest(login));
  }
  yield put({ type: "@SAGA_SEND_CODE", step });
}

function* userActivateSuccess({ payload: { token } }) {
  token && setToken(token);
  yield put(Actions.goToStep(Step.GET_USER_INFO));
}

function* userChangeInfo() {
  yield authorizeUserSaga();
}

function* processToken({ payload: { token } }) {
  token && setToken(token);
  yield authorizeUserSaga();
}

function removeToken() {
  dropToken();
}

function* removeAllTokens() {
  try {
    const pushToken = getPushToken();
    if (pushToken) {
      const messaging = getMessaging();
      messaging.deleteToken(pushToken);
      yield call(pushNotificationApi.deleteTokenFromServer, pushToken);
    }
  } catch (err) {
    console.warn(err);
  } finally {
    dropPushToken();
    dropToken();
  }
}

export default function*() {
  yield all([
    takeLatest(Types.USER_AUTHORIZE_REQUEST, authorizeUserSaga),
    takeLatest(Types.LOGIN_CHECK_REQUEST, checkLoginSaga),
    takeLatest(Types.PASSWORD_CHECK_REQUEST, checkPasswordSaga),
    takeLatest(Types.PASSWORD_RESET_REQUEST, passwordResetSaga),
    takeLatest(Types.CODE_SEND_REQUEST, sendCodeSaga),
    takeLatest(Types.CODE_VALIDATE_REQUEST, validateCodeSaga),
    takeLatest(Types.USER_ACTIVATE_REQUEST, activateUserSaga),
    takeLatest(Types.SET_STEP, onSetStep),

    takeLatest(Types.USER_AUTHORIZE_FAILURE, removeToken),
    takeLatest(Types.LOGOUT, removeAllTokens),

    takeLatest(Types.USER_ACTIVATE_SUCCESS, userActivateSuccess),
    takeLatest(Types.USER_CHANGE_INFO, userChangeInfo),
    takeLatest(Types.PASSWORD_CHECK_SUCCESS, processToken),
    takeLatest(Types.PASSWORD_RESET_SUCCESS, processToken)
  ]);
}
