import axios from "axios";
import { eventChannel, END } from "redux-saga";
import {
  take,
  fork,
  all,
  put,
  call,
  throttle,
  takeLatest,
  takeEvery,
  select,
  cancel
} from "redux-saga/effects";
import { normalize } from "normalizr";
import types from "~/store/consultation/consultationTypes";
import actions, {
  CONSULTATION_TYPES
} from "~/store/consultation/consultationActions";
import {
  getConsultationFiles,
  getConsultationsOpenPage
} from "~/store/consultation/consultationSelectors";
import wsTypes from "~/store/webscoket/wsTypes";
import toastActions from "~/store/toast/toastActions";
import uiActions from "~/store/ui/uiActions";
import conclusionsActions from "~/store/conclusions/conclusionActions";
import commonActions from "~/store/common/commonActions";
import messagesActions from "~/store/messages/messagesActions";
import { consultationSchema } from "~/store/consultation/consultationNormalizer";
import api from "~/api/consultationsApi";
import metricsApi from "~/api/metricsApi";
import {
  CONSULTATION_INFO_REQUEST_ERROR_TEXT,
  CONSULTATION_STATUSES,
  CONSULTATIONS_FILTER_STATUS_OPEN
} from "~/store/consultation/consultationConsts";
import { ERROR_REQUEST_DEFAULT } from "~/consts/texts";
import { CONSULTATION_STARTED } from "~/modals/ConsultationStarted/ConsultationStarted";
import { CONSULTATION_FEEDBACK_VIEW } from "~/modals/ConsultationFeedback/ConsultationFeedback";
import { currentRouteSelector } from "~/store/routerSelectors";

export const MAX_CONCURRENT_FILES = 5;

const getSort = status => (status === "open" ? "asc" : "desc");

const getStatus = status => {
  switch (status) {
    case "open":
      return CONSULTATION_STATUSES.slice(0, 4);
    case "closed":
      return CONSULTATION_STATUSES.slice(4);
    default:
      return CONSULTATION_STATUSES;
  }
};

function* fetchConsultationList({ request }) {
  try {
    const { mode, limit, page } = request;
    const { data } = yield call(api.fetchConsultations, {
      sort: getSort(mode),
      status: getStatus(mode),
      limit,
      page
    });
    const {
      result: order,
      entities: { consultations: items, ...rest }
    } = normalize(data, [consultationSchema]);
    yield put(actions.getConsultationsSuccess({ items, order }, request));
    yield put(commonActions.updateItems(rest));
  } catch (err) {
    yield put(actions.getConsultationsFailure(err.consultations, request));
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
  }
}

function* fetchConsultation({ request }) {
  try {
    const { data } = yield call(api.getConsultation, request.id);
    const conclusionId = data.conclusion_id;
    if (conclusionId) {
      yield put(conclusionsActions.getConclusion(conclusionId));
    }
    const {
      entities: { consultations: items, ...rest }
    } = normalize(data, consultationSchema);
    yield put(actions.getInfoSuccess({ items }));
    yield put(commonActions.updateItems(rest));
  } catch (error) {
    yield put(actions.getInfoFailure(error.message));
    yield put(
      toastActions.showErrorToast(CONSULTATION_INFO_REQUEST_ERROR_TEXT)
    );
  }
}

function* wsSlotsSaga({ type, event }) {
  // tariff_type: av | chat | offline
  if (type === wsTypes.WS_CONSULTATION_STARTED && event.tariff_type === "av") {
    const currentRoute = yield select(currentRouteSelector);

    if (currentRoute !== `/consultations/${event.id}`) {
      yield put(uiActions.showModal(CONSULTATION_STARTED, event));
    }
  }

  if (
    [
      wsTypes.WS_SLOT_MOVED,
      wsTypes.WS_SLOT_ENROLLED,
      wsTypes.WS_SLOT_CANCELED,
      wsTypes.WS_CONSULTATION_ENDED
    ].includes(type)
  ) {
    const page = yield select(getConsultationsOpenPage) || 1;
    yield put(
      actions.getConsultations({ page, mode: CONSULTATIONS_FILTER_STATUS_OPEN })
    );
  }

  if (type === wsTypes.WS_CONSULTATION_ENDED) {
    const { id, doctor } = event;

    yield put(
      uiActions.showModal(CONSULTATION_FEEDBACK_VIEW, {
        consultationId: id,
        doctorInfo: doctor,
        rating: null,
        sendMetrics: metricsApi.sendMetrics,
        isFromChat: false
      })
    );
  }

  const { entities: items } = normalize(event, consultationSchema);

  if (typeof items === "object") {
    yield put(commonActions.updateItems(items));
  }
}

function* linkUploadFile({ consultationId, tempFileId, file }) {
  try {
    const data = yield call(api.linkUploadFile, { consultationId, tempFileId });

    yield put(
      actions.updateFile({
        ...data,
        tempFileId,
        isLinked: true,
        file,
        consultationId
      })
    );
  } catch (err) {
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
    yield put(actions.updateFile({ error: err.message }));
  }
}

function createUploader({ payload, fileName, cancelSource }) {
  let emit;
  const channel = eventChannel(emitter => {
    emit = emitter;
    return () => {};
  });

  const uploadPromise = api.uploadFile({
    payload,
    cancelToken: cancelSource.token,
    onProgress: event => {
      if (event.total === event.loaded) {
        emit(END);
      }

      const progress = event.loaded / event.total;

      emit({
        progress,
        fileName
      });
    }
  });

  return [uploadPromise, channel];
}

function* watchOnProgress(channel) {
  while (true) {
    const data = yield take(channel);

    yield put(
      actions.uploadProgress({
        fileName: data.fileName,
        progress: data.progress
      })
    );
  }
}

function* linkingFile({ uploadPromise, consultationId, file }) {
  try {
    const result = yield call(() => uploadPromise);

    yield put(actions.uploadSuccess({ fileName: file.file.name }));
    yield linkUploadFile({
      consultationId,
      tempFileId: result.data.temp_file_id,
      file
    });
  } catch (err) {
    yield put(
      actions.updateFileError({
        fileName: file.file.name,
        error: err.message,
        progress: null
      })
    );
  }
}

function* uploadSource({ file, consultationId }) {
  let formData = new FormData();
  formData.append("file", file.file);
  const cancelSource = axios.CancelToken.source();
  const fileName = file.file.name;
  const [uploadPromise, channel] = createUploader({
    payload: formData,
    fileName,
    cancelSource
  });

  const watchingTask = yield fork(watchOnProgress, channel);
  const linkingFileTask = yield fork(linkingFile, {
    uploadPromise,
    consultationId,
    file
  });

  yield take(
    action =>
      action.type === types.CONSULTATION_CANCEL_UPLOAD &&
      action.fileName === fileName
  );
  channel.close();
  yield call(cancelSource.cancel);
  yield cancel(watchingTask);
  yield cancel(linkingFileTask);
}

function* uploadFiles({ consultationId }) {
  const files = yield select(getConsultationFiles);
  yield put({ type: types.CONSULTATION_START_UPLOAD_FILES });

  const filesCopy = Object.entries(files).reduce((acc, [fileName, file]) => {
    if (!file.tempFileId) {
      acc[fileName] = {
        ...file
      };
    }

    return acc;
  }, {});

  let filesFlag = 0;

  while (Object.keys(filesCopy).length > 0) {
    if (filesFlag < MAX_CONCURRENT_FILES) {
      let [fileName, file] = Object.entries(filesCopy)[0];

      filesFlag++;
      yield fork(uploadSource, { file, consultationId });

      delete filesCopy[fileName];
    } else {
      yield take([
        types.CONSULTATION_UPDATE_FILE,
        types.CONSULTATION_CANCEL_UPLOAD,
        types.CONSULTATION_UPLOAD_FILE_ERROR
      ]);
      filesFlag--;
    }
  }
}

function* uploadFile({ file, consultationId }) {
  yield uploadSource({ file, consultationId });
}

function* deleteFile({ consultationId, fileId }) {
  try {
    const { status } = yield call(api.deleteFile, {
      fileId
    });

    if (status === 200) {
      yield put(actions.deleteFileSuccess({ consultationId, fileId }));
    }
  } catch (err) {
    console.log("err: ", err);
  }
}

function* sendFeedback({
  payload: {
    isNew,
    consultationId,
    rating,
    comment,
    isFromChat,
    externalId,
    messageId
  }
}) {
  try {
    const { data } = yield call(
      isFromChat ? api.sendOrUpdateChatFeedback : api.sendFeedback,
      {
        isNew,
        consultationId,
        rating,
        comment
      }
    );
    yield put(actions.sendFeedbackSuccess({ consultationId, feedback: data }));
    yield put(
      messagesActions.updateMessageAction(
        rating,
        comment,
        externalId,
        messageId
      )
    );
    yield put(uiActions.hideModal(CONSULTATION_FEEDBACK_VIEW));
  } catch (err) {
    yield put(actions.sendFeedbackFailure(err));
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
  }
}

function* cancelConsultationSaga({ consultationId }) {
  try {
    yield call(api.cancelConsultation, consultationId);

    yield put(actions.getInfo(consultationId));
  } catch (err) {
    yield put(actions.sendFeedbackFailure(err));
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
  }
}

function* downloadFileSaga({ fileUrl, fileName }) {
  try {
    const { data } = yield call(api.downloadFile, fileUrl);
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(data);
    link.download = fileName;

    document.body.appendChild(link);

    link.click();
  } catch (err) {
    yield put(actions.sendFeedbackFailure(err));
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
  }
}

export default function* consultationSaga() {
  yield all([
    throttle(500, types.CONSULTATIONS_GET, fetchConsultationList),
    takeLatest(types.CONSULTATIONS_INFO_GET, fetchConsultation),
    takeLatest(types.CONSULTATION_SEND_FEEDBACK, sendFeedback),
    takeLatest(types.CONSULTATION_UPLOAD_FILES, uploadFiles),
    takeLatest(types.CONSULTATION_UPLOAD_FILE, uploadFile),
    takeLatest(types.CONSULTATION_DELETE_FILE, deleteFile),
    takeLatest(types.CONSULTATION_CANCEL, cancelConsultationSaga),
    takeEvery(CONSULTATION_TYPES.DOWNLOAD_FILE, downloadFileSaga),
    takeLatest(
      [
        wsTypes.WS_CONSULTATION_PAID,
        wsTypes.WS_CONSULTATION_STARTED,
        wsTypes.WS_CONSULTATION_ENDED,
        wsTypes.WS_SLOT_CANCELED,
        wsTypes.WS_SLOT_ENROLLED,
        wsTypes.WS_SLOT_MOVED
      ],
      wsSlotsSaga
    )
  ]);
}
