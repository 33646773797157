import React from "react";
import PropTypes from "prop-types";
import ModalContainer from "./ModalContainer";

const Modals = ({ modals, modalsMap, onBGClick }) => (
  <>
    {modals.map((modal) => {
      const { view, props: modalProps = {} } = modal;
      const SpecificModal = modalsMap[view];
      return (
        <ModalContainer
          key={view}
          onClick={() => !modalProps.preventClick && onBGClick(view)}
        >
          <SpecificModal {...modalProps} />
        </ModalContainer>
      );
    })}
  </>
);

Modals.propTypes = {
  /** Лист текущих открытых модалок вида [{view: string, props: object<any>}...] */
  modals: PropTypes.array.isRequired,
  /** Мапа компонентов модалок */
  modalsMap: PropTypes.object.isRequired,
  /** В функцию передается view конкретно BG */
  onBGClick: PropTypes.func,
};

export default Modals;
