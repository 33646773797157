import React from 'react';

export const HumanWithPenIcon = ({ className }) => (
  <svg width="149" height="132" viewBox="0 0 149 132" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
  <g clip-path="url(#clip0_2421_8998)">
  <path opacity="0.1" d="M74.5241 132C115.656 132 148.999 128.703 148.999 124.637C148.999 120.57 115.656 117.273 74.5241 117.273C33.3926 117.273 0.0488281 120.57 0.0488281 124.637C0.0488281 128.703 33.3926 132 74.5241 132Z" fill="#2A94F4"/>
  <path d="M137.668 11.1683H70.9932V124.526H137.668V11.1683Z" fill="#D3D3D3"/>
  <path d="M129.055 5.41785H58.9971V124.513H129.055V5.41785Z" fill="#F2F2F2"/>
  <path d="M120.332 0.0369873H47.0991V124.526H120.332V0.0369873Z" fill="white"/>
  <path d="M120.332 0.0368652H47.0996V124.526H120.332V0.0368652Z" fill="currentColor" fill-opacity="0.3"/>
  <path d="M109.431 66.7017H57.9883V15.2192H109.431V66.7017ZM61.5195 63.18H105.899V18.7532H61.5195V63.18Z" fill="white"/>
  <path d="M109.431 82.3522H57.9883V86.157H109.431V82.3522Z" fill="white"/>
  <path d="M109.431 90.7006H57.9883V94.5054H109.431V90.7006Z" fill="white"/>
  <path d="M92.7468 99.9482H57.9883V103.753H92.7468V99.9482Z" fill="white"/>
  <path d="M31.6455 31.1408C31.6455 31.1408 34.3277 25.4274 33.0974 22.8292C31.867 20.2311 29.8245 19.6524 27.4253 20.0833C25.026 20.5143 24.1893 19.2337 23.8325 19.5908C23.4757 19.9479 23.205 21.1423 23.5126 21.1423C23.8325 21.1423 22.4914 22.1274 22.7006 22.8292C22.9097 23.5311 21.0395 30.6729 26.1334 31.5225C31.2272 32.3722 31.6455 31.1408 31.6455 31.1408Z" fill="#0B4870"/>
  <path d="M17.2012 121.164H20.4986L20.9046 117.359H17.6195L17.2012 121.164Z" fill="#FFA98D"/>
  <path d="M17.2008 119.711C17.2008 119.711 14.2478 122.112 12.107 121.891C9.9661 121.681 9.84306 123.516 9.80615 124.464H16.6348C16.6348 124.464 18.0128 123.159 18.8987 124.464H20.9165V122.95V119.711C20.9042 119.711 19.0217 120.561 17.2008 119.711Z" fill="#0B4870"/>
  <path d="M37.1331 121.164H33.848L33.4297 117.359H36.7271L37.1331 121.164Z" fill="#FFA98D"/>
  <path d="M18.3331 65.9017L16.709 117.951H21.6428C21.6428 117.951 23.4761 81.6629 27.0566 79.545C31.0184 77.2054 33.0855 117.951 33.0855 117.951H37.2688C37.2688 117.951 36.186 67.5271 35.7677 65.9017C35.6324 65.2984 21.0892 59.3756 18.3331 65.9017Z" fill="#0E538C"/>
  <path d="M20.7937 36.2756C20.7937 36.2756 25.5923 31.8428 33.0976 35.4629C34.9309 36.3495 35.7922 64.498 35.7922 65.914C35.7922 65.914 29.6156 69.5834 18.3453 65.914C18.333 65.9017 20.3262 36.5958 20.7937 36.2756Z" fill="currentColor"/>
  <path d="M38.5977 42.0628C38.5977 42.0628 45.7585 49.6848 47.3457 49.3031C49.8065 48.7121 57.1642 46.7173 57.1642 46.7173L57.964 48.2195C57.964 48.2195 50.311 53.268 47.0381 53.5759C43.7653 53.8837 35.7678 45.0796 35.7678 45.0796L38.5977 42.0628ZM18.7884 44.7471C18.7884 44.7471 13.2763 51.1378 13.2024 52.9725C13.1286 54.8072 17.7918 62.2322 17.7918 62.2322L16.7214 64.1654C16.7214 64.1654 10.0404 56.8266 8.95761 54.1669C7.87487 51.5072 15.7986 42.0135 15.7986 42.0135L18.7884 44.7471Z" fill="#FFA98D"/>
  <path d="M19.1336 46.0402L14.938 41.6443C14.938 41.6443 20.3517 34.7487 23.9444 34.8965C26.7005 35.0073 24.695 42.6786 19.1336 46.0402ZM34.9564 45.9047L39.2013 41.5581C39.2013 41.5581 35.5101 35.1674 31.9174 35.2782C29.1736 35.3521 29.4443 42.4693 34.9564 45.9047Z" fill="currentColor"/>
  <path d="M24.5967 28.7396V35.1057C24.5967 36.3617 25.6179 37.3837 26.8729 37.3837C28.1279 37.3837 29.1491 36.3617 29.1491 35.1057V28.3702L24.5967 28.7396Z" fill="#FFA98D"/>
  <path d="M32.2111 22.8292C32.2111 22.8292 34.7457 31.1408 31.2268 32.3598C27.7079 33.5789 24.8411 31.0423 24.7919 29.1707C24.7919 29.1707 22.2942 27.336 24.2751 25.9322C24.9272 25.4643 25.5424 26.4001 25.5424 27.102C25.5424 27.8039 26.01 27.2867 26.01 26.9665C26.01 26.6464 26.01 23.8143 26.01 23.8143C26.01 23.8143 31.5098 26.1539 32.2111 22.8292Z" fill="#FFC7AF"/>
  <path d="M37.1455 119.711C37.1455 119.711 40.0984 122.112 42.2393 121.891C44.3801 121.681 44.5032 123.516 44.5401 124.464H37.7114C37.7114 124.464 36.3334 123.159 35.4475 124.464H33.4297V122.95V119.711C33.4297 119.711 35.3122 120.561 37.1455 119.711Z" fill="#0B4870"/>
  <path d="M17.779 62.2321C17.779 62.2321 19.2555 63.2294 18.7756 65.0764C18.2958 66.9357 16.9423 68.7089 15.6258 68.6227C15.1706 68.5857 14.7769 68.4503 14.4324 68.2779C13.6818 67.9085 13.325 67.0096 13.6941 66.2585C13.8049 66.0246 13.9525 65.9137 14.1371 66.1477C14.6415 66.8003 16.8439 65.4212 16.7086 64.1529C16.5732 62.897 15.4167 60.6313 17.779 62.2321Z" fill="#FFA98D"/>
  <path d="M57.9649 28.998H48.0356V114.983H57.9649V28.998Z" fill="currentColor"/>
  <path d="M48.0356 28.9982L52.9941 18.9258L57.9526 28.9982C57.9526 28.9982 56.0332 29.9832 52.9941 29.9832C49.955 29.9832 48.0356 28.9982 48.0356 28.9982Z" fill="#FCD2B1"/>
  <path d="M50.9268 23.1247L52.9938 18.9258L55.0609 23.1247H50.9268Z" fill="#0B4870"/>
  <path d="M56.2669 124.513H49.7336C48.7985 124.513 48.0356 123.75 48.0356 122.814V114.786H57.9526V122.814C57.9526 123.75 57.202 124.513 56.2669 124.513Z" fill="#EC4561"/>
  <path d="M57.9649 111.215H48.0356V117.273H57.9649V111.215Z" fill="#D3D3D3"/>
  <path d="M57.9508 43.8236C57.9508 43.8236 59.2919 48.515 57.1756 48.4534C55.0716 48.3796 54.0873 45.3751 57.9508 43.8236Z" fill="#FFC9AA"/>
  </g>
  <defs>
  <clipPath id="clip0_2421_8998">
  <rect width="149" height="132" fill="white"/>
  </clipPath>
  </defs>
  </svg>  
);
