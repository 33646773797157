import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Box, Button } from "ibolit-ui";
import DoctorItem from "~/components/DoctorItem/DoctorItem";
import newConsultationActions from "~/store/newConsultation/newConsultationActions";
import {
  getIsSymptomsFetching,
  getIsPostingConsultation,
  getSymptoms
} from "~/store/newConsultation/newConsultationSelectors";
import chatActions from "~/store/chat/chatActions";
import Loader from "~/components/Loader";
import SymptomsStatus from "~/modals/NewConsultation/components/SymptomsStatus/SymptomsStatus";
import NewSymptoms from "./NewSymptoms";
import PreviousSymptoms from "./PreviousSymptoms";
import UserAvatar from "~/components/UserAvatar";
import styles from "./Symptoms.scss";

export const BACKEND_CREATION_SOURCE = ["admin", "doctor"];

const SYMPTOMS_STEPS = {
  description: "DESCRIPTION",
  status: "STATUS"
};

const initialState = {
  symptomId: null,
  symptomValue: "",
  painLevel: null,
  daysValue: "",
  isEditDisabled: false,
  step: SYMPTOMS_STEPS.description,
  selectedStatus: null
};

function reducer(state, { type, payload }) {
  switch (type) {
    case "OLD_SYMPTOM_CLICKED": {
      return {
        ...state,
        symptomId: payload.id,
        symptomValue: payload.title,
        daysValue: payload.days,
        selectedStatus: payload.status,
        isEditDisabled: true
      };
    }

    case "NEW_SYMPTOM_CLICKED":
      return {
        ...state,
        symptomId: null,
        symptomValue: "",
        painLevel: null,
        daysValue: "",
        selectedStatus: null,
        isEditDisabled: false
      };

    case "PAIN_LEVEL_CHANGED":
      return { ...state, painLevel: payload };

    case "VALUE_CHANGED":
      return { ...state, [payload.type]: payload.value };

    case "TOGGLE_STEP":
      return {
        ...state,
        step:
          state.step === SYMPTOMS_STEPS.description
            ? SYMPTOMS_STEPS.status
            : SYMPTOMS_STEPS.description
      };

    case "STATUS_CHANGED":
      return { ...state, selectedStatus: payload };

    default:
      return initialState;
  }
}

function Symptoms(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    props.getSymptoms();
  }, []);

  function handleSymptomValueChange(value) {
    dispatch({
      type: "VALUE_CHANGED",
      payload: { type: "symptomValue", value }
    });
  }

  function handlePainLevelChange(value) {
    dispatch({ type: "PAIN_LEVEL_CHANGED", payload: value });
  }

  function handleDaysValueChange(value) {
    const digitsRegex = /^\d+$/;
    if (value.length > 0 && !digitsRegex.test(value)) return;

    dispatch({ type: "VALUE_CHANGED", payload: { type: "daysValue", value } });
  }

  function handleSymptomClick({ id, title, days, status }) {
    switch (true) {
      // Symptom which was automatically created on the backend
      case days === null:
        dispatch({
          type: "OLD_SYMPTOM_CLICKED",
          payload: { id, title, days, status }
        });
        break;

      // Previously saved symptom clicked
      case days >= 0:
        dispatch({
          type: "OLD_SYMPTOM_CLICKED",
          payload: { id, title, days: String(days), status }
        });
        break;

      // New symptom
      default:
        dispatch({ type: "NEW_SYMPTOM_CLICKED" });
    }
  }

  function handleSkipClick() {
    if (props.onClose) {
      props.onClose();
      return;
    }

    const { doctor, tariffId, slotId, tariffType } = props;
    props.createConsultation({
      doctorId: doctor.id,
      tariffId,
      slotId,
      tariffType
    });
  }

  function handleContinueClick() {
    if (state.step === SYMPTOMS_STEPS.description) {
      dispatch({ type: "TOGGLE_STEP" });
    }

    if (state.step === SYMPTOMS_STEPS.status) {
      const { id, doctor, tariffId, slotId, tariffType } = props;
      // If the user picked a previously created symptom, update it instead of creating a new one
      const isOldSymptom =
        props.symptoms.find(({ id }) => id === state.symptomId) &&
        !props.isBackendCreated;
      if (props.isForChat) {
        props.attachSymptoms({
          servicePatient: props.servicePatient,
          symptomId: isOldSymptom ? state.symptomId : null,
          painLevel: state.painLevel,
          symptom: {
            title: state.symptomValue,
            days: Number(state.daysValue),
            status: state.selectedStatus,
            painLevel: state.painLevel
          },
          onClose: props.onClose
        });
        return;
      }
      const consultation = {
        id,
        doctorId: doctor.id,
        slotId,
        tariffId,
        tariffType,
        problemId: state.symptomId
      };
      if (isOldSymptom) {
        props.updateSymptom(
          {
            symptomId: state.symptomId,
            status: state.selectedStatus,
            painLevel: state.painLevel
          },
          consultation,
          props.isBackendCreated,
          props.onClose,
          doctor
        );
      } else {
        props.createSymptom(
          {
            title: state.symptomValue,
            days: Number(state.daysValue),
            status: state.selectedStatus,
            painLevel: state.painLevel
          },
          consultation,
          props.isBackendCreated,
          props.onClose,
          doctor
        );
      }
    }
  }

  function handleGoBack() {
    dispatch({ type: "TOGGLE_STEP" });
  }

  function handleStatusChange(status) {
    dispatch({ type: "STATUS_CHANGED", payload: status });
  }

  const user = props.doctor;

  return (
    <Box testId="Symptoms" className={styles.symptoms}>
      <Loader
        className={styles.loader}
        loading={props.isSymptomsFetching || props.isPostingConsultation}
      >
        {state.step === SYMPTOMS_STEPS.description && (
          <>
            <h3
              className={styles.headerText}
              data-testid="NewConsultation__Symptoms__headerText"
            >
              Давайте соберем вводную информацию
            </h3>
            <div className={styles.userContainer}>
              <UserAvatar
                testid={`symptomps-avatar`}
                user={user}
                size="medium"
                showOnlineStatus={false}
              />
              <div className={styles.userData}>
                <div
                  className={styles.userTitle}
                  data-testid="NewConsultation__Symptoms__userTitle"
                >
                  {user.full_name}
                </div>
                <div
                  className={styles.userSpeciality}
                  data-testid="NewConsultation__Symptoms__userSpeciality"
                >
                  {user.specialty || user.specialties.join(", ")}
                </div>
              </div>
            </div>
            {props.symptoms.length > 0 && (
              <PreviousSymptoms
                symptoms={props.symptoms}
                onSymptomClick={handleSymptomClick}
              />
            )}
            <NewSymptoms
              disabled={state.isEditDisabled}
              symptomValue={state.symptomValue}
              painLevel={state.painLevel}
              daysValue={state.daysValue}
              onSymptomValueChange={handleSymptomValueChange}
              onPainLevelChange={handlePainLevelChange}
              onDaysValueChange={handleDaysValueChange}
            />
          </>
        )}
        {state.step === SYMPTOMS_STEPS.status && (
          <SymptomsStatus
            selectedStatus={state.selectedStatus}
            onGoBack={handleGoBack}
            onStatusChange={handleStatusChange}
          />
        )}
        <div data-testid="Symptoms__actions" className={styles.actions}>
          <Button
            testid="Symptoms__continue-button"
            className={styles.button}
            type="button"
            colorVariant="patient"
            disabled={
              state.step === SYMPTOMS_STEPS.description
                ? state.symptomValue === "" || state.daysValue === ""
                : !state.selectedStatus
            }
            onClick={handleContinueClick}
          >
            Продолжить
          </Button>
        </div>
      </Loader>
    </Box>
  );
}

Symptoms.propTypes = {
  id: PropTypes.string,
  doctor: PropTypes.shape({
    avatar: PropTypes.string,
    created_at: PropTypes.string,
    date_of_birth: PropTypes.string,
    email: PropTypes.string,
    experience: PropTypes.number,
    full_name: PropTypes.string,
    has_slots: PropTypes.bool,
    id: PropTypes.number,
    min_duration_tariff: PropTypes.number,
    phone: PropTypes.string,
    self_appointment: PropTypes.bool,
    specialty: PropTypes.string,
    tariff: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      price: PropTypes.number
    }),
    updated_at: PropTypes.string,
    working_days: PropTypes.shape({
      [PropTypes.string]: PropTypes.bool
    })
  }),
  tariffId: PropTypes.string,
  slotId: PropTypes.number,
  tariffType: PropTypes.string,
  isBackendCreated: PropTypes.bool,
  onClose: PropTypes.func,

  // From store
  symptoms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      days: PropTypes.number,
      // Subject to change. the possible statuses might be fetched from the backend
      // status: 'auto' means the symptom was created on the backend
      status: PropTypes.oneOf([
        "primary_appointment",
        "repeated_appointment",
        "transcript_of_analyzes",
        "appointed-offline-reception",
        "second_opinion",
        "auto"
      ]),
      created_at: PropTypes.string,
      updated_at: PropTypes.string
    })
  ),
  isSymptomsFetching: PropTypes.bool,
  isPostingConsultation: PropTypes.bool,
  getSymptoms: PropTypes.func,
  createSymptom: PropTypes.func,
  updateSymptom: PropTypes.func,
  createConsultation: PropTypes.func,
  isForChat: PropTypes.bool,
  servicePatient: PropTypes.number,
  attachSymptoms: PropTypes.func
};

const mapStateToProps = state => ({
  symptoms: getSymptoms(state),
  isSymptomsFetching: getIsSymptomsFetching(state),
  isPostingConsultation: getIsPostingConsultation(state)
});

const mapDispatchToProps = {
  getSymptoms: newConsultationActions.getSymptoms,
  createSymptom: newConsultationActions.createSymptom,
  updateSymptom: newConsultationActions.updateSymptom,
  createConsultation: newConsultationActions.post,
  attachSymptoms: chatActions.attachSymptoms
};

export const SYMPTOMS_VIEW = "symptoms";
export default connect(mapStateToProps, mapDispatchToProps)(Symptoms);
