import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Text from '../Text';
import Spinner from '../Spinner';
import IconWithText from '../IconWithText';
import styles from './Button.scss';

const VARIANTS = {
  primary: {
    doctor: { className: 'primary', text: 'secondary', spinner: 'white' },
    patient: { className: 'patient', text: 'secondary', spinner: 'white' },
    danger: { className: 'danger', text: 'secondary', spinner: 'white' },
    success: { className: 'success', text: 'secondary', spinner: 'white' },
  },
  secondary: {
    doctor: { className: 'secondary', text: 'primary', spinner: 'blue' },
    patient: { className: 'secondary', text: 'primary', spinner: 'pink' },
    danger: { className: 'secondary', text: 'error', spinner: 'red' },
    success: { className: 'secondary', text: 'success', spinner: 'blue' },
  },
  tertiary: {
    doctor: { className: 'tertiary', text: 'primary', spinner: 'blue' },
    patient: { className: 'tertiary', text: 'primary', spinner: 'pink' },
    danger: { className: 'tertiary', text: 'error', spinner: 'blue' },
    success: { className: 'tertiary', text: 'success', spinner: 'blue' },
  },
  hold: {
    doctor: { className: 'holdDoctor', text: 'primary', spinner: 'blue' },
    patient: { className: 'holdPatient', text: 'primary', spinner: 'pink' },
    danger: {
      className: 'holdDanger',
      text: 'error',
      spinner: 'blue',
      icon: 'fail',
    },
    success: {
      className: 'holdSuccess',
      text: 'success',
      spinner: 'blue',
      icon: 'success',
    },
  },
};

const Button = ({
  variant,
  children,
  fixedWidth,
  fullWidth,
  onClick,
  className,
  textClassName,
  colorVariant,
  disabled,
  type,
  size,
  testid,
  icon,
  iconColor,
  loading,
  ...props
}) => {
  const hold = variant === 'hold';
  const styleVariant = VARIANTS[variant][colorVariant];
  const _icon = icon || styleVariant.icon;
  const _iconColor = iconColor || styleVariant.text;
  return (
    <button
      data-testid={testid}
      type={type}
      disabled={disabled || loading || hold}
      className={cn(
        styles.button,
        styles[styleVariant.className],
        styles[size],
        {
          [styles.fullWidth]: fullWidth,
          [styles.fixed]: fixedWidth && !fullWidth,
          [styles.loading]: loading,
          [styles.hold]: hold,
        },
        className,
      )}
      {...(!loading && !hold && { onClick })}
      {...props}
    >
      {_icon ? (
        <IconWithText
          iconVariant={_icon}
          iconColor={_iconColor}
          text={children}
          textVariant="h4"
          textColorVariant={styleVariant.text}
          className={cn(styles.text, textClassName, { [styles.hidden]: loading })}
        />
      ) : (
        <Text
          variant="h4"
          colorVariant={styleVariant.text}
          className={cn(styles.text, textClassName, { [styles.hidden]: loading })}
        >
          {children}
        </Text>
      )}
      {loading && (
        <div className={styles.spinner}>
          <Spinner size={24} colorVariant={styleVariant.spinner} />
        </div>
      )}
    </button>
  );
};

Button.defaultProps = {
  fullWidth: false,
  variant: 'primary',
  disabled: false,
  type: 'submit',
  colorVariant: 'doctor',
  fixedWidth: true,
  size: 'medium',
  loading: false,
};

Button.propTypes = {
  /** Вариант отображения кнопки. */
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'hold']),
  /** Вариант цветового отображения кнопки. */
  colorVariant: PropTypes.oneOf(['doctor', 'patient', 'danger', 'success']),
  /** Вытягивает кнопку на всю ширину родительского контейнера */
  fullWidth: PropTypes.bool,
  /** Устанавливает фиксированную ширину кнопки (176px) */
  fixedWidth: PropTypes.bool,
  /** Устанавливает обработчик клика на кнопку */
  onClick: PropTypes.func,
  /** Передает класс для контейнера */
  className: PropTypes.string,
  /** Передает класс для текста */
  textClassName: PropTypes.string,
  /** Если true, блокирует кнопку */
  disabled: PropTypes.bool,
  /** Определяет тип кнопки */
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  size: PropTypes.oneOf(['small', 'medium']),
  /** Устанавливает уникальный QA идентификтор. */
  testid: PropTypes.string,
  /** Устанавливает режим ожидания (убирает текст, показывает spinner). */
  loading: PropTypes.bool,
  icon: Icon.propTypes.variant,
  iconColor: Icon.propTypes.fill,
};

export default Button;
