import React, { useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  DOCUMENT_MODE_ALL,
  documentRequestSelector
} from "~/store/documents/documentsModel";
import documentsActions from "~/store/documents/documentsActions";
import { LOAD_DATA_ERROR } from "~/consts/texts";
import { useFilteredPaginator } from "~/hooks/usePaginator";
import InfiniteList from "~/components/InfiniteList";
import Link from "~/components/Link";
import Loading from "~/components/Loading/Loading";
import EmptyStub from "~/components/EmptyStub";
import DocumentsListItem from "~/layouts/Documents/DocumentsListItem/DocumentsListItem";
import styles from "./DocumentsList.scss";

const DOCUMENTS_LIMIT = 20;

const DocumentsList = () => {
  const listRef = useRef();
  const match = useRouteMatch();

  const {
    items,
    isFetching,
    canLoadMore,
    hasErrors,
    isEmpty,
    mode,
    incrementPage
  } = useFilteredPaginator({
    selector: documentRequestSelector,
    initialMode: DOCUMENT_MODE_ALL,
    ref: useRef(),
    limit: DOCUMENTS_LIMIT,
    sendAction: documentsActions.requestSend
  });

  return (
    <InfiniteList
      canLoadMore={canLoadMore}
      loadMore={incrementPage}
      ref={listRef}
    >
      {items.map(document => (
        <Link
          className={styles.link}
          activeClassName={styles.selected}
          key={document.id}
          to={`${match.url}/${document.id}`}
        >
          <DocumentsListItem
            id={document.id}
            type={document.type}
            file={{
              ...document.file,
              url: "https://unsplash.com/photos/-zqe55fIOq8/download?force=true"
            }}
            author={document.author}
            conclusionId={document.conclusion}
            created_at={document.created_at}
          />
        </Link>
      ))}
      {isFetching && <Loading />}
      {hasErrors && <EmptyStub text={LOAD_DATA_ERROR} centered />}
      {isEmpty && (
        <EmptyStub
          centered
          text={
            mode === DOCUMENT_MODE_ALL
              ? "У вас пока нет документов"
              : "Документы не найдены"
          }
        />
      )}
    </InfiniteList>
  );
};

export default DocumentsList;
