import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  CHECK_PROMOCODE,
  checkPromocodeSuccessAction,
  checkPromocodeErrorAction
} from "./actions";
import promocodeApi from "~/api/promocodeApi";

export function* checkPromocodeSaga({ value }) {
  try {
    const { data } = yield call(promocodeApi.checkPromocodeApi, value);
    if (!data.success) {
      yield put(checkPromocodeErrorAction(data.error));
    } else {
      yield put(
        checkPromocodeSuccessAction(
          data.discountPrice.price,
          data.promotion_code.id
        )
      );
    }
  } catch (err) {
    yield put(
      toastActions.showErrorToast("Произошла ошибка, попробуйте позже")
    );
  }
}

export default function* promocodeSaga() {
  yield all([takeLatest(CHECK_PROMOCODE, checkPromocodeSaga)]);
}
