import React from "react";
import cn from "classnames";

import { videoExtensionsRegExp, imageExtensionsRegExp } from "~/utils/regExps";

import { FolderIcon } from "../../icons";

import { FileIcon } from "./FileIcon";

import { Icon } from "ibolit-ui";

import styles from "./ConsultationFiles.scss";

const FilesControlContainer = ({
  label,
  filesCount,
  files = [],
  onClick,
  placeholder,
  handleDownload,
  handleDownloadAllFiles,
  onDeleteFile,
  needDownloadAll,
  isBlueIconColor,
}) => (
  <div className={styles.filesControlContainer}>
    <div className={styles.filesControlHeader}>
      <div className={styles.filesData}>
        <div
          className={styles.filesControlLabel}
          data-testid="Receptions__ConsultationFiles-label"
        >
          {label}
        </div>
        <div
          className={styles.filesCount}
          data-testid="Receptions__ConsultationFiles-filesCount"
        >
          Количество файлов: {filesCount}
        </div>
      </div>
      {needDownloadAll && filesCount !== 0 ? (
        <button
          className={styles.downloadAllButton}
          onClick={handleDownloadAllFiles(files)}
          data-testid="Receptions__ConsultationFiles-button-download-all"
        >
          Скачать все
        </button>
      ) : null}
    </div>

    <div className={styles.filesControlBody} onClick={onClick}>
      {files.length === 0 ? (
        <div className={styles.placeholderContainer}>
          <FolderIcon />
          <div
            className={styles.placeholderHeader}
            data-testid="Receptions__ConsultationFiles-no-files"
          >
            Загруженных файлов нет
          </div>
          <div
            className={styles.placeholderText}
            data-testid="Receptions__ConsultationFiles-placeholder"
          >
            {placeholder}
          </div>
        </div>
      ) : (
        <div className={styles.containerFiles}>
          {files.map((file) => {
            imageExtensionsRegExp.lastIndex = 0;
            videoExtensionsRegExp.lastIndex = 0;
            const isFileVideo = videoExtensionsRegExp.test(file.extension);
            const isImage = imageExtensionsRegExp.test(file.extension);

            let Placeholder = () => (
              <FileIcon
                className={cn(
                  styles.fileIcon,
                  isBlueIconColor && styles.fileIconDoctor
                )}
              />
            );

            switch (true) {
              case isFileVideo:
                Placeholder = () => (
                  <video src={file.url} className={styles.filePlacehoder} />
                );
                break;
              case isImage:
                Placeholder = () => (
                  <img src={file.url} className={styles.filePlacehoder} />
                );
                break;
              default:
                break;
            }

            return (
              <div
                className={styles.fileContainer}
                onClick={handleDownload(file.url, file.name)}
                data-testid="Receptions__ConsultationFiles-download_file_button"
                key={file.url}
              >
                <div className={styles.fileImg}>
                  <Placeholder />
                  <FileIcon
                    className={cn(
                      styles.fileIcon,
                      isBlueIconColor && styles.fileIconDoctor
                    )}
                  />
                </div>
                <div className={styles.fileInfoContainer}>
                  <div className={styles.fileName}>
                    <div className={styles.fileNameText}>{file.name}</div>
                  </div>
                  <div className={styles.fileInfo}>
                    {file.extension}, {file.size}
                  </div>
                </div>
                {onDeleteFile ? (
                  <div className={styles.deleteFileContainer}>
                    <Icon
                      variant="trash"
                      onClick={onDeleteFile(file.id)}
                      testid="Receptions__ConsultationFiles-delete_file_button"
                    />
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      )}
    </div>
  </div>
);

export const ConsultationFiles = ({
  onDoctorClick = () => null,
  onPatientClick = () => null,
  patientFiles,
  doctorFiles,
  doctorPlaceholder,
  patientPlaceholder,
  handleDownloadFile,
  handleDownloadAllFiles,
  handleDeleteFile,
  mode,
}) => {
  return (
    <div className={styles.container}>
      <FilesControlContainer
        label="Файлы пациента"
        filesCount={patientFiles.length}
        onClick={onPatientClick}
        files={patientFiles}
        placeholder={patientPlaceholder}
        handleDownload={handleDownloadFile}
        handleDownloadAllFiles={handleDownloadAllFiles}
        onDeleteFile={mode === "patient" ? handleDeleteFile : null}
        needDownloadAll={mode === "doctor"}
      />
      <FilesControlContainer
        label="Файлы врача"
        filesCount={doctorFiles.length}
        onClick={onDoctorClick}
        files={doctorFiles}
        placeholder={doctorPlaceholder}
        handleDownload={handleDownloadFile}
        handleDownloadAllFiles={handleDownloadAllFiles}
        onDeleteFile={mode === "doctor" ? handleDeleteFile : null}
        needDownloadAll={mode === "patient"}
        isBlueIconColor={Boolean(mode === "doctor")}
      />
    </div>
  );
};
