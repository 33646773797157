import firebase from "firebase/app";
import { takeLatest, call, put, all } from "redux-saga/effects";
import types from "~/store/pushNotifications/pushNotificationTypes";
import actions from "~/store/pushNotifications/pushNotificationActions";
import notificationFileName from "~/firebase-messaging-sw";
import api from "~/api/pushNotification";
import { setPushToken } from "~/utils/localStorageHelper";

// From the firebase settings of the project
// https://console.firebase.google.com/u/1/project/ibolit-patient/settings/general/web:NzJkMzRmZDUtNmNlMC00YmI5LWJmMGUtMDM5ZWQ4NjU5NDJh
const firebaseConfig = {
  apiKey: "AIzaSyBrkmIyXLN97BTK156M56JhRueAeq6qDvs",
  authDomain: "ibolit-patient.firebaseapp.com",
  databaseURL: "https://ibolit-patient.firebaseio.com",
  projectId: "ibolit-patient",
  storageBucket: "ibolit-patient.appspot.com",
  messagingSenderId: "558981849159",
  appId: "1:558981849159:web:6a547bcb41cd7efd671162",
  measurementId: "G-9R4M6TRL87"
};

// From the firebase settings of the project
// https://console.firebase.google.com/u/1/project/ibolit-patient/settings/cloudmessaging/ios:pro.ibolit.iBolitPatient
const vapidKey =
  "BK-uY1C8a2MIra9Xgn99Ofc4L37F-JVa_IFmkisuwVtXrbdAmts6xnvRkt3pE8mIzTz-SQyv4oUsjHCgITanoxs";

let messagingInstance = null;
let serviceWorkerRegistration = null;

export const getMessaging = () => {
  if (messagingInstance !== null) {
    return messagingInstance;
  }

  const app = firebase.initializeApp(firebaseConfig);
  messagingInstance = firebase.messaging(app);

  return messagingInstance;
};

const registration = async () => {
  if (!("serviceWorker" in navigator))
    throw new Error("Service Workers are not supported");
  serviceWorkerRegistration = await navigator.serviceWorker.register(
    notificationFileName
  );
  const permission = await Notification.requestPermission();
  if (permission !== "granted") {
    throw new Error("Разрешение на отображение уведомлений не получено");
  }
  const messaging = getMessaging();
  const token = await messaging.getToken({
    vapidKey,
    serviceWorkerRegistration
  });

  return token;
};

function* registrationSaga() {
  try {
    const token = yield registration();
    if (!token) {
      throw new Error("PUSH-токен не получен");
    }
    yield call(api.sendTokenToServer, token);
    setPushToken(token);
    yield put(actions.registrationSuccess());
  } catch (err) {
    yield put(actions.error(err));
  }
}

function pushNotification() {
  try {
    const messaging = getMessaging();

    // onMessage is called when the notification arrives in the foreground
    messaging.onMessage(event => {
      // The service worker is responsible for showing the notifications. send a message to the
      // service worker, so it could show a notification even when it arrives in the foreground
      serviceWorkerRegistration.active.postMessage({
        notification: event.notification,
        notificationData: event.data
      });
    });
  } catch (err) {
    console.warn(err);
  }
}

export default function* slots() {
  yield all([
    takeLatest(types.PUSH_NOTIFICATION_REGISTRATION, registrationSaga),
    takeLatest(types.PUSH_NOTIFICATION_REGISTRATION_SUCCESS, pushNotification)
  ]);
}
