/* eslint-disable */
function plural(n, str1, str2, str5) {
  return `${n} ${
    n % 10 === 1 && n % 100 !== 11
      ? str1
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? str2
      : str5
  }`;
}

export const pluralizeYears = val =>
  plural(val.toFixed(0), "год", "года", "лет");
export const pluralizeDays = val =>
  plural(val.toFixed(0), "день", "дня", "дней");
export const pluralizeHours = val =>
  plural(val.toFixed(0), "час", "часа", "часов");
export const pluralizeMinutes = val =>
  plural(val.toFixed(0), "минута", "минуты", "минут");
export const pluralizePerMinute = val =>
  plural(val.toFixed(0), "минуту", "минуты", "минут");
