import React from "react";
import Consultation from "../Consultation";
import Conclusion from "./Conclusion";

const ConclusionSwitcher = ({ consultation, ...props }) => {
  if (consultation) {
    return <Consultation id={consultation} isConclusionMessage />;
  }

  return <Conclusion {...props} />;
};

export default ConclusionSwitcher;
