import React from "react";
import cn from "classnames";

import styles from "./ConsultationVideoInfo.scss";

export const ConsultationVideoInfo = ({ videoUrl, openVideoModal }) => {
    const handleOpenVideoModal = () => {
        openVideoModal(videoUrl);
    };

    if (!videoUrl) {
        return (
            <div className={cn(styles.container, styles.container_noVideo)}>
                <div className={styles.videoContainer}>
                    <svg
                        width="32"
                        height="18"
                        viewBox="0 0 32 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.videoIcon}
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M32 17.212C32 17.5394 31.6282 17.7281 31.3639 17.5348L22.1538 10.8V16C22.1538 17.1046 21.2584 18 20.1538 18H2C0.895428 18 0 17.1046 0 16V2C0 0.89543 0.895431 0 2 0H20.1538C21.2584 0 22.1538 0.89543 22.1538 2V7.2L31.3639 0.465154C31.6282 0.271905 32 0.460645 32 0.788036V17.212Z"
                            fill="white"
                        />
                    </svg>
                </div>
                <div
                    className={styles.textContainer}
                    data-testid="Receptions__ConsultationVideoInfo__textContainer"
                >
                    Тут появится видео запись вашей консультации
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.dataContainer}>
                <div className={styles.videoContainer}>
                    <video src={videoUrl} className={styles.video} />
                    <svg
                        width="32"
                        height="18"
                        viewBox="0 0 32 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.videoPlaceholder}
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M32 17.212C32 17.5394 31.6282 17.7281 31.3639 17.5348L22.1538 10.8V16C22.1538 17.1046 21.2584 18 20.1538 18H2C0.895428 18 0 17.1046 0 16V2C0 0.89543 0.895431 0 2 0H20.1538C21.2584 0 22.1538 0.89543 22.1538 2V7.2L31.3639 0.465154C31.6282 0.271905 32 0.460645 32 0.788036V17.212Z"
                            fill="white"
                        />
                    </svg>
                </div>
                <div className={styles.textContainer}>Запись видеоконсультации</div>
            </div>
            <div className={styles.buttonContainer}>
                <button className={styles.button} onClick={handleOpenVideoModal}>
                    Смотреть
                </button>
            </div>
        </div>
    );
};
