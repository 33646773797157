import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useFilteredPaginator = ({
  initialMode,
  selector,
  ref,
  limit,
  sendAction
}) => {
  const [search, setSearch] = useState("");
  const [mode, setMode] = useState(initialMode);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const fetchItems = useCallback(
    (...args) => {
      dispatch(sendAction(...args));
    },
    [sendAction]
  );

  const {
    items,
    isFetching,
    initialFetched,
    canLoadMore,
    hasErrors,
    isEmpty
  } = useSelector(state => selector(state, mode));

  useEffect(() => {
    fetchItems({
      value: search,
      mode,
      page,
      limit
    });
  }, [search, mode, page]);

  useEffect(() => {
    setPage(1);
    ref.current && ref.current.scrollToTop();
  }, [search, mode]);

  const incrementPage = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  return {
    setSearch,
    mode,
    setMode,
    incrementPage,
    items,
    isFetching,
    initialFetched,
    canLoadMore,
    hasErrors,
    isEmpty
  };
};
