export const CLINICS_LIST = {
  HEADER: "clinics__contactsList__header",
  CONTAINER: "clinics__contactsList__container",
  USER_ITEM: "clinics__contactList__user-item"
};

export const CONTACT = {
  HEADER_DESCRIPTION: "contacts__contact__header-description",
  AVATAR: "contacts__contact__avatar",
  NAME: "contacts__contact__name",
  EXPERIENCE: "contacts__contact__experience",
  EDUCATION: "contacts__contact__education",
  CATEGORY: "contacts__contact__category",
  CONSULTATION: "contacts__contact__consultation",
  BUTTON_TARIFFS: "contacts__contact__button-tariffs",
  DESCRIPTION: "contacts__contact__description-wrapper",
  CLINIC: "contacts__contact__clinic",
  CLINIC_DESCRIPTION: "contacts__contact__clinic_description"
};
