import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon } from 'ibolit-ui';
import styles from './RoundButton.scss';

const RoundButton = ({ disabled, variant, isDenied, onClick }) => {
  const iconVariant = variant && variant.length > 0 && variant[0];
  const iconFill = variant.some(v => v === 'medium') ? 'var(--grey)' : 'var(--white)';

  return (
    <div
      className={cn(styles.roundButton, { [styles.isActive]: !disabled })}
      onClick={!disabled ? onClick : () => {}}
      role="button"
      tabIndex={0}
    >
      <div
        className={cn(
          styles.circle,
          ...variant.map(v => styles[v]),
          { [styles.disabled]: disabled },
        )}
      >
        <div className={cn(styles.bgIcon, { [styles.denied]: isDenied })}>
          <Icon variant={iconVariant} fill={iconFill} />
        </div>
      </div>
    </div>
  );
};

RoundButton.propTypes = {
  disabled: PropTypes.bool,
  variant: PropTypes.arrayOf(PropTypes.string),
  isDenied: PropTypes.bool,
  onClick: PropTypes.func,
};

export default RoundButton;
