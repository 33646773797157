import React from "react";
import cn from "classnames";
import { Input, Icon } from "ibolit-ui";

import styles from "./SearchIconInput.scss";

export const SearchIconInput = ({
  placeholder,
  className,
  testId,
  onChange
}) => {
  return (
    <div
      className={cn(styles.searchContainer, className)}
      data-testid={`${testId}_container`}
    >
      <Input
        placeholder={placeholder}
        className={styles.search}
        data-testId={`${testId}_input`}
        onChange={onChange}
      />
      <Icon
        size="s"
        className={styles.searchIcon}
        variant="search11"
        data-testId={`${testId}_icon`}
      />
    </div>
  );
};
