import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { Textarea, Button, Text, Dialog } from "ibolit-ui";
import { SUPPORT } from "~/locators/privateProfile";
import styles from "./ProfileSupport.scss";

const ProfileSupport = ({
  onBackClick,
  error,
  loading,
  handleSubmit,
  isSubmitting,
  isValid,
  values,
  errors,
  handleChange
}) => (
  <Dialog
    testId="ProfileSupportModal"
    header="Служба поддержки"
    onBackClick={onBackClick}
    testIdTitle={SUPPORT.TITLE}
    backTestId={SUPPORT.ICON_BACK}
    closeTestId={SUPPORT.ICON_CLOSE}
    boxClassName={styles.modalBox}
  >
    <form onSubmit={handleSubmit} action="">
      <Textarea
        testidProps={{
          textarea: SUPPORT.TEXTAREA
        }}
        focus
        fullWidth
        nativeChange
        name="text"
        value={values.text}
        onChange={handleChange}
        placeholder="Опишите проблему с которой вы столкнулись"
        disabled={loading}
        className={styles.field}
      />
      {isSubmitting && errors.text && (
        <Text
          testid={SUPPORT.TEXTAREA_ERROR}
          variant="desc"
          colorVariant="error"
        >
          {errors.text}
        </Text>
      )}
      <Button
        testid={SUPPORT.SEND_BUTTON}
        fullWidth
        disabled={!isValid || loading}
      >
        Отправить
      </Button>
      {error && (
        <Text testid={SUPPORT.ERROR} variant="desc" colorVariant="error">
          {error}
        </Text>
      )}
    </form>
  </Dialog>
);

const validate = values => {
  const result = {};

  if (!values.text) {
    result.text = "This field is required";
  }

  return result;
};

const handleSubmit = (values, { props }) => {
  props.onFormSubmit(values);
};

ProfileSupport.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string
};

export default withFormik({
  mapPropsToValues: () => ({ text: "" }),
  validate,
  handleSubmit
})(ProfileSupport);
