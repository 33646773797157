import cn from "classnames";
import React, { memo } from "react";
import PropTypes from "prop-types";
import { ActiveText } from "ibolit-ui";
import styles from "./Bubble.scss";

const Bubble = ({ variant, text }) => {
  const textProps = {
    className: styles.text,
    variant: "desc",
    colorVariant: "inherit"
  };
  return (
    <div className={styles.bubbleWrapper}>
      <div className={cn(styles.bubble, styles[variant])}>
        <ActiveText
          text={text}
          textProps={textProps}
          activeClassName={styles[variant]}
        />
      </div>
    </div>
  );
};

Bubble.defaultProps = {
  variant: "primary"
};

Bubble.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary"]),
  text: PropTypes.string.isRequired
};

export default memo(Bubble);
