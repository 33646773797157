import { useEffect } from "react";
import throttle from "lodash/throttle";
import styles from "~/layouts/Chats/Chat/ChatMessagesList/components/MessagesList/MessagesList.scss";

const SHOW_TIME = 1000;
const THROTTLE_DELAY = 300;
export const useChatBubble = (scrollRef, size) => {
  useEffect(() => {
    const targets = scrollRef.current.view.querySelectorAll(
      "[data-observer=target]"
    );
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entity => {
          if (entity.isIntersecting) {
            if (!entity.target.classList.contains(styles.bubble_text)) {
              entity.target.classList.add(styles.bubble_text);
            }
          } else {
            if (entity.target.classList.contains(styles.bubble_text)) {
              entity.target.classList.remove(styles.bubble_text);
            }
          }
        });
      },
      {
        root: scrollRef.current.view,
        rootMargin: "-35px 0px 100px 0px"
      }
    );

    targets.forEach(target => {
      observer.observe(target);
    });
    let timeout = null;
    const catchScroll = throttle(() => {
      targets.forEach(target => {
        if (target.classList.contains(styles.bubble_text)) {
          return;
        }
        if (!target.classList.contains(styles.bubble_visible)) {
          target.classList.add(styles.bubble_visible);
        }
      });
      if (timeout !== null) {
        clearTimeout(timeout);
        timeout = null;
      }
      timeout = setTimeout(() => {
        targets.forEach(target => {
          if (target.classList.contains(styles.bubble_text)) {
            return;
          }
          if (target.classList.contains(styles.bubble_visible)) {
            target.classList.remove(styles.bubble_visible);
          }
        });
        clearTimeout(timeout);
        timeout = null;
      }, SHOW_TIME);
    }, THROTTLE_DELAY);
    scrollRef.current.view.addEventListener("scroll", catchScroll);
    return () => {
      scrollRef.current.view.removeEventListener("scroll", catchScroll);
      targets.forEach(target => {
        observer.unobserve(target);
      });
    };
  }, [size]);
};
