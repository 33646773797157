import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import PropTypes from "prop-types";
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import ModalsRoot from "~/modals/ModalsRoot";
import modalsMap from "~/modals/modalsMap";
import appStartedActon from "~/store/appStarted/appStartedAction";
import PrivateRoute from "~/components/PrivateRoute/PrivateRoute";
import AppContainer from "~/layouts/App/AppContainer";
import { history } from "~/store/store";
import Authorization from "~/layouts/Authorization/Authorization";
import NotFound from "~/components/NotFound";
import Chats from "~/layouts/Chats";
import Clinics from "~/layouts/Clinics";
import Documents from "~/layouts/Documents";
import Consultations from "~/layouts/Consultations";
import { AuthFromWidget } from "~/layouts/AuthFromWidget";
import "./ym";
import "./sentryConfig";
import "./styles/colors.scss";
import "./styles/_reset.scss";
import "./styles/basic.scss";
import "./assets/fonts/Roboto/Roboto.scss";
import "react-toastify/dist/ReactToastify.css";
import { FROM_WIDGET_USER_INFO_VIEW } from "~/modals/FromWidget";

import { useShowModal } from "~/hooks/useModal";
import { getUser } from "~/store/auth/authSelectors";

import toastStyles from "./styles/toastStyles.scss";
import Mobile from "~/layouts/Mobile";
import { getMobileOperatingSystem } from "~/utils/mobile";
import {
  getAuthRoute,
  getChatsRoute,
  getConsultationRoute
} from "~/utils/routes/getRoutes";

const App = ({ setAppStarted: setAppStartedAction }) => {
  useEffect(() => {
    setAppStartedAction();
  }, []);

  const { gender, date_of_birth } = useSelector(getUser) || {};

  const hasUserInfo = useMemo(() => gender !== null && date_of_birth !== null, [
    gender,
    date_of_birth
  ]);

  const showUserInfoModal = useShowModal(FROM_WIDGET_USER_INFO_VIEW, {
    hasUserInfo,
    preventClick: !hasUserInfo
  });

  useEffect(() => {
    if (!hasUserInfo) {
      showUserInfoModal();
    }
  }, [hasUserInfo]);

  const mobileOS = useMemo(() => getMobileOperatingSystem(), []);

  return mobileOS !== null ? (
    <Mobile os={mobileOS} />
  ) : (
    <BrowserRouter>
      <ConnectedRouter history={history}>
        <AppContainer>
          <Switch>
            <Route exact path={getAuthRoute()} component={Authorization} />
            <Route exact path="/notfound" component={NotFound} />
            <Route exact path="/auth/widget" component={AuthFromWidget} />
            <PrivateRoute
              path={getConsultationRoute({})}
              component={Consultations}
            />
            <PrivateRoute path={getChatsRoute({})} component={Chats} />
            <PrivateRoute path="/clinics" component={Clinics} />
            <PrivateRoute path="/documents" component={Documents} />
            <PrivateRoute
              exact
              path="/"
              component={() => <Redirect to="chats" />}
            />
            <Redirect to="/notfound" />
          </Switch>
        </AppContainer>
        <ModalsRoot modalsMap={modalsMap} idDOMRoot="modal-root" />
        <ToastContainer
          position={toast.POSITION.TOP_RIGHT}
          className={cn("iBolitToast", toastStyles.container)}
          toastClassName={toastStyles.toast}
          progressClassName={toastStyles.progress}
          bodyClassName={toastStyles.body}
          enableMultiContainer
          containerId="notification"
          closeButton={false}
          closeOnClick={false}
          autoClose={5000}
        />
        <ToastContainer
          position={toast.POSITION.TOP_CENTER}
          className={cn("iBolitToast", toastStyles.container)}
          toastClassName={toastStyles.toast}
          progressClassName={toastStyles.progress}
          bodyClassName={toastStyles.body}
          enableMultiContainer
          containerId="toast"
          pauseOnFocusLoss={false}
          closeOnClick={false}
          closeButton={false}
          autoClose={3000}
        />
      </ConnectedRouter>
    </BrowserRouter>
  );
};

App.propTypes = {
  setAppStarted: PropTypes.func.isRequired
};

export default connect(null, { ...appStartedActon })(App);
