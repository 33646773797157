import cn from 'classnames';
import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './Separator.scss';

const Separator = ({ className, style }) => (
  <div style={style} className={cn(styles.separator, className)} />
);

Separator.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Separator;
