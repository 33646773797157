import React from "react";
import { Text } from "ibolit-ui";
import styles from "./Conslusion.scss";

const CONCLUSION_CHAT_MSG = "Добавлено заключение";

const Conclusion = ({ body, title = CONCLUSION_CHAT_MSG }) => (
  <div className={styles.conclusionText}>
    <Text variant="h4" className={styles.conclusionText_title}>
      {title}
    </Text>
    <Text tag="pre" variant="h5" className={styles.conclusionText_body}>
      {body}
    </Text>
  </div>
);

export default Conclusion;
