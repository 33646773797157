import React, { useState } from "react";
import classnames from "classnames";

import { ConsultationRatingIcon } from "./ConsultationRatingIcon";

import { Text, Icon, Input, Separator } from "ibolit-ui";

import RowWithImage from "../../components/RowWithImage";
import UserAvatar from "../../components/UserAvatar";

import { StarRating } from "../../components/StarRating";

import styles from "./ConsultationRating.scss";

function ProfileTitle({ fullName, rating, createdAt, testid }) {
  return (
    <div>
      <Text variant="h4" tag="h4" testid={testid} className={styles.patientName}>
        {fullName}
      </Text>
      {rating && <StarRating rating={rating} />}
    </div>
  );
}

export const ConsultationRating = ({
  feedback,
  title,
  withIcon,
  noDate = false,
  handleDelete,
  handleDeleteReply,
  handleReplyConsultationRatingCall,
  type,
  handleEdit,
}) => {
  const [commentValue, setCommentValue] = useState("");
  const [showCommentField, setShowCommentField] = useState(false);

  const handleSetComment = (e) => {
    setCommentValue(e.target.value);
  };
  const handleSendMessage = (reply) => () => {
    handleReplyConsultationRatingCall(reply);
    setCommentValue("");
  };

  const handleToggleCommentField = () => setShowCommentField(!showCommentField);

  const { reply } = feedback;

  return (
    <div className={styles.consultationRating}>
      <div className={styles.container}>
        <RowWithImage
          className={styles.rowWithImage}
          title={
            <ProfileTitle
              fullName={title ? title : feedback.patient.full_name}
              rating={feedback.rating}
              createdAt={!noDate && feedback.created_at}
              testid={"ConsultationRating__patientName"} 
            />
          }
          image={
            withIcon ? (
              <ConsultationRatingIcon />
            ) : (
              <UserAvatar size="small" user={feedback.patient} />
            )
          }
          imageClass={styles.avatar}
        >
          <div
            className={styles.comment}
            data-testId="Receptions__ConsultationRating__feedbackComment"
          >
            {feedback.comment}
          </div>
          <div className={styles.operations}>
            {handleReplyConsultationRatingCall && !reply && (
              <Icon
                variant="outline-reply"
                className={classnames(styles.operations_icon, {
                  [styles["operations_icon-active"]]: showCommentField,
                })}
                onClick={handleToggleCommentField}
                testid="Receptions__ConsultationRating__replyButton"
              />
            )}
            {handleDelete && (
              <Icon
                variant="outline-bin"
                className={styles.operations_icon}
                onClick={() => handleDelete(feedback.id)}
              />
            )}
          </div>
        </RowWithImage>
        {reply && (
          <>
            <Separator className={styles.replySeparator} />
            <RowWithImage
              className={styles.rowWithImage}
              title={
                <ProfileTitle
                  fullName={feedback.doctor.full_name}
                  createdAt={!noDate && reply.created_at}
                  testid={"ConsultationRating__replyName"}
                />
              }
              image={<UserAvatar size="small" user={feedback.doctor} />}
              imageClass={styles.avatar}
            >
              <div
                className={styles.comment}
                data-testId="Receptions__ConsultationRating__replyComment"
              >
                {reply.comment}
              </div>
              <div className={styles.operations}>
                {type === "doctor" ? (
                  <Icon
                    variant="outline-bin"
                    className={styles.operations_icon}
                    onClick={() => handleDeleteReply(reply.id)}
                    testid="Receptions__ConsultationRating__deleteReply"
                  />
                ) : null}
              </div>
            </RowWithImage>
          </>
        )}
      </div>
      {showCommentField && !reply && (
        <>
          <div className={styles.messages}>
            <Input
              nativeChange
              placeholder="Сообщение…"
              autocomplete="off"
              className={styles.messages__input}
              value={commentValue}
              onChange={handleSetComment}
              containerClass={styles.messages__input_container}
              maxlength={1000}
              testidProps={{
                input: "Receptions__ConsultationRating__input"
              }}
            />
            <Icon
              variant="send"
              className={styles.messages__icon}
              fill={"primary"}
              onClick={handleSendMessage(commentValue)}
              testid="Receptions__ConsultationRating__send"
            />
          </div>
        </>
      )}
    </div>
  );
};
