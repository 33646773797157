import types from "./newConsultationTypes";

export default {
  selectClinic: selectedClinic => ({
    type: types.NEW_CONSULTATION_SELECT_CLINIC,
    selectedClinic
  }),
  selectDoctor: selectedDoctor => ({
    type: types.NEW_CONSULTATION_SELECT_DOCTOR,
    selectedDoctor
  }),
  selectSlot: selectedSlot => ({
    type: types.NEW_CONSULTATION_SELECT_SLOT,
    selectedSlot
  }),
  selectSlotType: selectedSlotType => ({
    type: types.NEW_CONSULTATION_SELECT_SLOT_TYPE,
    selectedSlotType
  }),
  selectDate: selectedDate => ({
    type: types.NEW_CONSULTATION_SELECT_DATE,
    selectedDate
  }),
  getSymptoms: () => ({
    type: types.NEW_CONSULTATION_SYMPTOMS_REQUEST
  }),
  createSymptom: (
    symptom,
    consultation,
    isBackendCreated,
    onClose,
    doctor
  ) => ({
    type: types.NEW_CONSULTATION_SYMPTOM_CREATE_REQUEST,
    symptom,
    consultation,
    isBackendCreated,
    onClose,
    doctor
  }),
  updateSymptom: (
    symptom,
    consultation,
    isBackendCreated,
    onClose,
    doctor
  ) => ({
    type: types.NEW_CONSULTATION_SYMPTOM_UPDATE_REQUEST,
    symptom,
    consultation,
    isBackendCreated,
    onClose,
    doctor
  }),
  post: ({
    doctorId,
    tariffId,
    tariffType,
    slotId,
    problemId,
    price,
    doctor,
    startAt
  }) => ({
    type: types.CONSULTATIONS_POST_REQUEST,
    doctorId,
    tariffId,
    tariffType,
    slotId,
    problemId,
    price,
    doctor,
    startAt
  }),
  fillDateTimeData: payload => ({
    type: types.FILL_DATE_TIME_DATA,
    payload
  })
};
