const PLACEHOLDER_COLORS = [
  'rgb(229, 115, 115)',
  'rgb(244, 143, 177)',
  'rgb(206, 147, 216)',
  'rgb(159, 168, 218)',
  'rgb(128, 222, 234)',
  'rgb(165, 214, 167)',
  'rgb(255, 224, 130)',
  'rgb(255, 171, 145)',
  'rgb(197, 225, 165)',
  'rgb(144, 202, 249)',
];

const getColorByUserId = userId => (
  PLACEHOLDER_COLORS[Math.floor(userId % PLACEHOLDER_COLORS.length)]
);

export default getColorByUserId;
