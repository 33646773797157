import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { Text } from "ibolit-ui";
import styles from "./Article.scss";

const Article = ({
  className,
  style,
  title,
  children,
  text,
  noFlex,
  testid
}) => (
  <article
    className={cn(styles.container, className, {
      [styles.flex]: !noFlex
    })}
    style={style}
    data-testid={testid}
  >
    <Text variant="h3" colorVariant="primary" tag="header">
      {title}
    </Text>
    {text && (
      <Text variant="desc" tag="div" className={styles.text}>
        {text}
      </Text>
    )}
    {children}
  </article>
);

Article.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string,
  title: PropTypes.string,
  noFlex: PropTypes.bool,
  testid: PropTypes.string
};

export default Article;
