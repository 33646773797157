const isImage = filename => /\.(gif|jpg|jpeg|tiff|png)$/i.test(filename);

export default isImage;

const imageExt = {
  png: "png",
  jpg: "jpg",
  jpeg: "jpeg"
};

export const isImageByExtension = extension =>
  !!imageExt[extension.toLowerCase()];
