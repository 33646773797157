import { eventChannel } from "redux-saga";
import { takeLatest, call, take, select, put } from "redux-saga/effects";
import { normalize } from "normalizr";
import { getCurrentUserId } from "../auth/authSelectors";
import WebSocketClient from "~/utils/WebSocketClient";
import { getToken } from "~/utils/localStorageHelper";
import authTypes from "~/store/auth/authTypes";
import wsActions from "~/store/webscoket/wsActions";
import servicesAction from "~/store/services/servicesActions";
import consultationActions from "~/store/consultation/consultationActions";
import { consultationSchema } from "~/store/consultation/consultationNormalizer";

const webSocketClient = new WebSocketClient();

const webSocketChannel = ({ token, currentUserId }) =>
  eventChannel(emitter => {
    webSocketClient.init({ token, host: __WS__ });

    webSocketClient.addListeners(`chat.${currentUserId}`, [
      [".chatMessageNewV3", event => emitter(wsActions.setChatMessage(event))],
      [".update", event => emitter(wsActions.updateChat(event))]
    ]);
    webSocketClient.addListeners(`toolbar.${currentUserId}`, [
      [".update", event => emitter(wsActions.setToolbarUpdate(event))]
    ]);
    webSocketClient.addListeners(`user.${currentUserId}.conclusion`, [
      [".add", event => emitter(wsActions.addConclusion(event))]
    ]);
    webSocketClient.addListeners(`user.${currentUserId}.consultations`, [
      [".started", event => emitter(wsActions.consultationStarted(event))],
      [".ended", event => emitter(wsActions.consultationEnded(event))],
      [".paid", event => emitter(wsActions.consultationPaid(event))],
      [".moved", event => emitter(wsActions.slotMoved(event))],
      [".enrolled", event => emitter(wsActions.slotEnrolled(event))],
      [".canceled", event => emitter(wsActions.slotCanceled(event))],
      [
        ".file-attached",
        event => {
          const {
            entities: { consultations: items }
          } = normalize(event, consultationSchema);

          return emitter(consultationActions.getInfoSuccess({ items }));
        }
      ]
    ]);
    webSocketClient.addListeners(`user.${currentUserId}.service`, [
      [".paid", event => emitter(servicesAction.paid(event))]
    ]);

    return () => {
      webSocketClient.close();
    };
  });

const webSocketDisconnect = () => {
  webSocketClient.close();
};

function* webSocketSaga() {
  const token = getToken();
  const currentUserId = yield select(getCurrentUserId);

  const channel = yield call(webSocketChannel, { token, currentUserId });

  while (true) {
    try {
      const action = yield take(channel);
      yield put(action);
    } catch (err) {
      yield put({ type: `@@err/WEB_SOCKET_SAGA`, err });
    }
  }
}

export default [
  takeLatest(authTypes.USER_AUTHORIZE_SUCCESS, webSocketSaga),
  takeLatest(authTypes.LOGOUT, webSocketDisconnect)
];
