import React from "react";
import { Route, Switch } from "react-router-dom";
import EmptyStub from "~/components/EmptyStub";
import PublicProfileContainer from "~/layouts/Clinics/PublicProfile/PublicProfile";
import FadeGroup from "~/components/FadeGroup";
import ClinicsAside from "./ClinicsAside";
import styles from "./ClinicsLayout.scss";
import ClinicDetails from "./ClinicDetails";

const ClinicsLayout = ({ match }) => (
  <div className={styles.main}>
    <ClinicsAside />
    <FadeGroup>
      <Switch>
        <Route exact path={match.url}>
          <EmptyStub
            centered
            key="stub"
            text={"Выберите клинику в левой\nколонке"}
          />
        </Route>
        <Route exact path={`${match.url}/:clinicId`}>
          {({
            match: {
              params: { clinicId }
            }
          }) => <ClinicDetails clinicId={+clinicId} />}
        </Route>
        <Route path={`${match.url}/doctors/:userId`}>
          {({
            match: {
              params: { userId }
            }
          }) => <PublicProfileContainer userId={+userId} />}
        </Route>
      </Switch>
    </FadeGroup>
  </div>
);

export default ClinicsLayout;
