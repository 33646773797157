import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import Icon from '../Icon';
import styles from './Label.scss';
import useHover from '../../utils/useHover';

const Label = ({ title, subTitle, className, children, testid, titleStyle, infoText }) => {
  const { isHover, onMouseOver, onMouseOut } = useHover(Boolean(infoText));

  return (
    <div className={cn(styles.container, className)}>
      {(title || subTitle) && (
        <div className={styles.header}>
          {title && (
            <Text style={titleStyle} testid={testid} variant="desc" weight="bold">
              {title}
            </Text>
          )}
          {subTitle && (
            <Text variant="caption" colorVariant="tertiary" className={styles.subTitle}>
              {subTitle}
            </Text>
          )}
          {infoText && (
            <div
              className={styles.infoIconContainer} 
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
            >
              <Icon 
                variant="infoOutlined"
                fill="var(--silver)"
                size="s"
                style={{ verticalAlign: 'top' }}
                testid={`${testid}__INFO_ICON`}
              />
              {isHover ? (
                <Text variant="sub2" weight="bold" className={styles.infoIconText}
                testid={`${testid}__INFO_TEXT`}>
                  {infoText}
                </Text>
              ) : null}
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

Label.propTypes = {
  /** Главный заголовок. */
  title: PropTypes.string,
  /** Дополнительное описание справа от заголовка. */
  subTitle: PropTypes.string,
  /** Устанавливает для контейнера имя класса. */
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** Устанавливает уникальный QA идентификтор. */
  testid: PropTypes.string,
  /** Устанавливает стили для title */
  titleStyle: PropTypes.object,
};

export default Label;
