import cn from 'classnames';
import React, { useState, useRef, useLayoutEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './Dropdown.scss';

const Dropdown = ({ children, content, position }) => {
  const buttonRef = useRef();
  const contentRef = useRef();
  const [show, setShow] = useState(false);

  const onWindowClickMemo = useCallback(event => {
    if (!contentRef.current.contains(event.target)) {
      setShow(false);
    }
  }, []);

  const onContainerClick = useCallback(event => {
    event.stopPropagation();

    setShow(true);
  }, []);

  useLayoutEffect(() => {
    if (show) {
      window.addEventListener('click', onWindowClickMemo);
    } else {
      buttonRef.current.addEventListener('click', onContainerClick);
    }

    return () => {
      if (show) {
        window.removeEventListener('click', onWindowClickMemo);
      } else {
        buttonRef.current.removeEventListener('click', onContainerClick);
      }
    };
  }, [show]);

  const button = useMemo(() => (typeof children === 'function' ? children(show) : children), [
    children,
    show,
  ]);

  return (
    <div className={styles.container}>
      <div ref={buttonRef}>{button}</div>
      {show && (
        <div ref={contentRef} className={cn(styles.content, styles[position])}>
          {content}
        </div>
      )}
    </div>
  );
};

Dropdown.defaultProps = {
  position: 'left-top',
};

Dropdown.propTypes = {
  /** Устанавливает позицию контент области относительно children */
  position: PropTypes.oneOf(['left-top', 'right-top', 'left-bottom', 'right-bottom']),
  /** Устанавливает контент выпадашки */
  content: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default Dropdown;
