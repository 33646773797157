import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { Text } from "ibolit-ui";
import useHover from "~/hooks/useHover";
import styles from "./RowWithImage.scss";

const TEXT_VARIANTS = {
  title: {
    medium: "h4",
    small: "h6"
  },
  info: {
    medium: "desc",
    small: "descTitle"
  }
};

const getWrapper = to => {
  if (!to) return <div />;
  if (/^[\w]+:/.test(to)) return React.createElement("a", { href: to });
  return <NavLink to={to} />;
};
const RowWithImage = ({
  className,
  style,
  title,
  titleRight,
  info,
  infoRight,
  image,
  renderRight,
  onClick,
  children,
  isSelected,
  contentClass,
  infoClass,
  titleClass,
  imageClass,
  variant,
  hasHoverEffect,
  to,
  disableBackgroundHover,
  sizeVariant,
  testid,
  hoveredTextClassName
}) => {
  const { isHover, onMouseOver, onMouseOut } = useHover();

  const containerProps = {
    className: cn(styles.container, className, styles[variant], {
      [styles.selected]: isSelected,
      [styles.clickable]: onClick || to,
      [styles.disableBackgroundHover]: disableBackgroundHover
    }),
    style,
    ...(!to && { onClick }),
    ...(hasHoverEffect && {
      onMouseOver,
      onMouseOut
    }),
    ...(testid && { "data-testid": testid })
  };

  const Wrapper = getWrapper(to);
  return React.cloneElement(
    Wrapper,
    containerProps,
    <>
      <div className={cn(styles.leftBlock, imageClass)}>{image}</div>
      <div className={cn(styles.contentContainer, contentClass)}>
        <div
          className={cn({ [styles.withRightElement]: titleRight }, titleClass)}
        >
          <Text
            variant={TEXT_VARIANTS.title[sizeVariant]}
            testid={testid && `${testid}-title`}
            isHover={isHover}
            className={isHover ? hoveredTextClassName : null}
          >
            {title}
          </Text>
          {titleRight}
        </div>
        <div
          className={cn({ [styles.withRightElement]: infoRight }, infoClass)}
        >
          <Text
            variant={TEXT_VARIANTS.info[sizeVariant]}
            colorVariant="tertiary"
            testid={testid && `${testid}-info`}
          >
            {info}
          </Text>
          {infoRight}
        </div>
        {children}
      </div>
      {renderRight && renderRight()}
    </>
  );
};

RowWithImage.defaultProps = {
  disableBackgroundHover: false,
  sizeVariant: "medium"
};

RowWithImage.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  contentClass: PropTypes.string,
  info: PropTypes.node,
  infoClass: PropTypes.string,
  title: PropTypes.node,
  titleClass: PropTypes.string,
  image: PropTypes.node,
  imageClass: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  renderRight: PropTypes.func,
  style: PropTypes.object,
  variant: PropTypes.oneOf(["shape"]),
  to: PropTypes.string,
  disableBackgroundHover: PropTypes.bool,
  sizeVariant: PropTypes.oneOf(["medium", "small"]),
  testid: PropTypes.string
};

export default RowWithImage;
