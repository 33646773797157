import React from "react";
import PropTypes from "prop-types";
import { Text } from "ibolit-ui";
import styles from "./SystemText.scss";

const SystemText = ({ text }) => (
  <Text variant="h4" className={styles.container}>
    {text.split("").map(char => (char === "\n" ? <br /> : char))}
  </Text>
);

SystemText.propTypes = {
  text: PropTypes.string.isRequired
};

export default SystemText;
