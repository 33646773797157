import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Icon, Text } from "ibolit-ui";
import { Redirect } from "react-router-dom";
import callActions from "~/store/call/callActions";
import CALL_STATUS from "~/components/CallRoom/constants/callStatus";
import { useCloseAllModal, useCloseModal } from "~/hooks/useModal";
import styles from "./ConsultationStarted.scss";

export const CONSULTATION_STARTED = "CONSULTATION_STARTED";

const ConsultationStarted = props => {
  const dispatch = useDispatch();
  const callStatus = useSelector(state => state.call.callStatus);
  const [isRedirect, setRedirect] = useState(false);
  const closeAllModal = useCloseAllModal();
  const linkTo = `/consultations/${props.id}`;
  const handleClose = useCloseModal(CONSULTATION_STARTED);

  if (isRedirect) {
    closeAllModal();

    return <Redirect to={linkTo} />;
  }

  function handleButtonClick() {
    setRedirect(true);

    if (callStatus === CALL_STATUS.ACTIVE) {
      dispatch(callActions.setCallStatus(CALL_STATUS.END));
    }
  }

  return (
    <div className={styles.box}>
      <Avatar srcImg={props.doctor.avatar} className={styles.avatar} />
      <Text
        variant="h3"
        className={styles.name}
        testid="ConsultationStarted__full_name"
      >
        {props.doctor.full_name}
      </Text>
      <Text
        variant="h5"
        colorVariant="tertiary"
        className={styles.desc}
        testid="ConsultationStarted__desc"
      >
        Онлайн - консультация сейчас начнется. Войдите на прием и ожидайте врача
      </Text>
      <Button
        fullWidth
        colorVariant="patient"
        onClick={handleButtonClick}
        testid="ConsultationStarted__button"
      >
        Открыть прием
      </Button>
      <div
        className={styles.close}
        data-testid="close_modal_window"
        testid="ConsultationStarted__closeIcon"
      >
        <Icon variant="close" onClick={handleClose} />
      </div>
    </div>
  );
};

export default ConsultationStarted;
