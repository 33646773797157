export default {
  INPUT_KEY: "reset__password__input-key",
  BUTTON_SUBMIT_KEY: "reset__password__button-submit-key",
  INPUT_CODE_ERROR_MESSAGE: "reset__password__input-code-error-message",
  LABEL_INPUT_CODE: "reset__password__label-input-code",
  BUTTON_REQUEST_AGAIN: "reset__password__button-request-again",
  CONFIRM_FORM__MESSAGE: "reset__password__form-message",
  CONFIRM_FORM__TITLE: "reset__password__form-title",
  CONFIRM_FORM__CODE_INPUT_LABEL: "reset__password__code-input-label"
};
