import { pluralizeDays, pluralizePerMinute } from "~/utils/pluralize";
import {
  IN_DAY_TEXT,
  IN_MONTH_TEXT,
  IN_WEEK_TEXT,
  MINUTES_IN_DAY,
  MINUTES_IN_MONTH,
  MINUTES_IN_WEEK
} from "~/consts/dates";

// TODO: probably should nuke this when tariffs for doctor/subscriptions will be reworked/removed.
export const formatDuration = ({ duration, is_subscription, type }) => {
  if (is_subscription) {
    return type === "chat"
      ? `на ${pluralizeDays(duration / MINUTES_IN_DAY)}`
      : `на ${pluralizePerMinute(duration)}`;
  }
  switch (duration) {
    case MINUTES_IN_DAY:
      return IN_DAY_TEXT;
    case MINUTES_IN_WEEK:
      return IN_WEEK_TEXT;
    case MINUTES_IN_MONTH:
      return IN_MONTH_TEXT;
    default:
      return "";
  }
};
