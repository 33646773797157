import React from "react";
import EmptyStub from "~/components/EmptyStub";
import ItemsListContainer from "~/components/ItemsList";
import SubscriptionsListItem from "./SubscriptionsListItem";
import styles from "../Subscriptions.scss";

const SubscriptionsListContainer = ({ onItemClick, fetchRequest, testid }) => (
  <ItemsListContainer
    itemsMapper={itemsMapper}
    className={styles.listContainer}
    fetchRequest={fetchRequest}
    mode="all"
    onItemClick={onItemClick}
    renderEmpty={renderEmpty}
    renderItem={SubscriptionsListItem}
    storeKey="subscriptions"
    testid={testid}
    minHeight={"100%"}
    maxHeight={"calc(100vh - 120px)"}
  />
);

const itemsMapper = (order, items) =>
  order.reduce((acc, itemId) => {
    const item = items[itemId];
    item && !item.deleted && acc.push(item);
    return acc;
  }, []);

const renderEmpty = () => <EmptyStub text={"У Вас нет\nактивных подписок"} />;

export default SubscriptionsListContainer;
