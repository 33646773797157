import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { Icon } from "ibolit-ui";
import styles from "./DocumentFile.scss";

const SIZE = {
  KB: 1000,
  MB: 1000000
};

const DocumentFile = ({ file_name, extension, size, className }) => (
  <div className={cn(styles.container, className)}>
    <div className={styles.iconContainer}>
      <Icon variant="file" fill="var(--pink)" size="s" />
    </div>
    <div className={styles.textContainer}>
      <div className={styles.fileNameText} title={file_name}>
        {file_name}
      </div>
      <div className={styles.fileInfoText}>
        <span>{`${(size / SIZE.MB).toFixed(2)} МБ`}</span>
        <span>{extension.toUpperCase()}</span>
      </div>
    </div>
  </div>
);

DocumentFile.propTypes = {
  file_name: PropTypes.string,
  extension: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string
};

export default DocumentFile;
