import React from "react";
import PropTypes from "prop-types";
import { Icon, Text } from "ibolit-ui";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { useShowModal, useCloseModal } from "~/hooks/useModal";
import {
  SYMPTOMS_VIEW,
  BACKEND_CREATION_SOURCE
} from "~/modals/NewConsultation/components/Symptoms/Symptoms";
import { getConsultationMsgFormattedRange } from "~/utils/dateUtils";
import {
  getConsultationIcon,
  isConsultationApproved,
  isConsultationExpired,
  isConsultationWaitPatientAction
} from "~/store/consultation/consultationSelectors";
import {
  getTariffTextByType,
  getTariffType
} from "~/store/profile/profileSelectors";
import styles from "./Consultation.scss";
import {
  CONSULTATION_STATUS,
  MAP_CONSULTATION_STATUSES_TO_TEXTS
} from "~/store/consultation/consultationConsts";
import TransparentButton from "~/components/Payment/PaymentButtonTransparent";

const Consultation = ({
  id,
  tariff_price,
  tariff_name,
  type,
  start_at,
  end_at,
  status,
  conclusion,
  userId,
  doctor,
  tariff_id,
  isConclusionMessage,
  source
}) => {
  const icon = getConsultationIcon(type);
  const tariffText = getTariffTextByType(type);
  const tariffType = getTariffType(type);
  const textStatus =
    tariff_price === 0 && status === CONSULTATION_STATUS.APPROVED
      ? MAP_CONSULTATION_STATUSES_TO_TEXTS[CONSULTATION_STATUS.CONFIRMED]
      : MAP_CONSULTATION_STATUSES_TO_TEXTS[status];

  const expired = isConsultationExpired(status);
  const expiredClass = expired ? styles.expired : "";
  // isConclusionMessage separates between messageType===service and messageType===conclusion
  // to not display conclusion in every block.
  const showConclusionBlock = isConclusionMessage && conclusion?.text;

  const handleOpenSymptoms = useShowModal(SYMPTOMS_VIEW, {
    id: String(id),
    doctor,
    tariffId: String(tariff_id),
    tariffType,
    isBackendCreated: BACKEND_CREATION_SOURCE.includes(source),
    onClose: useCloseModal(SYMPTOMS_VIEW)
  });

  function handlePaymentSuccess() {
    if (BACKEND_CREATION_SOURCE.includes(source)) {
      handleOpenSymptoms();
    }
  }

  return (
    <NavLink to={`/consultations/${id}`} className={styles.consultationChat}>
      <div className={styles.bubble}>
        <div
          className={cn(
            styles.consultationChat_row,
            styles.consultationChat_rowTop
          )}
        >
          <span>
            <Icon
              variant={icon}
              fill="var(--pink)"
              size="s"
              className={styles.consultationChat_icon}
            />
            <Text
              tag="span"
              variant="caption"
              className={cn(expiredClass, styles.consultationChat_type)}
            >
              {tariffText}
            </Text>
            <Text
              tag="span"
              variant="caption"
              colorVariant="tertiary"
              className={cn(expiredClass, styles.consultationChat_date)}
            >
              {getConsultationMsgFormattedRange(start_at, end_at)}
            </Text>
          </span>
          <Text
            tag="span"
            variant="caption"
            colorVariant="tertiary"
            className={cn({
              [styles.consultationChat_status__approved]: isConsultationApproved(
                status
              ),
              [styles.consultationChat_status__canceled]: expired
            })}
          >
            {textStatus}
          </Text>
        </div>
        <div className={styles.consultationChat_row}>
          <Text variant="h4" className={expiredClass}>
            {tariff_name}
          </Text>
          {isConsultationWaitPatientAction(status) && type !== "offline" && (
            <TransparentButton
              price={tariff_price}
              serviceId={tariff_id}
              consultationId={id}
              userId={userId}
              doctor={doctor}
              isPaid={isConsultationApproved(status)}
              onSuccess={handlePaymentSuccess}
            />
          )}
        </div>
      </div>
      {showConclusionBlock && <Conclusion body={conclusion.text} />}
    </NavLink>
  );
};

const Conclusion = ({ body }) => (
  <div className={styles.consultationChat_conclusion}>
    <Text variant="h4">Заключение</Text>
    <Text variant="desc" tag="pre">
      {body}
    </Text>
  </div>
);

Consultation.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string.isRequired,
  start_at: PropTypes.string.isRequired,
  end_at: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

export default Consultation;
