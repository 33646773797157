import { select, takeLatest, all, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import Types from "~/store/ui/uiTypes";
import actions from "~/store/ui/uiActions";
import servicesTypes from "~/store/services/servicesTypes";
import { getRelocation } from "~/store/ui/uiSelectors";
import { sendRequestSaga } from "~/store/chat/chatSaga";
import { CHAT_MODE_ALL } from "~/store/chat/chatModel";
import {
  CHAT_SEARCH_ITEMS_LIMIT,
  CHAT_SERVICE_TYPE
} from "~/store/chat/chatConsts";

function* modalReplace({ payload }) {
  const { viewOld, viewNew, props } = payload;

  yield put(actions.hideModal(viewOld));
  yield put(actions.showModal(viewNew, props));
}

function* relocation({ event }) {
  const { type, external_id } = event;
  const relocationState = yield select(getRelocation);
  if (
    type === CHAT_SERVICE_TYPE &&
    relocationState[CHAT_SERVICE_TYPE] !== null
  ) {
    yield put(actions.unsubscribeRelocation(type));
    // update chat list
    yield* sendRequestSaga({
      payload: {
        page: 1,
        mode: CHAT_MODE_ALL,
        value: "",
        limit: CHAT_SEARCH_ITEMS_LIMIT
      }
    });
    yield put(push(`${relocationState.chat}/${external_id}`));
  }
}

export default function* uiSaga() {
  yield all([
    takeLatest(Types.REPLACE_MODAL, modalReplace),
    takeLatest(servicesTypes.SERVICE_PAID, relocation)
  ]);
}
