import { all, put, takeLatest, call, throttle } from "redux-saga/effects";
import { normalize, schema } from "normalizr";
import api from "~/api/conclusionsApi";
import types from "~/store/conclusions/conclusionTypes";
import action from "~/store/conclusions/conclusionActions";
import commonActions from "~/store/common/commonActions";
import toastActions from "~/store/toast/toastActions";
import wsTypes from "~/store/webscoket/wsTypes";
import { GET_CONCLUSION_ERROR } from "~/store/conclusions/conclusionConsts";

function* searchSaga({ request }) {
  try {
    const { mode, chatId, ...params } = request;
    const { data } = yield call(api.conclusionsSearch, params);
    const {
      result: order,
      entities: { conclusions: items, ...rest }
    } = normalize(data, [new schema.Entity("conclusions")]);
    yield put(action.searchSuccess({ items, order }, request));
    yield put(commonActions.updateItems(rest));
  } catch (e) {
    yield put(action.searchFailure(e.conclusion, request));
  }
}

function* getConclusion({ id }) {
  try {
    const { data } = yield call(api.getConclusion, id);
    yield put(action.getConclusionSuccess(data));
  } catch (error) {
    yield put(action.getConclusionFailure());
    yield put(toastActions.showErrorToast(GET_CONCLUSION_ERROR));
  }
}

function* updateConclusion({ event }) {
  try {
    yield put(action.getConclusionSuccess(event));
  } catch (e) {
    yield put({
      type: `@@err/PROCESS_WS_CONCLUSION`,
      e
    });
  }
}

function* getConclusionPDFSaga({ payload: { id } }) {
  try {
    const { data } = yield api.getConclusionPDF(id);
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = "PdfName-" + new Date().getTime() + ".pdf";

    // append the link to the document body

    document.body.appendChild(link);

    link.click();
  } catch(err) {
    console.log(err);
  }
}

export default function* conclusionsSaga() {
  yield all([
    throttle(500, types.SEARCH_REQUEST, searchSaga),
    takeLatest(types.GET_CONCLUSION, getConclusion),
    takeLatest(types.GET_CONCLUSION_PDF, getConclusionPDFSaga),
    takeLatest(wsTypes.WS_CONCLUSION_ADD, updateConclusion)
  ]);
}
