import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { useShowModal } from "~/hooks/useModal";
import { ADDING_FILES } from "~/layouts/Consultations/Consultation/components/FileManager/AddingFilesModal/AddingFilesModal";

import { getConsultationInProgress } from "~/store/consultation/consultationSelectors";

import {
  tabsInfo,
  Header,
  Tabs,
  enumTabsNames,
  getFormattedStringDateWithTime
} from "ibolit-components";

import styles from "./ConsultationPage.scss";

import { CONSULTATION_STATUS } from "~/store/consultation/consultationConsts";

import Loading from "~/components/Loading/Loading";

import { ConsultationInfoPage } from "./pages/ConsultationInfoPage";
import { ConsultationAnamnesisPage } from "./pages/ConsultationAnamnesisPage";
import { ConsultationFilesPage } from "./pages/ConsultationFilesPage";
import { ConsultationConslusionPage } from "./pages/ConsultationConslusionPage";

import { BottomButton } from "./BottomButton";

export const ConsultationPage = ({ consultationData, doctorData }) => {
  const [activeTab, setActiveTab] = useState(tabsInfo[0]);

  const isLoading = false;

  const handleShowAddingFiles = useShowModal(ADDING_FILES, {
    consultationId: consultationData?.id
  });

  const isInProgress = useSelector(state =>
    getConsultationInProgress(state, consultationData)
  );

  if (!consultationData || !doctorData) {
    return null;
  }

  const setConclusionTab = () => setActiveTab(tabsInfo[3]);

  const isTariffTypeOffline = consultationData.tariff_type === "offline";

  return (
    <>
      <Loading showLoading={isLoading} />
      <Header
        role={"Врач"}
        name={doctorData.full_name}
        specialties={doctorData.specialties}
        duration={`${consultationData.tariff_duration} мин`}
        price={`${consultationData.tariff_price} ₽`}
        consultationDate={getFormattedStringDateWithTime(
          consultationData.start_at
        )}
        status={consultationData.status}
        tariffName={consultationData.tariff_name}
        humanImage={doctorData.avatar}
        isInProgress={isInProgress}
        Component={NavLink}
        to={`/clinics/doctors/${doctorData.id}`}
        isTariffTypeOffline={isTariffTypeOffline}
      />
      <Tabs
        activeTabInfo={activeTab}
        setActiveTab={setActiveTab}
        variant="patient"
        counter={consultationData.attached_files?.length}
      />
      <div className={styles.renderedPageContainer}>
        <RenderedPage
          activeTab={activeTab}
          consultationData={consultationData}
          doctorData={doctorData}
          setConclusionTab={setConclusionTab}
          isTariffTypeOffline={isTariffTypeOffline}
        />
      </div>
      <BottomButton
        activeTab={activeTab.name}
        consultationData={consultationData}
        doctorInfo={doctorData}
        handleDownloadFile={handleShowAddingFiles}
        isTariffTypeOffline={isTariffTypeOffline}
      />
    </>
  );
};

const RenderedPage = ({
  activeTab,
  consultationData,
  doctorData,
  setConclusionTab,
  isTariffTypeOffline
}) => {
  const consultationStatusInfo = renderConsultationInfo(consultationData);
  if (!consultationData) {
    return null;
  }

  switch (activeTab.name) {
    case enumTabsNames.INFO:
      return (
        <ConsultationInfoPage
          activeTab={activeTab}
          consultationData={consultationData}
          doctorData={doctorData}
          consultationStatusInfo={consultationStatusInfo}
          setConclusionTab={setConclusionTab}
          isTariffTypeOffline={isTariffTypeOffline}
        />
      );
    case enumTabsNames.ANAMNESIS:
      return (
        <ConsultationAnamnesisPage
          consultationStatusInfo={consultationStatusInfo}
          consultationProblemData={consultationData.problem}
        />
      );
    case enumTabsNames.FILES:
      return <ConsultationFilesPage consultationData={consultationData} />;
    default:
      return (
        <ConsultationConslusionPage
          isTariffTypeOffline={isTariffTypeOffline}
          consultationStatusInfo={consultationStatusInfo}
          consultationData={consultationData}
        />
      );
  }
};

const renderConsultationInfo = consultationData => {
  const consultationDataProblem = consultationData.problem || {};
  switch (true) {
    case consultationDataProblem.status === "appointed_offline_reception":
      return {
        label: "Назначен очный прием",
        text:
          "Чтобы получить диагноз, или дополнительную информацию по жалобе требуется очный прием. Так как врачу недостаточно информации полученной онлайн.",
        icon: "appointed-offline-reception"
      };
    case consultationData.status === CONSULTATION_STATUS.COMPLETED &&
      !consultationData.conclusion:
      return {
        text: "Врач пока не указал следующий этап лечения.",
        icon: "next-step",
        label: "Какой следующий шаг?"
      };
    case consultationDataProblem.status === "auto":
      return {
        header: "Ответьте на несколько вопросов врача",
        text:
          "Это необходимо, чтобы определить цель консультации и предоставить врачу первичные данные по жалобе.",
        textClassName: styles.grayText
      };
    case consultationDataProblem.status === "diagnostics_assigned":
      return {
        text:
          "Врач назначил диагностику. Все результаты анализов и исследований вы сможете показать врачу онлайн. Для этого запишитесь на повторный прием и прикрепите ваши файлы в карте будущего приема.",
        icon: "diagnostics-assigned",
        label: "Назначена диагностика"
      };
    case consultationDataProblem.status === "treatment_prescribed":
      return {
        label: "Рекомендовано лечение",
        text:
          "Врач рекомендовал лечение. В период лечения будьте внимательны к самочувствию. Очень важно фиксировать для врача все реакции организма на лечение, чтобы врач смог дать привальную оценку и скорректировать курс лечения при необходимости",
        icon: "treatment-prescribed"
      };
    case consultationDataProblem.status === "primary_appointment":
      return {
        text:
          "Спросите врача какие исследования вам понадобятся, чтобы снять максимальное кол-во рисков. Спросите врача о необходимости очного приема.",
        icon: "primary-appointment",
        label: "Первичная консультация"
      };
    case consultationDataProblem.status === "second_opinion":
      return {
        text:
          "Если у вас уже имеется диагноз, прикрепите файлы анализов и других исследований, чтобы врач смог сложить всю картину болезни. Спросите врача, специалистов какого профиля вам стоит дополнительно посетить, чтобы снять все возможные риски.",
        icon: "second-opinion",
        label: "Второе мнение"
      };
    case consultationDataProblem.status === "transcript_of_analyzes":
      return {
        text:
          "Прикрепите файлы анализов и других исследований, чтобы врач смог с ними ознакомиться. Результатом такой консультации станут рекомендации без постановки диагноза.",
        icon: "transcript-of-analyzes",
        label: "Расшифровка анализов"
      };
    case consultationDataProblem.status === "repeated_appointment":
      return {
        text:
          "Похоже, что ранее вы уже были у этого врача. Постарайтесь дать как можно больше информации врачу о текущем состоянии и его изменениях. Важны все детали.",
        icon: "repeated-appointment",
        label: "Повторная консультация"
      };
    case consultationDataProblem.status === "closed" ||
      consultationDataProblem.status === "treatment_completed":
      return {
        text:
          "Врач завершил лечение по указанной жалобе. iBolit благодарит вас за внимательное отношение к своему здоровью.",
        label: "Лечение завершено",
        icon: "treatment-completed"
      };
    default:
      return {};
  }
};
