export default {
  CONSULTATIONS_POST: "consultations/POST",
  CONSULTATIONS_POST_SUCCESS: "consultations/POST_SUCCESS",
  CONSULTATIONS_POST_FAILURE: "consultations/POST_FAILURE",

  CONSULTATIONS_GET: "consultations/GET",
  CONSULTATIONS_GET_SUCCESS: "consultations/GET_SUCCESS",
  CONSULTATIONS_GET_FAILURE: "consultations/GET_FAILURE",

  CONSULTATIONS_INFO_GET: "consultations/INFO_GET",
  CONSULTATIONS_INFO_GET_SUCCESS: "consultations/INFO_GET_SUCCESS",
  CONSULTATIONS_INFO_GET_FAILURE: "consultations/INFO_GET_FAILURE",

  CONSULTATIONS_UPDATE_ITEMS: "consultations/UPDATE_ITEMS",

  CONSULTATIONS_ADD_FILE: "consultations/CONSULTATIONS_ADD_FILE",
  CONSULTATIONS_REMOVE_FILE: "consultations/CONSULTATIONS_REMOVE_FILE",
  CONSULTATION_UPLOAD_FILES: "consultations/CONSULTATIONS_UPLOAD_FILES",
  CONSULTATION_UPLOAD_FILE: "consultations/CONSULTATIONS_UPLOAD_FILE",
  CONSULTATION_UPDATE_FILE: "consultations/CONSULTATIONS_UPDATE_FILE",
  CONSULTATION_UPLOAD_PROGRESS: "consultations/CONSULTATION_UPLOAD_PROGRESS",
  CONSULTATION_UPLOAD_FILE_ERROR:
    "consultations/CONSULTATION_UPLOAD_FILE_ERROR",
  CONSULTATION_CANCEL_UPLOAD: "consultations/CONSULTATION_CANCEL_UPLOAD",
  CONSULTATION_UPLOAD_SUCCESS: "consultations/CONSULTATION_UPLOAD_SUCCESS",
  CONSULTATION_START_UPLOAD_FILES:
    "consultations/CONSULTATION_START_UPLOAD_FILES",
  CONSULTATION_CLEAR_FILES: "consultations/CONSULTATION_CLEAR_FILES",
  CONSULTATION_DELETE_FILE: "consultations/CONSULTATIONS_DELETE_FILE",
  CONSULTATION_DELETE_FILE_SUCCESS:
    "consultations/CONSULTATIONS_DELETE_FILE_SUCCESS",

  CONSULTATION_SEND_FEEDBACK: "consultations/CONSULTATION_SEND_FEEDBACK",
  CONSULTATION_SEND_FEEDBACK_SUCCESS:
    "consultations/CONSULTATION_SEND_FEEDBACK_SUCCESS",
  CONSULTATION_SEND_FEEDBACK_FAILURE:
    "consultations/CONSULTATION_SEND_FEEDBACK_FAILURE",

  CONSULTATION_CANCEL: "consultations/CONSULTATION_CANCEL"
};
