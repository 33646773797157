import React from "react";
import { Dialog } from "ibolit-ui";
import { useCloseModal } from "~/hooks/useModal";
import styles from "./CancelConsultation.scss";

export const CANCEL_CONSULTATION = "cancel_consultation";

function CancelConsultationModal({ handleSubmitCancel }) {
  const onClose = useCloseModal(CANCEL_CONSULTATION);

  const handleAgree = () => {
    handleSubmitCancel();
    onClose();
  };

  const renderDialogInner = () => {
    return (
      <>
        <div
          className={styles.description}
          data-testId="CancelConsultationModal__desc"
        >
          До внесения оплаты вы можете{"\n"}самостоятельно отменить консультацию
        </div>
        <div className={styles.buttons}>
          <button
            className={styles.agreeButton}
            onClick={handleAgree}
            data-testId="CancelConsultationModal__yes"
          >
            Да
          </button>
          <button
            onClick={onClose}
            className={styles.disagreeButton}
            data-testId="CancelConsultationModal__no"
          >
            Нет
          </button>
        </div>
      </>
    );
  };

  return (
    <Dialog
      boxClassName={styles.box}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      header={"Отменить консультацию?"}
      onCloseClick={null}
      testId="CancelConsultationModal"
    >
      {renderDialogInner()}
    </Dialog>
  );
}

CancelConsultationModal.propTypes = {};

export default CancelConsultationModal;
