import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "ibolit-ui";
import { createPortal } from "react-dom";
import { connect, useDispatch } from "react-redux";
import { getModalViews } from "~/store/ui/uiSelectors";
import uiActions from "~/store/ui/uiActions";
import { resetPromocodeAction } from "~/store/promocode/actions";
import { PROMO_MODAL_VIEW } from "./Promo/PromoModal";

const Modals = ({ modals, modalsMap, idDOMRoot, onBGClick }) => {
  const dispatch = useDispatch();

  const handleBGClick = view => {
    onBGClick(view);
  };

  useEffect(() => {
    return () => dispatch(resetPromocodeAction());
  });

  return createPortal(
    <Modal modals={modals} modalsMap={modalsMap} onBGClick={handleBGClick} />,
    document.getElementById(idDOMRoot)
  );
};

const ModalContainer = connect(
  state => ({
    modals: getModalViews(state)
  }),
  {
    onBGClick: uiActions.hideModal
  }
)(Modals);

ModalContainer.propTypes = {
  idDOMRoot: PropTypes.string.isRequired,
  modalsMap: PropTypes.object.isRequired
};

export default ModalContainer;
