import Types from './callTypes';

export default {
  callStart: ({ consultationId }) => ({
    type: Types.CALL_START,
    consultationId,
  }),
  callJoin: ({ consultationId, doctorId }) => ({
    type: Types.CALL_JOIN,
    consultationId,
    doctorId,
  }),
  callLeave: ({ consultationId }) => ({
    type: Types.CALL_LEAVE,
    consultationId,
  }),
  setVoxImplantParams: callId => ({
    type: Types.SET_VOXIMPLANT_PARAMS,
    callId
  }),
  setCallStatus: status => ({ type: Types.SET_CALL_STATUS, status }),
  callError: error => ({
    type: Types.CALL_ERROR,
    error,
  }),
};
