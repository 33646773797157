import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import WaveSurfer from "wavesurfer.js";
import { Icon } from "ibolit-ui";
import styles from "./Voice.scss";

function Voice(props) {
  const waveForm = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState("");
  const containerRef = useRef();

  useEffect(() => {
    waveForm.current = WaveSurfer.create({
      container: containerRef.current,
      barWidth: 3,
      barHeight: 10,
      waveColor: "rgba(0, 181, 206, 0.3)",
      progressColor: "#00b5ce",
      barRadius: 3,
      height: 20,
      cursorColor: "transparent"
    });

    waveForm.current.load(props.voiceSrc);
    waveForm.current.on("audioprocess", handleAudioProcess);
    waveForm.current.on("seek", handleSeek);
    waveForm.current.on("finish", handleFinish);
  }, []);

  function handleTogglePlay() {
    if (waveForm.current) {
      waveForm.current.playPause();
    }

    setIsPlaying(prevState => !prevState);
  }

  function handleAudioProcess(currentProgress) {
    const progressInSeconds = moment(currentProgress * 1000).format("mm:ss");

    if (progressInSeconds !== progress) {
      setProgress(progressInSeconds);
    }
  }

  function handleSeek(durationPercentage) {
    const totalDuration = waveForm.current.getDuration();
    const progressInSeconds = moment(
      totalDuration * durationPercentage * 1000
    ).format("mm:ss");
    setProgress(progressInSeconds);
  }

  function handleFinish() {
    setIsPlaying(false);
    setProgress("");
  }

  const totalDuration = moment((props.duration || 0) * 1000).format("mm:ss");
  return (
    <div className={styles.container}>
      <div>
        <Icon
          className={styles.actionIcon}
          variant={isPlaying ? "pause" : "play"}
          fill="var(--blue)"
          onClick={handleTogglePlay}
        />
      </div>
      <div className={styles.waveContainer}>
        <div className={styles.wave} ref={containerRef} />
        <div className={styles.duration}>{progress || totalDuration}</div>
      </div>
    </div>
  );
}

Voice.propTypes = { voiceSrc: PropTypes.string, duration: PropTypes.number };

export default Voice;
