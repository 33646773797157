import { CONSULTATION_STATUS } from "~/store/consultation/consultationConsts";

export const returnConsultationStatusText = (consultationData, tarrifDescription, isInProgress) => {
  const statusIsCanceled =
    consultationData.status === CONSULTATION_STATUS.CANCELED ||
    consultationData.status === CONSULTATION_STATUS.EXPIRED ||
    consultationData.status === CONSULTATION_STATUS.NOT_APPEARED;
  const isTariffTypeOffline = consultationData.tariff_type === 'offline';

  switch (true) {
    case consultationData.status === CONSULTATION_STATUS.PENDING:
      return {
        text:
          tarrifDescription,
        label: consultationData.tariff_name,
        duration: consultationData.tariff_duration
      };
    case statusIsCanceled:
      return {
        label: "Консультация не состоялась",
        text:
          "Консультация не состоялась. Возможно позже врач повторно запишет вас на новый слот доступный в его расписании.",
      }
    case consultationData.status === CONSULTATION_STATUS.WAIT_PAYMENT:
      return {
        label: consultationData.tariff_name,
        text:
          tarrifDescription,
        duration: consultationData.tariff_duration
      };
    case consultationData.problem?.status === "auto":
      const label = isTariffTypeOffline
      ? "Консультация подтверждена"
      : "Вы оплатили консультацию"
      return {
        text:
          "Ответьте на несколько вопросов врача и прикрепите необходимые для консультации файлы.",
        label
      };
    case !!consultationData.conclusion:
      return {
        label: "Врач оставил заключение",
        text: "Ознакомьтесь с рекомендациями врача в разделе заключение."
      };
    case consultationData.status === CONSULTATION_STATUS.COMPLETED:
      return {
        text: `В ближайшее время врач оставит заключение, возможно прикрепит сопутствующие файлы.\n Так же вам будет доступна запись вашей консультации.`,
        label: "Консультация состоялась"
      };
    case consultationData.problem?.status !== "auto":
      if (!isInProgress) {
        return {
          text:
            "Ответьте на несколько вопросов врача и прикрепите необходимые для консультации файлы.",
          label: "Дождитесь начала консультации"
        };
      }
      
      if (!isTariffTypeOffline) {
        return {
          text:
            "Проверьте качество связи, войдите на прием и дождитесь собеседника, он скоро подключится.",
          label: "Подошло время консультации",
        };
      }
    default:
      return {
        text:
          tarrifDescription,
        label: consultationData.tariff_name,
        duration: consultationData.tariff_duration
      };
  }
};

export const returnButtonsData = ({
  consultationData,
  status,
  isInProgress,
  hCancelConfirmation,
  hPay,
  hDescribeProblem,
  hEnterConsultation,
  hShowTab,
  clinicPhone,
  isTariffTypeOffline,
}) => {
  switch (true) {
    case consultationData.status === CONSULTATION_STATUS.CANCELED:
      return [];
    case status === CONSULTATION_STATUS.PENDING:
      return [
        {
          label: "Ожидает подтверждения",
          color: "pink",
          type: "disabled",
        },
        {
          label: "Отменить консультацию",
          type: "cancel",
          onClick: hCancelConfirmation,
          color: "gray",
        }
      ];
    case status === CONSULTATION_STATUS.WAIT_PAYMENT:
    case status === CONSULTATION_STATUS.WAIT_CLIENT_CONFIRMATION:
      return [
        {
          label: `Оплатите ${consultationData?.tariff_price} ₽`,
          type: "submit",
          onClick: hPay
        },
        {
          label: "Отменить консультацию",
          type: "cancel",
          onClick: hCancelConfirmation,
          color: "gray",
        }
      ];
    case status === CONSULTATION_STATUS.APPROVED &&
      consultationData.problem?.status === "auto":
      return [
        {
          label: "Ответить",
          color: "pink",
          onClick: hDescribeProblem
        }
      ];
    case status === CONSULTATION_STATUS.APPROVED && !isTariffTypeOffline:
      return [
        {
          label: "Войти на прием",
          type: isInProgress ? "default" : "disabled",
          color: "pink",
          onClick: hEnterConsultation
        }
      ];
    case status === CONSULTATION_STATUS.COMPLETED && !!consultationData.conclusion:
      return [
        {
          label: "Посмотреть",
          type: "submit",
          color: "pink",
          onClick: hShowTab
        }
      ];
    case status === CONSULTATION_STATUS.CANCELED:
      return [
        {
          label: "Записаться повторно",
          type: "link",
          color: "pink",
          phone: clinicPhone
        }
      ];
    default:
      return [];
  }
};
