import axios from "axios";

const instance = axios.create({
  baseURL: `${__API__}`.replace(/\/api$/, ""),
  timeout: 5000
});

export default {
  login: ({ token }) =>
    instance.post("/public/v1/patients/login", {
      onetime_token: token
    })
};
