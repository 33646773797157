import { takeLatest, call, put, all } from "redux-saga/effects";
import types from "~/store/faq/faqTypes";
import { getFaqApi } from "~/api/faqApi";
import actions from "./faqAction";

function* faqDataFetchSaga() {
  try {
    const { data } = yield call(getFaqApi);

    yield put(actions.faqDataSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error.message);
  }
}

export default function*() {
  yield all([yield takeLatest(types.FAQ_DATA_REQUEST, faqDataFetchSaga)]);
}
