import React, { useEffect, useState, useLayoutEffect } from "react";
import { Button } from "ibolit-ui";
import { useDispatch, useSelector } from "react-redux";
import { useCloseModal } from "~/hooks/useModal";
import locators from "~/locators/auth";
import authActions from "~/store/auth/authActions";
import { getUser } from "~/store/auth/authSelectors";
import profileAction from "~/store/profile/profileActions";
import DateSelector from "./DateSelector";
import GenderInput from "~/layouts/Authorization/screens/UserInfo/GenderInput";

import { FROM_WIDGET_USER_INFO_VIEW } from "~/modals/FromWidget";

const UserInfo = ({ onSubmit, isScene }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [dateOfBirth, setDateOfBirth] = useState(
    () => user?.date_of_birth || ""
  );
  const [gender, setGender] = useState(() => user?.gender || "");
  const [canSubmit, setCanSubmit] = useState(false);
  const handleСloseModal = useCloseModal(FROM_WIDGET_USER_INFO_VIEW);
  useEffect(() => {
    if (gender && dateOfBirth) {
      setCanSubmit(true);
    } else if (canSubmit) {
      setCanSubmit(false);
    }
  }, [gender, dateOfBirth]);

  useLayoutEffect(() => {
    if (isScene) {
      handleСloseModal();
    }
  }, []);

  const _onSubmit = e => {
    e.preventDefault();

    const newFields = {};

    if (dateOfBirth) newFields.date_of_birth = dateOfBirth;
    if (gender && typeof gender === "object") {
      newFields.gender = gender.value;
    }

    dispatch(profileAction.userDataUpdateRequest({ ...user, ...newFields }));
    dispatch(authActions.userChangeInfo());
    onSubmit && onSubmit();
  };

  return (
    <form onSubmit={_onSubmit}>
      <GenderInput gender={gender} setGender={setGender} />
      <DateSelector dateOfBirth={dateOfBirth} setDateOfBirth={setDateOfBirth} />
      <Button
        disabled={!canSubmit}
        fullWidth
        colorVariant="patient"
        type="submit"
        testid={locators.AUTH_SEND_INFO}
      >
        Продолжить
      </Button>
    </form>
  );
};

export default UserInfo;
