import React from "react";
import { Text } from "ibolit-ui";
import { CLINICS_LIST } from "~/locators/contacts";
import { useFilteredPaginator } from "~/hooks/usePaginator";
import actions from "~/store/clinics/clinicsActions";
import {
  CLINICS_MODE_ALL,
  clinicsRequestSelector
} from "~/store/clinics/clinicsModel";
import ClinicsList from "./ClinicsList";
import styles from "./ClinicsAside.scss";

const CLINICS_ITEMS_LIMIT = 10000;

const ContactAside = () => {
  const listRef = React.createRef();

  const {
    items,
    isFetching,
    initialFetched,
    canLoadMore,
    hasErrors,
    isEmpty,
    mode,
    incrementPage
  } = useFilteredPaginator({
    initialFetched,
    selector: clinicsRequestSelector,
    initialMode: CLINICS_MODE_ALL,
    ref: listRef,
    limit: CLINICS_ITEMS_LIMIT,
    sendAction: actions.requestSend
  });

  return (
    <div className={styles.aside}>
      <Text className={styles.header} variant="h3" testid={CLINICS_LIST.HEADER}>
        Клиники
      </Text>
      <ClinicsList
        ref={listRef}
        items={items}
        isFetching={isFetching}
        initialFetched={initialFetched}
        canLoadMore={canLoadMore}
        mode={mode}
        hasErrors={hasErrors}
        isEmpty={isEmpty}
        incrementPage={incrementPage}
      />
    </div>
  );
};

export default ContactAside;
