import React, { memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Textarea from "react-textarea-autosize";
import { Dropdown, Toast, Button } from "ibolit-ui";
import IconButton from "~/layouts/Chats/Chat/ChatFooter/IconButton";
import isImage from "~/utils/isImage";
import AVAILABLE_MIME_TYPES from "~/layouts/Chats/utils/availableMimeTypes";
import { CHAT_FOOTER } from "~/locators/chats";
import ChatAttachList from "../ChatAttachList";
import styles from "./ChatFooter.scss";
import { MAX_SIZE_MB } from "~/store/chat/chatConsts";
import { SYMPTOMS_VIEW } from "~/modals/NewConsultation/components/Symptoms/Symptoms";
import { useCloseModal, useShowModal } from "~/hooks/useModal";
import { isTariffNotPurchased } from "../../utils/isTariffNotPurchased";

const ChatFooter = ({ onSubmit, partner, chatTariff, hasPatientProblem }) => {
  const forceUpdate = useState()[1];
  const inputRef = useRef();
  const messageRef = useRef();
  const textareaRef = useRef();

  const checkAndSendMessage = () => {
    const message = messageRef.current.value.trim();
    messageRef.current.value = message;
    if (message !== "") {
      messageRef.current.value = "";
      onSubmit({ type: "text", body: message });
    }
    forceUpdate({});
  };

  const onMessagesSubmit = event => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();

      checkAndSendMessage();
    }
  };

  const onButtonClick = event => {
    event.preventDefault();

    checkAndSendMessage();
  };

  const onSendFileClick = event => {
    event.preventDefault();

    inputRef.current.click();
  };

  const onInputFileChange = event => {
    const fileList = event.target.files;

    if (fileList.length > 0 && fileList[0].size >= 1024 * 1024 * MAX_SIZE_MB) {
      toast(
        <Toast
          text={`Выбранный файл слишком большого размера,\nмаксимальный размер ${MAX_SIZE_MB}мб`}
          variant="error"
        />,
        { containerId: "toast", autoClose: 10000 }
      );

      inputRef.current.value = "";
    } else if (fileList.length > 0) {
      const type = isImage(fileList[0].name) ? "photo" : "file";

      onSubmit({ type, file: fileList[0] });
    }
  };

  const handleCloseSymptomsModal = useCloseModal(SYMPTOMS_VIEW);

  const servicePatient = chatTariff?.service_patient_id;

  const handleOpenSymptomsModal = useShowModal(SYMPTOMS_VIEW, {
    doctor: partner,
    onClose: handleCloseSymptomsModal,
    isForChat: true,
    servicePatient
  });

  const isShowSymptomsButton =
    !hasPatientProblem &&
    servicePatient &&
    !isTariffNotPurchased(chatTariff?.source);

  return (
    <div className={styles.footer}>
      {isShowSymptomsButton ? (
        <Button
          onClick={handleOpenSymptomsModal}
          colorVariant="patient"
          fixedWidth={false}
          className={styles.openSymptomsButton}
          size="small"
        >
          Ответьте на вопросы врача
        </Button>
      ) : (
        <>
          <div className={styles.left}>
            <Dropdown
              content={
                <ChatAttachList
                  user={partner}
                  onSendFileClick={onSendFileClick}
                />
              }
            >
              {show => (
                <IconButton
                  testid={CHAT_FOOTER.MENU_BUTTON}
                  iconVariant="attach"
                  fill={show ? "var(--blue)" : undefined}
                  hasState
                />
              )}
            </Dropdown>
          </div>
          <Textarea
            onKeyDown={onMessagesSubmit}
            inputRef={messageRef}
            ref={textareaRef}
            className={styles.textarea}
            placeholder="Напишите сообщение…"
            maxLength={1000}
            maxRows={12}
            data-testid={CHAT_FOOTER.TEXTAREA}
          />
          <div className={styles.right}>
            <IconButton
              onClick={onButtonClick}
              iconVariant="send"
              hasState
              testid={CHAT_FOOTER.BUTTON_SEND_MESSAGE}
            />
          </div>
          <input
            accept={AVAILABLE_MIME_TYPES}
            onChange={onInputFileChange}
            ref={inputRef}
            name="file"
            type="file"
            style={{ display: "none" }}
          />
        </>
      )}
    </div>
  );
};

ChatFooter.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default memo(ChatFooter);
