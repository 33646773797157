import React, { useRef, forwardRef } from "react";
import { useRouteMatch } from "react-router-dom";
import Link from "~/components/Link";
import Loading from "~/components/Loading/Loading";
import EmptyStub from "~/components/EmptyStub";
import { LOAD_DATA_ERROR } from "~/consts/texts";
import styles from "./DoctorsList.scss";
import Speciality from "~/layouts/Clinics/ClinicDetails/DoctorsList/Speciality";
import DoctorItem from "~/components/DoctorItem";
import { CLINIC_DETAILS_DOCTORS_ITEM } from "~/locators/clinics";
import debounce from "lodash/debounce";

const DoctorsList = (
  {
    items,
    isFetching,
    initialFetched,
    canLoadMore,
    hasErrors,
    isEmpty,
    incrementPage
  },
  ref
) => {
  const speciality = useRef("");
  const match = useRouteMatch();
  const urlRoot = match.url
    .split("/")
    .slice(0, -1)
    .join("/");

  const callbackFunction = debounce(() => {
    if (canLoadMore) {
      incrementPage();
    }
  }, 1000);

  const handleScroll = () => {
    const block = ref.current;
    const nearBottom = block.scrollHeight - block.scrollTop < 1000;
    if (!isFetching && nearBottom && canLoadMore) {
      callbackFunction();
    }
  };

  return (
    <div
      ref={ref}
      className={styles.list}
      onScroll={handleScroll}
    >
      {items.map((item, index) => {
        let showSpeciality = true;
        const text = item.specialty;
        if (speciality.current === text) {
          showSpeciality = false;
        }
        speciality.current = text;
        if (index === items.length - 1) {
          speciality.current = "";
        }
        return (
          <React.Fragment key={index}>
            {showSpeciality && <Speciality text={text} />}
            <Link
              to={`${urlRoot}/doctors/${item.id}`}
              className={styles.link}
              activeClassName={styles.selected}
            >
              <DoctorItem
                user={item}
                testId={CLINIC_DETAILS_DOCTORS_ITEM}
                contentClassName={styles.itemContent}
              />
            </Link>
          </React.Fragment>
        );
      })}
      {isFetching && <Loading />}
      {!initialFetched && !hasErrors && (
        <EmptyStub text="Загружаем список врачей" centered />
      )}
      {hasErrors && <EmptyStub text={LOAD_DATA_ERROR} centered />}
      {isEmpty && !isFetching && (
        <EmptyStub centered text="Нет врачей в выбранной клиники" />
      )}
    </div>
  );
};

export default forwardRef(DoctorsList);
