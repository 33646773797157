import cn from "classnames";
import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { Icon, Text } from "ibolit-ui";
import { getTzDate } from "~/utils/dateUtils";
import styles from "./Row.scss";

const Row = ({
  testId,
  children,
  position,
  showFooter,
  isRead,
  isSending,
  isSendFailure,
  showChecksIcon,
  created_at,
  margin
}) => {
  const time = useMemo(
    () => created_at && getTzDate(created_at).format("HH:mm"),
    [created_at]
  );

  return (
    <div
      data-testid={testId}
      className={cn(styles.row, styles[position], { [styles.margin]: margin })}
    >
      <div
        className={cn(styles.rowWrapper, {
          [styles.rowRight]: position === "right",
          [styles.rowLeft]: position === "left"
        })}
      >
        {children}
        {isSendFailure && (
          <Icon
            className={styles.iconFailure}
            variant="info"
            fill="var(--pink)"
          />
        )}
      </div>
      {isSendFailure && (
        <Text
          className={styles.rowFailure}
          variant="caption"
          colorVariant="error"
        >
          Не отправлено
        </Text>
      )}
      {showFooter && !isSendFailure && (
        <div className={cn(styles.rowFooter, styles[position])}>
          <Text
            className={cn(styles.time, { [styles.order]: position === "left" })}
            variant="caption"
            colorVariant="tertiary"
          >
            {isSending ? "отправляется..." : time}
          </Text>
          {!isSending && showChecksIcon && (
            <Icon
              variant="checks-mark"
              size="s"
              fill={isRead ? "var(--green)" : undefined}
            />
          )}
        </div>
      )}
    </div>
  );
};

Row.defaultProps = {
  position: "left",
  showFooter: true,
  isRead: false,
  isSendFailure: false,
  showChecksIcon: false,
  margin: true
};

Row.propTypes = {
  position: PropTypes.oneOf(["left", "center", "right"]),
  showFooter: PropTypes.bool,
  isRead: PropTypes.bool,
  isSendFailure: PropTypes.bool,
  showChecksIcon: PropTypes.bool,
  created_at: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  margin: PropTypes.bool
};

export default memo(Row);
