/* eslint-disable no-console */

class Logger {
  clientLogLevel = 0;

  trace(message) {
    if (this.clientLogLevel > 1) {
      console.log(message);
    }
  }

  warn(message) {
    if (this.clientLogLevel > 2) {
      console.warn(message);
    }
  }

  error(message) {
    if (this.clientLogLevel > 3) {
      console.error(message);
    }
  }

  setClientLogLevel(level) {
    this.clientLogLevel = level;
  }
}

const logger = new Logger();

if (__DEV__) {
  window.logger = logger;
}

export default logger;
