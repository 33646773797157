import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Text } from "ibolit-ui";
import styles from "./EntitledBox.scss";

const EntitledBox = ({
  title,
  children,
  content,
  className,
  testid,
  titleOffset
}) => {
  const style = useMemo(
    () => ({
      marginBottom: titleOffset
    }),
    [titleOffset]
  );

  return (
    <div className={className}>
      <Text variant="h6" className={styles.label} style={style}>
        {title}
      </Text>
      {content &&
        (typeof content === "string" || typeof content === "number" ? (
          <Text testid={testid} variant="h5">
            {content}
          </Text>
        ) : (
          content
        ))}
      {children}
    </div>
  );
};

EntitledBox.defaultProps = {};

EntitledBox.propTypes = {
  content: PropTypes.node,
  children: PropTypes.node,
  title: PropTypes.string
};

export default EntitledBox;
