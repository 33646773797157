import { Text } from "ibolit-ui";
import React from "react";
import Icon from "ibolit-ui/src/UI/Icon";
import styles from "./DoctorsChat.scss";

export function Header({ onBackClick, onCloseClick }) {
  return (
    <div className={styles.header}>
      <Text tag="span" variant="h2" testid="header_modal_window">
        Выберите врача
      </Text>
      <div className={styles.back} data-testid="back_modal_window">
        <Icon variant="arrow" onClick={onBackClick} />
      </div>
      <div className={styles.close} data-testid="close_modal_window">
        <Icon variant="close" fill="var(--grey)" onClick={onCloseClick} />
      </div>
    </div>
  );
}
