import {
  takeLatest,
  all,
  put,
  throttle,
  call,
  select
} from "redux-saga/effects";
import { normalize } from "normalizr";
import api from "~/api/usersApi";
import clinicApi from "~/api/clinicsApi";
import Types from "~/store/users/userTypes";
import Actions from "~/store/users/userActions";
import commonActions from "~/store/common/commonActions";
import { userSchema } from "~/store/users/userNormalizer";
import { getUserTariffsByType } from "~/store/users/userSelectors";
import slotsActions from "../slots/slotsActions";

const customNormalize = items =>
  items.reduce(
    (acc, curr) => {
      const { specialty, id } = curr;
      return {
        result: [
          ...acc.result,
          {
            specialty,
            id
          }
        ],
        entities: {
          ...acc.entities,
          [id]: curr
        }
      };
    },
    {
      result: [],
      entities: {}
    }
  );

function* sendRequestSaga({ payload }) {
  const { page, limit, mode, value, clinicId } = payload;
  try {
    const params = value ? { page, limit, query: value } : { page, limit };
    const { data } = yield call(
      clinicApi.getDoctorsByClinicApi,
      clinicId,
      params
    );
    const { result: order, entities } = customNormalize(data);
    yield put(commonActions.updateItems({ users: entities }));
    yield put(Actions.requestSuccess({ page, limit, order, mode }));
  } catch (error) {
    yield put(Actions.requestFailed({ page, limit, mode, errors: error }));
  }
}

function* userTariffsSaga({ payload }) {
  const { userId, tariffsType } = payload;
  try {
    const { data } = yield call(api.userTariffsApi, { id: userId });
    yield put(slotsActions.fetchSlots("online", userId));
    yield put(slotsActions.fetchSlots("offline", userId));
    const usersTariffs = { users: { [userId]: { tariffs: data } } };
    yield put(Actions.tariffsSuccess());
    yield put(commonActions.updateItems(usersTariffs));
    // TODO проверить на сайд эффекты
    if (tariffsType) {
      const userTariffsByType = yield select(getUserTariffsByType, {
        userId,
        tariffsType
      });

      if (userTariffsByType.length) {
        yield put(Actions.selectTariff(userTariffsByType[0]));
      }
    }
  } catch (e) {
    yield put(Actions.tariffsFailure(e.message));
  }
}

function* getChatId({ id }) {
  try {
    const {
      data: { id: chat_id }
    } = yield api.userChatIdApi({ id });
    return chat_id;
  } catch (e) {
    return null;
  }
}

function* userInfoSaga({ payload }) {
  try {
    const { data } = yield api.userInfoApi(payload);
    const chat_id = yield getChatId(payload);
    const { entities } = normalize({ ...data, chat_id }, userSchema);
    yield put(commonActions.updateItems(entities));
    yield put(Actions.userInfoSuccess());
  } catch (e) {
    yield put(Actions.userInfoFailure(e.message));
  }
}

function* userCheckNewSaga({ request }) {
  try {
    const { data } = yield api.userCheckNewApi(request);
    data.status && (yield put(Actions.userCheckNewSuccess(data, request)));
  } catch (e) {
    yield put(Actions.userCheckNewFailure(e.message, request));
  }
}

export default function* usersSaga() {
  yield all([
    throttle(2000, Types.REQUEST_SEND, sendRequestSaga),
    takeLatest(Types.TARIFFS_REQUEST, userTariffsSaga),
    takeLatest(Types.USER_INFO_REQUEST, userInfoSaga),
    takeLatest(Types.USER_CHECK_NEW_REQUEST, userCheckNewSaga)
  ]);
}
