import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { Text } from "ibolit-ui";
import styles from "./ShapeButton.scss";

const ShapeButton = ({
  className,
  onClick,
  style,
  rounded,
  disabled,
  text,
  children,
  textColorVariant,
  textVariant,
  testid
}) => (
  <Text
    testid={testid}
    tag="div"
    variant={textVariant}
    className={cn(styles.container, className, {
      [styles.rounded]: rounded,
      [styles.clickable]: !disabled && onClick
    })}
    style={style}
    onClick={!disabled ? onClick : undefined}
    colorVariant={textColorVariant}
  >
    {text}
    {children}
  </Text>
);

ShapeButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  text: PropTypes.string,
  textColorVariant: Text.propTypes.colorVariant,
  textVariant: Text.propTypes.variant,
  testid: PropTypes.string
};

ShapeButton.defaultProps = {
  textVariant: "h5",
  rounded: true
};

export default ShapeButton;
