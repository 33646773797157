import React from "react";

import cn from "classnames";

import styles from "./tabs.module.scss";
import { Text } from "ibolit-ui";

import { PlusIcon } from "./PlusIcon.jsx";

export const enumTabsNames = {
  INFO: "info",
  ANAMNESIS: "anamnesis",
  FILES: "files",
  CONCLUSION: "conclusion",
};

export const enumTabsIndexes = {
  INFO: 0,
  ANAMNESIS: 1,
  FILES: 2,
  CONCLUSION: 3,
};

export const tabsInfo = [
  { name: enumTabsNames.INFO, label: "Информация" },
  { name: enumTabsNames.ANAMNESIS, label: "Анамнез" },
  { name: enumTabsNames.FILES, label: "Файлы", Icon: PlusIcon },
  { name: enumTabsNames.CONCLUSION, label: "Заключение" },
];

export const Tabs = ({
  activeTabInfo,
  setActiveTab,
  variant = "doctor",
  counter,
}) => {
  const handleSetActiveTab = (index) => () => setActiveTab(tabsInfo[index]);

  return (
    <div
      className={cn(
        styles.container,
        variant === "doctor" ? styles.doctorContainer : styles.patientContainer
      )}
    >
      {tabsInfo.map(({ name, label, Icon }, index) => (
        <div
          className={cn(
            styles.tabButton,
            activeTabInfo.name === name && styles.tabButton_active,
            styles[`tabButton_active-${variant}`],
            Icon && styles.tabButtonWithIcon
          )}
          onClick={handleSetActiveTab(index)}
          key={index}
        >
          <div className={styles.selectedTabStuffLeft} />
          <div className={styles.selectedTabStuffRight} />
          <div className={styles.label}>
            {name === enumTabsNames.FILES ? (
              <>
                {counter !== 0 ? (
                  <div
                    className={styles.counter}
                    data-testid={"Receptions__Tabs__counter"}
                  >
                    {counter}
                  </div>
                ) : (
                  <Icon
                    className={cn(
                      styles.labelIcon,
                      activeTabInfo.name === enumTabsNames.FILES &&
                        variant === "doctor"
                        ? styles.doctorIcon
                        : null
                    )}
                  />
                )}
              </>
            ) : (
              Icon && <Icon className={styles.labelIcon} />
            )}
            <Text
              colorVariant={
                activeTabInfo.name === name ? "primary" : "secondary"
              }
              variant={"h6"}
              testid={"Receptions__Tabs__label"}
            >
              {label}
            </Text>
          </div>
        </div>
      ))}
    </div>
  );
};
