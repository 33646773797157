import React from "react";
import PropTypes from "prop-types";
import { Icon } from "ibolit-ui";
import Logo from "~/components/Logo";
import UserAvatar from "~/components/UserAvatar";
import locators from "~/locators/navbar";
import Nav from "../Nav";
import styles from "./Header.scss";

const Header = ({ loading, user, onAvatarClick, routesList }) => {
  return (
    <div className={styles.header}>
      <Logo
        href="/"
        className={styles.logo}
        testid={locators.LOGO}
        loading={loading}
      />
      <Nav list={routesList} className={styles.navContainer} />
      <div
        data-testid={locators.PROFILE}
        className={styles.avatarContainer}
        onClick={onAvatarClick}
      >
        <UserAvatar user={user} />
      </div>
    </div>
  );
};

Header.propTypes = {
  ...UserAvatar.propTypes,
  routesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string,
      to: PropTypes.string.isRequired,
      icon: Icon.propTypes.variant
    })
  ),
  onAvatarClick: PropTypes.func
};

export default Header;
