import Immutable from "seamless-immutable";
import types from "~/store/feedback/feedbackTypes";

const initialState = Immutable({
  loading: false,
  success: null,
  error: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FEEDBACK_SEND_REQUEST: {
      return state.merge({
        loading: true,
        success: null,
        error: null
      });
    }

    case types.FEEDBACK_SEND_SUCCESS: {
      return state.merge({
        loading: false,
        success: true
      });
    }

    case types.FEEDBACK_SEND_FAILURE: {
      return state.merge({
        loading: false,
        success: false,
        error: action.error
      });
    }

    case types.FEEDBACK_DEFAULT_STATE_SET: {
      return state.merge({
        ...initialState
      });
    }

    default:
      return state;
  }
};
