import React from "react";
import { Text } from "ibolit-ui";
import auth from "~/locators/auth";
import styles from "./Activation.scss";
import formatPhone from "~/utils/formatPhone";
import { getIsEmail } from "~/utils/validations";

const TEXT = [
  "Вы получили приглашение на e-mail",
  "Вы получили приглашение на номер"
];

const MainMessage = ({ login }) => {
  const lineLogin = login.replace(/\s/, "&nbsp;");
  const isEmail = getIsEmail(login);
  return (
    <div className={styles.registrationContainer}>
      <Text tag="h3" variant="h3" testid="MainMessage__registration_title">
        Регистрация
      </Text>
      <Text
        tag="p"
        variant="desc"
        testid={auth.AUTH_ACTIVATION_INVITATION_TEXT}
      >
        {TEXT[isEmail ? 0 : 1]}
        <br />
        <Text
          tag="span"
          weight="bold"
          testid={auth.AUTH_ACTIVATION_INVITATION_NUMBER}
        >
          {/** TODO: make formatting everywhere the same. */}
          {isEmail ? lineLogin : formatPhone(lineLogin.replace(/(^8)/, "+7"))}
        </Text>
      </Text>
    </div>
  );
};

export default React.memo(MainMessage);
