import cn from "classnames";
import React from "react";
import { Tabs } from "ibolit-ui";
import { CONSULTATIONS_LIST } from "~/locators/consultations";
import styles from "./TopBlock.scss";

const TopBlock = ({ className, filters, filter, setFilter }) => (
  <div className={cn(styles.container)}>
    <Tabs
      testid={CONSULTATIONS_LIST.TABS}
      onChange={setFilter}
      values={filters}
      value={filter}
      shift
      className={cn(className, styles.tabs)}
      textStyles={{ color: "var(--pink)" }}
      buttonActiveStyles={{ backgroundColor: "rgba(var(--pinkRGB), 0.1)" }}
      textActiveStyles={{ color: "var(--pink)" }}
    />
  </div>
);

export default TopBlock;
