import React from "react";
import styles from "./PatientProblem.scss";
import cn from "classnames";
import { IconWithText, Text } from "ibolit-ui";
import _ from "lodash";
import { PATIENT_PROBLEM_STATUSES } from "../../constants";
import { pluralizeDay } from "../../../../utils/pluralize";

export const PatientProblem = ({ message, isDoctor }) => {
  const { patient_problem } = message;
  const problemStatusData = PATIENT_PROBLEM_STATUSES.find(
    item => item.value === patient_problem.status
  );

  const formattedProblemDuration = () => {
    if (!patient_problem.days) return "не установлено";
    return pluralizeDay(patient_problem.days);
  };

  return (
    <div className={styles.consultationChat}>
      <div className={styles.bubble}>
        <div className={styles.consultationChat_row}>
          <IconWithText
            className={styles.messageTitle}
            iconSize="s"
            iconColor={isDoctor ? "var(--blue)" : "var(--pink)"}
            iconVariant="msg-bubble"
          >
            <Text variant="caption">Чат с врачом</Text>
          </IconWithText>
        </div>
        {problemStatusData && (
          <Text className={styles.problemTitle} variant="h4">
            {problemStatusData.title}
          </Text>
        )}
        <div className={styles.consultationChat_row}>
          <Text variant="h4">Жалоба</Text>
          <Text variant="sub1">{patient_problem.title || "Не определена"}</Text>
        </div>
        <div className={styles.consultationChat_row}>
          <Text variant="h4">Жалоба наблюдается</Text>
          <Text variant="sub1">{formattedProblemDuration()}</Text>
        </div>
        <div className={styles.consultationChat_row}>
          <Text variant="h4">Уровень дискомфорта</Text>
          <div className={styles.painScale}>
            {_.range(11).map(number => (
              <div
                key={number}
                className={cn(styles.painLevel, {
                  [styles[`selectedPainLevel${isDoctor ? 'Doctor' : ''}`]]:
                    patient_problem.discomfort_level !== null &&
                    number <= patient_problem.discomfort_level
                })}
              >
                {number}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
