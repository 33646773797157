import React, { useState, useCallback, useMemo, useRef } from "react";
import Carousel, { ModalGateway, Modal } from "react-images";
import useOutsideClickHandler from "~/hooks/useOutsideClickHandler";
import styles from "./LightBoxProvider.scss";

import { videoExtensionsRegExp, imageExtensionsRegExp } from "~/utils/regExps";

export const LightBoxContext = React.createContext();

const LightBoxProvider = ({ children }) => {
  const [images, setImages] = useState(null);
  const [videos, setVideos] = useState(null);
  const videoRef = useRef();

  const handleImagesPreviewOpen = useCallback(imageFiles => {
    const _images = imageFiles.reduce((acc, file) => {
      imageExtensionsRegExp.lastIndex = 0;
      if (imageExtensionsRegExp.test(file.extension)) {
        acc.push({ source: file.url, caption: file.name });
      }
      return acc;
    }, []);

    setImages(_images.length ? _images : null);
  }, []);

  const handleImagesPreviewClose = useCallback(() => setImages(null), []);

  const handleVideosPreviewOpen = useCallback(videoFiles => {
    const _videos = videoFiles.reduce((acc, file) => {
      videoExtensionsRegExp.lastIndex = 0;
      if (videoExtensionsRegExp.test(file.extension)) {
        acc.push({ source: file.url, caption: file.name });
      }

      return acc;
    }, []);

    setVideos(_videos.length ? _videos : null);
  }, []);

  const handleVideosPreviewClose = useCallback(() => {
    setVideos(null);
  }, []);

  const value = useMemo(
    () => ({
      showImagesPreview: handleImagesPreviewOpen,
      hideImagesPreview: handleImagesPreviewClose,
      showVideosPreview: handleVideosPreviewOpen,
      hideVideosPreview: handleVideosPreviewClose
    }),
    []
  );

  useOutsideClickHandler(videoRef, () => {
    handleVideosPreviewClose();
  });

  return (
    <LightBoxContext.Provider value={value}>
      {children}
      {images ? (
        <ModalGateway>
          <Modal onClose={handleImagesPreviewClose}>
            <Carousel views={images} />
          </Modal>
        </ModalGateway>
      ) : null}
      {videos ? (
        <ModalGateway>
          <Modal onClose={handleVideosPreviewClose}>
            <video
              className={styles.video}
              ref={videoRef}
              src={videos[0].source}
              controls
            />
          </Modal>
        </ModalGateway>
      ) : null}
    </LightBoxContext.Provider>
  );
};

LightBoxProvider.propTypes = {};

export default LightBoxProvider;
