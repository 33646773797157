import LaravelEcho from "laravel-echo";
import io from "socket.io-client";

window.io = io;

export default class WebSocketClient {
  echo = null;

  init = ({ token, host }) => {
    if (!this.echo) {
      this.echo = new LaravelEcho({
        broadcaster: "socket.io",
        auth: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        },
        transports: ["websocket"],
        host
      });
    }
  };

  addListeners = (privateId, listeners) => {
    if (this.echo !== null) {
      const priv = this.echo.private(privateId);

      listeners.forEach(([listenId, handler]) => {
        priv.listen(listenId, handler);
      });
    }
  };

  addPublicListeners = (channelId, listeners) => {
    if (this.echo !== null) {
      const channel = this.echo.channel(channelId);

      listeners.forEach(([listenId, handler]) => {
        channel.listen(listenId, handler);
      });
    }
  };

  close = () => {
    if (this.echo !== null) {
      this.echo.disconnect();
      this.echo = null;
    }
  };
}
