import React from "react";
import cn from "classnames";

import styles from "./ConsultationConclusion.scss";

import { getDateDiffs } from "~/utils/dateUtils";
import { pluralizeDay } from "../../utils/pluralize";

import { Button, Icon } from "ibolit-ui";

export const ConsultationConclusion = ({
  consultationData,
  onDownloadPDF,
  color,
  isDoctor,
}) => (
  <>
    <div className={styles.doctorRecomendation}>
      <div
        className={styles.conclusionHeader}
        data-testid="Receptions__ConsultationConclusion-conclusionRecommendationHeader"
      >
        Рекомендации врача
      </div>
      <div
        className={cn(
          styles.conclusionText,
          isDoctor ? null : styles.conclusionTextPatient
        )}
        data-testid="Receptions__ConsultationConclusion-conclusionText"
      >
        {consultationData.conclusion.text}
      </div>
    </div>

    <div className={styles.conclusionOptionsContainer}>
      {consultationData.recommended_consultation?.date_time ? (
        <div className={styles.dataRecomendation}>
          <div
            className={styles.conclusionHeader}
            data-testid="Receptions__ConsultationConclusion-conclusionHeader"
          >
            Рекомендация следующего приема
          </div>
          <div className={styles.nextConultationRecommendation}>
            <Icon
              className={styles.icon}
              fill={color}
              stroke={color}
              style={{
                alignSelf: "middle",
              }}
              variant="calendar"
              sizeValue={36}
            />
            <div
              className={styles.recommendationText}
              data-testid="Receptions__ConsultationConclusion-recommendationText"
            >
              Через{" "}
              {pluralizeDay(
                getDateDiffs(
                  consultationData.recommended_consultation.date_time,
                  consultationData.recommended_consultation.created_at
                ).days
              )}
            </div>
          </div>
        </div>
      ) : null}

      <Button
        colorVariant="patient"
        icon={"upload"}
        iconColor={color}
        testid={"test-download-conclusion"}
        className={styles["conclusion__download-button"]}
        textClassName={
          styles[
            `conclusion__download-button-text${
              isDoctor ? "-doctor" : "-patient"
            }`
          ]
        }
        variant="tertiary"
        onClick={onDownloadPDF}
        style={{
          borderColor: color,
          color,
        }}
      >
        Скачать заключение
      </Button>
    </div>
  </>
);
