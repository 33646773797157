export default {
  PROFILE_USER_DATA_LOAD_SUCCESS: "profile/USER_DATA_LOAD_SUCCESS",
  PROFILE_USER_DATA_LOAD_REQUEST: "profile/USER_DATA_LOAD_REQUEST",
  PROFILE_USER_DATA_UPDATE_SUCCESS: "profile/USER_DATA_UPDATE_SUCCESS",
  PROFILE_USER_DATA_UPDATE_ERROR: "profile/USER_DATA_UPDATE_ERROR",
  PROFILE_USER_DATA_UPDATE_REQUEST: "profile/USER_DATA_UPDATE_REQUEST",
  PROFILE_USER_AVATAR_REQUEST: "profile/USER_AVATAR_REQUEST",
  PROFILE_USER_DATA_DEFAULT_STATE_SET: "profile/USER_DATA_DEFAULT_STATE_SET",
  PROFILE_RESET_STATE_ACTIONS_SET: "profile/RESET_STATE_ACTIONS_SET",

  PROFILE_SETTINGS_CHANGE_SCENE: "profile/SETTINGS_CHANGE_SCENE",
  PROFILE_SETTINGS_GO_BACK: "profile/SETTINGS_GO_BACK",

  PROFILE_PASSWORD_CHANGE_REQUEST: "profile/PASSWORD_CHANGE_REQUEST",
  PROFILE_PASSWORD_CHANGE_SUCCESS: "profile/PASSWORD_CHANGE_SUCCESS",
  PROFILE_PASSWORD_CHANGE_ERROR: "profile/PASSWORD_CHANGE_ERROR",
  PROFILE_PASSWORD_CHANGE_VALIDATION_ERROR:
    "profile/PASSWORD_CHANGE_VALIDATION_ERROR",

  PROFILE_SETTINGS_CONFIRM_REQUEST: "profile/SETTINGS_CONFIRM_REQUEST",
  PROFILE_SETTINGS_CONFIRM_SUCCESS: "profile/SETTINGS_CONFIRM_SUCCESS",
  PROFILE_SETTINGS_CONFIRM_ERROR: "profile/SETTINGS_CONFIRM_ERROR",
  PROFILE_SETTINGS_CONFIRM_VALIDATION_ERROR:
    "profile/SETTINGS_CONFIRM_VALIDATION_ERROR",

  PROFILE_SETTINGS_CODE_SEND: "profile/SETTINGS_CODE_SEND",
  PROFILE_SETTINGS_CODE_SEND_SUCCESS: "profile/SETTINGS_CODE_SEND_SUCCESS",
  PROFILE_SETTINGS_CODE_SEND_ERROR: "profile/SETTINGS_CODE_SEND_ERROR",

  PROFILE_LOGIN_CHANGE_REQUEST: "profile/LOGIN_CHANGE_REQUEST",
  PROFILE_LOGIN_CHANGE_SUCCESS: "profile/LOGIN_CHANGE_SUCCESS",
  PROFILE_LOGIN_CHANGE_ERROR: "profile/LOGIN_CHANGE_ERROR",
  PROFILE_LOGIN_CHANGE_VALIDATION_ERROR:
    "profile/LOGIN_CHANGE_VALIDATION_ERROR",
  PROFILE_LOGIN_RESET_SETTINGS: "profile/LOGIN_RESET_SETTINGS"
};
