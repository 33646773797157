import CallRoomModal, {
  CALL_ROOM_VIEW
} from "~/components/CallRoom/components/CallRoomModal/CallRoomModal";
import CallRoomExitModal, {
  CALL_ROOM_EXIT_VIEW
} from "~/components/CallRoom/components/CallRoomExitModal/CallRoomExitModal";
import LoginErrorModal, {
  AUTH_LOGIN_ERROR_VIEW
} from "~/layouts/Authorization/containers/LoginErrorModal";
import PersonalUserProfile, { PROFILE_VIEW } from "~/modals/Profile";
import ConnectionCheck, {
  CONNECTION_CHECK_VIEW
} from "~/components/HealthCheck/ConnectionCheckModal";
import ProfileSettingsModal, {
  PROFILE_SETTINGS_VIEW
} from "~/modals/ProfileSettings";
import { Subscriptions, SUBSCRIPTION_VIEW } from "~/modals/Subscriptions";
import ProfileSupportContainer, {
  PROFILE_SUPPORT_VIEW
} from "~/modals/ProfileSupport";
import TariffsModal, { TARIFFS_VIEW } from "~/modals/Tariffs/TariffsModal";
import PaymentModal, { PAYMENT_VIEW } from "~/components/Payment/PaymentModal";
import InvoicedPayment, {
  PAYMENT_IFRAME_VIEW
} from "~/components/Payment/InvoicedPayment";
import ChangeSettings from "~/modals/ProfileSettings/components/ChangeSettingsModal/ChangeSettingsModal";
import { PROFILE_CHANGE_SETTINGS_VIEW } from "~/consts/profile";
import ClinicsModal, { CLINICS_VIEW } from "~/modals/Clinics";
import {
  SPECIALIZATION_CHOOSE,
  SpecializationChoose
} from "~/modals/SpecializationChoose/SpecializationChoose";
import DoctorsChatModal, { DOCTORS_CHAT_VIEW } from "~/modals/DoctorsChat";
import {
  NewConsultation,
  SelectClinic,
  SelectTariff,
  StatusSuccess,
  StatusOccupied,
  StatusError
} from "~/modals/NewConsultation";
import ConsultationStarted, {
  CONSULTATION_STARTED
} from "~/modals/ConsultationStarted/ConsultationStarted";
import ConsultationVideoModal, {
  CONSULTATION_VIDEO_VIEW
} from "~/layouts/Consultations/Consultation/components/ConsultationVideoModal/ConsultationVideoModal";
import ConsultationFeedbackModal, {
  CONSULTATION_FEEDBACK_VIEW
} from "~/modals/ConsultationFeedback/ConsultationFeedback";
import { NEW_CONSULTATION_CLINICS_MODAL } from "~/modals/NewConsultation/components/SelectClinic/SelectClinic";
import { NEW_CONSULTATION_MODAL } from "~/modals/NewConsultation/components/NewConsultation/NewConsultation";
import { NEW_CONSULTATION_TARIFFS_MODAL } from "~/modals/NewConsultation/components/SelectTariff/SelectTariff";
import { NEW_CONSULTATION_SUCCESS_MODAL } from "~/modals/NewConsultation/components/Statuses/StatusSuccess";
import { NEW_CONSULTATION_OCCUPIED_MODAL } from "~/modals/NewConsultation/components/Statuses/StatusOccupied";
import { NEW_CONSULTATION_ERROR_MODAL } from "~/modals/NewConsultation/components/Statuses/StatusError";
import {
  FROM_WIDGET_NEW_PASSWORD,
  FROM_WIDGET_USER_INFO_VIEW,
  NewPassword,
  UserInfoModal
} from "~/modals/FromWidget";
import ErrorModal, {
  CALL_ROOM_ERROR
} from "~/components/CallRoom/components/ErrorModal/ErrorModal";
import Symptoms, {
  SYMPTOMS_VIEW
} from "~/modals/NewConsultation/components/Symptoms/Symptoms";
import AddingFilesModal, {
  ADDING_FILES
} from "~/layouts/Consultations/Consultation/components/FileManager/AddingFilesModal/AddingFilesModal";
import FileAlertModal, {
  FILE_ALERT_MODAL
} from "~/layouts/Consultations/Consultation/components/FileManager/FileAlertModal/FileAlertModal";
import ErrorBox, {
  ERROR_BOX_MODAL
} from "~/components/ErrorSwitcher/ErrorBox/ErrorBox";
import ApproveDeleteModal, {
  APPROVE_DELETE_MODAL
} from "~/layouts/Consultations/Consultation/components/FileManager/ApproveDeleteModal/ApproveDeleteModal";
import CancelConsultationModal, {
  CANCEL_CONSULTATION
} from "~/modals/CancelConsultation/CancelConsultation";
import ChooseService, {
  CHOOSE_SERVICE_VIEW
} from "./ChooseService/ChooseService";
import ChooseDateTime, {
  CHOOSE_DATE_TIME
} from "./ChooseDateTime/ChooseDateTime";
import {
  STATUS_PAY,
  StatusPay
} from "./NewConsultation/components/Statuses/StatusPay";
import { PROMO_MODAL_VIEW, PromoModal } from "./Promo/PromoModal";

export default {
  [AUTH_LOGIN_ERROR_VIEW]: LoginErrorModal,
  [CALL_ROOM_EXIT_VIEW]: CallRoomExitModal,
  [CALL_ROOM_VIEW]: CallRoomModal,
  [CALL_ROOM_ERROR]: ErrorModal,
  [ERROR_BOX_MODAL]: ErrorBox,
  [CLINICS_VIEW]: ClinicsModal,
  [CONNECTION_CHECK_VIEW]: ConnectionCheck,
  [CONSULTATION_STARTED]: ConsultationStarted,
  [CONSULTATION_VIDEO_VIEW]: ConsultationVideoModal,
  [CONSULTATION_FEEDBACK_VIEW]: ConsultationFeedbackModal,
  [DOCTORS_CHAT_VIEW]: DoctorsChatModal,
  [NEW_CONSULTATION_CLINICS_MODAL]: SelectClinic,
  [NEW_CONSULTATION_ERROR_MODAL]: StatusError,
  [NEW_CONSULTATION_MODAL]: NewConsultation,
  [NEW_CONSULTATION_OCCUPIED_MODAL]: StatusOccupied,
  [NEW_CONSULTATION_SUCCESS_MODAL]: StatusSuccess,
  [NEW_CONSULTATION_TARIFFS_MODAL]: SelectTariff,
  [SYMPTOMS_VIEW]: Symptoms,
  [PAYMENT_IFRAME_VIEW]: InvoicedPayment,
  [PAYMENT_VIEW]: PaymentModal,
  [PROFILE_CHANGE_SETTINGS_VIEW]: ChangeSettings,
  [PROFILE_SETTINGS_VIEW]: ProfileSettingsModal,
  [PROFILE_SUPPORT_VIEW]: ProfileSupportContainer,
  [PROFILE_VIEW]: PersonalUserProfile,
  [SUBSCRIPTION_VIEW]: Subscriptions,
  [TARIFFS_VIEW]: TariffsModal,
  [FROM_WIDGET_USER_INFO_VIEW]: UserInfoModal,
  [FROM_WIDGET_NEW_PASSWORD]: NewPassword,
  [ADDING_FILES]: AddingFilesModal,
  [FILE_ALERT_MODAL]: FileAlertModal,
  [APPROVE_DELETE_MODAL]: ApproveDeleteModal,
  [CANCEL_CONSULTATION]: CancelConsultationModal,
  [SPECIALIZATION_CHOOSE]: SpecializationChoose,
  [CHOOSE_SERVICE_VIEW]: ChooseService,
  [CHOOSE_DATE_TIME]: ChooseDateTime,
  [STATUS_PAY]: StatusPay,
  [PROMO_MODAL_VIEW]: PromoModal
};
