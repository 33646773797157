import { takeLatest, all, put, call, select } from "redux-saga/effects";
import types from "./newConsultationTypes";
import api from "~/api/consultationsApi";
import consultationsActions from "~/store/consultation/consultationActions";
import uiActions from "~/store/ui/uiActions";
import toastActions from "~/store/toast/toastActions";
import { NEW_CONSULTATION_SUCCESS_MODAL } from "~/modals/NewConsultation/components/Statuses/StatusSuccess";
import { CONSULTATIONS_FILTER_STATUS_OPEN } from "~/store/consultation/consultationConsts";
import { NEW_CONSULTATION_OCCUPIED_MODAL } from "~/modals/NewConsultation/components/Statuses/StatusOccupied";
import { SYMPTOMS_VIEW } from "~/modals/NewConsultation/components/Symptoms/Symptoms";
import { CookieList, deleteCookie, getCookie } from "~/utils/cookies";
import { getSelectedSlot } from "./newConsultationSelectors";
import { CHOOSE_DATE_TIME } from "~/modals/ChooseDateTime/ChooseDateTime";
import { selectPromotionCodeId } from "../promocode/selectors";

function* postConsultationSaga({
  doctorId,
  tariffId,
  tariffType,
  slotId,
  problemId,
  painLevel,
  doctor,
  price,
  startAt
}) {
  try {
    const recommendation_id = Number(getCookie(CookieList.RECOMMENDATION_ID));
    const params = {
      doctor_id: doctorId,
      tariff_id: tariffId,
      slot_id: slotId,
      problem_id: problemId,
      type: tariffType,
      discomfort_level: painLevel
    };

    if (recommendation_id) params.recommendation_id = recommendation_id;

    const { data } = yield call(api.createConsultation, params);
    // Чистим куки. Пациент успешно записался после пуша
    deleteCookie(CookieList.RECOMMENDATION_ID);
    yield put({ type: types.CONSULTATIONS_POST_SUCCESS, data });
    yield put(
      consultationsActions.getConsultations({
        page: 1,
        mode: CONSULTATIONS_FILTER_STATUS_OPEN
      })
    );
    yield put(
      uiActions.replaceModal(CHOOSE_DATE_TIME, NEW_CONSULTATION_SUCCESS_MODAL, {
        doctor,
        slotType: tariffType,
        price,
        startAt: startAt
      })
    );
  } catch (error) {
    if (error.response?.status === 400) {
      yield put(
        uiActions.replaceModal(
          CHOOSE_DATE_TIME,
          NEW_CONSULTATION_OCCUPIED_MODAL,
          {
            doctor,
            slotType: tariffType,
            price,
            startAt
          }
        )
      );
    } else {
      yield put({ type: types.CONSULTATIONS_POST_FAILURE, error });
      yield put(
        uiActions.replaceModal(
          CHOOSE_DATE_TIME,
          NEW_CONSULTATION_OCCUPIED_MODAL,
          {
            doctor,
            slotType: tariffType,
            price,
            startAt
          }
        )
      );
    }
  }
}

function* getSymptoms() {
  try {
    const { data } = yield call(api.getSymptoms);
    yield put({ type: types.NEW_CONSULTATION_SYMPTOMS_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.NEW_CONSULTATION_SYMPTOMS_FAILURE, error });
    yield put(uiActions.hideModal(SYMPTOMS_VIEW));
    yield put(
      toastActions.showErrorToast("Произошла ошибка, попробуйте позже")
    );
  }
}

function* createSymptom({
  symptom,
  consultation,
  isBackendCreated,
  onClose,
  doctor
}) {
  try {
    const { data } = yield call(api.createSymptom, symptom);
    if (isBackendCreated) {
      yield call(api.linkSymptomToConsultation, {
        symptomId: data.id,
        consultationId: consultation.id,
        painLevel: symptom.painLevel
      });
      yield put(consultationsActions.getInfo(consultation.id));
    }
    if (consultation.slotId) {
      // Update the consultation payload with freshly created symptom
      const withNewSymptom = {
        ...consultation,
        problemId: data.id,
        painLevel: symptom.painLevel,
        doctor
      };
      yield call(postConsultationSaga, withNewSymptom);
      yield put(
        uiActions.replaceModal(SYMPTOMS_VIEW, NEW_CONSULTATION_SUCCESS_MODAL, {
          doctor
        })
      );
    } else {
      onClose();
    }
    yield put({ type: types.NEW_CONSULTATION_SYMPTOM_CREATE_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.NEW_CONSULTATION_SYMPTOM_CREATE_FAILURE, error });
    yield put(
      toastActions.showErrorToast("Произошла ошибка, попробуйте позже")
    );
  }
}

function* updateSymptom({
  symptom: { symptomId, status, painLevel },
  consultation,
  isBackendCreated,
  onClose,
  doctor
}) {
  try {
    const { data } = yield call(api.updateSymptom, {
      symptomId,
      params: { status }
    });
    if (isBackendCreated) {
      yield call(api.linkSymptomToConsultation, {
        symptomId: data.id,
        consultationId: consultation.id,
        painLevel
      });
      yield put(consultationsActions.getInfo(consultation.id));
    }
    if (consultation.slotId) {
      const withNewPainLevel = { ...consultation, painLevel, doctor };
      yield call(postConsultationSaga, withNewPainLevel);
      yield put(
        uiActions.replaceModal(SYMPTOMS_VIEW, NEW_CONSULTATION_SUCCESS_MODAL, {
          doctor
        })
      );
    } else {
      onClose();
    }
    yield put({ type: types.NEW_CONSULTATION_SYMPTOM_UPDATE_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.NEW_CONSULTATION_SYMPTOM_UPDATE_FAILURE, error });
    yield put(
      toastActions.showErrorToast("Произошла ошибка, попробуйте позже")
    );
  }
}

export default function* usersSaga() {
  yield all([
    takeLatest(types.CONSULTATIONS_POST_REQUEST, postConsultationSaga),
    takeLatest(types.NEW_CONSULTATION_SYMPTOMS_REQUEST, getSymptoms),
    takeLatest(types.NEW_CONSULTATION_SYMPTOM_CREATE_REQUEST, createSymptom),
    takeLatest(types.NEW_CONSULTATION_SYMPTOM_UPDATE_REQUEST, updateSymptom)
  ]);
}
