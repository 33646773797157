import React, { useState, useCallback } from "react";
import { Button, Dialog, Text } from "ibolit-ui";
import { useSelector } from "react-redux";
import cn from "classnames";
import styles from "./SelectTariff.scss";
import { useCloseModal, useReplaceModal } from "~/hooks/useModal";
import {
  NEW_CONSULTATION_MODAL,
  SLOT_TYPES
} from "~/modals/NewConsultation/components/NewConsultation/NewConsultation";
import { getTariffsByUserId } from "~/store/users/userSelectors";
import {
  getSelectedDoctor,
  getSelectedSlot,
  getSelectedSlotType
} from "~/store/newConsultation/newConsultationSelectors";
import { NEW_CONSULTATION } from "~/locators/newConsultation";
import Scrollbar from "~/components/Scrollbar";
import { pluralizeDays } from "~/utils/pluralize";
import { MINUTES_IN_DAY } from "~/consts/dates";
import { SYMPTOMS_VIEW } from "~/modals/NewConsultation/components/Symptoms/Symptoms";

export const NEW_CONSULTATION_TARIFFS_MODAL = "newConsultationTariffsModal";

const SelectTariff = () => {
  const doctor = useSelector(getSelectedDoctor);
  const selectedSlot = useSelector(getSelectedSlot);
  const selectedSlotType = useSelector(getSelectedSlotType);
  const memoizedTariffsSelector = useCallback(
    state => getTariffsByUserId(state, { userId: doctor.id }),
    [doctor.id]
  );
  const tariffList = useSelector(memoizedTariffsSelector);
  const tariffs = tariffList[SLOT_TYPES.items[selectedSlotType].typeAPI];
  const [selectedTariff, setSelectedTariff] = useState(tariffs[0]);
  const handleClose = useCloseModal(NEW_CONSULTATION_TARIFFS_MODAL);
  const handleOpenNewConsultation = useReplaceModal(
    NEW_CONSULTATION_TARIFFS_MODAL,
    NEW_CONSULTATION_MODAL
  );

  const handleOpenSymptoms = useReplaceModal(
    NEW_CONSULTATION_TARIFFS_MODAL,
    SYMPTOMS_VIEW,
    {
      tariffId: selectedTariff.id,
      slotId: selectedSlot.id,
      doctor,
      tariffType:
        selectedTariff.type === SLOT_TYPES.items.offline.type
          ? selectedTariff.type
          : SLOT_TYPES.items.online.type
    }
  );

  // Хак для правого отступа в блоке и для кнопки записаться на прием
  // Из-за запар с тенями и невидимым скролом
  const isHackScroll = tariffs.length > 3;

  return (
    <Dialog
      header="Выберите тариф"
      onBackClick={handleOpenNewConsultation}
      onCloseClick={handleClose}
      boxClassName={styles.modal_tariff}
      bodyClassName={styles.modal_tariff}
      testId={NEW_CONSULTATION.SELECT_TARIFF_MODAL}
    >
      <Scrollbar
        autoHeight
        autoHeightMin={232}
        autoHeightMax={400}
        className={styles.tariffs_scrollbar}
      >
        <div
          className={cn(styles.tariffs_list, {
            [styles.single_tariff]: tariffs.length === 1
          })}
        >
          {tariffs.map(tariff => {
            const isActive = tariff.id === selectedTariff.id;
            const handleChangeTariff = () => setSelectedTariff(tariff);

            return (
              <button
                key={tariff.id}
                type="button"
                onClick={handleChangeTariff}
                data-testid={NEW_CONSULTATION.TARIFF_CLICKABLE_BOX}
              >
                <Scrollbar
                  className={cn(styles.tariff_box_wrapper, {
                    [styles.active]: isActive
                  })}
                >
                  <div className={styles.tariff_box}>
                    <div>
                      <Text variant="h4" className={styles.tariff_margin}>
                        {tariff.name}
                      </Text>
                      <Text
                        variant="desc"
                        colorVariant={"tertiary"}
                        className={styles.tariff_desc}
                      >
                        {tariff.description}
                      </Text>
                    </div>
                    <div>
                      <Text variant="h4" className={styles.tariff_margin}>
                        {`${tariff.price} ₽`}
                      </Text>
                      <Text variant="desc" colorVariant={"tertiary"}>
                        {tariff.duration >= MINUTES_IN_DAY
                          ? `Длительность ${pluralizeDays(
                              tariff.duration / MINUTES_IN_DAY
                            )}`
                          : `Длительность ${tariff.duration} мин`}
                      </Text>
                    </div>
                  </div>
                </Scrollbar>
              </button>
            );
          })}
        </div>
      </Scrollbar>
      <div
        className={cn(styles.button_approve, {
          [styles.button_approve_hack]: isHackScroll
        })}
      >
        <Button
          fullWidth
          colorVariant="patient"
          onClick={handleOpenSymptoms}
          testid={NEW_CONSULTATION.BUTTON_ACCEPT_APPOINTMENT}
        >
          Записаться на приём
        </Button>
      </div>
    </Dialog>
  );
};

export default SelectTariff;
