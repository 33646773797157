import React from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./FadeItem.scss";

const FadeItem = ({ show, children }) => (
  <CSSTransition in={show} timeout={0} unmountOnExit classNames={{ ...styles }}>
    {children}
  </CSSTransition>
);

export default FadeItem;
