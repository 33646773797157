import React from "react";
import { Dialog } from "ibolit-ui";
import { useDispatch } from "react-redux";
import { useCloseModal } from "~/hooks/useModal";
import { UserInfo } from "~/layouts/Authorization/screens/UserInfo";
import uiActions from "~/store/ui/uiActions";
import { FROM_WIDGET_NEW_PASSWORD } from "~/modals/FromWidget/components/NewPassword/NewPassword";
import styles from "./UserInfo.scss";

export const FROM_WIDGET_USER_INFO_VIEW = "from_widget_user_info_view";

const UserInfoModal = props => {
  const dispatch = useDispatch();
  const onCloseClick = useCloseModal(FROM_WIDGET_USER_INFO_VIEW);
  const onSubmit = () => {
    if (props.hasUserInfo) {
      dispatch(
        uiActions.replaceModal(
          FROM_WIDGET_USER_INFO_VIEW,
          FROM_WIDGET_NEW_PASSWORD
        )
      );
    } else {
      onCloseClick();
    }
  };

  return (
    <Dialog
      header={"Укажите ваши данные"}
      onCloseClick={props.hasUserInfo ? onCloseClick : undefined}
      boxClassName={styles.box}
    >
      <UserInfo onSubmit={onSubmit} />
    </Dialog>
  );
};

export default UserInfoModal;
