import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from 'ibolit-ui';
import styles from './CallRoomExitModal.scss';

const CallRoomExitModal = props => {
  return (
    <Box className={styles.callRoomExitModal}>
      <h3>Завершить звонок?</h3>
      <p>Вы сможете вернуться в консультацию из раздела “приемы”</p>
      <div className={styles.actions}>
        <Button
          className={styles.positiveButton}
          variant="secondary"
          colorVariant="danger"
          onClick={props.onPositiveClick}
        >
          Да
        </Button>
        <Button variant="tertiary" onClick={props.onNegativeClick}>
          Нет
        </Button>
      </div>
    </Box>
  );
};

CallRoomExitModal.propTypes = {
  onPositiveClick: PropTypes.func,
  onNegativeClick: PropTypes.func,
};

export const CALL_ROOM_EXIT_VIEW = 'callRoomExitModal';
export default CallRoomExitModal;
