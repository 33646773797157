import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import { Avatar } from "ibolit-ui";
import splitUserName from "~/utils/splitUserName";
import isDefaultAvatar from "~/utils/isDefaultAvatar";
import styles from "./UserAvatar.scss";

const UserAvatar = ({
  user: { full_name, avatar, id, online } = {},
  showOnlineStatus,
  size,
  ...props
}) => {
  const [firstName, lastName] = splitUserName(full_name);
  return (
    <Avatar
      id={id}
      firstName={firstName}
      lastName={lastName}
      srcImg={isDefaultAvatar(avatar) ? undefined : avatar}
      className={cn({
        [styles.container]: showOnlineStatus !== undefined,
        [styles[size]]: showOnlineStatus !== undefined,
        online
      })}
      size={size}
      {...props}
    />
  );
};

UserAvatar.propTypes = {
  user: PropTypes.shape({
    full_name: PropTypes.string,
    avatar: PropTypes.string,
    id: PropTypes.number.isRequired
  }).isRequired,
  showOnlineStatus: PropTypes.bool
};

export default UserAvatar;
