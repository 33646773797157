import { DEFAULT_SEARCH_ITEMS_LIMIT } from "~/consts/values";

export const mergeIds = (
  ids = [],
  order = [],
  page = 1,
  limit = DEFAULT_SEARCH_ITEMS_LIMIT
) => [...ids.slice(0, (page - 1) * limit), ...order];

export const searchSuccessReducer = (
  state,
  {
    payload: { order, items = {}, ...payload } = {},
    request: { page, mode, params, limit = DEFAULT_SEARCH_ITEMS_LIMIT }
  }
) => {
  let newState = {
    ...payload,
    page,
    params,
    error: null,
    isFetching: false,
    initialFetched: true
  };
  if (order) {
    newState.order = mergeIds(
      state.getIn((mode ? [mode] : []).concat("order")),
      order,
      page,
      limit
    );
    newState.canLoadMore = order.length === limit;
  }
  if (mode) newState = { [mode]: newState };
  return state.merge(
    {
      items,
      ...newState
    },
    { deep: true }
  );
};

export const searchRequestReducer = (state, { request }) => {
  let newState = {
    params: {},
    canLoadMore: true,
    isFetching: true,
    error: null
  };
  if (request.mode) newState = { [request.mode]: newState };
  return state.merge(newState, { deep: true });
};

export const searchFailureReducer = (state, { error, request }) => {
  let newState = {
    error,
    request,
    isFetching: false,
    canLoadMore: false
  };
  if (request.mode) newState = { [request.mode]: newState };
  return state.merge(newState, { deep: true });
};
