import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "ibolit-ui";
import { getCurrentSymptom } from "~/store/newConsultation/newConsultationSelectors";
import Loading from "~/components/Loading/Loading";
import invoicesApi from "~/api/invoicesApi";
import toastActions from "~/store/toast/toastActions";
import PaymentIFrame from "./PaymentIFrame";
import { useAction } from "~/hooks/useAction";
import { ERROR_REQUEST_DEFAULT } from "~/consts/texts";
import { useCloseModal } from "~/hooks/useModal";
import styles from "./Payment.scss";

export const PAYMENT_IFRAME_VIEW = "PAYMENT_IFRAME_VIEW";

const InvoicedPayment = ({
  serviceId,
  consultationId,
  promotion_code_id,
  promoModalProps,
  ...otherProps
}) => {
  const [uri, setUri] = useState(false);
  const showErrorMessage = useAction(toastActions.showErrorToast);
  const currentSymptom = useSelector(getCurrentSymptom);
  const closeModal = useCloseModal(PAYMENT_IFRAME_VIEW);
  useEffect(() => {
    const params = consultationId
      ? {
          service_id: serviceId,
          extra: {
            consultation_id: consultationId,
            problem_id: currentSymptom?.id,
            promotion_code_id
          }
        }
      : {
          service_id: promoModalProps.tariffId,
          extra: { problem_id: currentSymptom?.id, promotion_code_id }
        };

    invoicesApi
      .fetchUri(params)
      .then(({ data }) => {
        setUri(data.url);
      })
      .catch(() => {
        closeModal();
        showErrorMessage(ERROR_REQUEST_DEFAULT);
      });
  }, []);

  return uri ? (
    <PaymentIFrame uri={uri} {...otherProps} />
  ) : (
    <Box className={styles.boxLoading}>
      <Loading showLoading />
    </Box>
  );
};

export default InvoicedPayment;
