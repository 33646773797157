import axiosInstance from "./httpService";

export default {
  fetchConsultations: params =>
    axiosInstance.get(`/v3/user/consultations/patient`, { params }),
  getConsultation: id => axiosInstance.get(`/v3/user/consultation/${id}`),
  createConsultation: params =>
    axiosInstance.post("/v3/user/consultation/patient", params),
  uploadFile: ({ payload, onProgress, cancelToken }) =>
    axiosInstance.post(`/v3/upload-file`, payload, {
      onUploadProgress(progressEvent) {
        onProgress(progressEvent);
      },
      cancelToken,
      timeout: 0
    }),
  linkUploadFile: ({ consultationId, tempFileId }) =>
    axiosInstance.post(`/v3/user/consultation/${consultationId}/file`, {
      temp_file_id: tempFileId
    }),
  deleteFile: ({ fileId }) =>
    axiosInstance.delete(`/v3/user/consultation/file/${fileId}`),
  getSymptoms: () => axiosInstance.get("/v3/patients/problems"),
  createSymptom: params => axiosInstance.post("/v3/patients/problems", params),
  updateSymptom: ({ symptomId, params }) =>
    axiosInstance.put(`/v3/patients/problems/${symptomId}`, params),
  linkSymptomToConsultation: ({ symptomId, consultationId, painLevel }) =>
    axiosInstance.put(`/v3/user/consultation/${consultationId}/problem`, {
      patient_problem_id: symptomId,
      discomfort_level: painLevel
    }),
  sendFeedback: ({ isNew, consultationId, rating, comment }) => {
    const method = isNew ? axiosInstance.post : axiosInstance.put;

    return method(`/v3/user/consultation/${consultationId}/rating`, {
      rating,
      comment
    });
  },
  cancelConsultation: consultationId =>
    axiosInstance.post(`v3/user/consultation/${consultationId}/patient/cancel`),
  downloadFile: url =>
    axiosInstance.get(url, {
      responseType: "blob"
    }),
  sendOrUpdateChatFeedback: ({ isNew, consultationId, rating, comment }) => {
    const method = isNew ? axiosInstance.post : axiosInstance.put;

    return method(`/v3/user/service/${consultationId}/rating`, {
      rating,
      comment
    });
  }
};
