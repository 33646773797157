import PropTypes from 'prop-types';
import React from 'react';
import ModalBG from './ModalBG';
import styles from './Modal.scss';

export default function ModalContainer({ onClick, children }) {
  return (
    <div className={styles.root}>
      <ModalBG onClick={onClick} />
      <div className={styles.content}>{children}</div>
    </div>
  );
}

ModalContainer.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
};
