import Types from "~/store/users/userTypes";
import authTypes from "~/store/auth/authTypes";
import {
  reducer as requestReducer,
  state as requestState
} from "~/store/users/userModel";
import { composeReducers } from "~/utils/redux/compose";

const initialState = requestState.merge({
  selectedTariff: {},
  tariffsFetching: false,
  infoFetching: false,
  infoErrors: {}
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.TARIFFS_REQUEST:
      return state.merge({ tariffsFetching: true });
    case Types.TARIFFS_SUCCESS:
      return state.merge({ tariffsFetching: false });
    case Types.TARIFFS_FAILURE:
      return state.merge({
        tariffsFetching: false
      });

    case Types.USER_INFO_REQUEST:
      return state.merge({
        infoFetching: true,
        infoErrors: {}
      });
    case Types.USER_INFO_SUCCESS:
      return state.merge({
        infoFetching: false,
        infoErrors: {}
      });
    case Types.USER_INFO_FAILURE:
      return state.merge({
        infoFetching: false,
        infoErrors: action.errors
      });

    case Types.USER_CHECK_NEW_SUCCESS:
      return state.setIn(["items", action.request.id, "is_new"], false);

    case Types.TARIFF_SELECT:
      return state.set("selectedTariff", action.tariff);

    case authTypes.LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default composeReducers(reducer, requestReducer);
