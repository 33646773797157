import { normalize } from "normalizr";
import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { replace } from "connected-react-router";
import profileActions from "~/store/profile/profileActions";
import usersActions from "~/store/users/userActions";
import clinicsActions from "~/store/clinics/clinicsActions";
import authTypes from "~/store/auth/authTypes";
import messagesActions from "~/store/messages/messagesActions";
import consultationsActions from "~/store/consultation/consultationActions";
import chatsActions from "~/store/chat/chatActions";
import documentsActions from "~/store/documents/documentsActions";
import conclusionsActions from "~/store/conclusions/conclusionActions";
import webSocketTypes from "~/store/webscoket/wsTypes";
import messagesTypes from "~/store/messages/messagesTypes";
import { chatSchema } from "~/store/chat/chatNormalizer";
import usersTypes from "~/store/users/userTypes";
import consultationsTypes from "~/store/consultation/consultationTypes";
import { isNotFoundRequest } from "~/utils/notFound";
import chatsTypes from "~/store/chat/chatTypes";
import toolbarActions from "~/store/toolbar/toolbarActions";
import pushNotificationAction from "~/store/pushNotifications/pushNotificationActions";
import commonTypes from "~/store/common/commonTypes";
import commonActions from "~/store/common/commonActions";
import logger from "~/utils/Logger";
import subscriptionsActions from "~/store/subscriptions/subscriptionsActions";

export function* onSuccessAuth() {
  yield put(profileActions.userDataLoadRequest());
  yield put(toolbarActions.getToolbar());
  yield put(pushNotificationAction.registration());
}

function* processSocketMessage({ event }) {
  const { id: chatId } = event;
  const {
    entities: {
      messages,
      chats: {
        [chatId]: {
          messages: [last_message],
          ...chat
        },
        ...chats
      },
      ...rest
    }
  } = normalize(event, chatSchema);
  if (messages[last_message].type !== "system") {
    chat.last_message = messages[last_message];
  }

  rest.chats = { ...chats, [chatId]: chat };

  yield put(messagesActions.updateItems(messages));
  yield put(commonActions.updateItems(rest));
}

function* processFailSendMessage({ error, request }) {
  if (error && error.status === 403) {
    yield put(chatsActions.infoRequest(request.chatId));
  }
}

// for now - force set "count_new" to 0
function* processReadingNewMessages({ request }) {
  const chats = Object.entries(request).reduce((acc, [chatId]) => {
    acc[chatId] = { count_new: 0 };
    return acc;
  }, {});
  yield put(chatsActions.updateItems(chats));
}

function* checkRequestNotFound(href, { error }) {
  if (isNotFoundRequest(error)) {
    yield put(replace(href));
  }
}

function* processCheckNewUserSaga() {
  try {
    const { doctors } = yield select(({ toolbar }) => toolbar);
    if (doctors > 0) {
      yield put(toolbarActions.updateToolbar({ doctors: doctors - 1 }));
    }
  } catch (e) {
    yield put({ type: `@@err/PROCESS_CHECK_NEW_USER_SAGA`, e });
  }
}

const updateActionsMap = {
  users: usersActions.updateItems,
  chats: chatsActions.updateItems,
  documents: documentsActions.updateItems,
  conclusions: conclusionsActions.updateItems,
  clinics: clinicsActions.updateItems,
  messages: messagesActions.updateItems,
  consultations: consultationsActions.updateItems,
  subscriptions: subscriptionsActions.updateItems
};

export function* updateItems({ items }) {
  for (let key of Object.keys(items)) {
    const action = updateActionsMap[key];
    if (action === undefined) {
      logger.warn(`updateItemsError: Need action for ${key}`);
      continue;
    }
    yield put(action(items[key]));
  }
}

export default [
  takeLatest(authTypes.USER_AUTHORIZE_SUCCESS, onSuccessAuth),
  takeLatest(messagesTypes.SEND_FAILURE, processFailSendMessage),
  takeLatest(
    messagesTypes.READING_NEW_MESSAGE_SUCCESS,
    processReadingNewMessages
  ),
  takeLatest(usersTypes.USER_CHECK_NEW_SUCCESS, processCheckNewUserSaga),
  takeLatest(usersTypes.USER_INFO_FAILURE, checkRequestNotFound, "/clinics"),
  takeLatest(
    consultationsTypes.CONSULTATIONS_INFO_GET_FAILURE,
    checkRequestNotFound,
    "/consultations"
  ),
  takeEvery(commonTypes.UPDATE_ITEMS, updateItems),
  takeLatest(chatsTypes.INFO_FAILURE, checkRequestNotFound, "/chats"),
  takeEvery(webSocketTypes.WS_CHAT_MESSAGE, processSocketMessage)
];
