export const CLINICS_DETAILS_TABS = "clinics__details_tabs";
export const CLINIC_DETAILS_DOCTORS_SEARCH = "clinics__details_doctors_search";
export const CLINIC_DETAILS_DOCTORS_ITEM = "clinics__details_doctros_item";

export const CLINICS_PRIMARY_LIST = "clinics__primary_list";
export const CLINICS_PRIMARY_LIST_ITEM = "clinics__primary_list_item";
export const CLINICS_PRIMARY_LIST_ITEM_BUTTON =
  "clinics__primary_list_item_button";

export const CLINICS_LIST_ITEM = "clinics__list_item";
