import React from "react";
import { Text } from "ibolit-ui";
import styles from "./Speciality.scss";

const Speciality = ({ text }) => {
  return (
    <Text
      variant="h6"
      colorVariant="tertiary"
      className={styles.speciality}
      testid="Speciality__text"
    >
      {text}
    </Text>
  );
};

export default Speciality;
