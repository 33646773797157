export const getUser = state => state.auth.user;
export const getLogin = state => state.auth.login;
export const getCode = state => state.auth.code;
export const getStatus = state => state.auth.status;
export const getCurrentUserId = state => state.auth.currentUserId;
export const getAuthPassword = state => state.auth.password;
export const getTimeout = state => state.auth.timeout;
export const getAuthError = state => state.auth.error;
export const getAuthStep = state => state.auth.step;
export const getIsAuthFetching = state => state.auth.isFetching;

export const getIsUserAuthorized = state => !!state.auth.currentUserId;
