import React from "react";
import { useSelector } from "react-redux";
import { getClinicById } from "~/store/clinics/clinicsSelectors";
import ClinicDetails from "~/layouts/Clinics/ClinicDetails/ClinicDetails";

const ClinicDetailsContainer = ({ clinicId }) => {
  const clinic = useSelector(getClinicById(clinicId));

  return clinic ? <ClinicDetails key={clinicId} clinic={clinic} /> : null;
};

export default ClinicDetailsContainer;
