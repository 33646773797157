import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { connect, useSelector } from "react-redux";
import { Avatar, Box, Textarea, Button } from "ibolit-ui";
import consultationsActions from "~/store/consultation/consultationActions";
import isDefaultAvatar from "~/utils/isDefaultAvatar";
import ConsultationFeedbackRanking, {
  RANKINGS
} from "~/modals/ConsultationFeedback/components/ConsultationFeedbackRanking/ConsultationFeedbackRanking";
import styles from "./ConsultationFeedback.scss";

const STEPS = {
  Initial: "initial",
  Comment: "comment"
};

const MAX_COMMENT_LENGTH = 1000;

const initialState = {
  step: STEPS.Initial,
  ranking: undefined,
  comment: ""
};

function initReducer({ rating }) {
  return rating
    ? {
        ...initialState,
        ranking: rating.rating,
        comment: rating.comment || ""
      }
    : initialState;
}

function reducer(state, { type, payload }) {
  switch (type) {
    case "FIELD_CHANGE":
      return { ...state, [payload.field]: payload.value };

    default:
      return initialState;
  }
}

export function ConsultationFeedback({
  consultationId,
  doctorInfo,
  rating,
  sendFeedback,
  isFetchingFeedback,
  externalId,
  messageId,
  isFromChat = false
}) {
  const [state, dispatch] = useReducer(reducer, { rating }, initReducer);
  function handleRankingClick(ranking) {
    dispatch({
      type: "FIELD_CHANGE",
      payload: { field: "ranking", value: ranking }
    });
  }

  function handleSubmit() {
    if (state.step === STEPS.Initial) {
      dispatch({
        type: "FIELD_CHANGE",
        payload: { field: "step", value: STEPS.Comment }
      });
      return;
    }

    sendFeedback({
      isNew: !rating,
      consultationId,
      rating: state.ranking,
      comment: state.comment,
      isFromChat,
      externalId,
      messageId
    });
  }

  function handleCommentChange(value) {
    if (value.length > MAX_COMMENT_LENGTH) return;

    dispatch({ type: "FIELD_CHANGE", payload: { field: "comment", value } });
  }

  const name = doctorInfo.full_name.split(" ");
  return (
    <Box
      testId="ConsultationFeedback"
      className={cn(styles.feedbackContainer, {
        [styles.secondStep]: state.step === STEPS.Comment
      })}
    >
      {state.step === STEPS.Initial && (
        <>
          <h1
            className={styles.header}
            data-testId="ConsultationFeedback__rate"
          >
            Оцените консультацию
          </h1>
          <div className={styles.doctor}>
            <Avatar
              id={doctorInfo.id}
              firstName={name[0]}
              lastName={name[name.length - 1]}
              srcImg={
                isDefaultAvatar(doctorInfo.avatar)
                  ? undefined
                  : doctorInfo.avatar
              }
              size="medium"
            />
            <div className={styles.doctorText}>
              <h3 data-testid="ConsultationFeedback__doctorInfo_fullName">
                {doctorInfo.full_name}
              </h3>
              <p
                className={styles.doctorSpecialties}
                data-testid="ConsultationFeedback__doctorInfo_specialties"
              >
                {doctorInfo.specialties.join(", ")}
              </p>
            </div>
          </div>
          <p
            className={styles.description}
            data-testid="ConsultationFeedback__description"
          >
            Ваша оценка поможет улучшить наш сервис
          </p>
          <ConsultationFeedbackRanking
            ranking={state.ranking}
            onClick={handleRankingClick}
          />
        </>
      )}
      {state.step === STEPS.Comment && (
        <>
          <h1
            className={styles.header}
            data-testid="ConsultationFeedback__yourRate"
          >
            <img
              src={RANKINGS.find(({ value }) => value === state.ranking).img}
              alt="satisfaction icon"
            />
            Ваша оценка
          </h1>
          <p
            className={styles.description}
            data-testid="ConsultationFeedback__yourRate_desc"
          >
            {state.ranking < 4
              ? "Расскажите нам, что вам не понравилось?"
              : "Расскажите нам, что вам понравилось?"}
          </p>
          <div className={styles.textAreaContainer}>
            <Textarea
              className={styles.textArea}
              autosize
              placeholder="Написать комментарий"
              rows={3}
              fullWidth
              value={state.comment}
              onChange={handleCommentChange}
            />
            <div
              className={styles.count}
              data-testId="ConsultationFeedback__count"
            >{`${state.comment.length}/${MAX_COMMENT_LENGTH}`}</div>
          </div>
        </>
      )}
      {((state.step === STEPS.Initial && state.ranking) ||
        state.step === STEPS.Comment) && (
        <Button
          className={styles.button}
          colorVariant="patient"
          fullWidth
          disabled={isFetchingFeedback}
          onClick={handleSubmit}
          testid="ConsultationFeedback__send"
        >
          Отправить
        </Button>
      )}
    </Box>
  );
}

ConsultationFeedback.propTypes = {
  consultationId: PropTypes.number,
  doctorInfo: PropTypes.shape({
    avatar: PropTypes.string,
    full_name: PropTypes.string,
    id: PropTypes.number,
    specialties: PropTypes.arrayOf(PropTypes.string)
  }),
  rating: PropTypes.shape({
    comment: PropTypes.string,
    created_at: PropTypes.string,
    id: PropTypes.number,
    rating: PropTypes.number,
    slot_patient_id: PropTypes.number,
    updated_at: PropTypes.string
  }),
  sendMetrics: PropTypes.func,
  // From store
  isFetchingFeedback: PropTypes.bool,
  sendFeedback: PropTypes.func
};

const mapStateToProps = state => ({
  isFetchingFeedback: state.consultations.consultationFeedbackLoading
});

const mapDispatchToProps = {
  sendFeedback: consultationsActions.sendFeedback
};

export const CONSULTATION_FEEDBACK_VIEW = "consultationFeedbackModal";
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultationFeedback);
