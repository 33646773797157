import Types from "./messagesTypes";

export default {
  searchRequest: (chatId, partner, page, search, mode, limit = 30) => ({
    type: Types.SEARCH_REQUEST,
    request: { partner, chatId, page, search, mode, limit }
  }),
  searchSuccess: (payload, request = {}) => ({
    type: Types.SEARCH_SUCCESS,
    payload,
    request
  }),
  searchFailure: (error, request) => ({
    type: Types.SEARCH_FAILURE,
    error,
    request
  }),

  readingNewMessageRequest: (receiver_id, chat_id, message_ids) => ({
    type: Types.READING_NEW_MESSAGE_REQUEST,
    request: { receiver_id, chat_id, message_ids }
  }),
  readingNewMessageSuccess: (payload, request = {}, rest) => ({
    type: Types.READING_NEW_MESSAGE_SUCCESS,
    payload,
    request,
    rest
  }),
  readingNewMessageFailure: (error, request) => ({
    type: Types.READING_NEW_MESSAGE_FAILURE,
    error,
    request
  }),

  sendRequest: (chatId, userId, message, messageData) => ({
    type: Types.SEND_REQUEST,
    request: { chatId, userId, message, messageData }
  }),

  sendFailure: (error, request) => ({
    type: Types.SEND_FAILURE,
    error,
    request
  }),

  addOwnMessage: message => ({
    type: Types.ADD_OWN_ITEM,
    message
  }),

  updateItems: (payload, rest) => ({
    type: Types.UPDATE_CHATS_MESSAGES,
    payload,
    rest
  }),

  updateMessageAction: (rating, comment, externalId, messageId) => ({
    type: Types.UPDATE_MESSAGE_RATING,
    rating,
    comment,
    externalId,
    messageId
  })
};
