import { takeLatest, call, put, all } from "redux-saga/effects";
import { normalize } from "normalizr";
import api from "~/api/clinicsApi";
import Types from "~/store/clinics/clinicsTypes";
import Actions from "~/store/clinics/clinicsActions";
import commonActions from "~/store/common/commonActions";
import toastActions from "~/store/toast/toastActions";
import { clinicSchema } from "~/store/clinics/clinicsNomalizer";
import { ERROR_REQUEST_DEFAULT } from "~/consts/texts";
import { modes } from "~/store/clinics/clinicsModel";

const switchRequest = mode => {
  switch (mode) {
    case modes[0]:
      return api.clinicList; // CLINICS_MODE_ALL
    case modes[1]:
      return api.clinicListForSlots; // CLINICS_MODE_SELF_APPOINTMENT

    default:
      return api.clinicList;
  }
};

function* sendRequestSaga({ payload }) {
  const { page, limit, mode } = payload;
  const request = switchRequest(mode);

  try {
    const { data } = yield call(request, {});
    const { result: order, entities } = normalize(data, [clinicSchema]);
    yield put(commonActions.updateItems(entities));
    yield put(Actions.requestSuccess({ page, limit, order, mode }));
  } catch (error) {
    yield put(Actions.requestFailed({ page, limit, mode, errors: error }));
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
  }
}

export default function*() {
  yield all([takeLatest(Types.REQUEST_SEND, sendRequestSaga)]);
}
