import cn from 'classnames';
import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './Input.scss';
import Icon from '../Icon';

const Input = ({
  type,
  placeholder,
  onChange,
  value,
  fullWidth,
  className,
  nativeChange,
  disabled,

  name,
  focus,
  secured: defaultSecured = true,
  inputBinds: { ref, ...inputBinds },
  containerClass,
  testidProps,
  autocomplete,
  maxlength,
  readOnly,
}) => {
  const inputRef = ref || useRef(null);
  const [secured, setSecure] = useState(defaultSecured);

  const isPassword = type === 'password';

  useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    }
  }, []);

  const toggleSecure = useCallback(
    event => {
      event.preventDefault();
      setSecure(!secured);
      inputRef.current.focus();
    },
    [secured],
  );

  const onInputChange = event => {
    if (nativeChange) {
      onChange(event);
    } else {
      const { target } = event;
      onChange(target.value);
    }
  };

  const [internalType, switcher] = useMemo(() => {
    if (!isPassword) return [type, null];

    const switcherProps = { onClick: toggleSecure };

    if (secured) {
      return ['password', <Icon testid="secure_button" variant="eye" {...switcherProps} />];
    }

    return ['text', <Icon testid="secure_button" variant="crossed-eye" {...switcherProps} />];
  }, [secured]);

  return (
    <div
      className={cn(styles.wrapper, containerClass, {
        [styles.fullWidth]: fullWidth,
      })}
    >
      <input
        readOnly={readOnly}
        ref={inputRef}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        className={cn(
          styles.input,
          {
            [styles.fullWidth]: fullWidth,
            [styles.password]: isPassword,
            [styles.secured]: isPassword && secured,
          },
          className,
        )}
        type={internalType}
        onChange={onInputChange}
        value={value}
        data-testid={testidProps.input}
        autoComplete={autocomplete}
        maxLength={maxlength}
        {...(inputBinds || {})}
      />
      {isPassword && <div className={styles.switcher}>{switcher}</div>}
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  fullWidth: false,
  nativeChange: false,
  disabled: false,
  focus: false,
  inputBinds: {},
  testidProps: {},
};

Input.propTypes = {
  /** Устанавливает к какому типу относится элемент. */
  type: PropTypes.string,
  /** Вытягивает поле ввода на всю ширину родительского контейнера */
  fullWidth: PropTypes.bool,
  /** Устанавливает значение элемента */
  value: PropTypes.string,
  /** Устанавливает обработчик изменения значения элемента */
  onChange: PropTypes.func,
  /** Если true, в обработчик onChange передается аргумент event, вместо value */
  nativeChange: PropTypes.bool,
  /** Если true, блокирует поле ввода */
  disabled: PropTypes.bool,
  /** Определяет уникальное имя элемента формы. */
  name: PropTypes.string,
  /** Устанавливает фоксы при монтировании. */
  focus: PropTypes.bool,
  /** Устанавливает внешние бинды для инпута (ref, onFocus, onBlur, onChange etc). */
  inputBinds: PropTypes.object,
  /** Выводит текст внутри поля формы, который исчезает при получении фокуса. */
  placeholder: PropTypes.string,
  /** Устанавливает имя класса на input элемент */
  className: PropTypes.string,
  /** Устанавливает имя класса на контейнер */
  containerClass: PropTypes.string,
  secured: PropTypes.bool,
  /** Устанавливает уникальные QA идентификторы. */
  testidProps: PropTypes.shape({
    input: PropTypes.string,
  }),
  /** Этот атрибут помогает заполнять поля форм текстом, который был введён в них ранее. */
  autocomplete: PropTypes.string,
  /** Устанавливает максимальное число символов,  которое может быть введено пользователем. */
  maxlength: PropTypes.number,

  readOnly: PropTypes.bool,
};

export default Input;
