import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import uiActions from "~/store/ui/uiActions";
import actions from "~/store/subscriptions/subscriptionsActions";
import Subscriptions from "./Subscriptions";
import {
  getIsSubscriptionLoading,
  getSelectedSubscription
} from "~/store/subscriptions/subscriptionsSelectors";
import { PROFILE_VIEW } from "~/modals/Profile";

export const SUBSCRIPTION_VIEW = "subscriptions";

export default function SubscriptionsContainer() {
  const selected = useSelector(getSelectedSubscription);
  const isLoading = useSelector(getIsSubscriptionLoading);
  const dispatch = useDispatch();
  const onCloseClick = useCallback(
    () => dispatch(uiActions.replaceModal(SUBSCRIPTION_VIEW, PROFILE_VIEW)),
    []
  );
  const onBackClick = useCallback(() => {
    if (selected) {
      dispatch(actions.selectItem(null));
    } else {
      onCloseClick();
    }
  }, [selected]);

  const onDeleteClick = useCallback(
    id => dispatch(actions.deleteRequest(id)),
    []
  );

  const onListItemClick = useCallback(
    item => dispatch(actions.selectItem(item)),
    []
  );
  const fetchSubscriptions = useCallback(
    page => dispatch(actions.searchRequest(page, "all")),
    []
  );

  return (
    <Subscriptions
      selected={selected}
      isLoading={isLoading}
      onBackClick={onBackClick}
      onCloseClick={onCloseClick}
      onDeleteClick={onDeleteClick}
      onListItemClick={onListItemClick}
      fetchSubscriptions={fetchSubscriptions}
    />
  );
}
