import * as React from "react";

import styles from "./ConsultationClinicData.scss";

export const ConsultationClinicData = ({
  clinicName = "Название клиники",
  phone = "+7 (485) 528 - 10 - 10",
  logoImageSrc = "./placeholder.svg",
  clinicId,
}) => {
  const handleAboutClinic = () => {};

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logoImageSrc} alt="doctor image" />
      <div className={styles.clinicDataContainer}>
        <div
          className={styles.clinicName}
          data-testid="Receptions__ConsultationClinicData-clinicName"
        >
          {clinicName}
        </div>
        <div className={styles.phoneDataContainer}>
          <div
            className={styles.phoneLabel}
            data-testid="Receptions__ConsultationClinicData-phoneLabel"
          >
            Телефон регистратуры
          </div>
          <div
            className={styles.phoneValue}
            data-testid="Receptions__ConsultationClinicData-phoneValue"
          >
            {phone}
          </div>
        </div>
      </div>
      <a
        className={styles.button}
        onClick={handleAboutClinic}
        href={`/clinics/${clinicId}?tab=clinic`}
        target="_blank"
        data-testid="ConsultationClinicData__aboutClinic"
      >
        О клинике
      </a>
    </div>
  );
};
