import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Dialog } from "ibolit-ui";
import EmptyStub from "~/components/EmptyStub";
import usersActions from "~/store/users/userActions";
import TariffsSelector from "~/modals/Tariffs/TariffsSelector";
import formatPrice from "~/utils/formatPrice";
import styles from "./Payment.scss";
import {
  getIsUserTariffsLoading,
  getSelectedTariff,
  getUserTariffsByType
} from "~/store/users/userSelectors";
import Loading from "~/components/Loading/Loading";
import { useCloseModal, useReplaceModal } from "~/hooks/useModal";
import { PAYMENT_IFRAME_VIEW } from "./InvoicedPayment";
import { DOCTORS_CHAT_VIEW } from "~/modals/DoctorsChat";
import { SYMPTOMS_VIEW } from "~/modals/NewConsultation/components/Symptoms/Symptoms";
import { PROMO_MODAL_VIEW } from "~/modals/Promo/PromoModal";

export const PAYMENT_VIEW = "payment";

const PaymentModal = ({
  tariffsType,
  getTariffs,
  onSuccess,
  selectTariff,
  loading,
  tariffs,
  selectedTariff,
  userId,
  doctor,
  clinicId,
  onFailure,
  specialtyProps,
  price
}) => {
  useEffect(() => {
    userId && getTariffs(userId, tariffsType);
  }, [userId, tariffsType]);

  const onCloseClick = useCloseModal(PAYMENT_VIEW);
  const onBackClick = useReplaceModal(PAYMENT_VIEW, DOCTORS_CHAT_VIEW, {
    clinicId,
    specialty: specialtyProps
  });
  const showPaymentModal = useReplaceModal(
    PAYMENT_VIEW,
    selectedTariff.can_apply_promocode ? PROMO_MODAL_VIEW : PAYMENT_IFRAME_VIEW,
    {
      serviceId: selectedTariff.id,
      onSuccess,
      onFailure,
      price: selectedTariff.price,
      tariffId: selectedTariff.id,
      promoModalProps: {
        doctor,
        tariffId: selectedTariff.id,
        slotType: tariffsType,
        clinicId,
        price
      }
    }
  );

  useEffect(
    () => () => {
      selectTariff({});
    },
    []
  );

  return (
    <Dialog
      testId="PaymentModal"
      header="Выберите тариф"
      onBackClick={clinicId ? onBackClick : null}
      onCloseClick={onCloseClick}
      bodyClassName={styles.reset}
    >
      {!loading && tariffs.length > 0 ? (
        <>
          <TariffsSelector
            tariffs={tariffs}
            onClick={selectTariff}
            selectedTariff={selectedTariff}
          />
          <div className={styles.buttonWrap}>
            <Button
              colorVariant="success"
              className={styles.submitButton}
              disabled={!selectedTariff.id}
              onClick={showPaymentModal}
              data-testid="select_tariff_pay"
            >
              Оплатить
              {selectedTariff.price
                ? ` ${formatPrice(selectedTariff.price)}`
                : ""}
            </Button>
          </div>
        </>
      ) : (
        renderEmpty(tariffsType, loading)
      )}
      {loading && <Loading showLoading />}
    </Dialog>
  );
};

const renderEmpty = (mode, loading) => (
  <EmptyStub
    text={!loading ? getEmptyTariffText(mode) : "Запрашивем тарифы..."}
    className={styles.emptyStub}
    variant={!loading ? "default" : "loading"}
  />
);

const getEmptyTariffText = mode => {
  switch (mode) {
    case "chat":
      return "Для чатов\nнет доступных тарифов";
    case "av":
      return "Нет доступных тарифов\nаудио-видео консультаций";
    default:
      return "Тарфы не найдены";
  }
};

PaymentModal.propTypes = {
  getTariffs: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  clinicId: PropTypes.number.isRequired,
  tariffs: PropTypes.array,
  onFailure: PropTypes.func,
  onSuccess: PropTypes.func
};

const mapStateToProps = (state, props) => ({
  tariffs: getUserTariffsByType(state, props),
  loading: getIsUserTariffsLoading(state),
  selectedTariff: getSelectedTariff(state)
});

const mapDispatchToProps = {
  getTariffs: usersActions.tariffsRequest,
  selectTariff: usersActions.selectTariff
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
