import React from "react";
import { Label, Select } from "ibolit-ui";
import styles from "./UserInfo.scss";
import locators from "~/locators/auth";

const genderItems = [
  {
    label: "Мужской",
    value: "male"
  },
  {
    label: "Женский",
    value: "female"
  }
];

const GenderInput = ({ gender, setGender }) => (
  <Label title="Пол" className={styles.gender} testid="GenderInput__SEX_SELECT">
    <Select
      value={gender}
      placeholder={"Укажите пол"}
      items={genderItems}
      onChange={setGender}
      testId={locators.GENDER_SELECT}
    />
  </Label>
);

export default GenderInput;
