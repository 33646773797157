import React from "react";
import { Box, Button, Icon, Text } from "ibolit-ui";
import occupied from "../../assets/images/occupied.png";
import styles from "./Statuses.scss";
import { useReplaceModal } from "~/hooks/useModal";
import { NEW_CONSULTATION } from "~/locators/newConsultation";
import { ModalHeader } from "~/components/ModalHeader/ModalHeader";
import { CHOOSE_DATE_TIME } from "~/modals/ChooseDateTime/ChooseDateTime";

const defaultTestId = "StatusOccupied";

export const NEW_CONSULTATION_OCCUPIED_MODAL = "newConsultationOccupiedModal";

export default function StatusOccupied({ doctor, price, slotType, startAt }) {
  const handleClose = useReplaceModal(
    NEW_CONSULTATION_OCCUPIED_MODAL,
    CHOOSE_DATE_TIME
  );

  return (
    <Box
      className={styles.status_box}
      testId={NEW_CONSULTATION.OCCUPIED_STATUS_MODAL}
      isModalBlock
    >
      <div className={styles.header}>
        <Text tag="span" variant="h2" testid="header_date_time_modal_window">
          Запись к врачу
        </Text>
        <div
          className={styles.close}
          data-testid="close_date_time_modal_window"
        >
          <Icon variant="close" fill="var(--grey)" onClick={handleClose} />
        </div>
      </div>
      <ModalHeader
        doctor={doctor}
        price={price}
        slotType={slotType}
        startAt={startAt}
        specialty={doctor.specialty || doctor.specialties[0]}
      />
      <div className={styles.statusImgContainer}>
        <img
          src={occupied}
          alt="Данное время занято"
          className={styles.status_img}
        />
      </div>
      <Text
        tag="h2"
        variant="h2"
        maxLength={60}
        className={styles.status_header}
        testid={NEW_CONSULTATION.OCCUPIED_STATUS_DESC}
      >
        {"Данное время приема занято"}
      </Text>
      <Text
        tag="div"
        variant="body1"
        colorVariant={"tertiary"}
        maxLength={60}
        className={styles.status_desc}
        testid={`${defaultTestId}-you-can-choose`}
      >
        {"Попробуйте выбрать другое время"}
      </Text>
      <Button
        fullWidth
        colorVariant="patient"
        onClick={handleClose}
        testid={NEW_CONSULTATION.OCCUPIED_STATUS_BUTTON}
      >
        Выбрать другое время
      </Button>
    </Box>
  );
}
