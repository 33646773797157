import types from "./wsTypes";

export default {
  addConclusion: event => ({
    type: types.WS_CONCLUSION_ADD,
    event
  }),
  setChatMessage: event => ({
    type: types.WS_CHAT_MESSAGE,
    event
  }),
  updateChat: event => ({
    type: types.WS_CHAT_UPDATE,
    event
  }),
  setToolbarUpdate: event => ({
    type: types.WS_TOOLBAR_UPDATE,
    event
  }),
  consultationStarted: event => ({
    type: types.WS_CONSULTATION_STARTED,
    event
  }),
  consultationEnded: event => ({
    type: types.WS_CONSULTATION_ENDED,
    event
  }),
  consultationPaid: event => ({
    type: types.WS_CONSULTATION_PAID,
    event
  }),
  slotEnrolled: event => ({
    type: types.WS_SLOT_ENROLLED,
    event
  }),
  slotMoved: event => ({
    type: types.WS_SLOT_MOVED,
    event
  }),
  slotCanceled: event => ({
    type: types.WS_SLOT_CANCELED,
    event
  })
};
