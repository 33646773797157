import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import styles from './Tabs.scss';

const Tabs = ({
  values,
  onChange,
  value,
  className,
  shift,
  testid,
  buttonStyles,
  textStyles,
  buttonActiveStyles,
  textActiveStyles,
}) => {
  const extType = val => (value !== 0 && !value ? val : value.constructor(val));
  return (
    <div className={cn(styles.tabs, className)}>
      {Object.entries(values).map(([key, text]) => {
        const active = extType(key) === value;
        let _buttonStyles = buttonStyles;
        let _textStyles = textStyles;

        if (active) {
          _buttonStyles = { ..._buttonStyles, ...buttonActiveStyles };
          _textStyles = { ..._textStyles, ...textActiveStyles };
        }

        return (
          <button
            data-testid={testid && `${testid}__nav-item-${key}`}
            type="button"
            key={`${testid}__nav-item-${key}`}
            onClick={() => onChange(extType(key))}
            className={cn(styles.tab, {
              [styles.active]: active,
              [styles.shift]: shift,
            })}
            style={_buttonStyles}
            data-testid={`chat_aside_tab_${key}`}
          >
            <Text
              variant="h4"
              colorVariant={extType(key) === value ? 'pressing' : undefined}
              style={_textStyles}
            >
              {text}
            </Text>
          </button>
        );
      })}
    </div>
  );
};

Tabs.defaultProps = {
  shift: false,
  buttonStyles: {},
  textStyles: {},
  buttonActiveStyles: {},
  textActiveStyles: {},
};

Tabs.propTypes = {
  /** Список возможных вариантов */
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  /** Индекс активного варианта */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Устанавливает для контейнера имя класса. */
  className: PropTypes.string,
  /** Если true убирает левый padding у первого элемента. */
  shift: PropTypes.bool,
  /** Срабатывает при изменении активного элемента. */
  onChange: PropTypes.func,
  /** Устанавливает уникальный QA идентификтор. */
  testid: PropTypes.string,
  /** Параметр содержит объект с любыми параметрами для кнопки. */
  buttonStyles: PropTypes.object,
  /** Параметр содержит объект с любыми параметрами для текста. */
  textStyles: PropTypes.object,
  /** Устанавливает стили для активного элемента кнопки. */
  buttonActiveStyles: PropTypes.object,
  /** Устанавливает стили для активного элемента текста. */
  textActiveStyles: PropTypes.object,
};

export default Tabs;
